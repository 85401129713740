import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { environment } from "../../environments/environment";
import * as CryptoJS from "crypto-js";

@Injectable()
export class AuthService {
  constructor(private router: Router) {}

  encrypt(message: string,secretkey: string) {
      let encrypted = CryptoJS.AES.encrypt(message, secretkey? secretkey: environment.secretkey).toString();
      return encrypted;
  }

  decrypt(encrypted:string, secretkey: string) {
      let decrypted = CryptoJS.AES.decrypt(encrypted, secretkey? secretkey: environment.secretkey).toString(CryptoJS.enc.Utf8);
      return decrypted;
  }

  getUserInfo() {
    let userInfo = null;
    try {
      var encodedData = localStorage.getItem('authData');
      userInfo = JSON.parse(this.decrypt(encodedData, null));
      // userInfo = JSON.parse(localStorage.getItem("authData"));
      return userInfo;
    }
    catch(err) {
      return userInfo;
    }
  }

  getUserName() {
    var encodedData = localStorage.getItem('authData');
    console.log('encodedData ', encodedData);
    var userInfo = JSON.parse(this.decrypt(encodedData, null));
    console.log('userInfo', userInfo);
    if(userInfo.username){
      return userInfo.username;
    } else {
      return null;
    }
  }
  getUserToken() {
    try{
      console.log('getUserToken');
      var encodedData = localStorage.getItem('authData');
      var userInfo = JSON.parse(this.decrypt(encodedData, null));
      // var authInfo = JSON.parse(localStorage.getItem("authData"));
      if (userInfo == null) {
        userInfo = {
          Token: "",
        };
      }
      console.log("userInfo ", userInfo);
      // const username= userInfo.username;
      return userInfo.Token;
    }
    catch(err) {
      return false;
    }

  }
  getApiKey(){
    try{
      var encodedData = localStorage.getItem('authData');
      var userInfo = JSON.parse(this.decrypt(encodedData, null));
      // var userInfo = JSON.parse(localStorage.getItem("authData"));
      if (userInfo == null) {
        userInfo = {
          ApiKey: "",
        };
      }
      console.log("userInfo ", userInfo);
      // const username= userInfo.username;
      return userInfo.ApiKey;
    }
    catch(err) {
      return false;
    }
  }

  isAuthenticated() {
    // let authData = this.decrypt(localStorage.getItem("authData"),null);
    console.log('isAuthenticated');
    try {
      var encodedData = localStorage.getItem('authData');
      var authData = JSON.parse(this.decrypt(encodedData, null));
      if(authData == null) return false;
      else return true;
    }
    catch(err) {
      // localStorage.clear();
      return false;
    }
  }
  getParentObject() {
    try {
      console.log('getParentObject');
      var encodedData = localStorage.getItem('parentObject');
      console.log(encodedData);
      var parentInfo = JSON.parse(this.decrypt(encodedData, null));
      console.log('parentInfo ', parentInfo);
      return parentInfo;
    }
    catch(err) {
      // localStorage.clear();
      return null;
    }
  }

  getRedirectChannelUrl() {
    console.log('getRedirectChannelUrl');
    var isAuthenticated = this.isAuthenticated();
    console.log(isAuthenticated,':::::::::::::::::::')
    if(isAuthenticated){
      let url, username, userid, token, apikey, userInfo, parentInfo, playerid, usertype, orgname, permittedApps;
      userInfo = this.getUserInfo();
      parentInfo = this.getParentObject();
      console.log(parentInfo);
      console.log(userInfo);
      username = encodeURIComponent(this.encrypt(parentInfo.username, environment.secretkey));
      userid = encodeURIComponent(this.encrypt(userInfo.userId, environment.secretkey));
      token = encodeURIComponent(this.encrypt(userInfo.Token, environment.secretkey));
      apikey = encodeURIComponent(this.encrypt(userInfo.ApiKey, environment.secretkey));
      playerid = encodeURIComponent(this.encrypt(userInfo.idPlayer, environment.secretkey));
      usertype = encodeURIComponent(this.encrypt(userInfo.UserType, environment.secretkey));
      orgname = encodeURIComponent(this.encrypt(userInfo.Name, environment.secretkey));
      permittedApps = encodeURIComponent(this.encrypt(JSON.stringify( userInfo.permittedApps ), environment.secretkey));

      url = environment.channelurl + 'redirect?username=' + username + '&userid=' + userid + '&token=' + token + '&apikey=' + apikey + '&playerid=' + playerid + '&usertype=' + usertype + '&orgname=' + orgname + '&pa=' + permittedApps;
      console.log(url)
      return url;
    }
    else return null;
  }

  getRedirectDashboardUrl() {
    console.log('getRedirectDashboardUrl');
    var isAuthenticated = this.isAuthenticated();
    if(isAuthenticated){
      let url, username, userid, token, apikey, userInfo, parentInfo;
      userInfo = this.getUserInfo();
      parentInfo = this.getParentObject();
      console.log(parentInfo);
      console.log(userInfo);
      username = encodeURIComponent(this.encrypt(parentInfo.username, environment.secretkey));
      userid = encodeURIComponent(this.encrypt(userInfo.idPlayer, environment.secretkey));
      token = encodeURIComponent(this.encrypt(userInfo.Token, environment.secretkey));
      apikey = encodeURIComponent(this.encrypt(userInfo.ApiKey, environment.secretkey));

      url = environment.dashboardurl;
      console.log(url)
      return url;
    }
    else return null;
  }

  getRedirectValleyUrl() {
    console.log('getRedirectValleyUrl');
    var isAuthenticated = this.isAuthenticated();
    if(isAuthenticated){
      let url, username, userid, token, apikey, userInfo, parentInfo;
      userInfo = this.getUserInfo();
      parentInfo = this.getParentObject();
      console.log(parentInfo);
      console.log(userInfo);
      username = encodeURIComponent(this.encrypt(parentInfo.username, environment.secretkey));
      userid = encodeURIComponent(this.encrypt(userInfo.idPlayer, environment.secretkey));
      token = encodeURIComponent(this.encrypt(userInfo.Token, environment.secretkey));
      apikey = encodeURIComponent(this.encrypt(userInfo.ApiKey, environment.secretkey));

      url = environment.valleyurl + '?username=' + username + '&userid=' + userid + '&token=' + token + '&apikey=' + apikey;
      console.log(url)
      return url;
    }
    else return null;
  }

  getRedirectAcademyUrl() {
    console.log('getRedirectAcademyUrl');
    var isAuthenticated = this.isAuthenticated();
    if(isAuthenticated){
      let url, username, userid, token, apikey, userInfo, parentInfo, password,usertype, playerId, orgname, permittedApps;
      userInfo = this.getUserInfo();
      parentInfo = this.getParentObject();
      console.log(parentInfo);
      console.log(userInfo);
      username = encodeURIComponent(this.encrypt(parentInfo.username, environment.secretkey));
      password = encodeURIComponent(this.encrypt(parentInfo.password, environment.secretkey));
      userid = encodeURIComponent(this.encrypt(userInfo.userId, environment.secretkey));
      token = encodeURIComponent(this.encrypt(userInfo.Token, environment.secretkey));
      apikey = encodeURIComponent(this.encrypt(userInfo.ApiKey, environment.secretkey));
      usertype = encodeURIComponent(this.encrypt(userInfo.UserType, environment.secretkey));
      playerId = encodeURIComponent(this.encrypt(userInfo.idPlayer, environment.secretkey));
      orgname = encodeURIComponent(this.encrypt(userInfo.Name, environment.secretkey));
      permittedApps = encodeURIComponent(this.encrypt(JSON.stringify( userInfo.permittedApps ), environment.secretkey));

      // url = environment.academy + '/redirect?username=' + username + '&userid=' + userid + '&token=' + token + '&apikey=' + apikey;
      url = environment.academyurl + 'redirect?username='+ username + '&userid='+ userid + '&token=' + token + '&apikey=' + apikey + '&playerid=' + playerId + '&usertype=' + usertype + '&orgname=' + orgname+ '&pa=' + permittedApps + '&path=liveclasses';
      console.log(url)
      return url;
    }
    else return null;
  }

  getRedirectQuestUrl() {
    console.log('getRedirectQuestUrl');

    var isAuthenticated = this.isAuthenticated();
    if(isAuthenticated){
      let url, username, userid, token, apikey, userInfo, parentInfo, password,usertype, playerId, orgname, permittedApps;
      userInfo = this.getUserInfo();
      console.log("USERID", userInfo);
      parentInfo = this.getParentObject();
      console.log(parentInfo);
      console.log(userInfo);
      username = encodeURIComponent(this.encrypt(parentInfo.username, environment.secretkey));
      password = encodeURIComponent(this.encrypt(parentInfo.password, environment.secretkey));
      userid = encodeURIComponent(this.encrypt(userInfo.userId, environment.secretkey));
      token = encodeURIComponent(this.encrypt(userInfo.Token, environment.secretkey));
      apikey = encodeURIComponent(this.encrypt(userInfo.ApiKey, environment.secretkey));
      usertype = encodeURIComponent(this.encrypt(userInfo.UserType, environment.secretkey));
      playerId = encodeURIComponent(this.encrypt(userInfo.idPlayer, environment.secretkey));
      orgname = encodeURIComponent(this.encrypt(userInfo.Name, environment.secretkey));
      permittedApps = encodeURIComponent(this.encrypt(JSON.stringify( userInfo.permittedApps ), environment.secretkey));

      // url = environment.academy + '/redirect?username=' + username + '&userid=' + userid + '&token=' + token + '&apikey=' + apikey;
      url = environment.questurl  + '?username=' + username + '&userid=' + userid + '&token=' + token + '&apikey=' + apikey + '&idPlayer=' + playerId;
      // url = environment.academyurl + 'redirect?username='+ username + '&userid='+ userid + '&token=' + token + '&apikey=' + apikey + '&playerid=' + playerId + '&usertype=' + userType + '&orgname=' + orgname+ '&pa=' + password ;
      console.log(url)
      return url;
    }
    else return null;
  }

  // baseurl/redirect?username=&userid=&token=&apikey=&playerid=&usertype=&orgname=&pa=&path=liveclasses
  getRedirectParentPortalUrl() {
    console.log('getRedirectParentPortalUrl');

    var isAuthenticated = this.isAuthenticated();
    if(isAuthenticated){
      let url, username, userid, token, apikey, userInfo, parentInfo, password,usertype, playerId, orgname, permittedApps;
      userInfo = this.getUserInfo();
      console.log("USERID", userInfo);
      parentInfo = this.getParentObject();
      console.log(parentInfo);
      console.log(userInfo);
      username = encodeURIComponent(this.encrypt(parentInfo.username, environment.secretkey));
      userid = encodeURIComponent(this.encrypt(userInfo.userId, environment.secretkey));
      token = encodeURIComponent(this.encrypt(userInfo.Token, environment.secretkey));
      apikey = encodeURIComponent(this.encrypt(userInfo.ApiKey, environment.secretkey));
      url = environment.parenturl  + 'redirect?username=' + username + '&userid=' + userid + '&token=' + token + '&apikey=' + apikey;
      console.log(url)
      return url;
    }
    else return null;
  }

}