import { SubscriptionPlanModel } from "../../../models/subscription-plan.model";
import { ItemMapper } from "../../../lib/item-mapper";
import { GiftcardModel } from 'src/app/models/giftcard.model';

export class GiftcardMapper extends ItemMapper<GiftcardModel> {
  //   public imgPrefix = "https://d1vf2jccr14y1d.cloudfront.net/covers/";
  protected mapFromItem(item): GiftcardModel {
    console.log(item);
    const planModel = new SubscriptionPlanModel();
    planModel.planId = item.subscriptionId._id;
    planModel.planName = item.subscriptionId.name;
    planModel.planMonthly = item.subscriptionId.monthlyPrice;
    planModel.playerSlot = item.subscriptionId.playerSlot;
    planModel.stripePriceId = item.subscriptionId.stripePriceId;
    planModel.subscribed = item.subscriptionId.subscribed;
    planModel.currency = item.subscriptionId.currency;
    planModel.interval = item.subscriptionId.interval;
    planModel.apikey = item.subscriptionId.apikey;
    planModel.createdAt = item.subscriptionId.createdAt;
    planModel.active = item.subscriptionId.active;
    planModel.trialDays = item.subscriptionId.trialDays;

    const giftcardModel = new GiftcardModel();
    giftcardModel.status = item.status;
    giftcardModel._id = item._id;
    giftcardModel.amount = item.amount;
    giftcardModel.code = item.code;
    giftcardModel.message = item.message;
    giftcardModel.subscriptionId = planModel;

    console.log(giftcardModel);
    // profileImage
    return giftcardModel;
  }
}
