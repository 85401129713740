import {InjectionToken} from '@angular/core';
import { Observable } from 'rxjs';

export interface BusinessLogicRequirements {
  signupParent(parentData: object): Observable<any>;
  loginParent(): Observable<any>;
  getCountryList(): Observable<any>;
}

export const BusinessRequirementsInjectionToken = new InjectionToken<BusinessLogicRequirements>('school Business Requirements')
