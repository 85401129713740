import {Inject, Component} from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/lib/shared-service';
import {BusinessLogicRequirements, BusinessRequirementsInjectionToken} from '../business-logic.requirements';
import {CodeVerificationStore} from './code-verification.store'

@Component({
    selector: 'app-code-verification',
    templateUrl: './code-verification-controller.component.html',
    styleUrls: ['./code-verification-controller.component.css']
})
export class CodeVerificationControllerComponent {
    constructor(
        @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements,
        private store: CodeVerificationStore,
        private router: Router,
        private _shared: SharedService
    ) {}

    public discountCode = '';
    public errText = '';
    public appliedCode = false;
    public schoolName = 'An Imaginary STEM Institute';

    ngOnInit(){
    }

    checkDiscountCode(e){
      this.discountCode = e.trim();
      this._shared.setSchoolDiscountCode(this.discountCode);
      console.log(this.discountCode)
      this.appliedCode = false;
      this.errText = '';
      this.business.getDiscountInfo(this.discountCode).subscribe(data=>{
        console.log(data);
        this.appliedCode = true;
        this.schoolName = data[0].name;
        this.errText = '';
        this._shared.setSchoolDiscountInfo(data);
      },err=>{
        console.log(err);
        this.appliedCode = false;
        this.errText = 'We could not verify the code you entered. Please check your code again.';
      });
    }
}
