import { Inject, Component } from '@angular/core';
import { BusinessLogicRequirements, BusinessRequirementsInjectionToken } from '../business-logic.requirements';
import { RedirectHomeStore } from './redirect-home.store';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { AuthService } from '../../../lib/auth.service';
import { environment } from '../../../../environments/environment';
import { SharedService } from 'src/app/lib/shared-service';

@Component({
  selector: 'app-redirect-home',
  templateUrl: './redirect-home-controller.component.html',
  styleUrls: ['./redirect-home-controller.component.css']
})
export class RedirectHomeControllerComponent {
  username: any;
  token: any;
  apikey: any;
  idUser: any;
  idPlayer: any;
  redirectPath: any;

  constructor(
    private authService: AuthService,
    private sharedService: SharedService,
    private routeParams: ActivatedRoute,
    private router: Router,
    @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements,
    private store: RedirectHomeStore
  ) {}

  ngOnInit() {
    localStorage.clear();
    console.log('redirecting home...');
    this.routeParams.queryParams.subscribe((params: Params) => {
      console.log('params', params);
      console.log('secret key', environment.secretkey);
      this.username = this.authService.decrypt(decodeURIComponent(params['username']), environment.secretkey);
      this.token = this.authService.decrypt(decodeURIComponent(params['token']), environment.secretkey);
      this.idUser = this.authService.decrypt(decodeURIComponent(params['userid']), environment.secretkey);
      this.apikey = this.authService.decrypt(decodeURIComponent(params['apikey']), environment.secretkey);
      // this.idPlayer = this.authService.decrypt(decodeURIComponent(params['playerid']), environment.secretkey);
      this.redirectPath = params['redirectPath'];
      console.log(this.username);
    });
    if (this.username && this.idUser && this.token && this.apikey) {
      const userObj = {
        username: this.username,
        Token: this.token,
        ApiKey: this.apikey,
        userId: this.idUser
        // idPlayer: this.idPlayer
      };
      let encodedData = this.authService.encrypt(JSON.stringify(userObj), environment.secretkey);
      // this.saveUserInfo('userInfo', encodedData);
      console.log(userObj);
      this.sharedService.setUserInfo(userObj);
      if (this.redirectPath !== 'plans') {
        localStorage.setItem('authData', encodedData);
        localStorage.setItem('parentObject', encodedData);
      }

      console.log('redirect ', this.redirectPath);
      setTimeout(() => {
        if (this.redirectPath && this.redirectPath === 'plans') {
          this.router.navigate(['/signup_userupdate']);
        } else if (this.redirectPath && this.redirectPath !== 'plans') this.router.navigate([`/${this.redirectPath}`]);
        else this.router.navigate(['/dashboard']);
      }, 1000);

      // this.router.navigate(['/dashboard']);
    } else {
      console.log('404');
    }
  }
}

/*
http://localhost:4200/redirect?username=U2FsdGVkX1%2Fjq7UPJ6lMz2Te2Yy4XtQ6t6CJVQVflBQ%3D&userid=U2FsdGVkX18Md0gsfXSZZZQIjW%2BKuljZWGOnN2jJ9LFG4kAUux0%2FH%2BTjnMbs0z2p&token=U2FsdGVkX192A5jx1iqW23JpUHssX%2FAXVHUtTRNsVi4%2FZSUDznPgsdo4BzaD17qg&apikey=U2FsdGVkX18gplixID8xODpaszLPd2t1PYa8aGD835Z3Rg2ZHaug0%2FBW2oiP3zcuReQ0qJqOVq68lDNLE%2F%2Bhog%3D%3D&playerid=U2FsdGVkX1%2FVgFtRPIFirHkym0ayAUKNglvpm927B1Y%3D&usertype=U2FsdGVkX1%2BUtuJSk%2FpwIbVPJVRi0Mv1CmZTUethJYo%3D&redirectPath=plans
 */

// redirect?username=U2FsdGVkX1%2Fjq7UPJ6lMz2Te2Yy4XtQ6t6CJVQVflBQ%3D&userid=U2FsdGVkX18Md0gsfXSZZZQIjW%2BKuljZWGOnN2jJ9LFG4kAUux0%2FH%2BTjnMbs0z2p&token=U2FsdGVkX192A5jx1iqW23JpUHssX%2FAXVHUtTRNsVi4%2FZSUDznPgsdo4BzaD17qg&apikey=U2FsdGVkX18gplixID8xODpaszLPd2t1PYa8aGD835Z3Rg2ZHaug0%2FBW2oiP3zcuReQ0qJqOVq68lDNLE%2F%2Bhog%3D%3D&redirectPath=plans
