import { DataRequirements, } from "../data.requirements";
import * as i0 from "@angular/core";
import * as i1 from "../data.requirements";
export class SignupNewUseCase {
    constructor(data) {
        this.data = data;
    }
    run(parentData) {
        return this.data.registerParentNew(parentData);
    }
}
SignupNewUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SignupNewUseCase_Factory() { return new SignupNewUseCase(i0.ɵɵinject(i1.DataRequirementsInjectionToken)); }, token: SignupNewUseCase, providedIn: "root" });
