import {Inject, Injectable} from '@angular/core';
import { DataRequirementsInjectionToken, DataRequirements } from '../data.requirements';
import { GiftcardMapper } from "../mappers/giftcard-info.mapper";

@Injectable({
    providedIn: 'root'
})
export class ClaimGiftCardUsecase {
    constructor(
        private mapper: GiftcardMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run(id, opt, redir) {
        console.log('usecase')
        return this.data.claimGiftCard(id, opt, redir);
        //return this.data.getGAuth(url);
    }
}
