import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GlobalComponentsModule } from "../../../global-components/global-components.module";
import { SharedComponentsModule } from "../shared-components/shared-components.module";
import { HomeLoginControllerComponent } from "./home-login-controller.component";
// import {FeatherModule} from 'angular-feather';
import { RouterModule } from "@angular/router";
import { LoginModule } from "../login/login.module";

@NgModule({
  declarations: [HomeLoginControllerComponent],
  exports: [HomeLoginControllerComponent],
  imports: [
    CommonModule,
    RouterModule,
    GlobalComponentsModule,
    SharedComponentsModule,
    LoginModule,
  ],
})
export class HomeLoginModule {}
