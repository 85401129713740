/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-not-found-home-controller.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../global-components/query-params-ls/query-params-ls.component.ngfactory";
import * as i3 from "../../../global-components/query-params-ls/query-params-ls.component";
import * as i4 from "../../../global-components/query-params-ls/query-params-ls.store";
import * as i5 from "@angular/router";
import * as i6 from "../../../lib/shared-service";
import * as i7 from "./page-not-found-home-controller.component";
import * as i8 from "../business-logic.requirements";
import * as i9 from "./page-not-found-home.store";
var styles_PageNotFoundHomeControllerComponent = [i0.styles];
var RenderType_PageNotFoundHomeControllerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageNotFoundHomeControllerComponent, data: {} });
export { RenderType_PageNotFoundHomeControllerComponent as RenderType_PageNotFoundHomeControllerComponent };
export function View_PageNotFoundHomeControllerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-query-params-ls", [], null, null, null, i2.View_QueryParamsLsComponent_0, i2.RenderType_QueryParamsLsComponent)), i1.ɵdid(1, 114688, null, 0, i3.QueryParamsLsComponent, [i4.QueryParamsLsStore, i5.ActivatedRoute, i6.SharedService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_PageNotFoundHomeControllerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-not-found-home", [], null, null, null, View_PageNotFoundHomeControllerComponent_0, RenderType_PageNotFoundHomeControllerComponent)), i1.ɵdid(1, 114688, null, 0, i7.PageNotFoundHomeControllerComponent, [i8.BusinessRequirementsInjectionToken, i9.PageNotFoundHomeStore, i5.Router, i5.ActivatedRoute, i6.SharedService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageNotFoundHomeControllerComponentNgFactory = i1.ɵccf("app-page-not-found-home", i7.PageNotFoundHomeControllerComponent, View_PageNotFoundHomeControllerComponent_Host_0, {}, {}, []);
export { PageNotFoundHomeControllerComponentNgFactory as PageNotFoundHomeControllerComponentNgFactory };
