/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../logo/logo.component.ngfactory";
import * as i3 from "../logo/logo.component";
import * as i4 from "../logo/logo.store";
import * as i5 from "../../lib/shared-service";
import * as i6 from "@angular/common";
import * as i7 from "../button/button.component.ngfactory";
import * as i8 from "../button/button.component";
import * as i9 from "../button/button.store";
import * as i10 from "../text/text.component.ngfactory";
import * as i11 from "../text/text.component";
import * as i12 from "../text/text.store";
import * as i13 from "../image/image.component.ngfactory";
import * as i14 from "../image/image.component";
import * as i15 from "../image/image.store";
import * as i16 from "../mobile-profile/mobile-profile.component.ngfactory";
import * as i17 from "../mobile-profile/mobile-profile.component";
import * as i18 from "../mobile-profile/mobile-profile.store";
import * as i19 from "../../lib/auth.service";
import * as i20 from "./header.component";
import * as i21 from "@angular/router";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logo", [], null, null, null, i2.View_LogoComponent_0, i2.RenderType_LogoComponent)), i1.ɵdid(1, 245760, null, 0, i3.LogoComponent, [i4.LogoStore, i5.SharedService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_HeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "d-flex justify-content-between align-item-center mobile-header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_3)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "cursor"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.clickedMenu; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.clickedMenu; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.hamburger; _ck(_v, 5, 0, currVal_2); }); }
function View_HeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "app-button", [["style", "margin-right: 20px;"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickedPortal() !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (_co.enter() !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (_co.leave() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i7.View_ButtonComponent_0, i7.RenderType_ButtonComponent)), i1.ɵdid(1, 49152, null, 0, i8.ButtonComponent, [i9.ButtonStore], { class: [0, "class"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "app-text", [], null, null, null, i10.View_TextComponent_0, i10.RenderType_TextComponent)), i1.ɵdid(3, 114688, null, 0, i11.TextComponent, [i12.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(-1, 0, ["Go to Parent Portal"])), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "app-image", [], null, null, null, i13.View_ImageComponent_0, i13.RenderType_ImageComponent)), i1.ɵdid(6, 114688, null, 0, i14.ImageComponent, [i15.ImageStore], { src: [0, "src"], width: [1, "width"], height: [2, "height"], maxWidth: [3, "maxWidth"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn-parent"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "btn-parent-text"; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.goto; var currVal_3 = "20px"; var currVal_4 = "20px"; var currVal_5 = "20px"; _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_HeaderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "d-flex justify-content-center"], ["style", "cursor: pointer;margin-right: 36px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-text", [["class", "btn-cancel"], ["style", "padding: 8px 12px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickedExplore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_TextComponent_0, i10.RenderType_TextComponent)), i1.ɵdid(2, 114688, null, 0, i11.TextComponent, [i12.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(-1, 0, [" Explore STEMWerkz! "]))], function (_ck, _v) { var currVal_0 = "btn-text-cancel"; _ck(_v, 2, 0, currVal_0); }, null); }
function View_HeaderComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "setting-dropdown"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "outline"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "app-text", [["class", "account"]], null, null, null, i10.View_TextComponent_0, i10.RenderType_TextComponent)), i1.ɵdid(3, 114688, null, 0, i11.TextComponent, [i12.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(-1, 0, ["You are currently logged in with your parent account."])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["style", "padding: 16px 16px 8px 16px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.profilePopup($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "app-text", [["class", "ur-pf"]], null, null, null, i10.View_TextComponent_0, i10.RenderType_TextComponent)), i1.ɵdid(7, 114688, null, 0, i11.TextComponent, [i12.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(-1, 0, ["Your profile"])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["style", "padding: 8px 16px 16px 16px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "app-text", [["class", "logout"]], null, null, null, i10.View_TextComponent_0, i10.RenderType_TextComponent)), i1.ɵdid(11, 114688, null, 0, i11.TextComponent, [i12.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(-1, 0, ["Log out"]))], function (_ck, _v) { var currVal_0 = "account"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "ur-pf"; _ck(_v, 7, 0, currVal_1); var currVal_2 = "logout"; _ck(_v, 11, 0, currVal_2); }, null); }
function View_HeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "d-flex justify-content-between align-item-center mb-24"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-logo", [], null, null, null, i2.View_LogoComponent_0, i2.RenderType_LogoComponent)), i1.ɵdid(2, 245760, null, 0, i3.LogoComponent, [i4.LogoStore, i5.SharedService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 14, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_5)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 11, "div", [["class", "d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_6)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 8, "div", [["class", "profile d-flex justify-content-center align-item-center"]], null, null, null, null, null)), i1.ɵprd(512, null, i6.ɵNgStyleImpl, i6.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(11, 278528, null, 0, i6.NgStyle, [i6.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(12, { "background-image": 0, "background-repeat": 1, "background-size": 2 }), (_l()(), i1.ɵeld(13, 0, null, null, 2, "app-text", [["class", "profile-pw"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.settingDropdown() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_TextComponent_0, i10.RenderType_TextComponent)), i1.ɵdid(14, 114688, null, 0, i11.TextComponent, [i12.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(15, 0, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_7)), i1.ɵdid(17, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.explore; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.explore; _ck(_v, 8, 0, currVal_1); var currVal_2 = _ck(_v, 12, 0, (("url(" + ((_co.parentData == null) ? null : _co.parentData.profileImage)) + ")"), "no-repeat", "cover"); _ck(_v, 11, 0, currVal_2); var currVal_3 = "profile-pw"; _ck(_v, 14, 0, currVal_3); var currVal_5 = _co.setting; _ck(_v, 17, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = ((_co.parentData == null) ? null : _co.parentData.shortName); _ck(_v, 15, 0, currVal_4); }); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "mobile-profile"], ["id", "mobile-profile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-mobile-profile", [], null, [[null, "planChange"], [null, "clickedExplore"], [null, "clickedDetail"], [null, "clickedLogout"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("planChange" === en)) {
        var pd_0 = (_co.planChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("clickedExplore" === en)) {
        var pd_1 = (_co.clickedExplore() !== false);
        ad = (pd_1 && ad);
    } if (("clickedDetail" === en)) {
        var pd_2 = (_co.clickedDetail() !== false);
        ad = (pd_2 && ad);
    } if (("clickedLogout" === en)) {
        var pd_3 = (_co.Logout() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i16.View_MobileProfileComponent_0, i16.RenderType_MobileProfileComponent)), i1.ɵdid(4, 638976, null, 0, i17.MobileProfileComponent, [i18.MobileProfileStore, i5.SharedService, i19.AuthService], { parentData: [0, "parentData"], createdCount: [1, "createdCount"], clickedMenu: [2, "clickedMenu"], zoneList: [3, "zoneList"] }, { planChange: "planChange", clickedExplore: "clickedExplore", clickedDetail: "clickedDetail", clickedLogout: "clickedLogout" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_4)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mobileView; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.parentData; var currVal_2 = _co.createdCount; var currVal_3 = _co.clickedMenu; var currVal_4 = _co.zoneList; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = !_co.mobileView; _ck(_v, 6, 0, currVal_5); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 4440064, null, 0, i20.HeaderComponent, [i21.Router, i5.SharedService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i20.HeaderComponent, View_HeaderComponent_Host_0, { parentData: "parentData", createdCount: "createdCount", zoneList: "zoneList", explore: "explore" }, { openProfile: "openProfile", exploreClick: "exploreClick", portalClick: "portalClick", changePlan: "changePlan" }, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
