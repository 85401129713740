import {DataRequirements} from '../business-logic/data.requirements';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import { DiscountApiService } from './api-services/discount-api.service';
import { Observable } from 'rxjs';

@NgModule({
    imports: [HttpClientModule]
})
export class DataFacade implements DataRequirements {
  constructor(private discountApi: DiscountApiService) {}
  getDiscountInfo(code): Observable<any>{
    return this.discountApi.getDiscounntInfo(code);
  }
}
