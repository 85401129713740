import {Inject, Component} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/lib/auth.service';
import {BusinessLogicRequirements, BusinessRequirementsInjectionToken} from '../business-logic.requirements';
import {SchoolLoginStore} from './school-login.store'

@Component({
    selector: 'app-school-login',
    templateUrl: './school-login-controller.component.html',
    styleUrls: ['./school-login-controller.component.css']
})
export class SchoolLoginControllerComponent {
  public userName="";
  public showRedirectPopup = false;
  public channelUrl = "";
  public valleyUrl = "";
  public academyUrl = "";
  public ClickSignup = false;
  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private store: SchoolLoginStore,
    private router: Router,
    private _authService: AuthService
  ) {}

  ngOnInit() {
    const isAuth = this._authService.isAuthenticated();
    console.log("this.authData ", isAuth);
    if (isAuth) {
      const userInfo = this._authService.getUserInfo();
      const parentInfo = this._authService.getParentObject();
      if(userInfo.UserType == 5){
        this.router.navigateByUrl(`/dashboard`);
      } else if(userInfo.UserType == 3){
        this.router.navigateByUrl("/login");
        this.showRedirectPopup = true;
        this.channelUrl = this._authService.getRedirectChannelUrl();
        this.valleyUrl = this._authService.getRedirectValleyUrl();
        this.academyUrl = this._authService.getRedirectAcademyUrl();
        this.userName = parentInfo.username;
      }
    }
  }
  checkRedirectPopup(data){
    console.log('checkRedirectPopup');
    console.log(data.academyUrl);
    this.showRedirectPopup = true;
    this.channelUrl = data.channelUrl;
    this.valleyUrl = data.valleyUrl;
    this.academyUrl = data.academyUrl;
    this.userName = data.userName;
    //localStorage.removeItem('authData');
  }
  onClickPoupBtn($event){
    console.log('onClickPoupBtn');
    //this.router.navigateByUrl("");
  }
  onClickLogoutBtn($event){
    console.log('onClickLogoutBtn');
    var ref;
    if (localStorage.getItem('ref'))
      ref = localStorage.getItem('ref');
  localStorage.clear();
  localStorage.setItem('ref',ref);
    this.showRedirectPopup = false;
    window.location.reload();
    // this.router.navigateByUrl("/login");
    // this.ngOnInit();
  }
  closeRedirectPopup(){
    this.showRedirectPopup = false;
  }
  buttonClickSignup() {
    this.ClickSignup = true;
  }
}
