import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/subscription-plans-mapper";
import * as i2 from "../data.requirements";
export class GetPlanUsecase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(id) {
        return this.data.getPlan(id).pipe(this.mapper.map);
    }
}
GetPlanUsecase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetPlanUsecase_Factory() { return new GetPlanUsecase(i0.ɵɵinject(i1.SubscriptionMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: GetPlanUsecase, providedIn: "root" });
