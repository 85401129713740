/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./gift-card-shop-home-controller.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../global-components/logo/logo.component.ngfactory";
import * as i3 from "../../../global-components/logo/logo.component";
import * as i4 from "../../../global-components/logo/logo.store";
import * as i5 from "../../../lib/shared-service";
import * as i6 from "@angular/router";
import * as i7 from "../../../global-components/query-params-ls/query-params-ls.component.ngfactory";
import * as i8 from "../../../global-components/query-params-ls/query-params-ls.component";
import * as i9 from "../../../global-components/query-params-ls/query-params-ls.store";
import * as i10 from "./gift-card-shop-home-controller.component";
import * as i11 from "../business-logic.requirements";
var styles_GiftCardShopHomeControllerComponent = [i0.styles];
var RenderType_GiftCardShopHomeControllerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GiftCardShopHomeControllerComponent, data: {} });
export { RenderType_GiftCardShopHomeControllerComponent as RenderType_GiftCardShopHomeControllerComponent };
export function View_GiftCardShopHomeControllerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "header-wrapper bg-s0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "d-flex align-items-center bg-s0 header-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-logo", [], null, null, null, i2.View_LogoComponent_0, i2.RenderType_LogoComponent)), i1.ɵdid(3, 245760, null, 0, i3.LogoComponent, [i4.LogoStore, i5.SharedService], { class: [0, "class"] }, null), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-query-params-ls", [], null, null, null, i7.View_QueryParamsLsComponent_0, i7.RenderType_QueryParamsLsComponent)), i1.ɵdid(7, 114688, null, 0, i8.QueryParamsLsComponent, [i9.QueryParamsLsStore, i6.ActivatedRoute, i5.SharedService], null, null)], function (_ck, _v) { var currVal_0 = "store"; _ck(_v, 3, 0, currVal_0); _ck(_v, 5, 0); _ck(_v, 7, 0); }, null); }
export function View_GiftCardShopHomeControllerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-gift-card-shop-home", [], null, null, null, View_GiftCardShopHomeControllerComponent_0, RenderType_GiftCardShopHomeControllerComponent)), i1.ɵdid(1, 114688, null, 0, i10.GiftCardShopHomeControllerComponent, [i11.BusinessRequirementsInjectionToken, i5.SharedService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GiftCardShopHomeControllerComponentNgFactory = i1.ɵccf("app-gift-card-shop-home", i10.GiftCardShopHomeControllerComponent, View_GiftCardShopHomeControllerComponent_Host_0, {}, {}, []);
export { GiftCardShopHomeControllerComponentNgFactory as GiftCardShopHomeControllerComponentNgFactory };
