import { Component, EventEmitter, Output } from '@angular/core';
import { MailToStore } from './mail-to.store';

@Component({
  selector: 'app-mail-to',
  templateUrl: './mail-to.component.html',
  styleUrls: ['./mail-to.component.css']
})
export class MailToComponent {
  public cross = './assets/images/cancel.svg';

  @Output() closeMailTo = new EventEmitter<any>();
  public closeBox = false;
  constructor(private store: MailToStore) {}
  ngOnInit() {
    this.closeBox = false;
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    window.scrollTo(0, 0);
  }

  close() {
    this.closeBox = true;

    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    document.getElementById('mail-box').classList.add('mail-box-close');
    setTimeout(() => {
      this.closeMailTo.emit();
    }, 100);
  }
}
