import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../data.requirements";
export class ResetPasswordUseCase {
    constructor(data) {
        this.data = data;
    }
    run(token, body) {
        return this.data.resetPassword(token, body);
    }
}
ResetPasswordUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ResetPasswordUseCase_Factory() { return new ResetPasswordUseCase(i0.ɵɵinject(i1.DataRequirementsInjectionToken)); }, token: ResetPasswordUseCase, providedIn: "root" });
