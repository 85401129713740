import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WarningboxStore {

}

export enum WarningboxStoreKeys {

}
