import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { SharedService } from 'src/app/lib/shared-service';
import { PlanTableStore } from './plan-table.store';

@Component({
  selector: 'app-plan-table',
  templateUrl: './plan-table.component.html',
  styleUrls: ['./plan-table.component.css']
})
export class PlanTableComponent {
  @Input() planList;
  @Input() currentPlanObj;
  @Input() mobilePlan;
  @Input() isSchoolDowngrade;
  @Input() remainTrialDay;
  @Input() parentData;
  @Input() typeFromParams;
  @Input() selectedPlan;
  @Input() selectedPlanId;

  @Output() planSelected = new EventEmitter<any>();
  constructor(private store: PlanTableStore, private shared: SharedService) {}

  public noramlTick = 'assets/images/normal-tick.svg';
  public selectedTick = 'assets/images/selected-tick.svg';
  public currentTick = 'assets/images/current-tick.svg';
  public cross = 'assets/images/alert-icon.svg';
  public selectedBg = 'assets/images/selected-bg.svg';
  public hoverBg = 'assets/images/hover-bg.svg';
  // public selectedPlan = '';
  // public selectedPlanId = null;
  public selectedPrice = '';
  public selectedSlot = null;
  public currentSlot = null;
  public mobileView;
  public mobileViewService;

  @HostListener('window:resize', ['$event'])
  onResize(e) {
    this.renderUiForCurrentPlan();
  }

  ngOnInit() {
    this.mobileViewService = this.shared.GetMobileView.subscribe((data) => {
      this.mobileView = data;
    });
    setTimeout(() => {
      this.renderUiForCurrentPlan();
    }, 500);
  }

  ngOnDestroy() {
    this.mobileViewService.unsubscribe();
  }

  renderUiForCurrentPlan() {
    console.log(this.currentPlanObj, this.planList);
    console.log(this.currentPlanObj.planName, this.currentPlanObj.planId);
    this.isSchoolDowngrade = this.planList.filter((plan) => plan.planId == this.currentPlanObj.planId);
    console.log('isSchoolDowngrade', this.isSchoolDowngrade);
    if (
      this.currentPlanObj.planName &&
      this.currentPlanObj.planId &&
      this.isSchoolDowngrade.length > 0 &&
      this.typeFromParams != 'trial'
    ) {
      document.getElementById(this.currentPlanObj.planName).style.background = 'url(assets/images/hover-bg.svg)';
      document.getElementById(this.currentPlanObj.planName).style.backgroundRepeat = 'no-repeat';
      document.getElementById(this.currentPlanObj.planName).style.backgroundPositionX = 'center';
    } else {
      console.log(this.planList, 'else from render');
      this.showDetail(
        this.planList[0].planName,
        this.planList[0].planId,
        this.planList[0].planMonthly,
        this.planList[0].playerSlot
      );
    }
  }

  headerHover(price, rect, value) {
    var height = document.getElementsByClassName('table')[0].clientHeight - 85;
    var width = document.getElementById(price).clientWidth;
    document.getElementById(rect).style.height = height + 'px';
    document.getElementById(rect).style.width = width + 'px';
    document.getElementById(rect).style.display = 'block';
    document.getElementById(value).style.background = 'url(assets/images/hover-bg.svg)';
    document.getElementById(value).style.backgroundRepeat = 'no-repeat';
    document.getElementById(value).style.backgroundPositionX = 'center';
  }

  headerClicked(price, rect, value) {
    var height = document.getElementsByClassName('table')[0].clientHeight - 85;
    var width = document.getElementById(price).clientWidth;
    document.getElementById(rect).style.height = height + 'px';
    document.getElementById(rect).style.width = width + 'px';
    document.getElementById(rect).style.display = 'block';
    document.getElementById(value).style.background = 'url(assets/images/selected-bg.svg)';
    document.getElementById(value).style.backgroundRepeat = 'no-repeat';
    document.getElementById(value).style.backgroundPositionX = 'center';
  }

  headerNormal(rect, value) {
    document.getElementById(rect).style.height = 0 + 'px';
    document.getElementById(rect).style.width = 0 + 'px';
    document.getElementById(rect).style.display = '';
    console.log(value, document.getElementById(value).style.background);
    document.getElementById(value).style.background = '';
    document.getElementById(value).style.backgroundRepeat = '';
  }

  headerEnter(value) {
    if (
      value == 'Solo' &&
      this.selectedPlan != 'Solo' &&
      (this.currentPlanObj.planName != 'Solo' ||
        (this.currentPlanObj.planName == 'Solo' && this.isSchoolDowngrade && this.isSchoolDowngrade.length == 0) ||
        (this.typeFromParams == 'trial' && this.currentPlanObj.planName == 'Solo'))
    ) {
      this.headerHover('solo-price', 'solo-rect', value);
    } else if (
      value == 'Duo' &&
      this.selectedPlan != 'Duo' &&
      (this.currentPlanObj.planName != 'Duo' ||
        (this.currentPlanObj.planName == 'Duo' && this.isSchoolDowngrade && this.isSchoolDowngrade.length == 0))
    ) {
      this.headerHover('duo-price', 'duo-rect', value);
    } else if (
      value == 'Family' &&
      this.selectedPlan != 'Family' &&
      (this.currentPlanObj.planName != 'Family' ||
        (this.currentPlanObj.planName == 'Family' && this.isSchoolDowngrade && this.isSchoolDowngrade.length == 0))
    ) {
      this.headerHover('family-price', 'family-rect', value);
    }
  }

  headerClick(value) {
    if (!this.mobileView) this.clearSelected(value);
    this.selectedPlan = value;
    this.mobilePlan = value;
    console.log(this.selectedPlan);
    console.log(this.currentPlanObj.planName);
    if (!this.mobileView) {
      if (this.currentPlanObj.planName) localStorage.setItem('isUpgrade', this.currentPlanObj.planName);
      if (value == 'Solo') {
        this.headerClicked('solo-price', 'solo-rect', value);
      } else if (value == 'Duo') {
        this.headerClicked('duo-price', 'duo-rect', value);
      } else if (value == 'Family') {
        this.headerClicked('family-price', 'family-rect', value);
      }
    }
  }

  headerLeave(value) {
    if (
      value == 'Solo' &&
      this.selectedPlan != 'Solo' &&
      (this.currentPlanObj.planName != 'Solo' ||
        (this.currentPlanObj.planName == 'Solo' && this.isSchoolDowngrade && this.isSchoolDowngrade.length == 0) ||
        (this.currentPlanObj.planName == 'Solo' && this.typeFromParams == 'trial'))
    ) {
      this.headerNormal('solo-rect', value);
    } else if (
      value == 'Duo' &&
      this.selectedPlan != 'Duo' &&
      (this.currentPlanObj.planName != 'Duo' ||
        (this.currentPlanObj.planName == 'Duo' && this.isSchoolDowngrade.length == 0))
    ) {
      this.headerNormal('duo-rect', value);
    } else if (
      value == 'Family' &&
      this.selectedPlan != 'Family' &&
      (this.currentPlanObj.planName != 'Family' ||
        (this.currentPlanObj.planName == 'Family' && this.isSchoolDowngrade.length == 0))
    ) {
      this.headerNormal('family-rect', value);
    }
  }

  showDetail(planName, planId, planPrice, playerSlot) {
    if (
      planName != this.currentPlanObj.planName ||
      (planName == this.currentPlanObj.planName && this.isSchoolDowngrade.length == 0) ||
      (planName == this.currentPlanObj.planName && this.typeFromParams == 'trial')
    ) {
      this.selectedPlanId = planId;
      this.selectedPrice = planPrice;
      this.selectedSlot = playerSlot;
      console.log('you select ' + planName + ' plan');
      this.headerClick(planName);
      var temp = {
        selectedPlanId: this.selectedPlanId,
        selectedPrice: this.selectedPrice,
        selectedSlot: this.selectedSlot
      };
      this.planSelected.emit(temp);
    }
  }

  clearSelected(value) {
    console.log('clear');
    if (
      !this.currentPlanObj.planName ||
      this.isSchoolDowngrade.length == 0 ||
      (this.currentPlanObj.planName && this.typeFromParams == 'trial')
    ) {
      switch (value) {
        case 'Solo':
          {
            this.headerNormal('duo-rect', 'Duo');
            this.headerNormal('family-rect', 'Family');
          }
          break;
        case 'Duo':
          {
            this.headerNormal('solo-rect', 'Solo');
            this.headerNormal('family-rect', 'Family');
          }
          break;
        case 'Family':
          {
            this.headerNormal('duo-rect', 'Duo');
            this.headerNormal('solo-rect', 'Solo');
          }
          break;
        default: {
          this.headerNormal('duo-rect', 'Duo');
          this.headerNormal('family-rect', 'Family');
        }
      }
    } else {
      switch (value) {
        case 'Solo':
          {
            if (this.currentPlanObj.planName != 'Duo') this.headerNormal('duo-rect', 'Duo');
            else this.headerNormal('family-rect', 'Family');
          }
          break;
        case 'Duo':
          {
            if (this.currentPlanObj.planName != 'Solo') this.headerNormal('solo-rect', 'Solo');
            else this.headerNormal('family-rect', 'Family');
          }
          break;
        case 'Family':
          {
            if (this.currentPlanObj.planName != 'Duo') this.headerNormal('duo-rect', 'Duo');
            else this.headerNormal('solo-rect', 'Solo');
          }
          break;
        default: {
          if (this.currentPlanObj.planName != 'Duo') this.headerNormal('duo-rect', 'Duo');
          else this.headerNormal('family-rect', 'Family');
        }
      }
    }
  }
}
