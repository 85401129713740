import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface BusinessLogicRequirements {
  getPlayerDetail(id): Observable<any>;
  editPlayer(playerId, playerData): Observable<any>;
  checkPlan(): Observable<any>;
  unsubscribeUser(data): Observable<any>;
  subscribeUser(data): Observable<any>;
  getPublishKey(): Observable<any>;
  changePayment(data): Observable<any>;
  resubscribePlan(uid, sid): Observable<any>;
  getCountries(): Observable<any>;
}

export const BusinessRequirementsInjectionToken = new InjectionToken<BusinessLogicRequirements>(
  'parent-detail Business Requirements'
);
