import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-list-view',
    templateUrl: './list-view.component.html',
    styleUrls: ['./list-view.component.css']
})
export class ListViewComponent {
  @Input() title;
  @Input() planList;
  @Input() durationList;
  @Input() selectedMonth;
  @Input() isListView;

  @Output() clickCard = new EventEmitter<any>();
  @Output() clickMonth = new EventEmitter<any>();

  onClickCard(e) {
    this.clickCard.emit(e);
  }

  onClickMonth(e) {
    this.clickMonth.emit(e);
  }

}
