import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/subscription-plans-mapper";
import * as i2 from "../data.requirements";
export class GetPromoInfoUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(tag) {
        // console.log('usecase', this.data.getPlanList().pipe(this.mapper.map))
        return this.data.getPromoInfo(tag).pipe(this.mapper.map);
        // return this.data.getPromoInfo(tag);
        //return this.data.getGAuth(url);
    }
}
GetPromoInfoUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetPromoInfoUseCase_Factory() { return new GetPromoInfoUseCase(i0.ɵɵinject(i1.SubscriptionMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: GetPromoInfoUseCase, providedIn: "root" });
