import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoginHeaderStore {

}

export enum LoginHeaderStoreKeys {

}
