import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import { GlobalComponentsModule } from 'src/app/global-components/global-components.module';
import { InformationComponent } from './information/information.component';


const components = [
  InformationComponent
]

@NgModule({
    declarations: components,
    exports: components,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        GlobalComponentsModule
    ]
})
export class SharedComponentsModule {}
