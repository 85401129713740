import { Inject, Injectable } from '@angular/core';
import { DataRequirements, DataRequirementsInjectionToken } from '../data.requirements';
import { SubscriptionMapper } from '../mappers/subscription-plans-mapper';

@Injectable({
  providedIn: 'root'
})
export class GetPlanUseCase {
  constructor(
    private mapper: SubscriptionMapper,
    @Inject(DataRequirementsInjectionToken) private data: DataRequirements
  ) {}

  run(id) {
    return this.data.getPlan(id).pipe(this.mapper.map);
  }
}
