import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GlobalComponentsModule } from "../global-components/global-components.module";
import { SharedComponentsModule } from "./presentation/shared-components/shared-components.module";
import { BusinessLogicFacade } from "./business-logic/business-logic.facade";
import { BusinessRequirementsInjectionToken } from "./presentation/business-logic.requirements";
import { DashboardHomeModule } from "./presentation/dashboard-home/dashboard-home.module";
import { DatePipe } from '@angular/common';

@NgModule({
  imports: [
    BusinessLogicFacade,
    CommonModule,
    GlobalComponentsModule,
    SharedComponentsModule,
    DashboardHomeModule,
  ],
  providers: [
    DatePipe,
    {
      provide: BusinessRequirementsInjectionToken,
      useClass: BusinessLogicFacade,
    },
  ],
  exports: [DashboardHomeModule],
})
export class DashboardFeatureModule {}
