<div class="mb-4">
    <div class="mb-2">
        <app-text class="h4-strong text-c300">{{inputLevel}}</app-text>
        <app-text *ngIf="required" class="h4-strong text-c300">*</app-text>
        <app-text class="body-m text-c100">&nbsp;{{note}}</app-text>
    </div>
    <app-input [hint]="placeholder"
        [type]="type"
        [width]="'100%'"
        [control]="form.get('value')"
        [class]="textArea? 'textarea-input': 'signup-input'"
        [multiline]="textArea"
        (blurEvent)="blurInput()">
    </app-input>
    <app-text *ngIf="error" [class]="'form-error-label'">{{error}}</app-text>
</div>
