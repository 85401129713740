import { Component, OnInit, HostListener, Input } from '@angular/core';
import { SharedService } from 'src/app/lib/shared-service';
import { LogoStore } from './logo.store';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent implements OnInit {
  @Input() class;
  public logo: any = './assets/images/swlogo_may.png';
  public logoMobile: any = './assets/images/swlogo_may.png';
  // public logoMobile: any = "./assets/images/STEMWerkzMobile.png";
  public mobileView;
  public mobileViewService;
  public origin;
  constructor(private store: LogoStore, private shared: SharedService) {}
  ngOnInit() {
    // console.log('on init');
    // this.mobileView = this.shared.getMobileView();
    this.mobileViewService = this.shared.GetMobileView.subscribe((data) => {
      this.mobileView = data;
    });
  }
  ngOnDestroy() {
    this.mobileViewService.unsubscribe();
  }
  gotolink() {
    // console.log('hello clicked');
    this.origin = this.shared.getOrigin();
    if (this.origin) this.goToOrigin();
    // window.open(environment.stemwerkzLink, '_self')
  }

  goToOrigin() {
    // console.log(this.origin);
    switch (this.origin) {
      case 'us':
        window.location.href = 'https://www.stemwerkz.org';
        console.log('us');
        break;
      case 'asia':
        window.location.href = 'https://asia.stemwerkz.org';
        console.log('asia');
        break;
      default:
        console.log('no origin in list');
    }
  }
}
