import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: "app-single-profiles",
  templateUrl: "./single-profiles.component.html",
  styleUrls: ["./single-profiles.component.css"],
})
export class SingleProfilesComponent implements OnInit {
  @Input() player;
  @Input() limit;
  @Input() order;
  @Input() isDowngrade = false;
  @Input() needToDelete;
  @Output() addSlot = new EventEmitter<any>();
  @Output() playerProfile = new EventEmitter<any>();
  @Output() deletePlayer = new EventEmitter<any>();

  public date = new Date();
  public expire: boolean = false;
  public hourAgo: any = 0;
  public horD: any = 'h';
  public colorCode;
  public RL;
  public newTimeFormat:any;
  constructor( private router: Router, private datePipe: DatePipe) {}

  ngOnInit() {
    console.log("zha pipe", this.datePipe.transform(this.player.lastActive,'h')+ 'h');
    console.log("moment", this.date.getDate() , new Date(this.player.lastActive).getDate());


    console.log("slot order", this.order);

    if(this.player.test){
      console.log('I am player test ',this.player.test)
    }else if(this.player.subscription.length > 0){
      if((this.date.getTime() - new Date(this.player.subscription[this.player.subscription.length-1].expiryDate).getTime()) > 0){
          this.expire = true;

      }
    }
    this.CalculateLastActive();

  }
  Subscribe($event) {
    $event.stopPropagation();
    console.log("clicked");
  }

  CalculateLastActive() {
    var difference = this.date.getTime() - new Date(this.player.lastActive).getTime(); //difference in milliseconds
    var mins = (difference / (1000 * 60)).toFixed(1); // get min from milli
    var hours = (difference / (1000 * 60 * 60)).toFixed(1); // get hour from milli
    var days = (difference / (1000 * 60 * 60 * 24)).toFixed(1); //get day from milli
    var isYesterday = this.date.getDate() - new Date(this.player.lastActive).getDate();
    //if hours more than 24 show day
    // if (Math.floor(Number(hours)) > 24) {
    //   this.hourAgo = Math.floor(Number(days));
    //   this.horD = 'D';
    // }
    // else {
    //   this.hourAgo = Math.floor(Number(hours));
    //   this.horD = 'h';
    // }
    //new format calculate
    if (Number(hours) > 48) {
      console.log("more than 2 day", Number(hours));
      this.newTimeFormat = this.datePipe.transform(this.player.lastActive,'d MMM yyyy');
    }
    else if (Number(hours) > 24 || (Number(hours) < 24 && isYesterday == 1)) {
      console.log("yesterday", Number(hours));
      this.newTimeFormat = 'Yesterday';
    }
    else if (Number(hours) > 3) {
      console.log("more than 3 hr", Number(hours));
      this.newTimeFormat = this.datePipe.transform(this.player.lastActive,'h:mm aaa')
    }
    else if (Number(hours) >= 1) {
      console.log("more than 1 hr", Number(hours));
      this.newTimeFormat = Math.floor(Number(hours)) + 'h ago';
    }
    else if (Number(hours) < 1) {
      if (Number(mins) > 2) {
        console.log("more than recent", Number(mins));
        this.newTimeFormat = Math.floor(Number(mins)) + 'm ago';
      }
      else {
        console.log("recent", Number(mins));
        this.newTimeFormat = '1m ago'
      }
    }
  }

  addNewSlot($event){
    this.addSlot.emit($event);
  }

  detailRoute() {
    // this.RL = this.order >= this.limit ? '' : '/player/detail/'+ this.player.playerID;
    // if(this.order < this.limit){ //remove condition for multiple account creation case
      // this.router.navigateByUrl('/player/detail/'+ this.player.playerID);
      // this.router.navigateByUrl('/parent/detail');

      this.playerProfile.emit(this.player);
    // }//remove condition for multiple account creation case
  }
  deleteProfile(){
    console.log(this.player);
    this.deletePlayer.emit(this.player);
  }

}
