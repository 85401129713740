import { Inject, Component } from "@angular/core";
import {
  BusinessLogicRequirements,
  BusinessRequirementsInjectionToken,
} from "../business-logic.requirements";
import { HomeInformationStore } from "./home-signup.store";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { AuthService } from "../../../lib/auth.service";

@Component({
  selector: "app-home-signup",
  templateUrl: "./home-signup-controller.component.html",
  styleUrls: ["./home-signup-controller.component.css"],
})
export class HomeSignupControllerComponent {
  public getInit = environment.initState;
  public ClickLogin = false;
  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private store: HomeInformationStore,
    private router: Router,
    private _authService: AuthService
  ) {}

  ngOnInit() {
    console.log(this.getInit);
    const isAuth = this._authService.isAuthenticated();
    console.log("this.authData ", isAuth);
    if (isAuth) {
      const userInfo = this._authService.getUserInfo();
      if(userInfo.UserType == 3)
        this.router.navigateByUrl("/login");
      else
        this.router.navigateByUrl(`/dashboard`);
    }
  }
  buttonClickLogin() {
    this.ClickLogin = true;
  }
}
