import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../data.requirements";
export class UpdateUserUseCase {
    constructor(data) {
        this.data = data;
    }
    run(parentData) {
        return this.data.updateUser(parentData);
    }
}
UpdateUserUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateUserUseCase_Factory() { return new UpdateUserUseCase(i0.ɵɵinject(i1.DataRequirementsInjectionToken)); }, token: UpdateUserUseCase, providedIn: "root" });
