import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { environment } from "../../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../../../lib/auth.service";
export class DiscountApiService {
    constructor(http, _router, _authService) {
        this.http = http;
        this._router = _router;
        this._authService = _authService;
        this.baseUrl = environment.apiurl;
        this.nodmaUrl = environment.nodmaApi;
        this.userInfo = {
            username: '',
            password: '',
            api_key: ''
        };
        this.userInfo = this._authService.getUserInfo();
        console.log("classes DataFacade", this.userInfo);
    }
    getDiscounntInfo(code) {
        let url = this.baseUrl + '/api/v1/discount-info/' + code;
        return this.http.get(url).pipe(map((res) => {
            let result = [res];
            return result;
        }));
    }
}
DiscountApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DiscountApiService_Factory() { return new DiscountApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.AuthService)); }, token: DiscountApiService, providedIn: "root" });
