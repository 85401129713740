import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ForgotPasswordHomeStore {

}

export enum ForgotPasswordHomeStoreKeys {

}
