import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../data.requirements";
export class GetSubscriptionUsecase {
    constructor(data) {
        this.data = data;
    }
    run() {
        console.log('usecase');
        return this.data.getSubscription();
        //return this.data.getGAuth(url);
    }
}
GetSubscriptionUsecase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetSubscriptionUsecase_Factory() { return new GetSubscriptionUsecase(i0.ɵɵinject(i1.DataRequirementsInjectionToken)); }, token: GetSubscriptionUsecase, providedIn: "root" });
