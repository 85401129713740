import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../data.requirements";
export class CheckEmptySubscriptionUsecase {
    constructor(data) {
        this.data = data;
    }
    run() {
        return this.data.checkEmptySub();
    }
}
CheckEmptySubscriptionUsecase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CheckEmptySubscriptionUsecase_Factory() { return new CheckEmptySubscriptionUsecase(i0.ɵɵinject(i1.DataRequirementsInjectionToken)); }, token: CheckEmptySubscriptionUsecase, providedIn: "root" });
