<div class="bg-s0 gift-sucess-main-wrap">
  <div class="gift-success-wrapper">
    <div>
      <app-text class="gift-success-title text-c300">
        Yay! Your gift has been delivered!
      </app-text>
    </div>
    <div class="gift-success-text pt-2 d-flex flex-column">
      <app-text class="text-c300 light-body-l">
        The gift card has successfully made its way to <app-text class="h4-strong">{{giftCard?.recipientEmail}}</app-text>.
      </app-text>
      <app-text class="text-c300 light-body-l">You may want to screenshot this page for your records.</app-text>
    </div>
    <div class="giftcard-info-wrapper">
      <div>
        <app-image [src]="giftCard?.posterImageUrl" [class]="'b-r-18'" [width]="'100%'" [height]="'100%'"></app-image>
      </div>
      <div class="d-flex flex-wrap align-items-center info-wrapper">
        <div class="info-column">
          <div>
            <app-text class="light-body-m text-c300">Plan</app-text>
          </div>
          <div>
            <app-text class="h1-strong text-c300">{{giftCard?.planName}}</app-text>
          </div>
        </div>
        <div class="info-column">
          <div>
            <app-text class="h1-strong text-c300">{{giftCard?.playerSlot}}</app-text>
          </div>
          <div>
            <app-text class="light-body-m text-c300">{{(giftCard?.playerSlot > 1) ? 'child accounts' : 'child account'}}</app-text>
          </div>
        </div>
        <div class="info-column">
          <div>
            <app-text class="h1-strong text-c300">{{giftCard?.amount}}</app-text>
          </div>
          <div>
            <app-text class="light-body-m text-c300">{{(giftCard?.amount > 1) ? 'months' : 'month'}}</app-text>
          </div>
        </div>
      </div>
      <div *ngIf="giftCard?.message != undefined && giftCard?.message != null && giftCard?.message != ''" class="bg-s10 message-wrapper">
        <div>
          <app-text class="light-body-m text-c100">
            {{giftCard?.message}}
          </app-text>
        </div>
      </div>
    </div>
    <div class="button-wrapper">
      <div class="pr-2 btn-wrap">
        <app-button [class]="'btn-flat-l'" (btnClick)="onBuyAnotherCardClick()">
          <app-text class="h4-strong">Buy another card</app-text>
        </app-button>
      </div>
      <div class="pl-2 btn-wrap">
        <app-button [class]="'btn-primary-l'">
          <a href="{{homeUrl}}" class="h4-strong text-s0 backToHome">Back to home</a>
        </app-button>
      </div>
    </div>
  </div>
</div>
