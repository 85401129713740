import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from '@angular/router';

@Component({
  selector: "app-subscription-plan",
  templateUrl: "./subscription-plan.component.html",
  styleUrls: ["./subscription-plan.component.css"],
})
export class SubscriptionPlanComponent implements OnInit {
  @Output() subscribeBtn = new EventEmitter<any>();
  @Input() loading;
  @Input() plan;
  @Input() cancel;
  constructor(private router: Router) {}

  ngOnInit() {}

  subscribeNow($event) {
    console.log('emit');
    this.subscribeBtn.emit($event);
  }
  backPage(){
    this.router.navigateByUrl(`/dashboard`);
  }
}
