import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../data.requirements";
import * as i2 from "../mappers/discountcode.mapper";
export class GetDiscounntInfoUseCase {
    constructor(data, discountmapper) {
        this.data = data;
        this.discountmapper = discountmapper;
    }
    run(code) {
        return this.data.getDiscountInfo(code).pipe(this.discountmapper.map);
    }
}
GetDiscounntInfoUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetDiscounntInfoUseCase_Factory() { return new GetDiscounntInfoUseCase(i0.ɵɵinject(i1.DataRequirementsInjectionToken), i0.ɵɵinject(i2.DiscountCodeMapper)); }, token: GetDiscounntInfoUseCase, providedIn: "root" });
