import {SubscriptionPlanModel} from '../../../models/subscription-plan.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class SubscriptionPlanListMapper extends ItemMapper<SubscriptionPlanModel> {
  protected mapFromItem(item): SubscriptionPlanModel {
    console.log(item)
      let planList:any = [];

      item.map(data => {
        const planModel= new SubscriptionPlanModel();
        planModel.planId = data._id;
        planModel.planName = data.name;
        planModel.planMonthly = data.monthlyPrice;
        planModel.playerSlot = data.playerSlot;
        planModel.stripePriceId = data.stripePriceId;
        planModel.subscribed = data.subscribed;
        planModel.currency = data.currency;
        planModel.interval = data.interval;
        planModel.apikey = data.apikey;
        planModel.createdAt = data.createdAt;
        planModel.active = data.active;
        planModel.monthlyPrice = data.monthlyPrice;
        planList.push(planModel)
      })
      return planList;
  }
}
