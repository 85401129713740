import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParentApiService {
  private baseUrl = environment.apiurl;
  constructor(private http: HttpClient) {}

  getParent(parentId) {
    let url = this.baseUrl + '/api/v2/users/' + parentId;
    console.log(url);
    return this.http.get(url).pipe(
      map((res: Response) => {
        let result = res;
        console.log(result);
        return result;
      })
    );
  }
  editParent(parentId, parentData) {
    let url = this.baseUrl + '/api/v2/users/' + parentId;
    console.log(url);
    console.log(parentData);
    return this.http.put(url, parentData).pipe(
      map((res: Response) => {
        let result = res;
        console.log(res);
        return result;
      })
    );
  }
  checkPlan(): Observable<any> {
    let url = this.baseUrl + '/api/v2/users/current-subscription';
    return this.http.get(url).pipe(
      map((res: Response) => {
        let result = res;
        console.log(result);
        return result;
      })
    );

    // const obj = {'playLimit':4};
    // return of(obj);
  }
  emailCopy(id, pw): Observable<any> {
    let url = this.baseUrl + '/api/v2/users/email-playerinfo';
    let body = {
      playerUserId: id,
      password: pw
    };
    console.log(url);
    return this.http.post(url, body).pipe(
      map((res: Response) => {
        let result = res;
        console.log(result);
        return result;
      })
    );
  }
}
