/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-header.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../global-components/text/text.component.ngfactory";
import * as i3 from "../../../../../global-components/text/text.component";
import * as i4 from "../../../../../global-components/text/text.store";
import * as i5 from "./login-header.component";
import * as i6 from "./login-header.store";
import * as i7 from "@angular/router";
var styles_LoginHeaderComponent = [i0.styles];
var RenderType_LoginHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginHeaderComponent, data: {} });
export { RenderType_LoginHeaderComponent as RenderType_LoginHeaderComponent };
export function View_LoginHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "signup-tap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-text", [["class", "desktop-view"]], null, [[null, "textClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("textClick" === en)) {
        var pd_0 = (_co.showSignup($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(2, 114688, null, 0, i3.TextComponent, [i4.TextStore], { class: [0, "class"] }, { textClick: "textClick" }), (_l()(), i1.ɵted(-1, 0, [" Sign up "]))], function (_ck, _v) { var currVal_0 = "signup-text"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LoginHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-header", [], null, null, null, View_LoginHeaderComponent_0, RenderType_LoginHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i5.LoginHeaderComponent, [i6.LoginHeaderStore, i7.Router], null, null)], null, null); }
var LoginHeaderComponentNgFactory = i1.ɵccf("app-login-header", i5.LoginHeaderComponent, View_LoginHeaderComponent_Host_0, {}, { signUp: "signUp" }, []);
export { LoginHeaderComponentNgFactory as LoginHeaderComponentNgFactory };
