import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// import $ = require('jquery')
import * as $ from 'jquery';
import { SharedService } from 'src/app/lib/shared-service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  @Input() parentData;
  @Output() closeProfile = new EventEmitter<any>();
  @Output() UserPasswordChange = new EventEmitter<any>();
  @Output() editUser = new EventEmitter<any>();
  @Output() deleteUser = new EventEmitter<any>();
  public colorCode;
  public isMobileView;
  public mobileViewService;

  constructor(private shared: SharedService) {
    // this.isMobileView = this.shared.getMobileView();
  }

  ngOnInit() {
    this.mobileViewService = this.shared.GetMobileView.subscribe((data) => {
      this.isMobileView = data;
    });
    let body = (document.getElementsByTagName('body')[0].style.overflow = 'hidden');
    window.scrollTo(0, 0);
  }
  ngOnDestroy() {
    let body = (document.getElementsByTagName('body')[0].style.overflow = 'auto');

    this.mobileViewService.unsubscribe();
  }

  closePopup($event) {
    document.getElementById('player-info-box').classList.add('player-info-box-close');
    setTimeout(() => {
      this.closeProfile.emit($event);
    }, 100);
  }

  changeUserPassword($event) {
    this.UserPasswordChange.emit($event);
  }
  editUserProfile($event) {
    console.log('zha 1');

    this.editUser.emit($event);
  }
  deleteAccount($event) {
    this.deleteUser.emit($event);
  }
}
