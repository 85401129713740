/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logo.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./logo.component";
import * as i4 from "./logo.store";
import * as i5 from "../../lib/shared-service";
var styles_LogoComponent = [i0.styles];
var RenderType_LogoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoComponent, data: {} });
export { RenderType_LogoComponent as RenderType_LogoComponent };
export function View_LogoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "img", [["class", "cursor"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotolink() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "cursor"; var currVal_2 = ((_co.class == "store") ? "store" : (_co.mobileView ? "mobile" : "desktop")); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.mobileView ? _co.logoMobile : _co.logo); _ck(_v, 0, 0, currVal_0); }); }
export function View_LogoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logo", [], null, null, null, View_LogoComponent_0, RenderType_LogoComponent)), i1.ɵdid(1, 245760, null, 0, i3.LogoComponent, [i4.LogoStore, i5.SharedService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoComponentNgFactory = i1.ɵccf("app-logo", i3.LogoComponent, View_LogoComponent_Host_0, { class: "class" }, {}, []);
export { LogoComponentNgFactory as LogoComponentNgFactory };
