import { UserDetailModel } from "../../../models/user-detail.model";
import { ItemMapper } from "../../../lib/item-mapper";

export class ParentDetailMapper extends ItemMapper<UserDetailModel> {
  //   public imgPrefix = "https://d1vf2jccr14y1d.cloudfront.net/covers/";

  protected mapFromItem(item): UserDetailModel {
    console.log(item);
    // const _authService = new AuthService();
    const detailModel = new UserDetailModel();
    detailModel.username = item.username;
    detailModel.shortName = item.generatedProfileImage.initials;
    detailModel.email = item.email ? item.email : "";
    detailModel.profileImage = item.generatedProfileImage.profileImage;
    detailModel.fullName = item.firstName + " " + item.lastName;
    detailModel.firstName = item.firstName;
    detailModel.lastName = item.lastName;
    detailModel.id = item._id;
    detailModel.payment = item.payment;
    detailModel.metadata = item.metadata;
    console.log(detailModel);
    return detailModel;
  }
}
