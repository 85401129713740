/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unsubscribe-popup.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../text/text.component.ngfactory";
import * as i4 from "../text/text.component";
import * as i5 from "../text/text.store";
import * as i6 from "../button/button.component.ngfactory";
import * as i7 from "../button/button.component";
import * as i8 from "../button/button.store";
import * as i9 from "./unsubscribe-popup.component";
var styles_UnsubscribePopupComponent = [i0.styles];
var RenderType_UnsubscribePopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnsubscribePopupComponent, data: {} });
export { RenderType_UnsubscribePopupComponent as RenderType_UnsubscribePopupComponent };
export function View_UnsubscribePopupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 31, "div", [["class", "overlay d-flex justify-content-center align-items-baseline"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 30, "div", [["class", "outer-frame d-flex flex-column bg-s0"], ["id", "outer-frame"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "d-flex justify-content-between"], ["style", "margin-bottom: 32px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "head-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel subscription?"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["class", "close-btn"], ["src", "../../assets/images/close.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDelete($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 25, "div", [["class", "inner-frame d-flex align-item-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 11, "div", [["class", "warning"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "app-text", [], null, null, null, i3.View_TextComponent_0, i3.RenderType_TextComponent)), i1.ɵdid(10, 114688, null, 0, i4.TextComponent, [i5.TextStore], null, null), (_l()(), i1.ɵted(-1, 0, ["Are you sure you want to cancel your subscription? "])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 6, "app-text", [], null, null, null, i3.View_TextComponent_0, i3.RenderType_TextComponent)), i1.ɵdid(14, 114688, null, 0, i4.TextComponent, [i5.TextStore], null, null), (_l()(), i1.ɵted(-1, 0, ["Your subscription will be cancelled at the end of your current billing period on "])), (_l()(), i1.ɵeld(16, 0, null, 0, 2, "span", [["style", "font-weight: bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), i1.ɵppd(18, 2), (_l()(), i1.ɵted(-1, 0, ["."])), (_l()(), i1.ɵeld(20, 0, null, null, 5, "div", [["class", "btn-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 4, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deletePlayer($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ButtonComponent_0, i6.RenderType_ButtonComponent)), i1.ɵdid(22, 49152, null, 0, i7.ButtonComponent, [i8.ButtonStore], { class: [0, "class"], loading: [1, "loading"] }, null), (_l()(), i1.ɵeld(23, 0, null, 0, 2, "app-text", [], null, null, null, i3.View_TextComponent_0, i3.RenderType_TextComponent)), i1.ɵdid(24, 114688, null, 0, i4.TextComponent, [i5.TextStore], { class: [0, "class"], loading: [1, "loading"] }, null), (_l()(), i1.ɵted(25, 0, [" ", " "])), (_l()(), i1.ɵeld(26, 0, null, null, 6, "div", [["class", "d-flex justify-content-center align-items-center"], ["style", "width: 100%"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDelete($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 5, "app-text", [], null, null, null, i3.View_TextComponent_0, i3.RenderType_TextComponent)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(29, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(30, { "btn-disable": 0, "btn-cancel2": 1 }), i1.ɵdid(31, 114688, null, 0, i4.TextComponent, [i5.TextStore], { class: [0, "class"], loading: [1, "loading"] }, null), (_l()(), i1.ɵted(-1, 0, ["Back"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 10, 0); _ck(_v, 14, 0); var currVal_1 = "btn-red"; var currVal_2 = _co.pleaseWait; _ck(_v, 22, 0, currVal_1, currVal_2); var currVal_3 = "btn-text-style2"; var currVal_4 = _co.pleaseWait; _ck(_v, 24, 0, currVal_3, currVal_4); var currVal_6 = "btn-text-cancel2"; var currVal_7 = _ck(_v, 30, 0, _co.pleaseWait, !_co.pleaseWait); _ck(_v, 29, 0, currVal_6, currVal_7); var currVal_8 = "btn-text-cancel2"; var currVal_9 = _co.pleaseWait; _ck(_v, 31, 0, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 17, 0, _ck(_v, 18, 0, i1.ɵnov(_v, 0), _co.expireDate, "d MMM yyyy")); _ck(_v, 17, 0, currVal_0); var currVal_5 = (_co.pleaseWait ? "Cancelling Subscription..." : "Confirm cancellation"); _ck(_v, 25, 0, currVal_5); }); }
export function View_UnsubscribePopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-unsubscribe-popup", [], null, null, null, View_UnsubscribePopupComponent_0, RenderType_UnsubscribePopupComponent)), i1.ɵdid(1, 245760, null, 0, i9.UnsubscribePopupComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnsubscribePopupComponentNgFactory = i1.ɵccf("app-unsubscribe-popup", i9.UnsubscribePopupComponent, View_UnsubscribePopupComponent_Host_0, { playerDetail: "playerDetail", expireDate: "expireDate" }, { closeBtn: "closeBtn", unsubscribePlayerBtn: "unsubscribePlayerBtn" }, []);
export { UnsubscribePopupComponentNgFactory as UnsubscribePopupComponentNgFactory };
