import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { BusinessLogicRequirements } from '../business-logic.requirements';
import { loadStripe } from '@stripe/stripe-js';
export class GiftCardControllerComponent {
    constructor(cdref, business) {
        this.cdref = cdref;
        this.business = business;
        this.planList = null;
        this.posterList = null;
        this.currentPreviewImgIndex = 0;
        this.durationList = [1, 3, 6, 12];
        this.loadingPurchaseButton = false;
    }
    ngOnInit() {
        this.getAllPlanList();
        this.getAllGiftCards();
    }
    ngAfterContentChecked() {
        this.cdref.detectChanges();
    }
    getAllPlanList() {
        this.business.getAllPlans().subscribe(data => {
            console.log("get all plan list", data);
            this.planList = data[0];
            if (this.planList[0]) {
                this.planList[0].selected = true;
                this.setPlanData(this.planList[0], 3, null, null, null, null, null);
            }
        }, err => {
            console.log('error get all plan--->', err);
            this.planList = [];
        });
    }
    getAllGiftCards() {
        this.business.getAllGiftCards().subscribe(data => {
            console.log("getAllGiftCards", data);
            this.posterList = data[0];
        }, err => {
            console.log('error poster list--->', err);
            this.posterList = [];
        });
    }
    setPlanData(plan, month, recipientName, recipientEmail, message, yourName, yourEmail) {
        this.selectedPlan = plan;
        this.selectedPlan.totalMonth = month;
        this.selectedPlan.recipientName = recipientName;
        this.selectedPlan.recipientEmail = recipientEmail;
        this.selectedPlan.message = message;
        this.selectedPlan.yourName = yourName;
        this.selectedPlan.yourEmail = yourEmail;
        this.calcTotalPrice();
    }
    choosePlan(e) {
        console.log('choose plan', e);
        var tempMonth = this.selectedPlan.totalMonth;
        var tempName1 = this.selectedPlan.recipientName;
        var tempEmail1 = this.selectedPlan.recipientEmail;
        var tempMessage = this.selectedPlan.message;
        var tempName2 = this.selectedPlan.yourName;
        var tempEmail2 = this.selectedPlan.yourEmail;
        this.setPlanData(e, tempMonth, tempName1, tempEmail1, tempMessage, tempName2, tempEmail2);
        this.planList.forEach(plan => {
            if (plan.planId != e.planId)
                plan.selected = false;
            else
                e.selected = true;
        });
    }
    chooseDuration(e) {
        this.selectedPlan.totalMonth = e;
        this.calcTotalPrice();
        console.log('choose duration', this.selectedPlan);
    }
    calcTotalPrice() {
        var price = this.selectedPlan.totalMonth * this.selectedPlan.monthlyPrice;
        this.selectedPlan.totalPrice = Number.parseFloat(price.toString()).toFixed(2);
    }
    onClickLeft(e) {
        if (this.currentPreviewImgIndex > 0)
            this.currentPreviewImgIndex -= 1;
    }
    onClickRight(e) {
        if (this.currentPreviewImgIndex < this.posterList.length - 1)
            this.currentPreviewImgIndex += 1;
    }
    onClickImage(index) {
        this.currentPreviewImgIndex = index;
    }
    onInputChange(val, key) {
        switch (key) {
            case 'recipient-name':
                this.selectedPlan.recipientName = val;
                break;
            case 'recipient-email':
                var isvalid = this.emailChecker(val);
                this.recipient_emailError = isvalid || val == '' ? null : 'Invalid email';
                this.selectedPlan.recipientEmail = val;
                break;
            case 'message':
                this.selectedPlan.message = val;
                break;
            case 'your-name':
                this.selectedPlan.yourName = val;
                break;
            case 'your-email':
                var isvalid = this.emailChecker(val);
                this.your_emailError = isvalid || val == '' ? null : 'Invalid email';
                this.selectedPlan.yourEmail = val;
                break;
        }
    }
    emailChecker(value) {
        const emailTest = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValidEmail = emailTest.test(String(value).toLowerCase());
        return isValidEmail;
    }
    onPurchaseGift() {
        if (this.selectedPlan.recipientName && this.selectedPlan.recipientEmail && this.selectedPlan.yourName && this.selectedPlan.yourEmail && !this.loadingPurchaseButton && !this.recipient_emailError && !this.your_emailError) {
            var sessionId, publishKey;
            var data = {
                subscriptionId: this.selectedPlan.planId,
                amount: this.selectedPlan.totalMonth,
                recipientName: this.selectedPlan.recipientName,
                recipientEmail: this.selectedPlan.recipientEmail,
                senderName: this.selectedPlan.yourName,
                senderEmail: this.selectedPlan.yourEmail,
                posterImageUrl: this.posterList[this.currentPreviewImgIndex].posterImageUrl,
                redirectURL: window.location.origin + '/store/purchase-processing',
                claimURL: '' //fill data in api service
            };
            if (this.selectedPlan.message)
                data['message'] = this.selectedPlan.message;
            console.log('purchasing gift-----', data);
            this.loadingPurchaseButton = true;
            this.business.purchaseGift(data).subscribe(res => {
                console.log('res--->', res);
                sessionId = res[0].code;
                this.business.getPublishKey().subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    console.log('res-->', res);
                    publishKey = res[0].code;
                    const stripe = yield loadStripe(publishKey);
                    stripe.redirectToCheckout({
                        sessionId: sessionId
                    }).then(function (result) {
                        console.log(result);
                    });
                }), err => {
                    console.log('error--->', err);
                    this.resetData();
                });
            }, err => {
                console.log('error---->', err);
                this.resetData();
            });
        }
    }
    resetData() {
        this.loadingPurchaseButton = false;
        this.planList.forEach(plan => plan.selected = false);
        this.planList[0].selected = true;
        this.setPlanData(this.planList[0], 3, null, null, null, null, null);
    }
    contactUs() {
        if (!this.loadingPurchaseButton) {
            console.log('click contact us----');
            window.open('https://www.stemwerkz.org/#bgLayers_comp-kgt3luhu', '_blank');
        }
    }
}
