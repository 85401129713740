import { Inject, Injectable } from '@angular/core';
import { DataRequirementsInjectionToken, DataRequirements } from '../data.requirements';
import { SubscriptionMapper } from '../mappers/subscription-plans-mapper';

@Injectable({
  providedIn: 'root'
})
export class CheckEmptySubscriptionUsecase {
  constructor(@Inject(DataRequirementsInjectionToken) private data: DataRequirements) {}

  run() {
    return this.data.checkEmptySub();
  }
}
