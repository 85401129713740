/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./redeem-loading.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./redeem-loading.component";
var styles_RedeemLoadingComponent = [i0.styles];
var RenderType_RedeemLoadingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RedeemLoadingComponent, data: {} });
export { RenderType_RedeemLoadingComponent as RenderType_RedeemLoadingComponent };
export function View_RedeemLoadingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "overlay d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "success"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "d-flex justify-content-center"], ["style", "margin-bottom: 24px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "spinner-border text-success"], ["role", "status"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading..."])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please wait for the processing to complete. "]))], null, null); }
export function View_RedeemLoadingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-redeem-loading", [], null, null, null, View_RedeemLoadingComponent_0, RenderType_RedeemLoadingComponent)), i1.ɵdid(1, 245760, null, 0, i2.RedeemLoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RedeemLoadingComponentNgFactory = i1.ɵccf("app-redeem-loading", i2.RedeemLoadingComponent, View_RedeemLoadingComponent_Host_0, {}, {}, []);
export { RedeemLoadingComponentNgFactory as RedeemLoadingComponentNgFactory };
