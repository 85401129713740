/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./global-components/custom-feedback/custom-feedback.component.ngfactory";
import * as i3 from "./global-components/custom-feedback/custom-feedback.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "./app.component";
import * as i7 from "./lib/shared-service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-feedback", [], null, [[null, "dimissFeedbackBtn"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dimissFeedbackBtn" === en)) {
        var pd_0 = (_co.dimissFeedbackBtn($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CustomFeedbackComponent_0, i2.RenderType_CustomFeedbackComponent)), i1.ɵdid(1, 114688, null, 0, i3.CustomFeedbackComponent, [], { feedbackTitle: [0, "feedbackTitle"], feedbackBody: [1, "feedbackBody"], feedbackBtn: [2, "feedbackBtn"], tickIcon: [3, "tickIcon"] }, { dimissFeedbackBtn: "dimissFeedbackBtn" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.globalFeedbackData == null) ? null : _co.globalFeedbackData.feedbackTitle); var currVal_1 = ((_co.globalFeedbackData == null) ? null : _co.globalFeedbackData.feedbackBody); var currVal_2 = ((_co.globalFeedbackData == null) ? null : _co.globalFeedbackData.feedbackBtn); var currVal_3 = ((_co.globalFeedbackData == null) ? null : _co.globalFeedbackData.tickIcon); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.globalFeedbackFlag; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i6.AppComponent, [i7.SharedService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
