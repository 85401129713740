import { DataRequirements, } from "../data.requirements";
import * as i0 from "@angular/core";
import * as i1 from "../data.requirements";
export class EditParentUseCase {
    constructor(data) {
        this.data = data;
    }
    run(parentId, parentData) {
        return this.data.editParent(parentId, parentData);
    }
}
EditParentUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EditParentUseCase_Factory() { return new EditParentUseCase(i0.ɵɵinject(i1.DataRequirementsInjectionToken)); }, token: EditParentUseCase, providedIn: "root" });
