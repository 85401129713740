/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./query-params-ls.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./query-params-ls.component";
import * as i3 from "./query-params-ls.store";
import * as i4 from "@angular/router";
import * as i5 from "../../lib/shared-service";
var styles_QueryParamsLsComponent = [i0.styles];
var RenderType_QueryParamsLsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QueryParamsLsComponent, data: {} });
export { RenderType_QueryParamsLsComponent as RenderType_QueryParamsLsComponent };
export function View_QueryParamsLsComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_QueryParamsLsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-query-params-ls", [], null, null, null, View_QueryParamsLsComponent_0, RenderType_QueryParamsLsComponent)), i1.ɵdid(1, 114688, null, 0, i2.QueryParamsLsComponent, [i3.QueryParamsLsStore, i4.ActivatedRoute, i5.SharedService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QueryParamsLsComponentNgFactory = i1.ɵccf("app-query-params-ls", i2.QueryParamsLsComponent, View_QueryParamsLsComponent_Host_0, {}, {}, []);
export { QueryParamsLsComponentNgFactory as QueryParamsLsComponentNgFactory };
