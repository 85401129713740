<div class="overlay d-flex justify-content-center">
  <div class="player-info-box" id="player-info-box">
    <div class="circle" (click)="closePopup($event)">
      <app-image
        class="close-btn"
        [src]="'../../../assets/images/cross_white.svg'"
        [width]="'12px'"
        [height]="'12px'"
      ></app-image>
    </div>
    <div style="height: 127.44px" class="w-460">
      <app-image [src]="'../../../assets/images/player-detail.svg'"></app-image>
    </div>
    <div
      class="profile-circle d-flex justify-content-center align-item-center"
      [ngStyle]="{
        'background-image': 'url(' + parentData.profileImage + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover'
      }"
    >
      <app-text class="shortName"> {{ parentData.playerInitial }}</app-text>
    </div>
    <div class="text-div">
      <app-text class="big-name"> {{ parentData.firstName }} {{ parentData.lastName }}</app-text>
      <app-text class="username"> Username </app-text>
      <app-text class="email"> {{ parentData.playerName ? parentData.playerName : '' }} </app-text>
      <div style="border-bottom: 1px solid #edeef0" class="d-flex align-items-center options">
        <app-image
          (click)="closePopup($event)"
          class="options-icon"
          [src]="'../../../assets/images/user_stroke.svg'"
          [height]="'24px'"
        ></app-image>
        <app-text (click)="editUserProfile($event)" class="edit">Edit profile</app-text>
      </div>
      <div style="border-bottom: 1px solid #edeef0" class="d-flex align-items-center options">
        <app-image
          (click)="closePopup($event)"
          class="options-icon"
          [src]="'../../../assets/images/key.svg'"
          [height]="'24px'"
        ></app-image>
        <app-text (click)="changeUserPassword($event)" class="reset">Reset password</app-text>
      </div>
      <div class="d-flex align-items-center options">
        <app-image
          (click)="closePopup($event)"
          class="options-icon"
          [src]="'../../../assets/images/delete.svg'"
          [height]="'24px'"
        ></app-image>
        <app-text (click)="deleteAccount($event)" class="delete">Delete account</app-text>
      </div>

      <div class="btn-div" *ngIf="!isMobileView">
        <app-button [class]="'btn-orange'" (click)="closePopup($event)">
          <app-text [class]="'btn-text-style2'"> Close </app-text>
        </app-button>
      </div>
    </div>
  </div>
</div>
