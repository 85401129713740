import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SharedService } from "src/app/lib/shared-service";


@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.css']
})
export class PaymentHistoryComponent implements OnInit {
  @Input() player;
  @Input() onlyPaymentHistory = false;
  @Output() clickedPayment = new EventEmitter <any> ();
  public isMobileView;
  public c_right = "./assets/images/mobile_chevron_right.svg";
  public mobileViewService;

  constructor(private shared: SharedService) {
  }

  ngOnInit() {
    this.mobileViewService = this.shared.GetMobileView.subscribe(data=>{
      this.isMobileView = data;
    })
  }

  receipt(){

  }

  showPaymentHistory(){
    this.onlyPaymentHistory = true;
    this.clickedPayment.emit();
  }

  ngOnDestory(){
    this.mobileViewService.unsubscribe();
  }

}
