import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../../../global-components/global-components.module';
import {SharedComponentsModule} from '../shared-components/shared-components.module';
import {SignupControllerComponent} from './signup-controller.component';
import {RouterModule} from '@angular/router';
import { SignupHeaderComponent } from './components/signup-header/signup-header.component';


@NgModule({
    declarations: [
        SignupControllerComponent,
        SignupHeaderComponent,
    ],
    exports: [
        SignupControllerComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GlobalComponentsModule,
        SharedComponentsModule,
    ]
})
export class SignupModule {}
