import {Inject, Injectable} from '@angular/core';
import { ParentDetailMapper } from 'src/app/dashboard/business-logic/mappers/parent-detail.mapper';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';

@Injectable({
    providedIn: 'root'
})
export class GetParentDetailUseCase {
    constructor(
        private mapper: ParentDetailMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run(id) {
        return this.data.getParentDetail(id).pipe(this.mapper.map);
    }
}
