import {Inject, Injectable} from '@angular/core';
import { DataRequirements, DataRequirementsInjectionToken } from '../data.requirements';
import { DiscountCodeMapper } from '../mappers/discountcode.mapper';

@Injectable({
    providedIn: 'root'
})
export class GetDiscounntInfoUseCase {
    constructor(
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements,
        private discountmapper: DiscountCodeMapper
    ) {}

    run(code) {
      return this.data.getDiscountInfo(code).pipe(this.discountmapper.map);
    }
}
