import { DataRequirements, } from "../data.requirements";
import * as i0 from "@angular/core";
import * as i1 from "../data.requirements";
export class DeletePlayerUseCase {
    constructor(data) {
        this.data = data;
    }
    run(playerId) {
        return this.data.deletePlayer(playerId);
    }
}
DeletePlayerUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DeletePlayerUseCase_Factory() { return new DeletePlayerUseCase(i0.ɵɵinject(i1.DataRequirementsInjectionToken)); }, token: DeletePlayerUseCase, providedIn: "root" });
