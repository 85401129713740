<div class="info-bg">
  <div class="img-bg">
      <img src="assets/images/stemwerkz_may.jpg" style="
  width: 100%;
">
  </div>
<div class="info-div">
  <app-logo></app-logo>
</div>
</div>
