import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RedirectPopupStore } from './redirect-popup.store';

@Component({
  selector: 'app-redirect-popup',
  templateUrl: './redirect-popup.component.html',
  styleUrls: ['./redirect-popup.component.css']
})
export class RedirectPopupComponent {
  @Input() userName = '';
  @Input() channelUrl = '';
  @Input() valleyUrl = '';
  @Input() academyUrl = '';
  @Input() dashboardUrl = '';
  @Input() questUrl = '';
  @Output() popupBtn = new EventEmitter<any>();
  @Output() logoutBtn = new EventEmitter<any>();
  @Output() closePopup = new EventEmitter<any>();
  // @Output() ChannelBtn = new EventEmitter<any>();
  constructor(private store: RedirectPopupStore) {}
  ngOnInit() {
    console.log('DURL', this.dashboardUrl);
    console.log('QURL', this.questUrl);
    console.log('OTHERS', this.valleyUrl, this.channelUrl, this.academyUrl);

    console.log('call redirect popup ', this.userName);
    console.log(this.academyUrl);
    window.scrollTo(0, 0);
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 2000);
  }
  ngOnDestroy() {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  }
  clickBtn($event) {
    this.popupBtn.emit($event);
  }
  clickLogout($event) {
    this.logoutBtn.emit($event);
  }
  clickCloseBtn() {
    document.getElementById('outer-frame').classList.add('outer-frame-close');
    setTimeout(() => {
      this.closePopup.emit();
    }, 100);
  }
}
