import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SharedService {
  private giftcard;
  private giftInfo;
  private planId;
  private mobileView = false;
  public title;
  public vendor = VendorType;
  public vendorArray = ['TIMES'];

  private userInfo;
  private origin = null;

  getOrigin() {
    return this.origin;
  }

  setOrigin(value) {
    this.origin = value;
  }

  constructor(private titleService: Title) {}
  setGiftCard(giftcard) {
    this.giftcard = giftcard;
  }

  getGiftCard() {
    return this.giftcard;
  }

  setGiftInfo(giftcard) {
    this.giftcard = giftcard;
  }

  getGiftInfo() {
    return this.giftcard;
  }
  setCurrentPlanId(planId) {
    this.planId = planId;
  }

  getCurrentPlanId() {
    return this.planId;
  }

  setMobileView(value) {
    this.mobileView = value;
  }

  getMobileView() {
    return this.mobileView;
  }

  private SetMobileView = new BehaviorSubject({});
  GetMobileView = this.SetMobileView.asObservable();
  defineMobileView(data: any) {
    this.SetMobileView.next(data);
  }

  setDocTitle(title: string) {
    // console.log('current title:::::' + this.titleService.getTitle());
    this.titleService.setTitle(title);
  }

  getVendorType(name) {
    return this.vendor[name];
  }

  public schoolDiscountCode;
  public schoolDiscountInfo;

  getSchoolDiscountCode() {
    return this.schoolDiscountCode;
  }

  setSchoolDiscountCode(code) {
    this.schoolDiscountCode = code;
  }

  setSchoolDiscountInfo(data) {
    this.schoolDiscountInfo = data[0];
  }

  getSchoolDiscountInfo() {
    return this.schoolDiscountInfo;
  }

  setUserInfo(obj) {
    this.userInfo = obj;
  }

  getUserInfo() {
    return this.userInfo;
  }

  //feedback test
  public feedbackFlag;

  setFeedbackFlag(value) {
    this.feedbackFlag = value;
    console.log(' have set feedback flag ', value);
  }

  getFeedbackFlag() {
    console.log('shared ', this.feedbackFlag);
    return this.feedbackFlag;
  }

  private SetFeedbackFlag = new BehaviorSubject({});
  GetFeedbackFlag = this.SetFeedbackFlag.asObservable();
  defineFeedbackFlag(data: any) {
    this.SetFeedbackFlag.next(data);
  }

  public feedbackData = {
    feedbackFlag: true,
    feedbackTitle: 'Shared Toast error',
    feedbackBody: '',
    tickIcon: false,
    feedbackBtn: {
      status: false,
      text: 'Dismiss'
    }
  };

  setFeedbackData(value) {
    this.feedbackData = value;
    console.log(' have set feedback flag ', value);
  }

  getFeedbackData() {
    console.log('shared ', this.feedbackData);
    return this.feedbackData;
  }

  toastForSuccess(title, body) {
    var feedbackData = {
      feedbackFlag: true,
      feedbackTitle: 'Shared Toast Success',
      feedbackBody: '',
      tickIcon: true,
      feedbackBtn: {
        status: false,
        text: 'Dismiss'
      }
    };

    feedbackData.feedbackTitle = title;
    feedbackData.feedbackBody = body;
    this.defineFeedbackData(feedbackData);
    this.defineFeedbackFlag(true);
    setTimeout(() => {
      this.defineFeedbackFlag(false);
    }, 3000);
  }

  toastForError(title, body) {
    var feedbackData = {
      feedbackFlag: true,
      feedbackTitle: 'Shared Toast error',
      feedbackBody: '',
      tickIcon: false,
      feedbackBtn: {
        status: false,
        text: 'Dismiss'
      }
    };

    feedbackData.feedbackTitle = title;
    feedbackData.feedbackBody = body;
    this.defineFeedbackData(feedbackData);
    this.defineFeedbackFlag(true);
    setTimeout(() => {
      this.defineFeedbackFlag(false);
    }, 3000);
  }

  private SetFeedbackData = new BehaviorSubject({});
  GetFeedbackData = this.SetFeedbackData.asObservable();
  defineFeedbackData(data: any) {
    this.SetFeedbackData.next(data);
  }
}

enum VendorType {
  times = 'TIMES'
}
