import { Inject, Injectable } from '@angular/core';
import { DataRequirements, DataRequirementsInjectionToken } from '../data.requirements';

@Injectable({
  providedIn: 'root'
})
export class UpdateUserUseCase {
  constructor(@Inject(DataRequirementsInjectionToken) private data: DataRequirements) {}

  run(parentData) {
    return this.data.updateUser(parentData);
  }
}
