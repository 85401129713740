import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalComponentsModule } from '../../../global-components/global-components.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { PlansControllerControllerComponent } from './plans-controller-controller.component';
// import {FeatherModule} from 'angular-feather';
import { RouterModule } from '@angular/router';
import { HeaderBarComponent } from './components/header-bar/header-bar.component';
import { PlanTableComponent } from './components/plan-table/plan-table.component';
import { TermsConditionComponent } from './components/terms-condition/terms-condition.component';
const components = [HeaderBarComponent, PlanTableComponent, TermsConditionComponent];
@NgModule({
  declarations: [PlansControllerControllerComponent, components],
  exports: [PlansControllerControllerComponent, components],
  imports: [CommonModule, RouterModule, GlobalComponentsModule, SharedComponentsModule]
})
export class PlansControllerModule {}
