import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { AuthService } from "src/app/lib/auth.service";
import { Router } from "@angular/router";
import { map } from 'rxjs/operators';
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class DiscountApiService {
  private baseUrl = environment.apiurl;
  private nodmaUrl = environment.nodmaApi;
  private userInfo = {
    username: '',
    password: '',
    api_key: ''
  }
  constructor(
    private http: HttpClient,
    private _router: Router,
    private _authService: AuthService
  ) {
    this.userInfo = this._authService.getUserInfo();
    console.log("classes DataFacade",this.userInfo)
  }

  getDiscounntInfo(code){
    let url = this.baseUrl + '/api/v1/discount-info/'+code;
    return this.http.get(url).pipe(
      map((res: Response) => {
        let result = [res];
        return result;
      })
    );
  }

}
