<div class="signup-box">
  <app-signup-header (goLogin)="showLogin($event)"></app-signup-header>
  <div class="signup-header-div">
    <app-text [class]="'signup-header'">Create a parent account</app-text>
  </div>
  <form (keyup.enter)="$event.preventDefault(); enterEvent($event)">
    <div class="names-div">
      <div class="signup-input-div first-name">
        <app-text [class]="'signup-label'">First name</app-text>
        <app-input
          [hint]="'Type your first name'"
          [control]="form.get('firstName')"
          [width]="'100%'"
          [class]="fNameClass"
          (blurEvent)="blurInput($event)"
        >
          <app-image *ngIf="!form.get('firstName').errors && fTick" [src]="tickURL"></app-image>
          <app-image *ngIf="form.get('firstName').errors && clickBtn" [src]="alertURL"></app-image>
        </app-input>
        <app-text *ngIf="form.get('firstName').errors && clickBtn" [class]="'form-error-label'"
          >Enter your first name</app-text
        >
      </div>
      <div class="signup-input-div last-name">
        <app-text [class]="'signup-label'">Last name</app-text>
        <app-input
          [hint]="'Type your last name'"
          [control]="form.get('lastName')"
          [width]="'100%'"
          [class]="lNameClass"
          (blurEvent)="blurInput($event)"
        >
          <app-image *ngIf="!form.get('lastName').errors && lTick" [src]="tickURL"></app-image>
          <app-image *ngIf="form.get('lastName').errors && clickBtn" [src]="alertURL"></app-image>
        </app-input>
        <app-text *ngIf="form.get('lastName').errors && clickBtn" [class]="'form-error-label'"
          >Enter your last name</app-text
        >
      </div>
    </div>

    <div class="signup-input-div">
      <app-text [class]="'signup-label'">Username</app-text>
      <app-input
        [hint]="'Type a username'"
        [control]="form.get('username')"
        [width]="'100%'"
        [class]="usernameClass"
        (blurEvent)="blurInput($event)"
      >
        <app-image *ngIf="!form.get('username').errors && uTick" [src]="tickURL"></app-image>
        <app-image *ngIf="(form.get('username').errors || sameUser) && clickBtn" [src]="alertURL"></app-image>
      </app-input>
      <app-text *ngIf="form.get('username').errors && clickBtn" [class]="'form-error-label'">Enter a username</app-text>
      <app-text *ngIf="sameUser && clickBtn" [class]="'form-error-label'">This username is not available.</app-text>
    </div>
    <div class="signup-input-div">
      <app-text [class]="'signup-label'">Email</app-text>
      <app-input
        [hint]="'Type your email address'"
        [control]="form.get('email')"
        [width]="'100%'"
        [class]="emailClass"
        (blurEvent)="blurInput($event)"
      >
        <app-image *ngIf="!form.get('email').errors && eTick" [src]="tickURL"></app-image>
        <app-image *ngIf="form.get('email').errors && clickBtn" [src]="alertURL"></app-image>
      </app-input>
      <app-text *ngIf="form.get('email').errors && clickBtn" [class]="'form-error-label'">Enter an email</app-text>
    </div>
    <div class="signup-input-div">
      <div class="create-password">
        <app-text [class]="'signup-label'">Create a password</app-text>
        <app-text *ngIf="show" [class]="'showPswd'" (textClick)="showPassword($event)">Show</app-text>
        <app-text *ngIf="hide" [class]="'showPswd'" (textClick)="hidePassword($event)">Hide</app-text>
      </div>
      <app-input
        [hint]="'Add a secure password'"
        [control]="form.get('password')"
        [width]="'100%'"
        [class]="passwordClass"
        [type]="togglePassword"
        (blurEvent)="blurInput($event)"
      >
        <app-image *ngIf="!form.get('password').errors && pTick" [src]="tickURL"></app-image>
        <app-image *ngIf="form.get('password').errors && clickBtn" [src]="alertURL"></app-image>
      </app-input>
      <app-text *ngIf="form.get('password').errors && clickBtn" [class]="'form-error-label'"
        >Passwords must be 8 characters or more</app-text
      >
      <div *ngIf="!form.get('password').errors || !clickBtn" [class]="'form-error-label'">
        <app-text [class]="'signup-text3'"> Passwords must be 8 characters or more </app-text>
      </div>
    </div>
    <div class="signup-input-div pos-relative">
      <app-text [class]="'signup-label'">Country</app-text>
      <app-drop-down-search
        [itemList]="countriesList"
        [hint]="currentCountry"
        [defaultValue]="currentCountry"
        *ngIf="countriesList"
        (isOpen)="isOpenCountry($event)"
        (comebackData)="chosenCountry($event)"
      ></app-drop-down-search>
    </div>
  </form>

  <div class="signup-btn-div" (click)="onSignup($event)">
    <app-button [class]="'btn-orange'" [loading]="pleasewait">
      <app-text [class]="'btn-text-style2'" [loading]="pleasewait">
        {{ pleasewait ? 'Please wait...' : 'Sign up' }}
      </app-text>
    </app-button>
  </div>
  <div class="terms">
    <app-text [class]="'signup-text4'">
      By signing up, you agree to our
      <span class="greentext" (click)="termsOfService($event)">Terms of Services</span>
      and
      <span class="greentext" (click)="privacyPolicy($event)"> Privacy Policy</span>
      .
    </app-text>
  </div>
</div>

<script>
  $('.selectpicker').selectpicker();
</script>
