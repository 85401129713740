<div class="overlay d-flex justify-content-center align-items-baseline">
  <div id="outer-frame" class="outer-frame d-flex flex-column bg-s0">
    <div class="d-flex justify-content-between" style="margin-bottom: 32px">
      <span class="head-text">Cancel subscription?</span>
      <img (click)="closeDelete($event)" class="close-btn" src="../../assets/images/close.svg" />
    </div>
    <div class="inner-frame d-flex align-item-center">
      <div class="warning">
        <app-text>Are you sure you want to cancel your subscription? </app-text><br />
        <app-text
          >Your subscription will be cancelled at the end of your current billing period on
          <span style="font-weight: bold">{{ expireDate | date: 'd MMM yyyy' }}</span
          >.</app-text
        >
      </div>

      <div class="btn-div">
        <app-button [class]="'btn-red'" [loading]="pleaseWait" (click)="deletePlayer($event)">
          <app-text [class]="'btn-text-style2'" [loading]="pleaseWait">
            {{ pleaseWait ? 'Cancelling Subscription...' : 'Confirm cancellation' }}
          </app-text>
        </app-button>
      </div>
      <div style="width: 100%" (click)="closeDelete($event)" class="d-flex justify-content-center align-items-center">
        <app-text
          [ngClass]="{ 'btn-disable': pleaseWait, 'btn-cancel2': !pleaseWait }"
          [class]="'btn-text-cancel2'"
          [loading]="pleaseWait"
          >Back</app-text
        >
      </div>
    </div>
  </div>
</div>
