import { DataRequirements } from '../business-logic/data.requirements';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { UserApiService } from './api-services/user-api.service';

@NgModule({
  imports: [HttpClientModule]
})
export class DataFacade implements DataRequirements {
  constructor(private userApi: UserApiService) {}
  registerParent(parentData): Observable<any> {
    // return of(true);
    return this.userApi.parentSignup(parentData);
  }
  registerParentNew(parentData): Observable<any> {
    // return of(true);
    return this.userApi.parentSignupNew(parentData);
  }
  loginParent(): Observable<any> {
    // return of(true);
    return this.userApi.parentLogin();
  }
  getCountryList(): Observable<any> {
    return this.userApi.getCountries();
  }
  getPublishKey(): Observable<any> {
    return this.userApi.getPublishKey();
  }
  updateUser(parentData: object): Observable<any> {
    return this.userApi.updateUser(parentData);
  }
}
