import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { of } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root",
})
export class PlayerDetailService {
  private baseUrl = environment.apiurl;
  constructor(private http: HttpClient) {}
  getPlayer(playerId) {
    let url = this.baseUrl + "/api/v2/users/" + playerId;
    console.log(url);
    return this.http.get(url).pipe(
      map((res: Response) => {
        // let result = [];
        let result = res;
        console.log(result);
        return result;
      })
    );
  }
  checkEmptySub(){
    return of(false);
  }
  // editPlayer(playerId, playerData) {
  //   let url = this.baseUrl + "/api/v2/users/" + playerId;
  //   console.log(url);
  //   console.log(playerData);
  //   return this.http.put(url, playerData).pipe(
  //     map((res: Response) => {
  //       let result = [];
  //       result[0] = res;
  //       console.log(result);
  //       return result;
  //     })
  //   );
  //   // return of (true);
  // }
  // deletePlayer(playerId) {
  //   let url = this.baseUrl + "/api/v2/users/" + playerId;
  //   console.log(url);
  //   return this.http.delete(url).pipe(
  //     map((res: Response) => {
  //       let result = res;
  //       console.log(result);
  //       return result;
  //     })
  //   );
  // }
}
