import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SignupHeaderStore } from "./signup-header.store";
import { environment } from "../../../../../../environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "app-signup-header",
  templateUrl: "./signup-header.component.html",
  styleUrls: ["./signup-header.component.css"],
})
export class SignupHeaderComponent {
  @Output() goLogin = new EventEmitter;
  constructor(private store: SignupHeaderStore, private router: Router) {}
  showLogin($event) {
    console.log("showLogin");
    // environment.initState = "login";
    this.goLogin.emit(true);
    // this.router.navigateByUrl(`/login`);
  }
}
