import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PlayerDetailService {
    constructor(http) {
        this.http = http;
        this.baseUrl = environment.apiurl;
        this.nodmaUrl = environment.nodmaApi;
    }
    getPlayer(playerId) {
        let url = this.baseUrl + '/api/v2/users/' + playerId;
        console.log(url);
        return this.http.get(url).pipe(map((res) => {
            // let result = [];
            let result = res;
            console.log(result);
            return result;
        }));
    }
    editPlayer(playerId, playerData) {
        let url = this.baseUrl + '/api/v2/users/' + playerId;
        console.log(url);
        console.log(playerData);
        return this.http.put(url, playerData).pipe(map((res) => {
            let result = [];
            result[0] = res;
            console.log(result);
            return result;
        }));
        // return of (true);
    }
    deletePlayer(playerId) {
        let url = this.baseUrl + '/api/v2/users/' + playerId;
        console.log(url);
        return this.http.delete(url).pipe(map((res) => {
            let result = res;
            console.log(result);
            return result;
        }));
    }
    getCountries() {
        console.log('country--->');
        let url = this.nodmaUrl + '/api/v1/country';
        return this.http.get(url).pipe(map((res) => {
            let result = [res];
            return result;
        }));
    }
}
PlayerDetailService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlayerDetailService_Factory() { return new PlayerDetailService(i0.ɵɵinject(i1.HttpClient)); }, token: PlayerDetailService, providedIn: "root" });
