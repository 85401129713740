/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./information.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../global-components/logo/logo.component.ngfactory";
import * as i3 from "../../../../global-components/logo/logo.component";
import * as i4 from "../../../../global-components/logo/logo.store";
import * as i5 from "../../../../lib/shared-service";
import * as i6 from "../../../../global-components/button/button.component.ngfactory";
import * as i7 from "../../../../global-components/button/button.component";
import * as i8 from "../../../../global-components/button/button.store";
import * as i9 from "../../../../global-components/text/text.component.ngfactory";
import * as i10 from "../../../../global-components/text/text.component";
import * as i11 from "../../../../global-components/text/text.store";
import * as i12 from "./information.component";
import * as i13 from "./information.store";
var styles_InformationComponent = [i0.styles];
var RenderType_InformationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InformationComponent, data: {} });
export { RenderType_InformationComponent as RenderType_InformationComponent };
export function View_InformationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "info-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "img-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "assets/images/stemwerkz_may.jpg"], ["style", "\n    width: 100%;\n"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "info-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-logo", [], null, null, null, i2.View_LogoComponent_0, i2.RenderType_LogoComponent)), i1.ɵdid(5, 245760, null, 0, i3.LogoComponent, [i4.LogoStore, i5.SharedService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "app-button", [["class", "mobile-view"]], null, [[null, "btnClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("btnClick" === en)) {
        var pd_0 = (_co.onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ButtonComponent_0, i6.RenderType_ButtonComponent)), i1.ɵdid(7, 49152, null, 0, i7.ButtonComponent, [i8.ButtonStore], { class: [0, "class"], width: [1, "width"], height: [2, "height"] }, { btnClick: "btnClick" }), (_l()(), i1.ɵeld(8, 0, null, 0, 2, "app-text", [], null, null, null, i9.View_TextComponent_0, i9.RenderType_TextComponent)), i1.ɵdid(9, 114688, null, 0, i10.TextComponent, [i11.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(10, 0, [" ", " "]))], function (_ck, _v) { _ck(_v, 5, 0); var currVal_0 = "btn-orange"; var currVal_1 = "96px"; var currVal_2 = "40px"; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "btn-text-style2"; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.buttonText; _ck(_v, 10, 0, currVal_4); }); }
export function View_InformationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-information", [], null, null, null, View_InformationComponent_0, RenderType_InformationComponent)), i1.ɵdid(1, 49152, null, 0, i12.InformationComponent, [i13.InformationStore], null, null)], null, null); }
var InformationComponentNgFactory = i1.ɵccf("app-information", i12.InformationComponent, View_InformationComponent_Host_0, { buttonText: "buttonText" }, { buttonClick: "buttonClick" }, []);
export { InformationComponentNgFactory as InformationComponentNgFactory };
