import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GlobalComponentsModule } from "../../../global-components/global-components.module";
import { SharedComponentsModule } from "../shared-components/shared-components.module";
import { LoginControllerComponent } from "./login-controller.component";
// import {FeatherModule} from 'angular-feather';
import { RouterModule } from "@angular/router";
import { LoginHeaderComponent } from "./components/login-header/login-header.component";
import { LoginFooterComponent } from "./components/login-footer/login-footer.component";

@NgModule({
  declarations: [LoginControllerComponent, LoginHeaderComponent, LoginFooterComponent],
  exports: [LoginControllerComponent],
  imports: [
    CommonModule,
    RouterModule,
    GlobalComponentsModule,
    SharedComponentsModule,
  ],
})
export class LoginModule {}
