import {Component, Input, Output, EventEmitter} from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
    selector: 'app-input-box',
    templateUrl: './input-box.component.html',
    styleUrls: ['./input-box.component.css']
})
export class InputBoxComponent {
  @Input() inputLevel;
  @Input() note = '';
  @Input() type;
  @Input() required = true;
  @Input() textArea = false;
  @Input() error = '';
  @Input() value = '';
  @Input() placeholder;

  @Output() onInputChange = new EventEmitter<any>();

  public form = this.fb.group({
    value: this.fb.control('')
  });

  constructor(private fb: FormBuilder) {
    this.form.get("value").valueChanges.subscribe((val) => {
      this.onInputChange.emit(val);
    });

  }

  ngOnChanges() {
    if(this.value==null){
      this.form.get('value').setValue('');
    }
  }

  blurInput(){
    // this.onInputChange.emit(this.form.get('value').value);
  }
}
