import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoginStore {

}

export enum LoginStoreKeys {

}
