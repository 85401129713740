import { Inject, Injectable } from "@angular/core";
import {
  DataRequirements,
  DataRequirementsInjectionToken,
} from "../data.requirements";
import { PlayerEditMapper } from "../mappers/player-edit-mapper";

@Injectable({
  providedIn: "root",
})
export class EditPlayerUseCase {
  constructor(
    private mapper: PlayerEditMapper,
    @Inject(DataRequirementsInjectionToken) private data: DataRequirements
  ) {}

  run(playerId, playerData) {
    return this.data.editPlayer(playerId, playerData).pipe(this.mapper.map);
  }
}
