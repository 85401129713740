import { Component, HostListener } from '@angular/core';
import { SharedService } from './lib/shared-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'steamwerkz-web';

  public globalFeedbackFlag;
  public globalFeedbackService;
  public globalFeedbackData;
  public globalFeedbackDataService;

  public feedbackData = {
    feedbackFlag: true,
    feedbackTitle: 'Shared Toast error',
    feedbackBody: '',
    tickIcon: false,
    feedbackBtn: {
      status: false,
      text: 'Dismiss'
    }
  };

  constructor(private shared: SharedService) {}

  ngOnInit() {
    this.shared.defineFeedbackFlag(false);
    this.globalFeedbackService = this.shared.GetFeedbackFlag.subscribe((flag) => {
      this.globalFeedbackFlag = flag;
    });

    this.shared.defineFeedbackData(this.feedbackData);
    this.globalFeedbackDataService = this.shared.GetFeedbackData.subscribe((data) => {
      this.globalFeedbackData = data;
    });

    if (window.innerWidth < 768 || document.body.clientWidth < 768) {
      this.shared.setMobileView(true);
      this.shared.defineMobileView(true);
    } else this.shared.defineMobileView(false);
  }

  ngOnDestroy() {
    this.globalFeedbackDataService.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(e) {
    // console.log('resize',window.innerWidth,document.body.clientWidth)
    if (window.innerWidth < 768 || document.body.clientWidth < 768) this.shared.defineMobileView(true);
    else this.shared.defineMobileView(false);
  }

  dimissFeedbackBtn($event) {
    this.globalFeedbackFlag = false;
  }

  //comment to test dev
}
