/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./giftcard-purchase-processing-controller.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./components/gift-fail/gift-fail.component.ngfactory";
import * as i3 from "./components/gift-fail/gift-fail.component";
import * as i4 from "./components/gift-success/gift-success.component.ngfactory";
import * as i5 from "./components/gift-success/gift-success.component";
import * as i6 from "@angular/common";
import * as i7 from "./giftcard-purchase-processing-controller.component";
import * as i8 from "@angular/router";
import * as i9 from "../business-logic.requirements";
var styles_GiftcardPurchaseProcessingControllerComponent = [i0.styles];
var RenderType_GiftcardPurchaseProcessingControllerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GiftcardPurchaseProcessingControllerComponent, data: {} });
export { RenderType_GiftcardPurchaseProcessingControllerComponent as RenderType_GiftcardPurchaseProcessingControllerComponent };
function View_GiftcardPurchaseProcessingControllerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-gift-fail", [], null, [[null, "onButtonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onButtonClick" === en)) {
        var pd_0 = (_co.backToGiftShop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_GiftFailComponent_0, i2.RenderType_GiftFailComponent)), i1.ɵdid(1, 49152, null, 0, i3.GiftFailComponent, [], null, { onButtonClick: "onButtonClick" })], null, null); }
function View_GiftcardPurchaseProcessingControllerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-gift-success", [], null, [[null, "buyAnotherCardClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buyAnotherCardClick" === en)) {
        var pd_0 = (_co.backToGiftShop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_GiftSuccessComponent_0, i4.RenderType_GiftSuccessComponent)), i1.ɵdid(1, 573440, null, 0, i5.GiftSuccessComponent, [], { giftCard: [0, "giftCard"], homeUrl: [1, "homeUrl"] }, { buyAnotherCardClick: "buyAnotherCardClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.giftCardInfo; var currVal_1 = _co.backToHomeUrl; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_GiftcardPurchaseProcessingControllerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GiftcardPurchaseProcessingControllerComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GiftcardPurchaseProcessingControllerComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.success; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.success; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_GiftcardPurchaseProcessingControllerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-giftcard-purchase-processing", [], null, null, null, View_GiftcardPurchaseProcessingControllerComponent_0, RenderType_GiftcardPurchaseProcessingControllerComponent)), i1.ɵdid(1, 114688, null, 0, i7.GiftcardPurchaseProcessingControllerComponent, [i8.Router, i8.ActivatedRoute, i9.BusinessRequirementsInjectionToken], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GiftcardPurchaseProcessingControllerComponentNgFactory = i1.ɵccf("app-giftcard-purchase-processing", i7.GiftcardPurchaseProcessingControllerComponent, View_GiftcardPurchaseProcessingControllerComponent_Host_0, {}, {}, []);
export { GiftcardPurchaseProcessingControllerComponentNgFactory as GiftcardPurchaseProcessingControllerComponentNgFactory };
