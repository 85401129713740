import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeFeatureModule } from './home/home.module';
import { DashboardFeatureModule } from './dashboard/dashboard.module';
import { UserPlansFeatureModule } from './user-plans/user-plans.module';
import { AuthService } from './lib/auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './lib/auth-interceptor.service';
import { RedirectFeatureModule } from './redirect/redirect.module';
import { ParentDetailFeatureModule } from './parent-detail/parent-detail.module';
import { ForgotPasswordFeatureModule } from './forgot-password/forgot-password.module';
import { GiftCardShopFeatureModule } from './gift-card-shop/gift-card-shop.module';
import { PageNotFoundFeatureModule } from './page-not-found/page-not-found.module';
import { SchoolSignupModule } from './school/presentation/school-signup/school-signup.module';
import { SchoolFeatureModule } from './school/school.module';
import { CodeVerificationFeatureModule } from './code-verification/code-verification.module';
import { SchoolPlansFeatureModule } from './school-plans/school-plans.module';
import { GlobalComponentsModule } from './global-components/global-components.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HomeFeatureModule,
    DashboardFeatureModule,
    UserPlansFeatureModule,
    RedirectFeatureModule,
    ParentDetailFeatureModule,
    ForgotPasswordFeatureModule,
    GiftCardShopFeatureModule,
    PageNotFoundFeatureModule,
    SchoolFeatureModule,
    CodeVerificationFeatureModule,
    SchoolPlansFeatureModule,
    GlobalComponentsModule
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
