import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/subscription-plans-mapper";
import * as i2 from "../data.requirements";
export class GetPlanListUsecase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run() {
        console.log('usecase', this.data.getPlanList().pipe(this.mapper.map));
        return this.data.getPlanList().pipe(this.mapper.map);
        //return this.data.getGAuth(url);
    }
}
GetPlanListUsecase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetPlanListUsecase_Factory() { return new GetPlanListUsecase(i0.ɵɵinject(i1.SubscriptionMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: GetPlanListUsecase, providedIn: "root" });
