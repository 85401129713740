<app-header *ngIf="parentData" (openProfile)="openProfile($event)" [parentData]="parentData"></app-header>

<div (click)="backPage()" class="back-div" *ngIf="currentPlanName">
  <img class="mr-8" src="../../../../assets/images/back-btn.svg" /> Back
</div>

<img *ngIf="!parentData" src="../../../assets/images/STEMWerkzLogo.png" />

<br *ngIf="isChange == 'false'" />
<label class="choice" *ngIf="isChange == 'false' && unsubscribe != 'true'">{{
  giftCodeAdded ? 'Claim Gift Card' : 'Good Choice'
}}</label>
<label class="choice" *ngIf="isChange == 'true' && unsubscribe != 'true'">Change Plan</label>
<label class="choice" *ngIf="unsubscribe == 'true'">You have unsubscribed</label>
<p class="highlight" *ngIf="unsubscribe != 'true'">Don't worry, you can add more child accounts later.</p>
<p class="highlight" *ngIf="unsubscribe == 'true'">
  Your subscription has ended. You can subscribe to the previous plan again or change to a new plan.
</p>
