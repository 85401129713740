<div class="header-bg">
    <app-image [src]="headerBg" [class]="'forgot-banner'"></app-image>
</div>
<div class="body-wrapper w-100">
    <div class="back-wrapper back-wrapper-margin bg-s0" routerLink="/login">
        <app-image [src]="backIcon"></app-image>
    </div>
    <div class="d-flex align-items-center flex-column padding-mobile">
        <div style="margin-top: 24px;">
            <app-image class="desktop-view" [src]="logo"></app-image>
            <app-image class="mobile-view" [src]="logoMobile"></app-image>
        </div>
        <div class="password-card bg-s0 d-flex flex-column" (keyup.enter)="$event.preventDefault(); sentMail()">
            <app-text class="card-title">Forgot your password?</app-text>
            <app-text class="card-desc text-c200">Enter your email below. We'll send you an email with a link to reset your password.</app-text>
            <app-input style="margin-top: 40px;"
            [hint]="'Type an email...'"
            [control]="form.get('email')"
            [width]="'100%'"
            [class]="emailClass"
            [value]="email"></app-input>
            <app-text *ngIf="form.get('email').errors  && (form.get('email').dirty || form.get('email').touched)" [class]="'form-error-label'">invalid email</app-text>
            <app-button [class]="btnStatus" style="margin-top: 32px;" (click)="sentMail()">
                <app-text [class]="btnTextStatus">
                    {{btnText}}
                </app-text>
            </app-button>

        </div>
        <div class="note-wrapper ">
            <app-text class="card-note text-c200">NOTE: This will reset password for all the accounts registered under your current email.</app-text>
        </div>
    </div>
</div>

<div class="feedback-wrapper" id="feedback_wrapper" [ngClass]="{'reset-error' : resetError}">
  <div class="feedback bg-fail" *ngIf="feedbackFlag && !isSuccess">
    <app-image [src]="feedbackFail" style="margin-right: 16px;"></app-image>
    <div class="feedback-text">
      <app-text class="feedback-title text-s0">Oh no! Something went wrong!</app-text>
      <app-text class="feedback-body text-s0">{{feedbackTitle}} Please try again.</app-text>
    </div>
    <app-image [src]="cancelBtn" (click)="hideFeedback()" style="cursor: pointer;"></app-image>
  </div>

  <div class="feedback bg-success" *ngIf="feedbackFlag && isSuccess">
    <app-image [src]="feedbackSuccess" style="margin-right: 16px;"></app-image>
    <div class="feedback-text">
      <app-text class="feedback-title text-s0">Password reset email sent!</app-text>
      <app-text class="feedback-body text-s0">We have sent an email to your rescue in your inbox, {{email}}. Follow the instructions in the email to reset your password.</app-text>
      <br>
  <app-text class="feedback-body text-s0">If you don’t see your reset email be sure to check your spam filter for an email from </app-text>
  <app-text class="feedback-body text-s0" style="display: block;">no-reply@pagewerkz.com</app-text>
</div>
    <app-image [src]="cancelBtn" (click)="hideFeedback()" style="cursor: pointer;"></app-image>
  </div>
</div>
<app-query-params-ls></app-query-params-ls>

<!-- <div class="feedback-text">
  <app-text class="feedback-title text-s0">Password reset email sent!</app-text>
  <app-text class="feedback-body text-s0">We have sent an email to your rescue in your inbox, {{email}}. Follow the instructions in the email to reset your password. </app-text>
  <br>
  <app-text class="feedback-body text-s0">If you don’t see your reset email be sure to check your spam filter for an email from no-reply@pagewerkz.com</app-text>
</div> -->
