<div class="info-bg">
    <div class="img-bg">
        <img src="assets/images/stemwerkz_may.jpg" style="
    width: 100%;
">
    </div>
  <div class="info-div">
    <app-logo></app-logo>
    <app-button class="mobile-view" [class]="'btn-orange'" [width]="'96px'" [height]="'40px'" (btnClick)="onClick($event)">
        <app-text [class]="'btn-text-style2'"> {{buttonText}} </app-text>
    </app-button>
  </div>
</div>
