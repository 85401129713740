import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderBarStore } from './header-bar.store';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.css']
})
export class HeaderBarComponent {
  @Input() parentData;
  @Input() currentPlanObj;
  @Input() unsubscribe;
  @Input() templateName;
  constructor(private store: HeaderBarStore, private router: Router) {}

  openProfile(value) {
    this.router.navigateByUrl('/parent/detail');
  }

  backPage() {
    this.router.navigateByUrl('/parent/detail');
  }
}
