<div class="lower-div" *ngIf="!isMobileView">
  <div class="title">Payment history</div>
  <table class="history-table">
    <tr class="mini-title" height="60px">
      <th><app-text>Product</app-text></th>
      <th><app-text>Date</app-text></th>
      <th><app-text>Amount</app-text></th>
      <th><app-text>Receipt</app-text></th>
    </tr>
    <tr *ngFor="let list of player.payment" class="history" height="60px">
      <td>
        <app-text>{{ list?.lineItems[0].subscription ? list?.lineItems[0].subscription.name : '-' }}</app-text>
      </td>
      <td>
        <app-text>{{ list?.createdAt | date: 'd MMM yyyy' }}</app-text>
      </td>
      <td>
        <app-text>${{ list?.amount }}</app-text>
      </td>
      <td class="receipt cursor" *ngIf="list.url">
        <a href="{{ list.url }}" target="_blank" class="anchor">View receipt</a>
      </td>
      <td class="receipt cursor" *ngIf="!list.url">---</td>
    </tr>
  </table>
</div>

<div class="lower-div pt-40" *ngIf="isMobileView && onlyPaymentHistory">
  <div class="title">Payment history</div>
  <div>
    <div class="d-flex">
      <div class="col1 mini-title">
        <app-text>Product</app-text>
      </div>
      <div class="col2 mini-title">
        <app-text>Date</app-text>
      </div>
      <div class="col3 mini-title">
        <app-text>Amount</app-text>
      </div>
    </div>
    <div class="history d-flex" *ngFor="let list of player.payment">
      <div class="col1">
        <app-text>{{ list?.lineItems[0].subscription ? list?.lineItems[0].subscription.name : '-' }}</app-text>
        <div class="receipt cursor mb-12" *ngIf="list.url">
          <a href="{{ list.url }}" target="_blank" class="anchor">View receipt</a>
        </div>
        <div class="receipt cursor mb-12" *ngIf="!list.url">---</div>
      </div>
      <div class="col2">
        <app-text>{{ list.createdAt | date: 'd MMM yyyy' }}</app-text>
      </div>
      <div class="col3">
        <app-text>${{ list.amount }}</app-text>
      </div>
    </div>
  </div>
</div>

<div class="history-tab" *ngIf="isMobileView && !onlyPaymentHistory" (click)="showPaymentHistory()">
  <div class="history-text">Payment history</div>
  <app-image [src]="c_right"></app-image>
</div>
