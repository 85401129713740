<div class="plan-header mobile-view">
  <div
    *ngFor="let plan of planList"
    class="mobile-plan-header"
    (click)="showDetail(plan.planName, plan.planId, plan.planMonthly, plan.playerSlot)"
    [ngClass]="{
      'border-none': plan.planName == selectedPlan,
      'mobile-selected': plan.planName == selectedPlan,
      'mobile-current': plan.planName == currentPlanObj.planName && typeFromParams != 'trial'
    }"
  >
    {{ plan.planName }}
  </div>
</div>

<table class="table">
  <tr class="desktop-view">
    <th></th>
    <th
      id="{{ plan.planName }}"
      (click)="showDetail(plan.planName, plan.planId, plan.planMonthly, plan.playerSlot)"
      (mouseenter)="headerEnter(plan.planName)"
      (mouseleave)="headerLeave(plan.planName)"
      class="plan-type value cursor"
      *ngFor="let plan of planList"
      [ngClass]="{
        'selected-plan': plan.planName == selectedPlan,
        'current-plan':
          plan.planName == currentPlanObj.planName && isSchoolDowngrade?.length > 0 && typeFromParams != 'trial'
      }"
    >
      {{ plan.planName }}
      <div
        id="{{ plan.planName | lowercase }}-rect"
        [ngClass]="{ 'selected-border': plan.planName == selectedPlan }"
      ></div>
    </th>
  </tr>

  <tr>
    <td class="td-title">Monthly price</td>
    <td
      id="{{ plan.planName | lowercase }}-price"
      class="plan-content cursor"
      [ngClass]="{
        'selected-plan': plan.planName == selectedPlan,
        'current-plan':
          plan.planId == currentPlanObj.planId && isSchoolDowngrade?.length > 0 && typeFromParams != 'trial',
        'desktop-view': mobilePlan != plan.planName
      }"
      *ngFor="let plan of planList"
      (mouseenter)="headerEnter(plan.planName)"
      (mouseleave)="headerLeave(plan.planName)"
    >
      {{ plan.planMonthly }} {{ plan.currency | uppercase }}
    </td>
  </tr>
  <tr>
    <td class="td-title">Child accounts</td>
    <td
      id="account"
      class="plan-content cursor"
      [ngClass]="{
        'selected-plan': plan.planName == selectedPlan,
        'current-plan':
          plan.planId == currentPlanObj.planId && isSchoolDowngrade?.length > 0 && typeFromParams != 'trial',
        'desktop-view': mobilePlan != plan.planName
      }"
      *ngFor="let plan of planList"
      (mouseenter)="headerEnter(plan.planName)"
      (mouseleave)="headerLeave(plan.planName)"
    >
      {{ plan.playerSlot }}
    </td>
  </tr>
  <tr>
    <td class="td-title">1,000+ engaging STEM lessons</td>
    <td
      *ngFor="let plan of planList"
      class="plan-content cursor"
      (mouseenter)="headerEnter(plan.planName)"
      (mouseleave)="headerLeave(plan.planName)"
      [ngClass]="{ 'desktop-view': mobilePlan != plan.planName }"
    >
      <app-image
        [src]="noramlTick"
        *ngIf="
          (plan.planName != selectedPlan && plan.planId != currentPlanObj.planId) ||
          (plan.planName != selectedPlan && plan.planId == currentPlanObj.planId && typeFromParams == 'trial')
        "
      ></app-image>
      <app-image
        [src]="selectedTick"
        *ngIf="
          (plan.planName == selectedPlan && plan.planId != currentPlanObj.planId) ||
          (plan.planName == selectedPlan && plan.planId == currentPlanObj.planId && typeFromParams == 'trial')
        "
      ></app-image>
      <app-image
        [src]="currentTick"
        *ngIf="plan.planId == currentPlanObj.planId && typeFromParams != 'trial'"
      ></app-image>
    </td>
  </tr>
  <tr *ngIf="!currentPlanObj.planId || typeFromParams == 'trial'">
    <td
      class="td-title"
      [ngClass]="{ 'line-through': parentData?.metadata.vendor || (remainTrialDay && remainTrialDay <= 0) }"
    >
      {{ remainTrialDay }} days free trial
    </td>
    <ng-container *ngIf="!parentData?.metadata.vendor">
      <td
        *ngFor="let plan of planList"
        class="plan-content cursor"
        [ngClass]="{ 'desktop-view': mobilePlan != plan.planName }"
        (mouseenter)="headerEnter(plan.planName)"
        (mouseleave)="headerLeave(plan.planName)"
      >
        <app-image
          [src]="noramlTick"
          *ngIf="
            (plan.planName != selectedPlan && plan.planId != currentPlanObj.planId) ||
            (plan.planName != selectedPlan && plan.planId == currentPlanObj.planId)
          "
        ></app-image>
        <app-image
          [src]="selectedTick"
          *ngIf="
            (plan.planName == selectedPlan && plan.planId != currentPlanObj.planId) ||
            (plan.planName == selectedPlan && plan.planId == currentPlanObj.planId && typeFromParams == 'trial')
          "
        ></app-image>
        <app-image
          [src]="currentTick"
          *ngIf="plan.planId == currentPlanObj.planId && typeFromParams != 'trial'"
        ></app-image>
      </td>
    </ng-container>
    <ng-container *ngIf="parentData?.metadata.vendor">
      <td
        id="account"
        class="plan-content cursor"
        [ngClass]="{
          'selected-plan': plan.planName == selectedPlan,
          'current-plan':
            plan.planName == currentPlanObj.planName && isSchoolDowngrade?.length > 0 && typeFromParams != 'trial',
          'desktop-view': mobilePlan != plan.planName
        }"
        *ngFor="let plan of planList"
        (mouseenter)="headerEnter(plan.planName)"
        (mouseleave)="headerLeave(plan.planName)"
      >
        -
      </td>
    </ng-container>
  </tr>
  <tr *ngIf="currentPlanObj.planId && typeFromParams != 'trial'">
    <td class="td-title">Status</td>
    <td
      *ngFor="let plan of planList"
      class="plan-content cursor"
      [ngClass]="{
        'selected-plan': plan.planName == selectedPlan,
        'current-plan':
          plan.planName == currentPlanObj.planName && isSchoolDowngrade?.length > 0 && typeFromParams != 'trial',
        'desktop-view': mobilePlan != plan.planName
      }"
      (mouseenter)="headerEnter(plan.planName)"
      (mouseleave)="headerLeave(plan.planName)"
    >
      <app-text *ngIf="plan.planId == currentPlanObj.planId">Current plan</app-text>
      <app-text *ngIf="plan.planId != currentPlanObj.planId">{{
        plan.playerSlot > currentPlanObj.playerSlot && currentPlanObj.interval != 'year'
          ? 'Upgrade'
          : plan.playerSlot == currentPlanObj.playerSlot && currentPlanObj.interval != 'year'
          ? 'Current Plan'
          : 'Downgrade'
      }}</app-text>
    </td>
  </tr>
</table>
