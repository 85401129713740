import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalComponentsModule } from '../../../global-components/global-components.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { PlansChosenControllerComponent } from './plans-chosen-controller.component';
// import {FeatherModule} from 'angular-feather';
import { RouterModule } from '@angular/router';
import { HeaderBarComponent } from './components/header-bar/header-bar.component';
import { PlanDetailComponent } from './components/plan-detail/plan-detail.component';
import { TermsConditionComponent } from './components/terms-condition/terms-condition.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
const components = [HeaderBarComponent, PlanDetailComponent, TermsConditionComponent, InfoCardComponent];
@NgModule({
  declarations: [PlansChosenControllerComponent, components],
  exports: [PlansChosenControllerComponent, components],
  imports: [CommonModule, RouterModule, GlobalComponentsModule, SharedComponentsModule]
})
export class PlansChosenModule {}
