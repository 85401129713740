import { Component, Input } from '@angular/core';
import { SharedService } from 'src/app/lib/shared-service';
import { PlanDetailStore } from './plan-detail.store';

@Component({
  selector: 'app-plan-detail',
  templateUrl: './plan-detail.component.html',
  styleUrls: ['./plan-detail.component.css']
})
export class PlanDetailComponent {
  @Input() loader;
  @Input() isChange;
  @Input() unsubscribe;
  @Input() parentData;
  @Input() remainTrialDay;
  @Input() trialDays;
  @Input() downgrade;
  @Input() plan;
  @Input() giftCodeAdded;
  public mobileView;
  public mobileViewService;
  public selectedTick = 'assets/images/selected-tick.svg';
  constructor(private store: PlanDetailStore, private _sharedService: SharedService) {}

  ngOnInit() {
    this.mobileViewService = this._sharedService.GetMobileView.subscribe((data) => {
      this.mobileView = data;
    });
    console.log('giftcode added? ', this.giftCodeAdded);
  }
}
