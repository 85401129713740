export class UserDetailModel {
  username: 'winwinnaing+p3@amdon.com';
  shortName: '--';
  email: 'winwinnaing+p3@amdon.com';
  profileImage: '';
  fullName: string = '';
  firstName: string = 'first';
  lastName: string = 'last';
  id: string = '';
  subscription = [];
  metadata = { dd: 'h' };
  onboardLog = { aa: 'h' };
  payment = [];
  players = [];
  gender = null;
}
