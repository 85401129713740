import { Inject, Component, OnInit } from '@angular/core';
import { BusinessLogicRequirements, BusinessRequirementsInjectionToken } from '../business-logic.requirements';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/lib/auth.service';
import { loadStripe } from '@stripe/stripe-js';
import * as moment from 'moment';
import { SharedService } from 'src/app/lib/shared-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-plans-chosen',
  templateUrl: './plans-chosen-controller.component.html',
  styleUrls: ['./plans-chosen-controller.component.css']
})
export class PlansChosenControllerComponent implements OnInit {
  constructor(
    @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements,
    private router: Router,
    private route: ActivatedRoute,
    private _authService: AuthService,
    private _sharedService: SharedService
  ) {}

  public planId: any;
  public plan: any;
  public planIndex = 'Solo';
  public selectedTick = 'assets/images/selected-tick.svg';
  public userInfo: any;
  public parentData: any;
  public discountCode = '';
  public correctDiscountCode = false;
  public discountFlag: boolean = false;
  public giftFlag: boolean = false;
  public giftCode = '';
  // public showerr = false;
  public discountText = 'Add discount code';
  public currentPlanName = null;
  public loading: boolean = false;
  public isChange = 'false';
  public date = new Date();
  public momentToday = moment(this.date);
  public giftmomentToday = moment(this.date);
  public momentNext;
  public giftCodeAdded: boolean = false;
  public isAuth = false;
  public isEmpty = true;
  public invalidCode: boolean = false;
  public downgrade = '';
  public unsubscribe = '';
  public giftExpiry;
  public planType = '';
  public expiryDate: any;
  public skipFlag: any = null;
  public redeeming: boolean = false;
  public loader: boolean = true;
  public mobileView;
  public mobileViewService;
  public trialDays;
  public trialDaysText;
  public isPromo = false;
  public remainTrialDay = null;
  public templateName;
  public subLoading: boolean = false;
  public amount: any;
  public currentPlanObj;

  ngOnInit() {
    this.mobileViewService = this._sharedService.GetMobileView.subscribe((data) => {
      this.mobileView = data;
    });
    this.isAuth = this._authService.isAuthenticated();

    this.parseParams();
    this.parseQueryParams();

    // call plan information before Auth as user can enter gift info with no auth
    // getting info ahead userdetails

    if (this.planId) this.getPlan(this.planId);
    //planchosen or changeplan
    else this.getGiftInfo(this.giftCode); //gift card

    this.determineAuth();
  }

  parseParams() {
    this.route.params.subscribe((data) => {
      console.log('params data', data);
      this.planId = data.id;
    });
  }

  parseQueryParams() {
    this.route.queryParams.subscribe((data) => {
      console.log('queryParmas data ', data);
      this.downgrade = data.downgrade;
      if (data.unsubscribe) {
        this.unsubscribe = data.unsubscribe;
      }
      if (data.discountcode) {
        this.discountCode = data.discountcode;
        this.discountText = 'Remove discount code';
        this.correctDiscountCode = true;
      }
      if (data.change) {
        this.isChange = data.change;
      }
      if (data.giftcode) {
        //giftcode from link
        this.giftCode = data.giftcode;
        this.giftCodeAdded = true;
        this.templateName = 'emptyGiftcard';
        this._sharedService.setGiftCard(this.giftCode);
      }
      if (data.skip) {
        //giftcode from link
        this.skipFlag = data.skip;
        this.redeeming = true;
      }
    });
  }

  determineAuth() {
    if (this.isAuth) {
      this.userInfo = this._authService.getUserInfo();
      console.log('auth', this.userInfo);
      var encodedData = localStorage.getItem('parentDetailObj');
      if (encodedData) this.parentData = JSON.parse(this._authService.decrypt(encodedData, null));
      this.getParentDetail();
    } else {
      if (this.discountCode != '') {
        //come from url with plan id and discount code then redirect to sign up
        this.router.navigateByUrl('/signup?planId=' + this.planId + '&discountcode=' + this.discountCode);
      } else if (this.giftCode != '') {
        console.log('Gift Card exists'); // if gitft card exists then will show gift card info
      } else this.router.navigateByUrl('/signup?planId=' + this.planId);
      //come from url with plan id without discount code then redirect to sign up
    }
  }

  //done above

  setupPayment() {
    localStorage.setItem('planForPixel', JSON.stringify(this.plan));
    localStorage.setItem('planType', this.plan.planName);
    this.loading = true;
    if (this.giftCodeAdded) {
      console.log('subscribe');
      this.business.claimGiftCard(this.giftCode, 'setup-payment', window.location.origin + '/dashboard').subscribe(
        (res) => {
          var sessionId = '';
          var publish_key = '';
          sessionId = res[0].sessionId;
          console.log('res', res);
          console.log('session id ', sessionId);
          this.business.getPublishKey().subscribe(async (res) => {
            this.loading = false;
            if (localStorage.getItem('isUpgrade')) localStorage.removeItem('isUpgrade');
            publish_key = res.publishableKey;
            console.log('publish key', publish_key);
            const stripe = await loadStripe(publish_key);
            stripe
              .redirectToCheckout({
                sessionId: sessionId
              })
              .then(function (result) {
                console.log(result);
              });
          });
        },
        (err) => {
          this.loading = false;
          console.log(err);
          this._sharedService.toastForError('Something went wrong! ' + err.error.message, '');

          setTimeout(() => {
            this.redeeming = false;
          }, 1500);
        }
      );
    } else this.upgradeSubscription();
  }

  signUp() {
    console.log('signUp and clear storage', this.giftCode);
    if (this.isAuth && this.isEmpty && this.giftCodeAdded) {
      console.log('claim with setup');
    } else {
      var ref;
      if (localStorage.getItem('ref')) ref = localStorage.getItem('ref');
      localStorage.clear();
      localStorage.setItem('ref', ref);
      this.router.navigateByUrl('/signup?giftcode=' + this.giftCode + '&skip=false');
    }
  }

  cancelPayment() {
    if (this.isAuth) {
      if (this.unsubscribe == 'true') this.router.navigateByUrl(`/parent/detail`);
      else this.router.navigateByUrl(`/plans`);
      // localStorage.removeItem('giftCode');
    } else if (this.giftCodeAdded) {
      this.router.navigateByUrl('/signup');
    } else {
      this.router.navigateByUrl('/signup?planId=' + this.planId);
    }
  }

  getPlan(id) {
    console.log('get plan');
    this.business.getPlan(id).subscribe(
      (data) => {
        console.log('plan', data[0]);
        this.plan = data[0];
        this.trialDays = this.plan.trialDays;
        if (this.trialDays == 14) {
          this.trialDaysText = 'fourteen';
        } else if (this.trialDays == 7) {
          this.trialDaysText = 'seven';
        } else {
          this.trialDaysText = '';
        }
        console.log(this.plan);
        // this.loader = false;
      },
      (err) => {
        console.log(err);
        this.loader = false;
      }
    );
  }

  renderData() {
    switch (this.planId) {
      case 'solo':
        this.planIndex = 'Solo';
        break;
      case 'duo':
        this.planIndex = 'Duo';
        break;
      case 'family':
        this.planIndex = 'Family';
        break;
      default:
        this.planIndex = 'Solo';
    }
  }

  upgradeSubscription() {
    this.subLoading = true;
    console.log('get');
    //publish key
    var publish_key = '';
    //session id
    var sessionId = '';
    var data = {
      userId: this.userInfo.userId,
      subscriptionId: this.planId,
      redirectURL: window.location.origin + '/dashboard',
      type: 'paid'
    };
    if (this.correctDiscountCode == true) {
      data['couponCode'] = this.discountCode;
    } else {
      delete data['couponCode'];
    }
    console.log(data);
    this.business.subscribeUser(data).subscribe(
      (res) => {
        sessionId = res.sessionId;
        console.log('session id ', sessionId);
        this.business.getPublishKey().subscribe(async (res) => {
          if (localStorage.getItem('isUpgrade')) localStorage.removeItem('isUpgrade');
          publish_key = res.publishableKey;
          console.log('publish key', publish_key);
          const stripe = await loadStripe(publish_key);
          stripe
            .redirectToCheckout({
              sessionId: sessionId
            })
            .then(function (result) {
              console.log(result);
            });
        });
      },
      (err) => {
        if (localStorage.getItem('isUpgrade')) localStorage.removeItem('isUpgrade');
        console.log(err.error.message.slice(0, 14));
        if (err.error.message)
          this._sharedService.toastForError(
            err.error.message.slice(0, 14) == 'No such coupon' ? 'Invalid discount code' : err.error.message,
            ''
          );
        else this._sharedService.toastForError('Something went wrong! ' + err.error.message, '');

        console.log(err);
        this.loading = false;
      }
    );
  }

  getParentDetail() {
    this.business.getPlyerDetail(this.userInfo.userId).subscribe((res) => {
      console.log('parent Detail', res[0]);
      this.parentData = res[0];
      this.checkEmptySub();
      if (res[0].subscription.length > 0) {
        this.currentPlanName = res[0].subscription[0].name;
        console.log(this.currentPlanName);
      }
    });
  }

  discountContinue(e) {
    // this.discountFlag = false;
    console.log('dis code', e);
    if (e != '') {
      this.discountFlag = false;
      this.correctDiscountCode = true;
      // this.showerr = false;
      this.discountText = 'Remove discount code';
      this.discountCode = e;
    } else {
      console.log('empty');
      this.discountFlag = false;
    }
  }

  addDiscount() {
    if (this.discountText == 'Remove discount code' && !this.loading) {
      this.correctDiscountCode = false;
      this.discountText = 'Add discount code';
      this.discountCode = ''; //apo added
    } else if (!this.loading) {
      this.discountFlag = true;
    }
  }

  giftCancel(e) {
    this.giftFlag = false;
    console.log(this.giftCode);
  }

  giftContinue(e) {
    console.log('gift code', e);
    if (e != '') {
      // this.giftFlag = false;
      // this.giftCode = e;
      // localStorage.setItem('giftCode',this.giftCode);
      this.business.getGiftInfo(e).subscribe(
        (res) => {
          this.giftFlag = false;
          this.giftCode = e;
          console.log('gift info', res);
          this._sharedService.setGiftInfo(res);
          this.router.navigateByUrl('/claim-giftcard?giftcode=' + e);
          this.getGiftInfo(e);
        },
        (err) => {
          this.invalidCode = true;
          console.log(err, this.giftCode);
        }
      );
    } else {
      console.log('empty');
      this.giftFlag = false;
    }
  }

  getGiftInfo(id) {
    console.log('giftInfo');
    this.business.getGiftInfo(id).subscribe(
      (res) => {
        console.log('gift info', res);
        this.plan = res[0].subscriptionId;
        this.trialDays = this.plan.trialDays;
        if (this.trialDays == 14) {
          this.trialDaysText = 'fourteen';
        } else if (this.trialDays == 7) {
          this.trialDaysText = 'seven';
        } else {
          this.trialDaysText = '';
        }
        this.amount = Number(res[0].amount);
        var days = Number(this.amount) * 30 + this.trialDays;
        console.log(days, 'days', this.plan.trialDays);
        //resetToday
        this.giftmomentToday = moment(this.date);
        this.giftmomentToday.add(days, 'days');
        this.giftExpiry = this.giftmomentToday.format();
        console.log(this.giftExpiry);
        if (!this.isAuth) {
          this.templateName = 'giftCardClaim';
          this.loader = false;
        }
        // this.loader = false;
      },
      (err) => {
        this.invalidCode = true;
        console.log(err);
        this._sharedService.toastForError('Something went wrong! ' + err.error.message, '');

        setTimeout(() => {
          if (err.error.message == 'No gift card found.') this.router.navigateByUrl('/dashboard');
        }, 1500);
        this.loader = false;
      }
    );
  }

  skipPayment() {
    console.log('skip payment');
    console.log(this.isAuth, this.isEmpty, this.giftCodeAdded);
    if (this.isAuth && this.isEmpty && this.giftCodeAdded) {
      console.log('claim with skip');
      this.skipRedeem();
    } else {
      var ref;
      if (localStorage.getItem('ref')) ref = localStorage.getItem('ref');
      localStorage.clear();
      localStorage.setItem('ref', ref);
      this.router.navigateByUrl('/signup?giftcode=' + this.giftCode + '&skip=true');
    }
  }

  // done

  checkEmptySub() {
    this.business.getSubscription().subscribe(
      (res) => {
        console.log('checkEmptySub', res[0]);
        this.currentPlanObj = res[0];
        this.isEmpty = false;
        this.planType = this.currentPlanObj.userSubInfo.type;
        this.expiryDate = this.currentPlanObj.userSubInfo.expiryDate;
        this.redeeming = false;
        console.log('meta', this.parentData);
        if (this.parentData != undefined) this.determineMomentNextSubActive(); // just cover

        // giftcode can come with ref then delete old ref and update
        if (this.isAuth && this.giftCodeAdded) {
          var ref;
          if (localStorage.getItem('ref')) ref = localStorage.getItem('ref');
          localStorage.clear();
          localStorage.setItem('ref', ref);
        }

        // time for terms and condition
        this.checkConditionForTerms();
      },
      (err) => {
        console.log(err.status);
        // special case return with 400 means no sub
        // not api error
        if (err.status == '400') {
          this.isEmpty = true;
          console.log(this.parentData, 'for safari');
          if (this.parentData != undefined) this.determineMomentNextForSepcialCase();

          //skipFlag means with setup payment or without
          if (this.skipFlag == 'true') {
            this.skipRedeem(); // auto redeem without stripe
          } else if (this.skipFlag == 'false') {
            this.setupPayment(); // need to go stripe
          }

          this.checkConditionForTerms();
          // this.templateName = 'giftCardClaimWithAuth';
          // this.loader = false;
        }
      }
    );
  }

  determineMomentNextSubActive() {
    // vendor, promo, start
    console.log('determineMomentNextSubActive');
    if (this.parentData['metadata'].vendor || this.parentData.metadata.registeredFrom) {
      // start
      if (this.planType == 'trial' && this.isChange != 'true' && this.parentData.metadata.registeredFrom) {
        this.momentNext = moment(this.expiryDate);
        this.remainTrialDay = this.momentNext.diff(this.momentToday, 'days');
        if (this.remainTrialDay > 0) this.momentNext = moment(this.expiryDate);
        else this.momentNext = this.momentToday.format();
        console.log('remian ', this.remainTrialDay);
      } else if (this.isChange != 'true') this.momentNext = this.momentToday.format();
      //vendor or promo trial period change
      else this.momentNext = moment(this.expiryDate);

      if (this.parentData.metadata.vendor && this.parentData.metadata.vendor.slice(0, 6) == 'PROMO_' && !this.isChange)
        this.isPromo = true;
      else this.isPromo = false;
    } else {
      if (this.giftCodeAdded) {
        this.momentToday.add(this.trialDays, 'days');
        this.momentNext = this.momentToday.format();
      } else if (this.planType == 'trial' && this.isChange != 'true' && this.unsubscribe != 'true') {
        this.momentToday.add(this.trialDays, 'days');
        this.momentNext = this.momentToday.format(); // adding trial days
      } else if (this.planType == 'trial' && this.isChange == 'true' && this.unsubscribe != 'true') {
        this.momentNext = moment(this.expiryDate);
      } else if (this.planType != 'trial' && this.isChange == 'true' && this.unsubscribe != 'true') {
        //full stop
        // there was a flow once upon a time so I didn't delete
      }
    }
  }

  determineMomentNextForSepcialCase() {
    if (this.parentData.metadata.registeredFrom) {
      this.momentToday.add(this.trialDays, 'days');
      this.momentNext = this.momentToday.format();
    } else if (this.parentData['metadata'].vendor) {
      this.momentNext = this.momentToday.format();
      if (this.parentData.metadata.vendor && this.parentData.metadata.vendor.slice(0, 6) == 'PROMO_')
        this.isPromo = true;
      else this.isPromo = false;
    } else {
      this.momentToday.add(this.trialDays, 'days');
      this.momentNext = this.momentToday.format();
    }
  }

  expireChange(id) {
    // you should use current sub id
    // have to consider no sub user
    console.log('currentPlanObj ', this.currentPlanObj._id);
    var redirectId = this.currentPlanObj._id ? this.currentPlanObj._id : id;
    if (this.unsubscribe == 'true') this.router.navigateByUrl('/changeplan/' + redirectId + '?unsubscribe=true');
    else this.router.navigateByUrl('/changeplan/' + redirectId);
  }

  skipRedeem() {
    this.business.claimGiftCard(this.giftCode, 'skip', window.origin + '/dashboard').subscribe(
      (data) => {
        console.log('claim', data);
        this._sharedService.toastForSuccess(
          'Woohoo!',
          'You have successfully redeemed your gift card. Enjoy exploring STEMWerkz! '
        );

        setTimeout(() => {
          this.router.navigateByUrl('/dashboard');
          this.redeeming = false;
        }, 3500);
      },
      (err) => {
        this._sharedService.toastForError(err.error.message, '');
        setTimeout(() => {
          this.redeeming = false;
        }, 1500);
        console.log(err.error.message);
      }
    );
  }

  addGift() {
    this.giftFlag = true;
  }

  discountCancel(e) {
    this.discountFlag = false;
  }

  openProfile(event) {
    this.router.navigateByUrl('/parent/detail');
  }

  backPage() {
    const curPlanId = this._sharedService.getCurrentPlanId();
    // start user can't get current plan id then redirect like this
    if (this.templateName == 'changeTrial' && !this.parentData.metadata.vendor)
      this.router.navigateByUrl(`/plans?type=trial`);
    else
      this.router.navigateByUrl(`/changeplan/${curPlanId}?unsubscribe=${this.unsubscribe ? this.unsubscribe : false}`);
  }

  ngOnDestory() {
    this.mobileViewService.unsubscribe();
  }

  // refactor added

  checkConditionForTerms() {
    console.log('empty from checkConditionForTerms', this.isEmpty);
    if (this.giftCodeAdded) this.templateName = 'giftCardClaim';
    else if (this.planId && this.planType == 'trial') this.templateName = 'changeTrial';
    else if ((this.planType == 'trial' && !this.planId) || this.isEmpty) {
      // if (this.parentData.metadata.registeredFrom) this.templateName = 'emptyFromStart';
      if (this.parentData.metadata.vendor) this.templateName = 'emptyVendor';
      else this.templateName = 'emptyNormal';
    } else if (this.planId && this.planType != 'trial') this.templateName = 'changePaid';
    console.log(this.planId, this.planType, this.parentData.metadata.vendor);
    console.log('terms', this.templateName);
    this.loader = false;
  }

  infoCardClicked(temp) {
    switch (temp.type) {
      case 'signUp':
        this.signUp();
        break;
      case 'setupPayment':
        this.setupPayment();
        break;
      case 'skipPayment':
        this.skipPayment();
        break;
      case 'cancelPayment':
        this.cancelPayment();
        break;
      case 'addGift':
        this.addGift();
        break;
      case 'expireChange':
        this.expireChange(temp.value);
        break;
      case 'addDiscount':
        this.addDiscount();
        break;
    }
  }
}
