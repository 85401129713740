<div class="overlay d-flex justify-content-center">
  <div id="outer-frame" class="outer-frame d-flex bg-s0 flex-column">
    <div class="d-flex justify-content-between" [ngClass]="{ 'align-items-start': isMobileView }">
      <span class="head-text">Reset password</span>
      <img
        (click)="closeEdit($event)"
        class="close-btn"
        src="../../assets/images/close.svg"
        [ngClass]="{ 'cursor-disable': loading }"
      />
    </div>
    <div class="inner-frame">
      <div class="form-div">
        <form (keyup.enter)="$event.preventDefault(); resetPassword($event)">
          <div class="mb-32">
            <div class="d-flex align-item-center justify-content-between">
              <div class="title">New Password</div>
              <div class="create-password">
                <app-text *ngIf="show" [class]="'showPswd'" (textClick)="showPassword($event)"> Show </app-text>
                <app-text *ngIf="hide" [class]="'showPswd'" (textClick)="hidePassword($event)"> Hide </app-text>
              </div>
            </div>
            <app-input
              [hint]="'Type your password'"
              [control]="form.get('newPassword')"
              [width]="'100%'"
              [class]="passwordClass"
              [type]="togglePassword"
              (blurEvent)="blurInput($event)"
            >
              <app-image *ngIf="!form.get('newPassword').errors && pTick" [src]="tickURL"></app-image>
              <app-image *ngIf="form.get('newPassword').errors && clickBtn" [src]="alertURL"></app-image>
            </app-input>
            <app-text *ngIf="form.get('newPassword').errors && clickBtn" [class]="'form-error-label'"
              >Passwords must be 8 characters or more</app-text
            >
            <div *ngIf="!form.get('newPassword').errors || !clickBtn" class="warning">
              Passwords must be 8 characters or more
            </div>
          </div>
          <div class="mb-32">
            <div class="title" style="margin-bottom: 5px">Confirm Password</div>
            <app-input
              [hint]="'Type your password'"
              [control]="form.get('confirmPassword')"
              [width]="'100%'"
              [class]="confirmPClass"
              [type]="togglePassword"
              (blurEvent)="blurInput($event)"
            >
              <app-image *ngIf="!form.get('confirmPassword').errors && cTick" [src]="tickURL"></app-image>
              <app-image
                *ngIf="(form.get('confirmPassword').errors || misPass) && clickBtn"
                [src]="alertURL"
              ></app-image>
            </app-input>
            <app-text *ngIf="(form.get('confirmPassword').errors || misPass) && clickBtn" [class]="'form-error-label'"
              >Passwords do not match</app-text
            >
          </div>
        </form>
        <div class="btn-div">
          <app-button [class]="'btn-orange'" [loading]="loading" (click)="resetPassword($event)">
            <app-text [class]="'btn-text-style2'" [loading]="loading"> {{ loading ? 'Updating' : 'Update' }} </app-text>
          </app-button>
        </div>
        <div>
          <app-button [class]="'btn-cancel'" [loading]="loading" (click)="closeEdit($event)">
            <app-text [class]="'btn-text-cancel'" [loading]="loading">Cancel</app-text>
          </app-button>
        </div>
      </div>
    </div>
  </div>
</div>
