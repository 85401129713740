import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import { environment } from 'src/environments/environment';
import {from, Observable, of} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GiftCardShopService {
    public baseUrl = environment.apiurl;
    constructor(private http: HttpClient) {}

    getAllPlans():Observable<any> {
      let url = this.baseUrl + "/api/v1/subscriptions";
      return this.http.get(url).pipe(
        map((res: Response) => {
          let result = []
          result.push(res);
          console.log(result);
          return result;
        })
      );
    }

    getAllGiftCardPoster(){
      let url = this.baseUrl + "/api/v1/gift-cards/posters";
      return this.http.get(url).pipe(
        map((res: Response) => {
          let result = []
          result.push(res);
          console.log(result);
          return result;
        })
      );
    }

    generateGiftCard(data) {
      let url = this.baseUrl + "/api/v1/gift-cards/generate";
      data.claimURL = environment.claimURL;
      return this.http.post(url, data).pipe(
        map((res: Response) => {
          let result = []
          result.push(res);
          return result;
        })
      );
    }

    getPublishKey() {
      let url = this.baseUrl + "/stripe/publish-key";
      return this.http.get(url).pipe(
        map((res: Response) => {
          let result = []
          result.push(res);
          return result;
        })
      );
    }

    getGiftCardDetails(id):Observable<any>{
      let url = this.baseUrl + "/api/v1/gift-cards/" + id;
      return this.http.get(url).pipe(
        map((res: Response) => {
          let result = []
          result.push(res);
          console.log(result);
          return result;
        })
      );
      // let fake_return = [
      //     {
      //       "status": "NEW",
      //       "_id": "5fc5fc37a08a9b212c5027ce",
      //       "subscriptionId": {
      //           "price": 9.99,
      //           "monthlyPrice": 9.99,
      //           "currency": "usd",
      //           "active": true,
      //           "_id": "5f5e30933cab921ec3a3cb2e",
      //           "apikey": "ndmc4acd43956e895b4de8aa7ec742edd3c",
      //           "playerSlot": 1,
      //           "name": "Solo",
      //           "interval": "month",
      //           "stripePriceId": "price_1HqWtwIZAcrsChZxdMwUSEbY",
      //           "stripeOneTimePriceId": "price_1HtDcMIZAcrsChZxdwjA9M46",
      //           "createdAt": "2020-09-13T14:45:39.468Z",
      //           "updatedAt": "2020-09-13T14:45:39.468Z",
      //           "__v": 0
      //       },
      //       "amount": 2,
      //       "posterImageUrl": "https://brainlitz-dev.s3.amazonaws.com/temp/posters/gift-card-1.png",
      //       "recipientName": "HeiN 2",
      //       "recipientEmail": "heinlinaung@amdon.com",
      //       "message": "testing....",
      //       "senderName": "HeiN 1",
      //       "senderEmail": "heinlinaung@amdon.com",
      //       "code": "0289-4493-3399",
      //       "createdAt": "2020-12-01T08:17:59.968Z",
      //       "updatedAt": "2020-12-01T08:17:59.968Z",
      //       "__v": 0
      //   }
      // ];
      // return of(fake_return);
    }
}
