import { DataRequirements, } from "../data.requirements";
import * as i0 from "@angular/core";
import * as i1 from "../mappers/parent-detail.mapper";
import * as i2 from "../data.requirements";
export class ParentDetailUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(parentId) {
        return this.data.getParentDetail(parentId).pipe(this.mapper.map);
    }
}
ParentDetailUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ParentDetailUseCase_Factory() { return new ParentDetailUseCase(i0.ɵɵinject(i1.ParentDetailMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: ParentDetailUseCase, providedIn: "root" });
