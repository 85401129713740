/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./image.component";
import * as i4 from "./image.store";
var styles_ImageComponent = [i0.styles];
var RenderType_ImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageComponent, data: {} });
export { RenderType_ImageComponent as RenderType_ImageComponent };
export function View_ImageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "img", [], [[8, "src", 4], [8, "alt", 0], [8, "className", 0]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.imageLoad() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { width: 0, height: 1, "max-width": 2, "border-radius": 3 })], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 3, 0, (_co.width ? _co.width : "auto"), (_co.height ? _co.height : "auto"), (_co.maxWidth ? _co.maxWidth : "100%"), (_co.borderRadius ? _co.borderRadius : "")); _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.src; var currVal_1 = _co.alt; var currVal_2 = _co.class; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image", [], null, null, null, View_ImageComponent_0, RenderType_ImageComponent)), i1.ɵdid(1, 114688, null, 0, i3.ImageComponent, [i4.ImageStore], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageComponentNgFactory = i1.ɵccf("app-image", i3.ImageComponent, View_ImageComponent_Host_0, { src: "src", alt: "alt", width: "width", height: "height", class: "class", maxWidth: "maxWidth", borderRadius: "borderRadius" }, { imageIsLoaded: "imageIsLoaded" }, []);
export { ImageComponentNgFactory as ImageComponentNgFactory };
