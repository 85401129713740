import { BusinessLogicRequirements } from "../presentation/business-logic.requirements";
import { NgModule } from "@angular/core";
import { DataRequirementsInjectionToken } from "./data.requirements";
import { DataFacade } from "../data/data.facade";
import { from, Observable, of } from "rxjs";
import { GetPlayersUseCase } from "./use-cases/get-players.use-case";
import { CreatePlayerUseCase } from "./use-cases/create-player.use-case";
import { PlayerListMapper } from "./mappers/player-list.mapper";
import { ParentDetailUseCase } from "./use-cases/parent-detail.use-case";
import { ParentDetailMapper } from "./mappers/parent-detail.mapper";
import { EditParentUseCase } from "./use-cases/edit-parent.use-case";
import { CheckPlanUseCase } from "./use-cases/check-plan.use-case";
import { GetPlayerDetailUseCase } from "./use-cases/get-player-detail.use-case";
import { PlayerDetailMapper } from './mappers/player-detail-mapper';
import { DeletePlayerUseCase } from './use-cases/delete-player.use-case';
import { GetPlanUseCase } from './use-cases/get-plan.use-case';
import { SubscriptionMapper } from "./mappers/subscription-plans-mapper";
import { resubscribePlanUseCase } from './use-cases/resubscribe.use-case';
import { emailCopyUseCase } from './use-cases/email-copy.use-case';
import { PaymentManagementUseCase } from "./use-cases/payment-management.use-case";

@NgModule({
  imports: [DataFacade],

  providers: [
    {
      provide: DataRequirementsInjectionToken,
      useClass: DataFacade,
    },
    PlayerListMapper,
    ParentDetailMapper,
    PlayerDetailMapper,
    SubscriptionMapper
  ],
})
export class BusinessLogicFacade implements BusinessLogicRequirements {
  constructor(
    private getPlayerDetailUseCase: GetPlayerDetailUseCase,
    private getPlayersUseCase: GetPlayersUseCase,
    private createPlayerUseCase: CreatePlayerUseCase,
    private parentDetailUseCase: ParentDetailUseCase,
    private editParentUseCase: EditParentUseCase,
    private checkPlanUseCase: CheckPlanUseCase,
    private deletePlayerUseCase: DeletePlayerUseCase,
    private getPlanUseCase: GetPlanUseCase,
    private resubscribeUseCase: resubscribePlanUseCase,
    private emailCopyUseCase: emailCopyUseCase,
    private paymentManagementUseCase: PaymentManagementUseCase
  ) {}
  getPlayers(id): Observable<any> {
    return this.getPlayersUseCase.run(id);
  }
  createUser(userData: Object): Observable<any> {
    // return of(true);
    return this.createPlayerUseCase.run(userData);
  }
  getParentDetail(parentId): Observable<any> {
    return this.parentDetailUseCase.run(parentId);
  }
  editParent(parentId, parentData): Observable<any> {
    return this.editParentUseCase.run(parentId, parentData);
  }
  checkPlan(): Observable<any> {
    return this.checkPlanUseCase.run();
  }
  getPlayerDetail(playerId): Observable<any> {
    return this.getPlayerDetailUseCase.run(playerId);
  }
  deletePlayer(playerId): Observable<any> {
    return this.deletePlayerUseCase.run(playerId);
  }
  getPlan(id): Observable<any>{
    return this.getPlanUseCase.run(id);
  }
  resubscribePlan(uid, sid): Observable<any>{
    return this.resubscribeUseCase.run(uid, sid);
  }
  emailCopy(id, pw): Observable<any>{
    return this.emailCopyUseCase.run(id, pw);
  }
  changePayment(data): Observable<any>{
    return this.paymentManagementUseCase.run(data);
}
}
