import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
  clearConsole();
}

function clearConsole() {
  window.console.log = () => {};
  window.console.warn = () => {};
  window.console.info = () => {};
  window.console.error = () => {};
  window.console.debug = () => {};
  window.console.group = () => {};
  window.console.trace = () => {};
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
