<div class="overlay d-flex justify-content-center align-items-baseline overflow-change">
  <div
    id="outer-frame"
    class="outer-frame d-flex bg-s0 flex-column"
    [ngClass]="{ 'justify-content-center': !isMobileView }"
  >
    <div class="d-flex justify-content-between">
      <span class="head-text">Edit profile</span>
      <img
        (click)="closeEdit($event)"
        class="close-btn"
        src="../../assets/images/close.svg"
        [ngClass]="{ 'cursor-disable': loading }"
      />
    </div>
    <div class="inner-frame d-flex align-items-start" [ngClass]="{ 'flex-column align-items-center': isMobileView }">
      <div class="left-profile-div d-flex justify-content-center">
        <div class="outer-circle">
          <div
            class="profile d-flex justify-content-center"
            [ngStyle]="{
              'background-image': 'url(' + playerDetail.profileImage + ')',
              'background-repeat': 'no-repeat',
              'background-size': 'cover'
            }"
          >
            <app-text class="shortName">{{ playerDetail.shortName }}</app-text>
          </div>
        </div>
      </div>
      <div class="form-div">
        <form (keyup.enter)="$event.preventDefault(); checkOpen($event)">
          <div class="first-div">
            <div class="first-name">First name</div>
            <app-input
              [hint]="'Type player first name'"
              [control]="form.get('firstName')"
              [width]="'100%'"
              [class]="fNameClass"
              [value]="playerDetail.firstName"
              (blurEvent)="blurInput($event)"
            >
              <app-image *ngIf="!form.get('firstName').errors && fTick" [src]="tickURL"></app-image>
              <app-image *ngIf="form.get('firstName').errors && clickBtn" [src]="alertURL"></app-image>
            </app-input>
            <app-text *ngIf="form.get('firstName').errors && clickBtn" [class]="'form-error-label'"
              >Enter your first name
            </app-text>
          </div>
          <div class="second-div">
            <div class="first-name">Last name</div>
            <app-input
              [hint]="'Type player last name'"
              [control]="form.get('lastName')"
              [width]="'100%'"
              [class]="lNameClass"
              [value]="playerDetail.lastName"
              (blurEvent)="blurInput($event)"
            >
              <app-image *ngIf="!form.get('lastName').errors && lTick" [src]="tickURL"></app-image>
              <app-image *ngIf="form.get('lastName').errors && clickBtn" [src]="alertURL"></app-image>
            </app-input>
            <app-text *ngIf="form.get('lastName').errors && clickBtn" [class]="'form-error-label'"
              >Enter your last name
            </app-text>
          </div>
          <!-- <div class="third-div">
            <div class="first-name">Year of birth</div>
            <div class="dob" [ngClass]="{'dob-clicked' : showYearBox , 'dob-normal' : !showYearBox}" (click)="showYearBox = !showYearBox">
              <app-text class="'chosen-year'">{{playerDetail?.metadata.yearOfBirth ? playerDetail?.metadata.yearOfBirth : 'Select year'}}</app-text>
              <app-image [src]="dobUrl"></app-image>
            </div>
            <div *ngIf="showYearBox" class="year-box">
              <div *ngFor="let year of yearList" class="single-year" (click)="choseYear(year)">
                {{year}}
              </div>
            </div>
            <app-text class="d-flex align-items-center" style="height: 48px;" *ngIf="playerDetail?.metadata.yearOfBirth">{{yearOld}} {{yearOld >1 ?'years' : 'year'}} old
            </app-text>
          </div> -->

          <div class="third-div" *ngIf="child">
            <div class="first-name">Year of birth</div>
            <app-drop-down-search
              [itemList]="yearList"
              [hint]="'Choose year'"
              [emailFlag]="emailFlag"
              [defaultValue]="playerDetail?.metadata.yearOfBirth"
              [pure]="true"
              *ngIf="yearList"
              (isOpen)="isOpenYear($event)"
              (comebackData)="selectedYear($event)"
            ></app-drop-down-search>
            <app-text class="d-flex align-items-center" style="height: 48px" *ngIf="playerDetail?.metadata.yearOfBirth"
              >{{ yearOld }} {{ yearOld > 1 ? 'years' : 'year' }} old
            </app-text>
          </div>
          <div class="third-div" *ngIf="child">
            <div class="username">Gender</div>
            <app-button [class]="gender.boy" (btnClick)="selectGenter($event)">Boy</app-button>
            <app-button [class]="gender.girl" (btnClick)="selectGenter($event)">Girl</app-button>
            <app-button [class]="gender.i_prefer_not_to_say" (btnClick)="selectGenter($event)"
              >I prefer not to say</app-button
            >
          </div>

          <div class="third-div">
            <div *ngIf="!emailFlag" class="email-text" (click)="emailOption()">Add email address</div>
            <div *ngIf="emailFlag">
              <div class="username">Email</div>
              <app-input
                [hint]="'Type player email'"
                [control]="form.get('email')"
                [width]="'100%'"
                [class]="emailClass"
                [value]="playerDetail.email"
                (blurEvent)="blurInput($event)"
              >
                <app-image *ngIf="!form.get('email').errors && eTick" [src]="tickURL"></app-image>
                <app-image *ngIf="form.get('email').errors && clickBtn" [src]="alertURL"></app-image>
              </app-input>
              <app-text *ngIf="form.get('email').errors && clickBtn" [class]="'form-error-label'"
                >Enter an email
              </app-text>
            </div>
          </div>
          <div class="third-div" *ngIf="!child">
            <div class="first-name">Country</div>
            <!-- <app-drop-down-search
              [itemList]="yearList"
              [hint]="'Choose year'"
              [emailFlag]="emailFlag"
              [defaultValue]="playerDetail?.metadata.yearOfBirth"
              [pure]="true"
              *ngIf="yearList"
              (isOpen)="isOpenYear($event)"
              (comebackData)="selectedYear($event)"
            ></app-drop-down-search> -->
            <app-drop-down-search
              [itemList]="countriesList"
              [hint]="currentCountry"
              [defaultValue]="currentCountry"
              *ngIf="countriesList"
              (isOpen)="isOpenYear($event)"
              (comebackData)="chosenCountry($event)"
            ></app-drop-down-search>
          </div>
        </form>
        <div class="footer-div">
          <div class="btn-div w-h">
            <app-button [class]="'btn-orange'" [loading]="loading" (click)="editPlayer($event)">
              <app-text [class]="'btn-text-style2'" [loading]="loading">
                {{ loading ? 'Updating' : 'Update' }}
              </app-text>
            </app-button>
          </div>
          <div style="cursor: pointer" class="mr-4 w-h d-flex flex-column justify-content-center">
            <app-button [class]="'btn-cancel'" [loading]="loading" (click)="closeEdit($event)">
              <app-text [class]="'btn-text-cancel'" [class]="'btn-text-cancel'" [loading]="loading">Cancel</app-text>
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
