import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../data.requirements";
export class SentMailUseCase {
    constructor(data) {
        this.data = data;
    }
    run(mail) {
        return this.data.sentMail(mail);
    }
}
SentMailUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SentMailUseCase_Factory() { return new SentMailUseCase(i0.ɵɵinject(i1.DataRequirementsInjectionToken)); }, token: SentMailUseCase, providedIn: "root" });
