import { Inject, Component, Output, EventEmitter, Input } from '@angular/core';
import { BusinessLogicRequirements, BusinessRequirementsInjectionToken } from '../business-logic.requirements';
import { LoginStore } from './login.store';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ParentModel } from '../../../models/parent.model';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../lib/auth.service';
import { SharedService } from 'src/app/lib/shared-service';

@Component({
  selector: 'app-login',
  templateUrl: './login-controller.component.html',
  styleUrls: ['./login-controller.component.css']
})
export class LoginControllerComponent {
  public width = '100%';
  public tmpData: Observable<ParentModel>;
  public togglePassword: string = 'password';
  public clickBtn = false;
  public existUser = false;
  public alertURL = './assets/images/alert-icon.svg';
  public misPassword = false;
  public show = true;
  public hide = false;
  public pleasewait = false;
  public btnStatus = 'btn-orange';
  public tickURL = './assets/images/tick-icon.svg';
  public usernameClass = 'signup-input';
  public passwordClass = 'signup-input';
  public uTick = false;
  public pTick = false;
  public discount = '';
  public giftCode = '';
  // public showRedirectPopup = false;
  @Output() checkRedirectPopup = new EventEmitter<any>();
  @Input() onClickSignup;

  constructor(
    private fb: FormBuilder,
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private store: LoginStore,
    private router: Router,
    private route: ActivatedRoute,
    private _authService: AuthService,
    private sharedService: SharedService
  ) {}
  public form = this.fb.group({
    username: this.fb.control('', [Validators.required]),
    password: this.fb.control('', [
      Validators.required
      // Validators.minLength(8),
    ])
  });

  public planName: any;
  public skip = '';
  public usernameFromUrl = '';

  ngOnInit() {
    this.route.queryParams.subscribe((data) => {
      console.log('queryParmas data ', data);
      // console.log(data.planId);
      this.planName = data.planId;
      this.discount = data.discountcode;
      if (data.giftcode) this.giftCode = data.giftcode;
      if (data.skip) this.skip = data.skip;
      if (data.username) this.usernameFromUrl = data.username;

      if (this.usernameFromUrl) this.form.controls['username'].setValue(this.usernameFromUrl);
      // if (data.username) this.usernameFromUrl = this._authService.decrypt(data.username, null);
      // console.log('aungphyooo', encodeURIComponent(this._authService.encrypt('aungphyooo', null)));
      //U2FsdGVkX19AfhBR41yV+1yOSCmqB12A9S2Apord8qk=
    });

    this.form.valueChanges.subscribe((value) => {
      console.log(value);
      this.tmpData = value;
      this.existUser = false;
      this.clickBtn = false;
      this.usernameClass = 'signup-input';
      this.passwordClass = 'signup-input';
    });
    this.form.get('username').valueChanges.subscribe(() => {
      this.uTick = true;
    });
    this.form.get('password').valueChanges.subscribe(() => {
      this.pTick = true;
    });
  }
  ngOnChanges() {
    if (this.onClickSignup) {
      this.showSignup(true);
    }
  }
  blurInput($event) {
    console.log('get blur event');
    this.uTick = false;
    this.pTick = false;
  }
  showPassword($event) {
    this.togglePassword = 'text';
    this.show = false;
    this.hide = true;
  }
  hidePassword($event) {
    this.togglePassword = 'password';
    this.show = true;
    this.hide = false;
  }
  showSignup($event) {
    console.log('shos');
    // environment.initState = "login";
    if (this.discount != '' && this.planName)
      this.router.navigateByUrl(`/signup?planId=` + this.planName + `&discountcode=` + this.discount);
    else if (this.giftCode != '' && this.skip == 'true')
      this.router.navigateByUrl(`/signup?&giftcode=` + this.giftCode + '&skip=true');
    else if (this.giftCode != '' && this.skip == 'false')
      this.router.navigateByUrl(`/signup?&giftcode=` + this.giftCode + '&skip=false');
    else if (this.planName) this.router.navigateByUrl('/signup?planId=' + this.planName);
    else this.router.navigateByUrl('/signup');
  }
  onLogin($event) {
    this.pleasewait = true;
    this.uTick = false;
    this.pTick = false;
    this.btnStatus = 'btn-orange waiting not-allowed';
    console.log('button click', this.pleasewait);
    this.clickBtn = true;
    this.existUser = false;
    this.misPassword = false;
    if (this.form.valid) {
      // this.business
      //   .signupParent(this.parentData)
      //   .subscribe(() => console.log("success"));
      console.log('valid');
      const parentObject = {
        username: this.tmpData['username'],
        email: this.tmpData['email'],
        password: this.tmpData['password']
      };
      localStorage.setItem('parentObject', this._authService.encrypt(JSON.stringify(parentObject), null));
      // localStorage.setItem("email", this.tmpData["email"]);
      // localStorage.setItem("password", this.tmpData["password"]);
      this.business.loginParent().subscribe(
        (res) => {
          console.log(res);
          localStorage.setItem('authData', this._authService.encrypt(JSON.stringify(res), null));
          console.log(this.planName, this.discount);
          if (res.UserType != 5) {
            // this.showRedirectPopup = true;
            var tempObj = {
              userName: parentObject.username,
              showPopup: true,
              dashboardUrl: this._authService.getRedirectDashboardUrl(),
              channelUrl: this._authService.getRedirectChannelUrl(),
              valleyUrl: this._authService.getRedirectValleyUrl(),
              academyUrl: this._authService.getRedirectAcademyUrl(),
              questUrl: this._authService.getRedirectQuestUrl()
            };
            this.checkRedirectPopup.emit(tempObj);
            this.pleasewait = false;
            this.form.reset();
          } else {
            if (this.discount != '' && this.planName && this.giftCode == '')
              this.router.navigateByUrl(`/plans/` + this.planName + `?discountcode=` + this.discount);
            else if (this.giftCode != '' && this.skip == 'true')
              this.router.navigateByUrl(`/claim-giftcard?giftcode=` + this.giftCode + '&skip=true');
            else if (this.giftCode != '' && this.skip == 'false')
              this.router.navigateByUrl(`/claim-giftcard?giftcode=` + this.giftCode + '&skip=false');
            else if (this.planName) this.router.navigateByUrl(`/plans/` + this.planName);
            else this.router.navigateByUrl(`/plans`);
            this.pleasewait = false;
            this.btnStatus = 'btn-orange';
          }
        },
        (err) => {
          console.log('err', err);
          // console.log(err.error.message);
          this.sharedService.toastForError(err.error.message ? err.error.message : 'Something went wrong!', '');
          if (err.error.message == '[Pagewerkz] Error occurs on user login.') {
            this.existUser = true;
            this.usernameClass = 'input-err';
          }
          if (err.error.message == 'Password Mismatch') {
            this.misPassword = true;
            this.passwordClass = 'input-err';
          }
          this.pleasewait = false;
          this.btnStatus = 'btn-orange';
        }
      );
    } else {
      console.log('missing form');
      this.pleasewait = false;
      this.btnStatus = 'btn-orange';
      if (this.form.get('username').errors) {
        this.usernameClass = 'input-err';
      }
      if (this.form.get('password').errors) {
        this.passwordClass = 'input-err';
      }
    }
  }
}
