import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import { GlobalComponentsModule } from 'src/app/global-components/global-components.module';
import {LoaderComponent} from './loader/loader.component';

const components = [
  LoaderComponent
]

@NgModule({
    declarations: components,
    exports: components,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        GlobalComponentsModule
    ]
})
export class SharedComponentsModule {}
