<div class="profile-wrapper" *ngIf="!profileDetail && mobileView">
  <div class="d-flex align-items-center mobile-profile justify-content-between" (click)="profileDetail = false">
    <div class="d-flex align-item-center">
      <div>
        <div
          class="profile d-flex justify-content-center align-item-center"
          [ngStyle]="{
            'background-image': 'url(' + parentData?.profileImage + ')',
            'background-repeat': 'no-repeat',
            'background-size': 'cover'
          }"
        >
          <app-text class="profile-pw">
            {{ parentData?.shortName }}
          </app-text>
        </div>
      </div>
      <app-text style="margin: 0px 16px" class="username-text"
        >{{ parentData?.firstName }} {{ parentData?.lastName }}</app-text
      >
    </div>
    <!-- <div class="right-arrow-wrap">
      <app-image [src]="c_right" [width]="'100%'" [height]="'100%'"></app-image>
    </div> -->
  </div>
  <div class="btn-gp" [ngClass]="{ 'btn-bp-padding': createdCount > 0 }">
    <div class="d-flex flex-column" *ngIf="createdCount > 0">
      <div class="dashboard">
        <a [href]="zoneList[0].link" target="_blank" class="w-100">
          <div class="d-flex">
            <app-image [src]="zoneList[0].image"></app-image>
            <div class="d-flex justify-content-between w-100 align-items-center">
              <div class="d-flex flex-column" style="margin-left: 12px">
                <div class="d-text">Dashboard</div>
                <div class="">Go to main Dashboard</div>
              </div>
              <app-image [src]="arrow"></app-image>
            </div>
          </div>
        </a>
      </div>
      <a class="dashboard-link" [href]="parentPortalLink" target="_blank">
        <div class="parent-portal d-flex justify-content-between">
          <app-text class="parent-portal-text">Go to Parent Portal</app-text>
          <app-image [src]="arrow"></app-image>
        </div>
      </a>
      <div class="zone-wrapper d-flex flex-column">
        <div class="d-flex justify-content-between" style="margin-bottom: 16px">
          <a style="height: 64px; width: 48%" [href]="zoneList[1].link" target="_blank"
            ><div class="zone-image"><app-image [src]="zoneList[1].image"></app-image></div
          ></a>
          <a style="height: 64px; width: 48%" [href]="zoneList[2].link" target="_blank"
            ><div class="zone-image"><app-image [src]="zoneList[2].image"></app-image></div
          ></a>
        </div>
        <div class="d-flex justify-content-between" style="padding-bottom: 16px; border-bottom: 1px solid #edeef0">
          <a style="height: 64px; width: 48%" [href]="zoneList[3].link" target="_blank"
            ><div class="zone-image"><app-image [src]="zoneList[3].image"></app-image></div
          ></a>
          <a style="height: 64px; width: 48%" [href]="zoneList[4].link" target="_blank"
            ><div class="zone-image"><app-image [src]="zoneList[4].image"></app-image></div
          ></a>
        </div>
      </div>
    </div>

    <!-- <app-button [class]="'btn-cancel2'" style="margin-bottom: 20px;" (click)="explore()">Explore STEMWerkz!</app-button> -->
    <!-- <div style="height: 20px;"></div> -->
    <div class="bar">
      <app-text (click)="changePlan()" class="slot cursor">Need more accounts?</app-text>
    </div>
    <div class="bar">
      <app-text (click)="detail()" class="cursor profile-color">Your profile</app-text>
    </div>
    <div class="bar">
      <app-text (click)="logout()" class="logout cursor text-r100">Log out</app-text>
    </div>

    <!-- <app-button [class]="'btn-cancel2'" (click)="changePlan()">Need more child accounts?</app-button> -->
  </div>
</div>

<!-- <div class="profile-wrapper" *ngIf="profileDetail && mobileView">
  <div class="d-flex align-items-center mobile-profile-detail">
    <div class="d-flex align-item-center" (click)="profileDetail=false">
      <app-image [src]="c_left"></app-image>
      <app-text class="back-text cursor d-flex align-items-center" >Back</app-text>
    </div>
  </div>
  <div class="detail-list" (click)="detail()">
    <app-text class="detail-list-text profile-text cursor" >Your profile</app-text>
  </div>
  <div class="detail-list" (click)="logout()">
    <app-text class="detail-list-text logout-text cursor" >Log out</app-text>
  </div>
</div> -->
