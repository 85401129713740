import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PlanApiService {
    constructor(http) {
        this.http = http;
        this.baseUrl = environment.apiurl;
    }
    getPlan(id) {
        let url = this.baseUrl + "/api/v2/subscriptions/" + id;
        console.log(url);
        return this.http.get(url).pipe(map((res) => {
            let result = [res];
            console.log(result);
            return result;
        }));
    }
    resubscribePlan(uid, sid) {
        let url = this.baseUrl + "/api/v2/subscriptions/resubscribe";
        let body = {
            userId: uid,
            subscriptionId: sid
        };
        console.log(url);
        return this.http.post(url, body).pipe(map((res) => {
            let result = res;
            console.log(result);
            return result;
        }));
    }
    updatePayment(data) {
        let url = this.baseUrl + "/api/v2/stripe/customer-portal";
        console.log(url);
        return this.http.post(url, data).pipe(map((res) => {
            let result = res;
            console.log(result);
            return result;
        }));
    }
}
PlanApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlanApiService_Factory() { return new PlanApiService(i0.ɵɵinject(i1.HttpClient)); }, token: PlanApiService, providedIn: "root" });
