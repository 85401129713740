<div class="overlay d-flex justify-content-center align-items-baseline">
  <div id="outer-frame" class="outer-frame">
    <div class="d-flex justify-content-between">
      <div class="title-wrap">
        <app-text [class]="'h2-strong text-secondary700 font-weight-bold'"> Logged in with child account </app-text>
      </div>
      <div class="close-btn" (click)="clickCloseBtn()">
        <img src="../../assets/images/close.svg" />
      </div>
    </div>
    <div class="pt-24">
      <app-text [class]="'text-c100'">
        <span class="text-bold word-break">{{ userName }}</span> is registered as STEMWerkz child account. Where do you
        want to go? Pick one below.
      </app-text>
    </div>
    <!-- <div class="pt-34 row center-box">
            <a [href]="valleyUrl" target="_blank">
                <div class="custom-box d-flex align-items-center justify-content-center">
                    <img class="valley-img" src="../../assets/images/steam-valley.png" />
                </div>
            </a>
            <a [href]="channelUrl" target="_blank">
                <div class="custom-box d-flex align-items-center justify-content-center">
                    <img class="channel-img" src="../../assets/images/stemwerkz-channel.png" />
                </div>
            </a>
            <a [href]="academyUrl" target="_blank">
              <div class="custom-box d-flex align-items-center justify-content-center">
                  <img class="academay-img" src="../../assets/images/STEMWERKZ_ACADEMY.png" />
              </div>
            </a>
        </div> -->
    <div class="d-flex flex-column">
      <div class="dashboard">
        <a style="width: 100%; color: #5c6773" [href]="dashboardUrl" target="_blank">
          <div class="d-flex">
            <img src="../../assets/images/dashboard_icon.png" />
            <div class="d-flex flex-column" style="margin-left: 12px">
              <div class="d-text">Dashboard</div>
              <div class="">Go to main Dashboard</div>
            </div>
          </div>
        </a>
      </div>
      <div class="zone-wrapper d-flex flex-column">
        <div class="d-flex justify-content-between" style="margin-bottom: 16px">
          <a style="width: 48%" [href]="valleyUrl" target="_blank"
            ><div class="zone-image"><img class="valley-img" src="../../assets/images/steam-valley.png" /></div
          ></a>
          <a style="width: 48%" [href]="channelUrl" target="_blank"
            ><div class="zone-image"><img class="channel-img" src="../../assets/images/stemwerkz-channel.png" /></div
          ></a>
        </div>
        <div class="d-flex justify-content-between" style="padding-bottom: 16px; border-bottom: 1px solid #edeef0">
          <a style="width: 48%" [href]="academyUrl" target="_blank"
            ><div class="zone-image"><img class="academay-img" src="../../assets/images/STEMWERKZ_ACADEMY.png" /></div
          ></a>
          <a style="width: 48%" [href]="questUrl" target="_blank"
            ><div class="zone-image"><img class="valley-img" src="../../assets/images/STEMQuest.png" /></div
          ></a>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center pt-4" (click)="clickLogout($event)">
      <app-button [class]="'btn-logout'">
        <app-text [class]=""> Log out </app-text>
      </app-button>
    </div>
  </div>
</div>
