/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./empty-slot.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../global-components/button/button.component.ngfactory";
import * as i3 from "../../../../../global-components/button/button.component";
import * as i4 from "../../../../../global-components/button/button.store";
import * as i5 from "../../../../../global-components/text/text.component.ngfactory";
import * as i6 from "../../../../../global-components/text/text.component";
import * as i7 from "../../../../../global-components/text/text.store";
import * as i8 from "./empty-slot.component";
var styles_EmptySlotComponent = [i0.styles];
var RenderType_EmptySlotComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmptySlotComponent, data: {} });
export { RenderType_EmptySlotComponent as RenderType_EmptySlotComponent };
export function View_EmptySlotComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "mb-40 single-player"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addNewSlot($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "circle-outer-div d-flex justify-content-center align-item-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "../../../../../../assets/images/Subtract.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "d-flex justify-content-center align-item-center flex-column"], ["style", "width: 100%;padding: 10px 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["style", "margin-top: 16px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "create-btn-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "app-button", [], null, null, null, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(7, 49152, null, 0, i3.ButtonComponent, [i4.ButtonStore], { class: [0, "class"], width: [1, "width"], height: [2, "height"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 2, "app-text", [], null, null, null, i5.View_TextComponent_0, i5.RenderType_TextComponent)), i1.ɵdid(9, 114688, null, 0, i6.TextComponent, [i7.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(-1, 0, [" Add new child account "]))], function (_ck, _v) { var currVal_0 = "btn-orange br-56"; var currVal_1 = "208px"; var currVal_2 = "72px"; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "btn-text-style2-20"; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_EmptySlotComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-empty-slot", [], null, null, null, View_EmptySlotComponent_0, RenderType_EmptySlotComponent)), i1.ɵdid(1, 114688, null, 0, i8.EmptySlotComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmptySlotComponentNgFactory = i1.ɵccf("app-empty-slot", i8.EmptySlotComponent, View_EmptySlotComponent_Host_0, { slot: "slot", player: "player" }, { addSlot: "addSlot" }, []);
export { EmptySlotComponentNgFactory as EmptySlotComponentNgFactory };
