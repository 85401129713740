import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../../../global-components/global-components.module';
import {SharedComponentsModule} from '../shared-components/shared-components.module';
import {ForgotPasswordHomeControllerComponent} from './forgot-password-home-controller.component';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [
        ForgotPasswordHomeControllerComponent
    ],
    exports: [
        ForgotPasswordHomeControllerComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GlobalComponentsModule,
        SharedComponentsModule,
    ]
})
export class ForgotPasswordHomeModule {}
