<div class="mb-40 single-player" (click)="addNewSlot($event)">
  <div class="circle-outer-div d-flex justify-content-center align-item-center">
      <img src="../../../../../../assets/images/Subtract.svg" />
  </div>
  <div class="d-flex justify-content-center align-item-center flex-column" style="width: 100%;padding: 10px 20px;">
    <!-- <app-text class="player-name">&nbsp;</app-text> -->
    <!-- <div class="last-active"><app-text>Add new child account</app-text></div> -->
    <div style="margin-top: 16px;">
      <div class="create-btn-div">
        <app-button [width]="'208px'" [height]="'72px'" [class]="'btn-orange br-56'" >
          <app-text [class]="'btn-text-style2-20'" > Add new child account </app-text>
        </app-button>
      </div>
    </div>
  </div>
</div>
