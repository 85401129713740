import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WarningboxStore } from './warningbox.store';

@Component({
  selector: 'app-warningbox',
  templateUrl: './warningbox.component.html',
  styleUrls: ['./warningbox.component.css']
})
export class WarningboxComponent {
  @Input() boxType;
  @Input() currentPlan;
  @Output() outputBoxType = new EventEmitter<any>();
  constructor(private store: WarningboxStore) {}

  ngOnInit() {
    console.log('box Type ', this.boxType);
  }

  clickedBoxBtn() {
    console.log('box Type from start clicked', this.boxType);
    this.outputBoxType.emit(this.boxType);
  }
}
