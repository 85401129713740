import { DataRequirements, } from "../data.requirements";
import * as i0 from "@angular/core";
import * as i1 from "../mappers/subscribe-user-mapper";
import * as i2 from "../data.requirements";
export class SubscribeUserUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(data) {
        return this.data.subscribeUser(data);
    }
}
SubscribeUserUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubscribeUserUseCase_Factory() { return new SubscribeUserUseCase(i0.ɵɵinject(i1.SubscribeUserMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: SubscribeUserUseCase, providedIn: "root" });
