<app-warningbox
  [boxType]="boxType"
  [currentPlan]="currentPlan"
  (outputBoxType)="boxTypeClicked($event)"
  *ngIf="showBox && !mobileView"
></app-warningbox>

<div
  class="upper-div"
  *ngIf="
    boxType != 'beforeUnsubscribe' &&
    boxType != 'beforeFromStart' &&
    boxType != 'afterFromStart' &&
    boxType != 'afterUnsubscribe' &&
    boxType != 'afterTrial' &&
    boxType != 'beforeGiftcard' &&
    boxType != 'afterGiftcard'
  "
>
  <div class="title">Subscription</div>
  <div class="d-flex upper-inner">
    <div class="sub-box">
      <app-text class="mb-1 mini-title">Plan</app-text>
      <app-text class="sub-data">{{ currentPlan.planName }}</app-text>
      <app-text class="update-card cursor" (click)="changePlan($event)">Change plan</app-text>
    </div>
    <div class="mini-title sub-box">
      <app-text class="mb-1 mini-title">Payment type</app-text>
      <app-text class="sub-data">{{
        currentPlan.userSubInfo.stripeSubscriptionId ? 'Credit/Debit Card' : 'No payment added'
      }}</app-text>
      <app-text class="update-card cursor" (click)="paymentChange()"> Manage payment card </app-text>
    </div>
  </div>
  <div class="d-flex upper-inner">
    <div class="mini-title sub-box">
      <app-text class="mb-1 mini-title">Next billing date</app-text
      ><app-text class="sub-data">{{ currentPlan.userSubInfo.expiryDate | date: 'd MMM yyyy' }}</app-text>
    </div>
    <div class="mini-title sub-box last-box">
      <app-text class="mb-1 mini-title">Next payment amount</app-text
      ><app-text class="sub-data">${{ parentData.subscription[parentData.subscription.length - 1].price }}</app-text>
      <app-text class="cancel-card cursor" (click)="cancelBtn($event)" *ngIf="boxType != 'beforeGiftcard'"
        >Cancel subscription</app-text
      >
      <!-- <app-text class="update-card cursor" (click)="resubscribe($event)" *ngIf="!expire">Resubscribe</app-text> -->
    </div>
  </div>
</div>

<div
  class="upper-div"
  *ngIf="
    boxType == 'beforeUnsubscribe' ||
    boxType == 'afterUnsubscribe' ||
    boxType == 'beforeGiftcard' ||
    boxType == 'afterGiftcard'
  "
>
  <div class="title">Subscription</div>
  <div class="d-flex upper-inner">
    <div class="sub-box">
      <app-text class="mb-1 mini-title">Plan</app-text>
      <app-text class="sub-data">{{ currentPlan.planName }}</app-text>
      <app-text class="update-card cursor" (click)="changePlan($event)">Change plan</app-text>
    </div>
    <div class="mini-title sub-box">
      <app-text class="mb-1 mini-title">Payment type</app-text>
      <app-text class="sub-data">{{
        currentPlan.userSubInfo.stripeSubscriptionId ? 'Credit/Debit Card' : 'No payment added'
      }}</app-text>
      <app-text class="update-card cursor" (click)="paymentChange()"> Manage payment card </app-text>
    </div>
  </div>
  <div class="d-flex upper-inner">
    <div class="mini-title sub-box">
      <app-text class="mb-1 mini-title">Next billing date</app-text>
      <app-text class="sub-data">---</app-text>
    </div>
    <div class="mini-title sub-box last-box">
      <app-text class="mb-1 mini-title">Next payment amount</app-text><app-text class="sub-data">---</app-text>
      <app-text class="update-card cursor" (click)="resubscribe($event)">Resubscribe</app-text>
    </div>
  </div>
</div>

<div class="upper-div" *ngIf="boxType == 'beforeFromStart' || boxType == 'afterFromStart'">
  <div class="title">Subscription</div>
  <div class="d-flex upper-inner">
    <div class="sub-box">
      <app-text class="mb-1 mini-title">Plan</app-text>
      <app-text class="sub-data">Trial</app-text>
    </div>
    <div class="mini-title sub-box">
      <app-text class="mb-1 mini-title">Payment type</app-text>
      <app-text class="sub-data">{{
        currentPlan.userSubInfo.stripeSubscriptionId ? 'Credit/Debit Card' : 'No payment added'
      }}</app-text>
      <app-text class="update-card cursor" (click)="changePlan($event)"> Subscribe </app-text>
    </div>
  </div>
</div>
