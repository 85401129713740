import { SubscriptionPlanModel } from "../../../models/subscription-plan.model";
import { ItemMapper } from "../../../lib/item-mapper";

export class SubscriptionMapper extends ItemMapper<SubscriptionPlanModel> {
  //   public imgPrefix = "https://d1vf2jccr14y1d.cloudfront.net/covers/";
  protected mapFromItem(item): SubscriptionPlanModel {
    console.log(item);
    const planModel = new SubscriptionPlanModel();
    planModel.planId = item._id;
    planModel.planName = item.name;
    planModel.planMonthly = item.monthlyPrice;
    planModel.playerSlot = item.playerSlot;
    planModel.stripePriceId = item.stripePriceId;
    planModel.subscribed = item.subscribed;
    planModel.currency = item.currency;
    planModel.interval = item.interval;
    planModel.apikey = item.apikey;
    planModel.createdAt = item.createdAt;
    planModel.active = item.active;
    planModel.planYear = item.price;
    planModel.trialDays = item.trialDays;

    console.log(planModel);
    // profileImage
    return planModel;
  }
}
