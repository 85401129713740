import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-plan-card',
    templateUrl: './plan-card.component.html',
    styleUrls: ['./plan-card.component.css']
})
export class PlanCardComponent {
  @Input() plan;

  @Output() clickCard = new EventEmitter<any>();

  onClickCard() {
    this.clickCard.emit(this.plan);
  }

}
