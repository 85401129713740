import {Inject, Injectable} from '@angular/core';
import { DataRequirementsInjectionToken, DataRequirements } from '../data.requirements';
import { SubscriptionMapper } from "../mappers/subscription-plans-mapper";

@Injectable({
    providedIn: 'root'
})
export class GetPlanListUsecase {
    constructor(
        private mapper: SubscriptionMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run() {
        console.log('usecase', this.data.getPlanList().pipe(this.mapper.map))
        return this.data.getPlanList().pipe(this.mapper.map);
        //return this.data.getGAuth(url);
    }
}
