<div class="container">
  <app-header
  *ngIf="parentData"
      (openProfile)="openProfile($event)"
      [parentData]="parentData"
    ></app-header>

    <div (click)="backPage()" class="back-div" *ngIf="currentPlanObj.planId">
      <img class="mr-8" src="../../../../assets/images/back-btn.svg" /> Back
    </div>

  <img *ngIf="!parentData" src="../../../assets/images/STEMWerkzLogo.png"><br *ngIf="!parentData">

  <div class="choice" *ngIf="!currentPlanObj.planId">Choose your plan</div>
  <p class="highlight">Don't worry, you can add more child accounts later.</p>

  <div class="plan-header mobile-view">
    <div *ngFor="let plan of planList;" class="mobile-plan-header" (click)="showDetail(plan.planName,plan.planId,plan.planMonthly,plan.playerSlot)" [ngClass]="{'border-none': plan.planName == selectedPlan , 'mobile-selected' : plan.planName == selectedPlan , 'mobile-current' : plan.planName == currentPlanObj.planName}">
      {{plan.planName}}
    </div>
  </div>


  <div class="mt-40" *ngIf="!loading" class="table-container">
    <table class="table">
      <tr class="desktop-view">
        <th></th>
        <th id="{{plan.planName}}" (click)="showDetail(plan.planName,plan.planId,plan.planMonthly,plan.playerSlot)" (mouseenter)="headerEnter(plan.planName)" (mouseleave)="headerLeave(plan.planName)" class="plan-type value cursor" *ngFor="let plan of planList;" [ngClass]="{'selected-plan' : plan.planName == selectedPlan , 'current-plan' : plan.planName == currentPlanObj.planName}">
          {{plan.planName}}
          <div id="{{plan.planName | lowercase}}-rect" [ngClass]="{'selected-border' : plan.planName == selectedPlan}"></div>
        </th>
        </tr>

        <tr >
          <td class="td-title"style="vertical-align: middle" >
            Yearly plan</td>
          <td id="{{plan.planName | lowercase}}-price" class="plan-content cursor" [ngClass]="{'selected-plan' : plan.planName == selectedPlan , 'current-plan' : plan.planName == currentPlanObj.planName , 'desktop-view' : mobilePlan != plan.planName}" *ngFor="let plan of planList" (mouseenter)="headerEnter(plan.planName)" (mouseleave)="headerLeave(plan.planName)">
            <app-text class="original">{{plan?.planYear}} {{plan?.currency | uppercase}}</app-text><br>
            <app-text class="discount" *ngIf="discountInfo?.percent_off">{{(plan?.planYear - (plan?.planYear*(discountInfo?.percent_off/100))).toFixed(2)}} {{plan?.currency | uppercase}}</app-text>
            <app-text class="discount" *ngIf="!discountInfo?.percent_off && discountInfo.amount_off">{{plan?.planYear - discountInfo?.amount_off}} {{plan?.currency | uppercase}}</app-text>
          </td>
        </tr>
        <tr>
          <td class="td-title">Child accounts</td>
          <td id="account" class="plan-content cursor" [ngClass]="{'selected-plan' : plan.planName == selectedPlan , 'current-plan' : plan.planName == currentPlanObj.planName , 'desktop-view' : mobilePlan != plan.planName}" *ngFor="let plan of planList" (mouseenter)="headerEnter(plan.planName)" (mouseleave)="headerLeave(plan.planName)">{{plan.playerSlot}}</td>
        </tr>
        <tr>
          <td class="td-title">1,000+ engaging STEM lessons</td>
          <td *ngFor="let plan of planList" class="plan-content cursor" (mouseenter)="headerEnter(plan.planName)" (mouseleave)="headerLeave(plan.planName)" [ngClass]="{'desktop-view' : mobilePlan != plan.planName}">
            <app-image [src]="noramlTick" *ngIf="plan.planName != selectedPlan && plan.planName != currentPlanObj.planName" ></app-image>
          <app-image [src]="selectedTick" *ngIf="plan.planName == selectedPlan && plan.planName != currentPlanObj.planName"></app-image>
          <app-image [src]="currentTick" *ngIf="plan.planName == currentPlanObj.planName"></app-image>
          </td>
        </tr>
        <tr *ngIf="!currentPlanObj.planId">
          <td class="td-title" [ngClass]="{'line-through' : parentData?.metadata.vendor}">{{trialDays}} days free trial</td>
          <ng-container *ngIf="!parentData?.metadata.vendor">
            <td *ngFor="let plan of planList" class="plan-content cursor" [ngClass]="{'desktop-view' : mobilePlan != plan.planName}" (mouseenter)="headerEnter(plan.planName)" (mouseleave)="headerLeave(plan.planName)">
              <app-image [src]="noramlTick" *ngIf="plan.planName != selectedPlan && plan.planName != currentPlanObj.planName" ></app-image>
              <app-image [src]="selectedTick" *ngIf="plan.planName == selectedPlan && plan.planName != currentPlanObj.planName"></app-image>
              <app-image [src]="currentTick" *ngIf="plan.planName == currentPlanObj.planName"></app-image>
            </td>
          </ng-container>
          <ng-container *ngIf="parentData?.metadata.vendor">
            <td id="account" class="plan-content cursor" [ngClass]="{'selected-plan' : plan.planName == selectedPlan , 'current-plan' : plan.planName == currentPlanObj.planName , 'desktop-view' : mobilePlan != plan.planName}" *ngFor="let plan of planList" (mouseenter)="headerEnter(plan.planName)" (mouseleave)="headerLeave(plan.planName)">-</td>
          </ng-container>
        </tr>
        <tr *ngIf="currentPlanObj.planId">
          <td class="td-title">Status</td>
          <td *ngFor="let plan of planList" class="plan-content cursor" [ngClass]="{'selected-plan' : plan.planName == selectedPlan , 'current-plan' : plan.planName == currentPlanObj.planName , 'desktop-view' : mobilePlan != plan.planName}" (mouseenter)="headerEnter(plan.planName)" (mouseleave)="headerLeave(plan.planName)">
            <app-text *ngIf="plan.planName == currentPlanObj.planName">Current plan</app-text>
            <app-text *ngIf="plan.planName != currentPlanObj.planName">{{ plan.playerSlot  > currentPlanObj.playerSlot ? 'Upgrade' : 'Downgrade'}}</app-text>
          </td>
        </tr>
    </table>
  </div>

  <div class="mt-40" class="table-container" *ngIf="loading">
    <app-loader [spinColor]="'#b1b1b3'" [height]="'43px'" [width]="'100%'" [borderRadius]="'10px'"></app-loader>
    <app-loader [spinColor]="'#b1b1b3'" [height]="'43px'" [width]="'100%'" [borderRadius]="'10px'"></app-loader>
    <app-loader [spinColor]="'#b1b1b3'" [height]="'43px'" [width]="'100%'" [borderRadius]="'10px'"></app-loader>
    <app-loader [spinColor]="'#b1b1b3'" [height]="'43px'" [width]="'100%'" [borderRadius]="'10px'"></app-loader>
    <app-loader [spinColor]="'#b1b1b3'" [height]="'43px'" [width]="'100%'" [borderRadius]="'10px'"></app-loader>
  </div>

  <div class="footer" *ngIf="!loading" >
    <span *ngIf="discountInfo?.percent_off">
      FREE TRIAL TERMS AND CONDITIONS:<br>
      This Free Trial is valid only for NEW subscribers for a period of {{trialDaysText}} ({{trialDays}}) days upon the creation of a Parent Account. At the end of this period, we will deduct, from your credit card, a fee of US${{(selectedPrice - (selectedPrice*(discountInfo?.percent_off/100))).toFixed(2)}} yearly starting from {{momentNext | date:'d LLL  yyyy'}}.
      <br>However, should you decide to make changes or upgrade your subscription plan at any time during this {{trialDaysText}} ({{trialDays}})-day period, the free trial will end and your first yearly fee will be deducted immediately. Please <a target="_blank" style="color: #007D49;text-decoration: none;" href="https://www.stemwerkz.org/end-user-agreement">click here</a> for more details on the payment terms and subscription cancellation.
    </span>
    <span *ngIf="!discountInfo?.percent_off && discountInfo.amount_off">
      FREE TRIAL TERMS AND CONDITIONS:<br>
      This Free Trial is valid only for NEW subscribers for a period of {{trialDaysText}} ({{trialDays}}) days upon the creation of a Parent Account. At the end of this period, we will deduct, from your credit card, a fee of US${{selectedPrice - discountInfo?.amount_off}} yearly starting from {{momentNext | date:'d LLL  yyyy'}}.
      <br>However, should you decide to make changes or upgrade your subscription plan at any time during this {{trialDaysText}} ({{trialDays}})-day period, the free trial will end and your first yearly fee will be deducted immediately. Please <a target="_blank" style="color: #007D49;text-decoration: none;" href="https://www.stemwerkz.org/end-user-agreement">click here</a> for more details on the payment terms and subscription cancellation.
    </span>
    <div class="d-flex justify-content-between align-item-center mt-40 gift-wrap">
      <div>
      </div>
      <button type="submit" class="cursor d-flex justify-content-center align-item-center" (click)="continue()" [ngClass]="{'continueButton': selectedPlanId , 'btn-disable' : !selectedPlanId}">
        <app-text [class]="'btn-text-style2'" [loading]="!selectedPlanId">Continue </app-text>
      </button>
    </div>
  </div>
</div>


<app-query-params-ls></app-query-params-ls>
