import {InjectionToken} from '@angular/core';
import { Observable } from 'rxjs';

export interface BusinessLogicRequirements {
    sentMail(mail): Observable<any>;
    checkMail(token): Observable<any>;
    resetPassword(token,body): Observable<any>;
}

export const BusinessRequirementsInjectionToken = new InjectionToken<BusinessLogicRequirements>('forgot-password Business Requirements')
