import {DataRequirements} from '../business-logic/data.requirements';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import { Observable } from 'rxjs';
import { PasswordApiService } from './api-services/password-api.service';

@NgModule({
    imports: [HttpClientModule]
})
export class DataFacade implements DataRequirements {
    constructor(
        private passwordApiService: PasswordApiService
    ) {}

    sentMail(mail) : Observable<any>{
        return this.passwordApiService.sentMail(mail);
    }

    checkMail(token) : Observable<any>{
        return this.passwordApiService.checkMail(token);
    }

    resetPassword(token,body) : Observable<any>{
        return this.passwordApiService.resetPassword(token,body);
    }
}
