import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  @Input() width = '100%';
  @Input() height = '50px';
  @Input() spinColor = '#FBF7EC';
  @Input() spinTopColor = '#FFFFFF';
  @Input() spinWidth = '6px';
  @Input() borderRadius = '10px';

  constructor() { }

  ngOnInit() {
  }

}
