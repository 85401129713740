import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../../../global-components/global-components.module';
import {SharedComponentsModule} from '../shared-components/shared-components.module';
// import {FeatherModule} from 'angular-feather';
import {RouterModule} from '@angular/router';
import { SchoolPlansChosenControllerComponent } from './school-plans-chosen-controller.component';

@NgModule({
    declarations: [
        SchoolPlansChosenControllerComponent
    ],
    exports: [
      SchoolPlansChosenControllerComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GlobalComponentsModule,
        SharedComponentsModule,
    ]
})
export class SchoolPlansChosenModule {}
