import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CodeStore {

}

export enum CodeStoreKeys {

}
