import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import {SubscriptionPlanListMapper} from '../mappers/subscription-plan-list.mapper';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionPlanListUseCase {
    constructor(
        private mapper: SubscriptionPlanListMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run() {
      return this.data.getAllPlans().pipe(this.mapper.map)
    }
}
