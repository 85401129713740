<div class="info-wrap mt-4 mb-4">
    <div class="d-flex of-hidden">
      <app-text class="info-title bg-secondary100 text-secondary700">
          <app-text class="text-caption-u h5-strong">{{title}}</app-text>
          <app-text class="light-body-m">&nbsp;{{note}}</app-text>
      </app-text>
      <div class="border-triangle bg-secondary100"></div>
      <!-- <app-image [src]="'./assets/images/highlight_bg.svg'"></app-image> -->
    </div>
    <div class="info-box">
        <ng-content></ng-content>
    </div>
</div>
