import {Component, Input, OnChanges, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-gift-success',
    templateUrl: './gift-success.component.html',
    styleUrls: ['./gift-success.component.css']
})
export class GiftSuccessComponent implements OnChanges {
  @Input() giftCard;
  @Input() homeUrl;
  @Output() buyAnotherCardClick = new EventEmitter<any>();

  ngOnChanges(){
    console.log("value changed",this.giftCard)
  }

  onBuyAnotherCardClick(){
    this.buyAnotherCardClick.emit();
  }
}
