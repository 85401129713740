import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { environment } from "../../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../../../lib/auth.service";
export class UserApiService {
    constructor(http, _router, _authService) {
        this.http = http;
        this._router = _router;
        this._authService = _authService;
        this.baseUrl = environment.apiurl;
        this.nodmaUrl = environment.nodmaApi;
        this.userInfo = {
            username: '',
            password: '',
            api_key: ''
        };
        this.userInfo = this._authService.getUserInfo();
        // this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        console.log("School DataFacade", this.userInfo);
    }
    parentSignup(parentData) {
        // const usersFromLocalStorage = this.getUsersLS()
        // usersFromLocalStorage.push({...user, imageURL: 'https://cdn1.iconfinder.com/data/icons/social-media-vol-1-1/24/_github-512.png'})
        // localStorage.setItem('users', JSON.stringify(usersFromLocalStorage))
        let url = this.baseUrl + "/register";
        return this.http.post(url, parentData).pipe(map((res) => {
            let result = res;
            // console.log(res);
            return result;
        }));
        // return of(true);
    }
    parentLogin() {
        // const usersFromLocalStorage = this.getUsersLS()
        // usersFromLocalStorage.push({...user, imageURL: 'https://cdn1.iconfinder.com/data/icons/social-media-vol-1-1/24/_github-512.png'})
        // localStorage.setItem('users', JSON.stringify(usersFromLocalStorage))
        let url = this.baseUrl + "/login";
        return this.http.get(url).pipe(map((res) => {
            let result = res;
            // console.log(res);
            return result;
        }));
        // return of(true);
    }
    getCountries() {
        console.log('hello--->');
        let url = this.nodmaUrl + '/api/v1/country';
        return this.http.get(url).pipe(map((res) => {
            let result = [res];
            return result;
        }));
    }
}
UserApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserApiService_Factory() { return new UserApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.AuthService)); }, token: UserApiService, providedIn: "root" });
