import { DataRequirements } from '../business-logic/data.requirements';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { PlayerDetailService } from './api-services/player-detail.service';
import { SubscriptionService } from './api-services/subscription.service';

@NgModule({
  imports: [HttpClientModule]
})
export class DataFacade implements DataRequirements {
  constructor(private playerDetailService: PlayerDetailService, private subscriptionService: SubscriptionService) {}
  editPlayer(playerId, playerData): Observable<any> {
    return this.playerDetailService.editPlayer(playerId, playerData);
  }

  getPlyerDetail(id): Observable<any> {
    return this.playerDetailService.getPlayer(id);
  }

  unsubscribeUser(data): Observable<any> {
    return this.subscriptionService.unsubscribeUser(data);
  }
  checkPlan(): Observable<any> {
    return this.subscriptionService.getPlan();
  }
  subscribeUser(data): Observable<any> {
    return this.subscriptionService.subscribeUser(data);
  }
  getPublishKey(): Observable<any> {
    return this.subscriptionService.getPublishKey();
  }
  changePayment(data): Observable<any> {
    return this.subscriptionService.updatePayment(data);
  }
  resubscribePlan(uid, sid): Observable<any> {
    return this.subscriptionService.resubscribePlan(uid, sid);
  }
  getCountries(): Observable<any> {
    return this.playerDetailService.getCountries();
  }
}
