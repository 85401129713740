import { Component, OnInit, Input, EventEmitter, Output, HostListener } from "@angular/core";
import { Router } from '@angular/router';
import { SharedService } from "src/app/lib/shared-service";

@Component({
  selector: "app-player-profiles",
  templateUrl: "./player-profiles.component.html",
  styleUrls: ["./player-profiles.component.css"],
})
export class PlayerProfilesComponent implements OnInit {
  @Input() players;
  @Input() playerLimit;
  @Input() createdCount;
  @Input() loader;
  @Input() isDowngrade = false;
  @Input() needToDelete;
  @Output() addSlot = new EventEmitter<any>();
  @Output() openplayerProfile = new EventEmitter<any>();
  @Output() planChange = new EventEmitter<any>();
  @Output() removePlayer = new EventEmitter<any>();

  public limitList=[];
  public isClick = false;
  public mobileView;
  public mobileViewService;
  public isHover = false;

  constructor(private router: Router,private shared: SharedService) {}

  @HostListener('document:click', ['$event.target'])
  onClick(btn) {
    if(btn.innerText.trim() != '') {
      this.isClick =false;
      this.normal();
    }
    console.log('button', btn.innerText.trim(), 'number of clicks:');
  }

  ngOnInit() {
    this.mobileViewService = this.shared.GetMobileView.subscribe(data=>{
      this.mobileView = data;
    })
    // this.mobileView = this.shared.getMobileView();
    this.limitList = Array(this.playerLimit).fill(4);
    console.log("player player");
    console.log(this.createdCount);
  }
  addNewSlot($event){
    this.addSlot.emit($event);
  }
  playerProfile(id){
    this.openplayerProfile.emit(id);
  }
  changePlan(){
    this.planChange.emit();
  }
  deletePlayer(data){
    console.log(data);
    this.removePlayer.emit(data);
  }
  hover(){
    this.isHover = true;
    if(!this.mobileView) document.getElementById('qinfo').style.display = 'flex';
    else if(this.mobileView) {
      document.getElementById('qinfo-mobile').style.opacity = '1';
      document.getElementById('qinfo-mobile').style.height = 'unset';
    }
  }
  normal(){
    this.isHover = false;
    if(!this.isClick && !this.mobileView) document.getElementById('qinfo').style.display = '';
    else if(!this.isClick && this.mobileView) {
      document.getElementById('qinfo-mobile').style.opacity = '0';
      document.getElementById('qinfo-mobile').style.height = '0px';
    }
  }
  clicked(){
    if(this.isHover){
      this.isHover = false;
    }
    this.isClick = !this.isClick;
    if(this.isClick && !this.isHover) this.hover()
    else if(this.isClick && this.isHover) console.log()
    else this.normal()
  }
  ngOnDestroy() {
    this.mobileViewService.unsubscribe();
  }
}
