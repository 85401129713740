import {BusinessLogicRequirements} from '../presentation/business-logic.requirements';
import {NgModule} from '@angular/core';
import {DataRequirementsInjectionToken} from './data.requirements';
import {DataFacade} from '../data/data.facade';
import { Observable } from 'rxjs';
import { SignupUseCase } from './use-cases/signup.use-case';
import { LoginUseCase } from './use-cases/login.use-case';
import { GetCountryListUseCase } from './use-cases/get-country-list.use-case';
import { CountryMapper } from './mappers/country.mapper';

@NgModule({
    imports: [DataFacade],

    providers: [
        {
            provide: DataRequirementsInjectionToken,
            useClass: DataFacade
        },
        CountryMapper
    ]
})
export class BusinessLogicFacade implements BusinessLogicRequirements {
  constructor(
    private signupUseCase: SignupUseCase,
    // private signupNewUseCase: SignupNewUseCase,
    private loginUseCase: LoginUseCase,
    private getCountryUseCase: GetCountryListUseCase
  ) {}
  signupParent(parentData: object): Observable<any> {
    // const parentObj = {
    //   password: "sc4zoeru3m9sc4zoeru3m9",
    // };
    // return of(parentObj);
    return this.signupUseCase.run(parentData);
  }
  loginParent(): Observable<any> {
    // const parentObj = {
    //   password: "sc4zoeru3m9sc4zoeru3m9",
    // };
    // return of(true);
    return this.loginUseCase.run();
  }

  getCountryList(): Observable<any> {
    return this.getCountryUseCase.run();
  }
}
