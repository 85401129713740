import {ItemMapper} from '../../../lib/item-mapper';
import { SchoolDiscountCodeModel } from 'src/app/models/school-discount-code.model';

export class DiscountCodeMapper extends ItemMapper<SchoolDiscountCodeModel> {
  protected mapFromItem(item): SchoolDiscountCodeModel {
      const result = new SchoolDiscountCodeModel();
      result.amount_off = item.amount_off;
      result.duration = item.duration;
      result.name = item.name;
      result.percent_off = item.percent_off;
      return result;
  }
}
