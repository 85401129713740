import {InjectionToken} from '@angular/core';
import { Observable } from 'rxjs';

export interface DataRequirements {
  registerParent(parentData): Observable<any>;
  loginParent(): Observable<any>;
  getCountryList(): Observable<any>;
}

export const DataRequirementsInjectionToken = new InjectionToken<DataRequirements>('school Data Requirements')
