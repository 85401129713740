import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SignupStore {

}

export enum SignupStoreKeys {

}
