<div class="overlay d-flex justify-content-center align-items-baseline">
  <div class="outer-frame d-flex flex-column bg-s0">
    <div class="d-flex justify-content-between" style="margin-bottom: 32px;">
      <span class="head-text">Confirm delete?</span>
      <img
      (click)="closeDelete($event)"
      class="close-btn"
      src="../../assets/images/close.svg"
      [ngClass]="{'cursor-disable' : pleaseWait}"
    />
    </div>
    <div class="inner-frame d-flex align-item-center">
      <div class="warning">
        Are you sure you want to delete <span class="player-name">{{ playerDetail.fullName}}</span>'s account? Deleting an account cannot be undone.
      </div>

      <div class="btn-div">
        <app-button [class]="'btn-red'" [loading]="pleaseWait" (click)="deletePlayer($event)">
          <app-text [class]="'btn-text-style2'" [loading]="pleaseWait"> {{ pleaseWait ? 'Deleting...' : 'Delete account'}} </app-text>
        </app-button>
      </div>
      <div (click)="closeDelete($event)" style="cursor: pointer;width: 100%;" class="d-flex justify-content-center align-item-center">
        <app-text [ngClass]="{'btn-disable' : pleaseWait , 'btn-cancel2' : !pleaseWait} " [class]="'btn-text-cancel2'" [loading]="pleaseWait">Cancel</app-text>
      </div>
    </div>


  </div>
</div>
