import {Inject, Injectable} from '@angular/core';
import { DataRequirementsInjectionToken, DataRequirements } from '../data.requirements';
import { GiftcardMapper } from "../mappers/giftcard-info.mapper";

@Injectable({
    providedIn: 'root'
})
export class GetGiftInfoUsecase {
    constructor(
        private mapper: GiftcardMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run(id) {
        console.log('usecase')
        return this.data.getGiftInfo(id).pipe(this.mapper.map);
        //return this.data.getGAuth(url);
    }
}
