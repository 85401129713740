/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home-login-controller.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../global-components/redirect-popup/redirect-popup.component.ngfactory";
import * as i3 from "../../../global-components/redirect-popup/redirect-popup.component";
import * as i4 from "../../../global-components/redirect-popup/redirect-popup.store";
import * as i5 from "@angular/common";
import * as i6 from "../shared-components/information/information.component.ngfactory";
import * as i7 from "../shared-components/information/information.component";
import * as i8 from "../shared-components/information/information.store";
import * as i9 from "../login/login-controller.component.ngfactory";
import * as i10 from "../login/login-controller.component";
import * as i11 from "@angular/forms";
import * as i12 from "../business-logic.requirements";
import * as i13 from "../login/login.store";
import * as i14 from "@angular/router";
import * as i15 from "../../../lib/auth.service";
import * as i16 from "../../../lib/shared-service";
import * as i17 from "../../../global-components/query-params-ls/query-params-ls.component.ngfactory";
import * as i18 from "../../../global-components/query-params-ls/query-params-ls.component";
import * as i19 from "../../../global-components/query-params-ls/query-params-ls.store";
import * as i20 from "./home-login-controller.component";
import * as i21 from "./home-login.store";
var styles_HomeLoginControllerComponent = [i0.styles];
var RenderType_HomeLoginControllerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeLoginControllerComponent, data: {} });
export { RenderType_HomeLoginControllerComponent as RenderType_HomeLoginControllerComponent };
function View_HomeLoginControllerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-redirect-popup", [["class", "cover-all"]], null, [[null, "popupBtn"], [null, "logoutBtn"], [null, "closePopup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("popupBtn" === en)) {
        var pd_0 = (_co.onClickPoupBtn($event) !== false);
        ad = (pd_0 && ad);
    } if (("logoutBtn" === en)) {
        var pd_1 = (_co.onClickLogoutBtn($event) !== false);
        ad = (pd_1 && ad);
    } if (("closePopup" === en)) {
        var pd_2 = (_co.closeRedirectPopup() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_RedirectPopupComponent_0, i2.RenderType_RedirectPopupComponent)), i1.ɵdid(1, 245760, null, 0, i3.RedirectPopupComponent, [i4.RedirectPopupStore], { userName: [0, "userName"], channelUrl: [1, "channelUrl"], valleyUrl: [2, "valleyUrl"], academyUrl: [3, "academyUrl"], dashboardUrl: [4, "dashboardUrl"], questUrl: [5, "questUrl"] }, { popupBtn: "popupBtn", logoutBtn: "logoutBtn", closePopup: "closePopup" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userName; var currVal_1 = _co.channelUrl; var currVal_2 = _co.valleyUrl; var currVal_3 = _co.academyUrl; var currVal_4 = _co.dashboardUrl; var currVal_5 = _co.questUrl; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_HomeLoginControllerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeLoginControllerComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "d-flex home-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-lg-7 home-left-side"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-information", [], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.buttonClickSignup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_InformationComponent_0, i6.RenderType_InformationComponent)), i1.ɵdid(5, 49152, null, 0, i7.InformationComponent, [i8.InformationStore], { buttonText: [0, "buttonText"] }, { buttonClick: "buttonClick" }), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "col-lg-5 no-padding home-right-side"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-login", [], null, [[null, "checkRedirectPopup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkRedirectPopup" === en)) {
        var pd_0 = (_co.checkRedirectPopup($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_LoginControllerComponent_0, i9.RenderType_LoginControllerComponent)), i1.ɵdid(8, 638976, null, 0, i10.LoginControllerComponent, [i11.FormBuilder, i12.BusinessRequirementsInjectionToken, i13.LoginStore, i14.Router, i14.ActivatedRoute, i15.AuthService, i16.SharedService], { onClickSignup: [0, "onClickSignup"] }, { checkRedirectPopup: "checkRedirectPopup" }), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-query-params-ls", [], null, null, null, i17.View_QueryParamsLsComponent_0, i17.RenderType_QueryParamsLsComponent)), i1.ɵdid(10, 114688, null, 0, i18.QueryParamsLsComponent, [i19.QueryParamsLsStore, i14.ActivatedRoute, i16.SharedService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showRedirectPopup; _ck(_v, 1, 0, currVal_0); var currVal_1 = "Sign up"; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.ClickSignup; _ck(_v, 8, 0, currVal_2); _ck(_v, 10, 0); }, null); }
export function View_HomeLoginControllerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home-login", [], null, null, null, View_HomeLoginControllerComponent_0, RenderType_HomeLoginControllerComponent)), i1.ɵdid(1, 114688, null, 0, i20.HomeLoginControllerComponent, [i12.BusinessRequirementsInjectionToken, i21.HomeLoginStore, i14.Router, i15.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeLoginControllerComponentNgFactory = i1.ɵccf("app-home-login", i20.HomeLoginControllerComponent, View_HomeLoginControllerComponent_Host_0, {}, {}, []);
export { HomeLoginControllerComponentNgFactory as HomeLoginControllerComponentNgFactory };
