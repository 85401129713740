/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./text.component";
import * as i3 from "./text.store";
var styles_TextComponent = [i0.styles];
var RenderType_TextComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextComponent, data: {} });
export { RenderType_TextComponent as RenderType_TextComponent };
export function View_TextComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onTextClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loading ? "btn-disable-text" : _co.class); _ck(_v, 0, 0, currVal_0); }); }
export function View_TextComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-text", [], null, null, null, View_TextComponent_0, RenderType_TextComponent)), i1.ɵdid(1, 114688, null, 0, i2.TextComponent, [i3.TextStore], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TextComponentNgFactory = i1.ɵccf("app-text", i2.TextComponent, View_TextComponent_Host_0, { class: "class", loading: "loading" }, { textClick: "textClick" }, ["*"]);
export { TextComponentNgFactory as TextComponentNgFactory };
