import { InjectionToken } from '@angular/core';
import { Observable, of } from 'rxjs';

export interface DataRequirements {
  registerParent(parentData): Observable<any>;
  registerParentNew(parentData): Observable<any>;
  loginParent(): Observable<any>;
  getCountryList(): Observable<any>;
  getPublishKey(): Observable<any>;
  updateUser(parentData: object): Observable<any>;
}

export const DataRequirementsInjectionToken = new InjectionToken<DataRequirements>('home Data Requirements');
