import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../global-components/global-components.module';
import {SharedComponentsModule} from './presentation/shared-components/shared-components.module';
import {BusinessLogicFacade} from './business-logic/business-logic.facade';
import {BusinessLogicRequirementsFake} from './presentation/business-logic.requirements.fake';
import {BusinessRequirementsInjectionToken} from './presentation/business-logic.requirements';
import {GiftCardShopRoutingModule} from './gift-card-shop-routing.module';
import {GiftCardModule} from './presentation/gift-card/gift-card.module';
import {GiftcardPurchaseProcessingModule} from './presentation/giftcard-purchase-processing/giftcard-purchase-processing.module';
import {GiftCardShopHomeModule} from './presentation/gift-card-shop-home/gift-card-shop-home.module';

@NgModule({
    imports: [
        BusinessLogicFacade,
        CommonModule,
        GlobalComponentsModule,
        SharedComponentsModule,
        GiftCardShopRoutingModule,

        GiftCardModule,
        GiftcardPurchaseProcessingModule,
        GiftCardShopHomeModule
    ],
    providers: [
        {
            provide: BusinessRequirementsInjectionToken,
            useClass: BusinessLogicFacade
            // useClass: BusinessLogicRequirementsFake
        }
    ],
    exports: []
})
export class GiftCardShopFeatureModule {}
