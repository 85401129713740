import {Inject, Component, OnInit} from '@angular/core';
import {BusinessLogicRequirements, BusinessRequirementsInjectionToken} from '../business-logic.requirements';
import {environment} from '../../../../environments/environment';
import { SharedService } from 'src/app/lib/shared-service';

@Component({
    selector: 'app-gift-card-shop-home',
    templateUrl: './gift-card-shop-home-controller.component.html',
    styleUrls: ['./gift-card-shop-home-controller.component.css']
})
export class GiftCardShopHomeControllerComponent implements OnInit  {
    constructor(
        @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements,
        private _shared: SharedService
    ) {}

    public stemwerkzUrl;
    public title = environment.titleStore;

    ngOnInit(){
      this.stemwerkzUrl = environment.stemwerkzLink;
      this._shared.setDocTitle(this.title);
    }
}
