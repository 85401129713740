import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export interface BusinessLogicRequirements {
  getPlayers(id): Observable<any>;
  createUser(userData: object): Observable<any>;
  getParentDetail(playerId): Observable<any>;
  getPlayerDetail(playerId): Observable<any>;
  editParent(parentId, parentData): Observable<any>;
  deletePlayer(playerId): Observable<any>;
  checkPlan(): Observable<any>;
  getPlan(id): Observable<any>;
  resubscribePlan(uid, sid): Observable<any>;
  emailCopy(id, pw): Observable<any>;
  changePayment(data): Observable<any>;
}

export const BusinessRequirementsInjectionToken = new InjectionToken<
  BusinessLogicRequirements
>("dashboard Business Requirements");
