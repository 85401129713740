import { Component, OnInit, Input, HostListener, Inject, NgModule, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-gift-card',
  templateUrl: './gift-card.component.html',
  styleUrls: ['./gift-card.component.css']
})
export class GiftCardComponent {
  @Input() invalidCode;
  @Input() loading;
  @Output() giftCancel = new EventEmitter<any>();
  @Output() giftContinue = new EventEmitter<any>();
  public alertURL = './assets/images/alert-icon.svg';

  //   @Input() discountCode = '';
  constructor(private fb: FormBuilder) {}
  public form = this.fb.group({
    giftCode: this.fb.control('', [Validators.required])
  });

  public convertedValue = '';

  ngOnInit() {
    console.log('zha loading', this.loading);

    let body = (document.getElementsByTagName('body')[0].style.overflow = 'hidden');
    window.scrollTo(0, 0);
    // this.form.setValue({
    //   giftCode: this.discountCode
    // });
    this.form.valueChanges.subscribe((value) => {
      console.log(this.form.get('giftCode').value);
      this.loading = false;
    });
  }
  cancelGift($event) {
    document.getElementById('discount').classList.add('discount-close');
    setTimeout(() => {
      this.giftCancel.emit();
    }, 100);
  }
  continueGift($event) {
    console.log(this.form.get('giftCode').value);
    if (!this.loading) this.giftContinue.emit(this.form.get('giftCode').value);
  }
  ngOnDestroy() {
    let body = (document.getElementsByTagName('body')[0].style.overflow = 'auto');
  }

  public mask = {
    guide: false,
    showMask: true,
    mask: [
      /[a-zA-Z0-9]/,
      /[a-zA-Z0-9]/,
      /[a-zA-Z0-9]/,
      /[a-zA-Z0-9]/,
      '-',
      /[a-zA-Z0-9]/,
      /[a-zA-Z0-9]/,
      /[a-zA-Z0-9]/,
      /[a-zA-Z0-9]/,
      '-',
      /[a-zA-Z0-9]/,
      /[a-zA-Z0-9]/,
      /[a-zA-Z0-9]/,
      /[a-zA-Z0-9]/
    ]
  };
}
