import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/lib/shared-service';
import * as moment from 'moment';
@Component({
  selector: 'app-subscription-history',
  templateUrl: './subscription-history.component.html',
  styleUrls: ['./subscription-history.component.css']
})
export class SubscriptionHistoryComponent implements OnInit {
  @Input() parentData;
  @Input() currentPlan;
  @Output() subscribeBtn = new EventEmitter<any>();
  @Output() cancelSubscribe = new EventEmitter<any>();
  @Output() planChange = new EventEmitter<any>();
  @Output() accountChange = new EventEmitter<any>();
  @Output() resubscribePlan = new EventEmitter<any>();
  @Output() blueDiv = new EventEmitter<any>();
  @Output() redDiv = new EventEmitter<any>();
  @Output() boxTypeEmit = new EventEmitter<any>();
  public expire: boolean = false;
  // public unsubscribe: boolean = false;
  public date = new Date();
  public mobileView;
  public mobileViewService;
  // public sevenDaysTrial = false;
  // public subTrial = false;
  public showBox = false;

  public boxType;
  public userType;

  constructor(private router: Router, private shared: SharedService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.parentData.metadata.registeredFrom == 'stemwerkz-start') {
      if (this.currentPlan.interval == 'year') this.userType = 'normal';
      else this.userType = 'start';
    } else if (this.parentData.metadata.vendor && this.parentData.metadata.vendor.slice(0, 6) != 'PROMO_')
      this.userType = 'vendor';
    else if (this.parentData.metadata.vendor && this.parentData.metadata.vendor.slice(0, 6) == 'PROMO_')
      this.userType = 'promo';
    else if (this.parentData.onboardLog && this.parentData.onboardLog.GIFTCARD) this.userType = 'giftcard';
    else this.userType = 'normal';

    console.error(this.userType, 'this user type !!!!');

    this.mobileViewService = this.shared.GetMobileView.subscribe((data) => {
      this.mobileView = data;
      this.ngOnChanges();
    });

    this.checkDateDiff();
    console.log('zha testing', this.currentPlan);
  }

  determineBoxType() {
    if (this.date.getTime() - new Date(this.currentPlan.userSubInfo.expiryDate).getTime() > 0) this.expire = true;
    console.error('userType ', this.userType);
    if (this.expire) {
      if (this.currentPlan.userSubInfo.status != 'UNSUBSCRIBED') this.boxType = 'afterNormal';
      else if (this.currentPlan.userSubInfo.status == 'UNSUBSCRIBED' && this.userType == 'giftcard')
        this.boxType = 'afterGiftcard';
      else if (
        this.currentPlan.userSubInfo.status == 'UNSUBSCRIBED' &&
        this.userType != 'start' &&
        this.userType != 'giftcard'
      )
        this.boxType = 'afterUnsubscribe';
      else if (this.currentPlan.userSubInfo.status == 'UNSUBSCRIBED' && this.userType == 'start')
        this.boxType = 'afterFromStart';
      this.showBox = true;
      this.boxTypeEmit.emit(this.boxType);
    } else {
      console.log(this.showBox, this.currentPlan.userSubInfo.status, this.userType);
      if (
        this.currentPlan.userSubInfo.status == 'UNSUBSCRIBED' &&
        this.userType != 'start' &&
        this.userType != 'giftcard'
      )
        this.boxType = 'beforeUnsubscribe';
      else if (this.currentPlan.userSubInfo.status == 'UNSUBSCRIBED' && this.userType == 'giftcard')
        this.boxType = 'beforeGiftcard';
      else if (
        this.currentPlan.userSubInfo.status == 'UNSUBSCRIBED' &&
        this.userType == 'start' &&
        this.currentPlan.userSubInfo.type == 'trial'
      )
        this.boxType = 'beforeFromStart';
      // else if (
      //   this.currentPlan.userSubInfo.status == 'ACTIVE' &&
      //   this.userType == 'start' &&
      //   this.currentPlan.userSubInfo.type == 'trial'
      // )
      //   this.boxType = 'beforeFromStart';
      // else if (this.userType == 'promo') this.boxType = 'beforePromo';
      // else if (this.userType == 'vendor') this.boxType = 'beforeVendor';
      if (this.showBox) this.boxTypeEmit.emit(this.boxType);
    }
    // afterNormal means cannot process subscribe with payment!
    // afterUnsubscribe
    // afterFromStart
    // beforeUnsubscribe
    // beforeFromStart
  }

  boxTypeClicked(boxType) {
    switch (boxType) {
      case 'afterNormal':
        this.subscribeNow('');
        break;
      case 'afterUnsubscribe':
        this.manageSub();
        break;
      case 'afterFromStart':
        this.changePlan('');
        break;
      case 'beforeUnsubscribe':
        this.resubscribe('');
        break;
      case 'beforeFromStart':
        this.changePlan('');
        break;
      case 'beforeGiftcard':
        this.resubscribe('');
        break;
      case 'afterGiftcard':
        this.resubscribe('');
        break;
    }
  }

  ngOnDestroy() {
    this.mobileViewService.unsubscribe();
  }

  // ngAfterViewInit() {

  // }

  ngOnChanges() {
    //emittoparent
    console.log('onchanges');
    this.checkDateDiff();
    // this.boxTypeEmit.emit(this.boxType);
    // if (this.currentPlan.userSubInfo.status == 'UNSUBSCRIBED' && this.currentPlan.userSubInfo.type != 'trial') {
    //   this.unsubscribe = true;
    // } else if (this.currentPlan.userSubInfo.status == 'UNSUBSCRIBED' && this.currentPlan.userSubInfo.type == 'trial') {
    //   if (!this.currentPlan.userSubInfo.subscriptionId) this.sevenDaysTrial = true;
    //   else this.unsubscribe = true;
    // } else {
    //   this.subTrial = false;
    // }
    // if (this.currentPlan.userSubInfo.status == 'ACTIVE') {
    //   this.unsubscribe = false;
    // }
    // if (this.unsubscribe && !this.expire && this.mobileView && this.showBox) {
    //   this.blueDiv.emit(this.currentPlan.userSubInfo.expiryDate);
    //   console.log('blue DIV');
    // } else if (this.unsubscribe && this.expire && this.mobileView) {
    //   this.redDiv.emit(this.currentPlan.userSubInfo.expiryDate);
    //   console.log('red DIV');
    // }
  }

  checkDateDiff() {
    var dateDiff;
    var expireDate = moment(this.currentPlan.userSubInfo.expiryDate);
    var momentToday = moment(this.date);
    dateDiff = expireDate.diff(momentToday);
    var days = (dateDiff / (1000 * 60 * 60 * 24)).toFixed(1);
    dateDiff = days;
    console.log('dateDiff ', dateDiff, expireDate);
    if (dateDiff <= 7 && dateDiff > 0) this.showBox = true;
    else this.showBox = false;
    this.determineBoxType();
  }

  subscribeNow($event) {
    this.subscribeBtn.emit($event);
  }

  cancelBtn($event) {
    this.cancelSubscribe.emit($event);
  }

  changePlan($event) {
    this.planChange.emit();
  }

  paymentChange() {
    this.accountChange.emit();
  }

  manageSub() {
    this.router.navigateByUrl(`/plans/${this.currentPlan.planId}?change=true&unsubscribe=true`);
  }

  resubscribe($event) {
    this.resubscribePlan.emit($event);
  }
}
