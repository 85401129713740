import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {GlobalComponentsModule} from '../../../global-components/global-components.module';
import {LoaderComponent} from './loader/loader.component';
import { HoorayComponent } from './hooray/hooray.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { EmailSuccessComponent } from './email-success/email-success.component';

const components = [
  LoaderComponent, HoorayComponent,ConfirmComponent, EmailSuccessComponent
]

@NgModule({
    declarations: components,
    exports: components,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        GlobalComponentsModule
    ]
})
export class SharedComponentsModule {}
