import { throwError } from 'rxjs';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "./shared-service";
// import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
//import { Router } from '@angular/router';
export class AuthInterceptorService {
    constructor(auth, shared) {
        this.auth = auth;
        this.shared = shared;
        // private tmpHeader ={
        //     "apikey": "bc77612ecd1a2e7f6ace383b4cee7848",
        //     "user": "request from wwn"
        // }
        this.baseUrl = environment.apiurl;
        this.nodmaUrl = environment.nodmaApi;
        this.superkey = environment.apikey;
        this.tmpHeader = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        this.token = null;
        this.parentData = null;
        this.apikey = null;
        this.userName = null;
    }
    intercept(request, next) {
        this.parentData = this.auth.getParentObject();
        console.log('parentData ', this.parentData);
        if (this.auth.isAuthenticated()) {
            this.token = this.auth.getUserToken();
            this.apikey = this.auth.getApiKey();
            this.userName = this.auth.getUserName();
            if (this.userName == null) {
                this.userName = this.parentData.username;
            }
        }
        console.log('request data', request);
        // console.log(this.baseUrl + '/api/v2/users/shadow-parent/' + this.shared.getUserInfo().userId);
        // console.log("request ", request,this.baseUrl + "/api/v1/subscriptions/" + this.shared.getCurrentPlanId());
        if (request.url == this.baseUrl + '/register') {
            this.tmpHeader = new HttpHeaders({
                'Content-Type': 'application/json',
                apikey: this.superkey
            });
        }
        else if (request.url == this.baseUrl + '/login') {
            console.log('this is login');
            this.tmpHeader = new HttpHeaders({
                'Content-Type': 'application/json',
                apikey: this.superkey,
                username: this.parentData.username,
                password: this.parentData.password
            });
        }
        else if (request.url == this.baseUrl + '/api/v2/users') {
            // const token = this.auth.getUserToken();
            console.log('/api/v2/users ');
            console.log(this.userName, this.token);
            if (request.method == 'POST') {
                this.tmpHeader = new HttpHeaders({
                    'Content-Type': 'application/json',
                    apikey: this.apikey,
                    superapikey: this.superkey,
                    username: this.userName,
                    password: this.token
                });
                console.log(this.tmpHeader);
            }
            else {
                this.tmpHeader = new HttpHeaders({
                    'Content-Type': 'application/json',
                    apikey: this.apikey,
                    username: this.userName,
                    password: this.token
                });
            }
        }
        else if (request.url == this.baseUrl + '/forgot-password') {
            console.log('this is forgot-password');
            this.tmpHeader = new HttpHeaders({
                'Content-Type': 'application/json'
            });
        }
        else if (request.url == this.baseUrl + '/reset-password?resetPasswordToken=' + localStorage.getItem('token')) {
            console.log('this is reset-password');
            this.tmpHeader = new HttpHeaders({
                'Content-Type': 'application/json'
            });
        }
        else if (request.url == this.baseUrl + '/api/v1/gift-cards/' + this.shared.getGiftCard()) {
            console.log('this is gift code');
            this.tmpHeader = new HttpHeaders({
                'Content-Type': 'application/json'
            });
        }
        else if (request.url == this.baseUrl + '/api/v1/discount-info/' + this.shared.getSchoolDiscountCode()) {
            console.log('this is school discount code');
            this.tmpHeader = new HttpHeaders({
                'Content-Type': 'application/json'
            });
        }
        else if (request.url == this.baseUrl + '/api/v2/subscriptions' ||
            request.url == this.baseUrl + '/api/v1/subscriptions' ||
            request.url == this.baseUrl + '/stripe/publish-key' ||
            request.url.includes(this.baseUrl + '/api/v1/gift-cards/') ||
            request.url.includes(this.nodmaUrl + '/api/v1/country')) {
            console.log('this is meme');
            this.tmpHeader = new HttpHeaders({
                'Content-Type': 'application/json'
            });
        }
        else if (request.url == this.baseUrl + '/api/v1/subscriptions?interval=year' ||
            request.url == this.baseUrl + '/api/v1/subscriptions/' + this.shared.getCurrentPlanId()) {
            console.log('this is school chosen');
            this.tmpHeader = new HttpHeaders({
                'Content-Type': 'application/json'
            });
        }
        else if (request.url.includes('shadow-parent') &&
            request.url == this.baseUrl + '/api/v2/users/shadow-parent/' + this.shared.getUserInfo().userId) {
            console.log('shadow parent', this.shared.getUserInfo());
            this.tmpHeader = new HttpHeaders({
                'Content-Type': 'application/json',
                apikey: this.shared.getUserInfo().ApiKey,
                username: this.shared.getUserInfo().username,
                password: this.shared.getUserInfo().Token
            });
        }
        else {
            this.tmpHeader = new HttpHeaders({
                'Content-Type': 'application/json',
                apikey: this.apikey,
                username: this.userName,
                password: this.token
            });
        }
        console.log('this.tmpHeader', this.tmpHeader);
        request = request.clone({
            //   headers: request.headers.set('apikey', 'bc77612ecd1a2e7f6ace383b4cee7848'),
            headers: this.tmpHeader
        });
        return next.handle(request).pipe(map((event) => {
            if (event instanceof HttpResponse) {
                console.log('event--->>>', event.status);
            }
            return event;
        }), catchError((error) => {
            let data = {};
            let res;
            data = {
                reason: error && error.error && error.error.reason ? error.error.reason : '',
                status: error.status
            };
            console.log('my error', data['reason']);
            console.log('ERROR...........', data['status']);
            return throwError(error);
        }));
    }
}
AuthInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthInterceptorService_Factory() { return new AuthInterceptorService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.SharedService)); }, token: AuthInterceptorService, providedIn: "root" });
