import {Inject, Injectable} from '@angular/core';
import { from } from 'rxjs';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';

import {GiftCardListMapper} from '../mappers/gift-card-list.mapper';

@Injectable({
    providedIn: 'root'
})
export class GiftCardListUseCase {
    constructor(
        private mapper: GiftCardListMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run() {
      return this.data.getAllGiftCards().pipe(this.mapper.map);
    }
}
