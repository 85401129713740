/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input-box-card.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../global-components/text/text.component.ngfactory";
import * as i3 from "../../../../../global-components/text/text.component";
import * as i4 from "../../../../../global-components/text/text.store";
import * as i5 from "./input-box-card.component";
var styles_InputBoxCardComponent = [i0.styles];
var RenderType_InputBoxCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputBoxCardComponent, data: {} });
export { RenderType_InputBoxCardComponent as RenderType_InputBoxCardComponent };
export function View_InputBoxCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "info-wrap mt-4 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "d-flex of-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "app-text", [["class", "info-title bg-secondary100 text-secondary700"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(3, 114688, null, 0, i3.TextComponent, [i4.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "app-text", [["class", "text-caption-u h5-strong"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(5, 114688, null, 0, i3.TextComponent, [i4.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(6, 0, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "app-text", [["class", "light-body-m"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(8, 114688, null, 0, i3.TextComponent, [i4.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(9, 0, ["\u00A0", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "border-triangle bg-secondary100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "info-box"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var currVal_0 = "info-title bg-secondary100 text-secondary700"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "text-caption-u h5-strong"; _ck(_v, 5, 0, currVal_1); var currVal_3 = "light-body-m"; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.title; _ck(_v, 6, 0, currVal_2); var currVal_4 = _co.note; _ck(_v, 9, 0, currVal_4); }); }
export function View_InputBoxCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-input-box-card", [], null, null, null, View_InputBoxCardComponent_0, RenderType_InputBoxCardComponent)), i1.ɵdid(1, 49152, null, 0, i5.InputBoxCardComponent, [], null, null)], null, null); }
var InputBoxCardComponentNgFactory = i1.ɵccf("app-input-box-card", i5.InputBoxCardComponent, View_InputBoxCardComponent_Host_0, { title: "title", note: "note" }, {}, ["*"]);
export { InputBoxCardComponentNgFactory as InputBoxCardComponentNgFactory };
