import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/giftcard-info.mapper";
import * as i2 from "../data.requirements";
export class GetGiftInfoUsecase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(id) {
        console.log('usecase');
        return this.data.getGiftInfo(id).pipe(this.mapper.map);
        //return this.data.getGAuth(url);
    }
}
GetGiftInfoUsecase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetGiftInfoUsecase_Factory() { return new GetGiftInfoUsecase(i0.ɵɵinject(i1.GiftcardMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: GetGiftInfoUsecase, providedIn: "root" });
