import { Component, Input } from '@angular/core';
import { TermsConditionStore } from './terms-condition.store';

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.css']
})
export class TermsConditionComponent {
  @Input() trialDaysText;
  @Input() trialDays;
  @Input() plan;
  @Input() momentNext;
  @Input() templateName;
  constructor(private store: TermsConditionStore) {}

  ngOnInit() {
    console.log('templateName ', this.templateName);
  }
}
