import { Inject, Injectable } from "@angular/core";
import {
  DataRequirements,
  DataRequirementsInjectionToken,
} from "../data.requirements";
import { PlayerListMapper } from "../mappers/player-list.mapper";

@Injectable({
  providedIn: "root",
})
export class GetPlayersUseCase {
  constructor(
    @Inject(DataRequirementsInjectionToken) private data: DataRequirements,
    private playermap: PlayerListMapper
  ) {}

  run(id) {
    return this.data.getPlayers(id).pipe(this.playermap.map);
  }
}
