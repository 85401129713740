<div class="overlay overlay-change">
  <div [ngClass]="{'create-container' : !isMobileView, 'create-container-mobile' : isMobileView}">
    <div class="title">Create your child’s account
      <img (click)="closePopup($event)" class="close-btn" src="../../assets/images/cancel.svg" [ngClass]="{'cursor-disable' : pleaseWait}"/>
      <img (click)="closePopup($event)" class="mobile-closeBtn" src="../../assets/images/close.svg" [ngClass]="{'cursor-disable' : pleaseWait}"/>
    </div>
    <div class="form-div">
      <form (keyup.enter)="$event.preventDefault(); createPlayer($event)">
        <div class="inner-form" [ngClass]="{'flex-column': isMobileView}">
          <div class="player-div">
            <div class="first-name">First name</div>
            <app-input [hint]="'Type your child first name'" [control]="form.get('firstName')" [width]="'100%'"
              [class]="fNameClass" (blurEvent)="blurInput($event)">
              <app-image *ngIf="!form.get('firstName').errors && fTick" [src]="tickURL"></app-image>
              <app-image *ngIf="form.get('firstName').errors && clickBtn" [src]="alertURL"></app-image>
            </app-input>
            <app-text *ngIf="form.get('firstName').errors && clickBtn" [class]="'form-error-label'">Enter your first name
            </app-text>
          </div>
          <br *ngIf="isMobileView">
          <div class="player-div">
            <div class="first-name">Last name</div>
            <app-input [hint]="'Type your child last name'" [control]="form.get('lastName')" [width]="'100%'"
              [class]="lNameClass" (blurEvent)="blurInput($event)">
              <app-image *ngIf="!form.get('lastName').errors && lTick" [src]="tickURL"></app-image>
              <app-image *ngIf="form.get('lastName').errors && clickBtn" [src]="alertURL"></app-image>
            </app-input>
            <app-text *ngIf="form.get('lastName').errors && clickBtn" [class]="'form-error-label'">Enter your last name
            </app-text>
          </div>
        </div>


        <div class="dob-form" [ngClass]="{'flex-column': isMobileView}">
          <div class="player-div">
            <div class="first-name">Year of birth</div>
            <!-- <app-input [hint]="'Choose year'" [control]="form.get('year')" [width]="'100%'"
              [class]="lNameClass" (blurEvent)="blurInput($event)" [readonly]="!showYearBox" class="dob-select">
              <app-image [src]="dobUrl"></app-image>
            </app-input>
            <div *ngIf="showYearBox" class="year-box">
              <div *ngFor="let year of yearList; let last=last, let first = first" class="single-year year-list" [ngClass]="{'last-row' : last, 'bg-b100 text-s0': year==form.get('year').value , 'd-none' : !year.toLowerCase().includes(keyword.toLowerCase()) , 'year-list-hover' : first && year!==form.get('year').value}" (click)="choseYear(year)">
                {{year}}
              </div>
            </div> -->
            <app-drop-down-search [itemList]="yearList" [emailFlag]="emailFlag" [hint]="'Choose year'" [defaultValue]="null" [pure]="true" [create]="true" *ngIf="yearList" (isOpen)="isOpenYear($event)" (comebackData)="selectYear($event)"></app-drop-down-search>
          </div>
          <div class="player-div" *ngIf="selectedYear || dobError">
            <app-text style="height: 35px;" [ngClass]="{'v-hidden' : !isMobileView , 'd-none' : isMobileView , 'd-flex' : !isMobileView ,'align-items-center' : !isMobileView}"> years old
            </app-text>
            <app-text *ngIf="!dobError" class="d-flex align-items-center" style="height: 48px;" [ngClass]="{'pl-24' : !isMobileView}">{{yearOld}} {{yearOld >1 ?'years' : 'year'}} old
            </app-text>
            <app-text *ngIf="dobError" [class]="'form-error-label'" class="d-flex align-items-center" style="height: 48px;" [ngClass]="{'pl-24' : !isMobileView}">required!
            </app-text>
          </div>
        </div>

        <!-- <div class="dob-form" [ngClass]="{'flex-column': isMobileView}">
          <div class="player-div" >
            <div class="first-name">Year of birth</div>

              <div class="dob" [ngClass]="{'dob-clicked' : showYearBox , 'dob-normal' : !showYearBox, 'dob-correct' : !dobError, 'dob-error' : dobError}" (click)="showYearBox = !showYearBox">
                <app-text [ngClass]="{'place-holder' : !selectedYear, 'chosen-year' : selectedYear}">{{dobText}}</app-text>
                <app-image [src]="dobUrl"></app-image>
              </div>
              <div *ngIf="showYearBox" class="year-box">
                <div *ngFor="let year of yearList" class="single-year" (click)="choseYear(year)">
                  {{year}}
                </div>
              </div>
          </div>
          <div class="player-div" *ngIf="selectedYear || dobError">
            <app-text style="height: 35px;" [ngClass]="{'v-hidden' : !isMobileView , 'd-none' : isMobileView , 'd-flex' : !isMobileView ,'align-items-center' : !isMobileView}"> years old
            </app-text>
            <app-text *ngIf="!dobError" class="d-flex align-items-center" style="height: 48px;" [ngClass]="{'pl-24' : !isMobileView}">{{yearOld}} {{yearOld >1 ?'years' : 'year'}} old
            </app-text>
            <app-text *ngIf="dobError" [class]="'form-error-label'" class="d-flex align-items-center" style="height: 48px;" [ngClass]="{'pl-24' : !isMobileView}">required!
            </app-text>
          </div>
        </div> -->
        <div class="username-div">
          <div class="username">Gender</div>
          <app-button [class]="'btn-gender boy'" (btnClick)="selectGenter($event)">Boy</app-button>
          <app-button [class]="'btn-gender girl'" (btnClick)="selectGenter($event)">Girl</app-button>
          <app-button [class]="'btn-gender i-prefer-not-to-say'" (btnClick)="selectGenter($event)">I prefer not to say</app-button>
          <div>
            <app-text *ngIf="emptygender" [class]="'form-error-label'">Choose your gender
            </app-text>
            <app-image *ngIf="emptygender" [src]="alertURL"></app-image>
          </div>
        </div>
        <div class="username-div">
          <div class="username">Child’s username for access to STEMWerkz</div>
          <app-input [hint]="'Type your child username'" [control]="form.get('username')" [width]="'100%'" [ngClass]="{
            'signup-input': !errMsg,
            'input-err': errMsg || (form.get('username').errors && clickBtn)
          }" (blurEvent)="blurInput($event)">
            <app-image *ngIf="!form.get('username').errors && uTick" [src]="tickURL"></app-image>
            <app-image *ngIf="(form.get('username').errors || errMsg) && clickBtn" [src]="alertURL"></app-image>
          </app-input>
          <app-text *ngIf="form.get('username').errors && clickBtn" [class]="'form-error-label'">Enter a username
          </app-text>
          <app-text *ngIf="errMsg && clickBtn" [class]="'form-error-label'">
            {{ errMsg }}
          </app-text>
        </div>
        <div class="email">
          <div *ngIf="!emailFlag" (click)="emailOption()" class="email-text">
            Add email address
          </div>
          <div *ngIf="emailFlag">
            <div class="username">Email</div>
            <app-input [hint]="'Type your child email'" [control]="form.get('email')" [width]="'100%'" [class]="emailClass"
              (blurEvent)="blurInput($event)">
              <app-image *ngIf="!form.get('email').errors && eTick" [src]="tickURL"></app-image>
              <app-image *ngIf="form.get('email').errors && clickBtn" [src]="alertURL"></app-image>
            </app-input>
            <app-text *ngIf="form.get('email').errors && clickBtn" [class]="'form-error-label'">Enter an email</app-text>
          </div>
        </div>
        <div class="pw-div">
          <div class="create-password">
            <div class="pw-text">Password</div>
            <app-text *ngIf="show" [class]="'showPswd'" (textClick)="showPassword($event)">Show</app-text>
            <app-text *ngIf="hide" [class]="'showPswd'" (textClick)="hidePassword($event)">Hide</app-text>
          </div>
          <app-input [hint]="'Add a secure password'" [control]="form.get('password')" [width]="'100%'"
            [class]="passwordClass" [type]="togglePassword" (blurEvent)="blurInput($event)">
            <app-image *ngIf="!form.get('password').errors && pTick" [src]="tickURL"></app-image>
            <app-image *ngIf="form.get('password').errors && clickBtn" [src]="alertURL"></app-image>
          </app-input>
          <app-text *ngIf="form.get('password').errors && clickBtn" [class]="'form-error-label'">Passwords must be 8
            characters or more</app-text>
          <div *ngIf="!form.get('password').errors || !clickBtn" class="pw-reminder">
            Passwords must be 8 characters or more
          </div>
        </div>
      </form>
      <div class="btn-div">
        <div class="create-btn-div" (click)="createPlayer($event)">
          <app-button [class]="'btn-orange'" [loading]="pleaseWait">
            <app-text [class]="'btn-text-style2'" [loading]="pleaseWait"> {{ pleaseWait ? 'Creating...' : 'Create your child account'}} </app-text>
          </app-button>
        </div>
      </div>
      <div class="btn-div">
      <div style="cursor: pointer;" (click)="closePopup($event)">
        <app-button [class]="'btn-cancel'" [loading]="pleaseWait">
          <app-text [class]="'btn-text-cancel'" [loading]="pleaseWait"> {{ pleaseWait ? 'Cancel' : 'Cancel'}} </app-text>
        </app-button>
      </div>
    </div>
    </div>

  </div>
</div>
