import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SubscriptionService {
    constructor(http) {
        this.http = http;
        this.baseUrl = environment.apiurl;
    }
    getPlan() {
        let url = this.baseUrl + "/api/v2/users/current-subscription";
        return this.http.get(url).pipe(map((res) => {
            let result = [];
            result[0] = res;
            console.log(result);
            return result;
        }));
    }
    subscribeUser(data) {
        let url = this.baseUrl + "/api/v2/subscriptions/subscribe";
        console.log(url);
        return this.http.post(url, data).pipe(map((res) => {
            let result = res;
            console.log(result);
            return result;
        }));
    }
    getPublishKey() {
        let url = this.baseUrl + "/stripe/publish-key";
        console.log(url);
        return this.http.get(url).pipe(map((res) => {
            let result = res;
            console.log(result);
            return result;
        }));
    }
    unsubscribeUser(data) {
        let url = this.baseUrl + "/api/v2/subscriptions/unsubscribe";
        console.log(url);
        return this.http.post(url, data).pipe(map((res) => {
            let result = res;
            console.log(result);
            return result;
        }));
    }
    updatePayment(data) {
        let url = this.baseUrl + "/api/v2/stripe/customer-portal";
        console.log(url);
        return this.http.post(url, data).pipe(map((res) => {
            let result = res;
            console.log(result);
            return result;
        }));
    }
    resubscribePlan(uid, sid) {
        let url = this.baseUrl + "/api/v2/subscriptions/resubscribe";
        let body = {
            userId: uid,
            subscriptionId: sid
        };
        console.log(url);
        return this.http.post(url, body).pipe(map((res) => {
            let result = res;
            console.log(result);
            return result;
        }));
    }
}
SubscriptionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubscriptionService_Factory() { return new SubscriptionService(i0.ɵɵinject(i1.HttpClient)); }, token: SubscriptionService, providedIn: "root" });
