import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../lib/auth.service";
export class PlanApiService {
    constructor(httpClient, http, _authService) {
        this.httpClient = httpClient;
        this.http = http;
        this._authService = _authService;
        this.baseUrl = environment.apiurl;
        this.userInfo = {
            username: '',
            password: '',
            api_key: ''
        };
        this.userInfo = this._authService.getUserInfo();
    }
    getPlanList() {
        let url = this.baseUrl + '/api/v1/subscriptions';
        // let url = this.baseUrl + "/api/v2/subscriptions";
        console.log(url);
        return this.http.get(url).pipe(map((res) => {
            let result = res;
            console.log(result);
            return result;
        }));
    }
    getPromoInfo(tag) {
        // let url = this.baseUrl + "/api/v2/subscriptions?intervel=quarter&tag="+tag;
        let url = this.baseUrl + '/api/v2/subscriptions?tag=' + tag;
        // let url = 'https://devstemgame-api.pagewerkz.com/api/v2/subscriptions?tag=PROMO_AUREON';
        // let url = this.baseUrl + "/api/v2/subscriptions";
        console.log(url);
        return this.http.get(url).pipe(map((res) => {
            let result = res;
            console.log(result);
            return result;
        }));
    }
    getPlan(id) {
        let url = this.baseUrl + '/api/v2/subscriptions/' + id;
        console.log(url);
        return this.http.get(url).pipe(map((res) => {
            let result = [res];
            console.log(result);
            return result;
        }));
    }
    getSubscription() {
        let url = this.baseUrl + '/api/v2/users/current-subscription';
        return this.http.get(url).pipe(map((res) => {
            let result = [res];
            console.log(result, 'ahhhhhhhhhhhhhhhhh');
            return result;
        }, (err) => {
            console.log('errrrrrrrrrrr', err);
        }));
    }
    subscribeUser(data) {
        let url = this.baseUrl + '/api/v2/subscriptions/subscribe';
        console.log(url);
        return this.http.post(url, data).pipe(map((res) => {
            let result = res;
            console.log(result);
            return result;
        }));
    }
    getPublishKey() {
        let url = this.baseUrl + '/stripe/publish-key';
        console.log(url);
        return this.http.get(url).pipe(map((res) => {
            let result = res;
            console.log(result);
            return result;
        }));
    }
    getGiftInfo(id) {
        let url = this.baseUrl + '/api/v1/gift-cards/' + id;
        console.log(url);
        return this.http.get(url).pipe(map((res) => {
            let result = res;
            console.log('zha giftcard', result);
            return [result];
        }));
    }
    claimGiftCard(id, opt, redir) {
        let body = {
            code: id,
            option: opt,
            redirectURL: redir
        };
        let url = this.baseUrl + '/api/v2/gift-cards/claim';
        console.log(url);
        return this.http.post(url, body).pipe(map((res) => {
            let result = res;
            console.log('giftcard claim', result);
            return [result];
        }));
    }
}
PlanApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlanApiService_Factory() { return new PlanApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: PlanApiService, providedIn: "root" });
