/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./discount-popup.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../image/image.component.ngfactory";
import * as i3 from "../image/image.component";
import * as i4 from "../image/image.store";
import * as i5 from "../text/text.component.ngfactory";
import * as i6 from "../text/text.component";
import * as i7 from "../text/text.store";
import * as i8 from "../input/input.component.ngfactory";
import * as i9 from "../input/input.component";
import * as i10 from "../input/input.store";
import * as i11 from "@angular/common";
import * as i12 from "../button/button.component.ngfactory";
import * as i13 from "../button/button.component";
import * as i14 from "../button/button.store";
import * as i15 from "./discount-popup.component";
import * as i16 from "@angular/forms";
var styles_DiscountPopupComponent = [i0.styles];
var RenderType_DiscountPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DiscountPopupComponent, data: {} });
export { RenderType_DiscountPopupComponent as RenderType_DiscountPopupComponent };
function View_DiscountPopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image", [], null, null, null, i2.View_ImageComponent_0, i2.RenderType_ImageComponent)), i1.ɵdid(1, 114688, null, 0, i3.ImageComponent, [i4.ImageStore], { src: [0, "src"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alertURL; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DiscountPopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-text", [], null, null, null, i5.View_TextComponent_0, i5.RenderType_TextComponent)), i1.ɵdid(1, 114688, null, 0, i6.TextComponent, [i7.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(-1, 0, ["Invalid discount code "]))], function (_ck, _v) { var currVal_0 = "form-error-label"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DiscountPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "overlay d-flex justify-content-center align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 21, "div", [["class", "d-flex discount"], ["id", "discount"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add discount code"])), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["style", "margin-bottom: 32px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Discount code"])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "app-input", [], null, [[null, "keydown"], [null, "paste"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("paste" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onPaste($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_InputComponent_0, i8.RenderType_InputComponent)), i1.ɵdid(8, 114688, null, 0, i9.InputComponent, [i10.InputStore, i11.DOCUMENT, i1.ElementRef], { control: [0, "control"], width: [1, "width"], hint: [2, "hint"], class: [3, "class"], value: [4, "value"] }, null), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_DiscountPopupComponent_1)), i1.ɵdid(10, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DiscountPopupComponent_2)), i1.ɵdid(12, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 4, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.continueDiscount($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_ButtonComponent_0, i12.RenderType_ButtonComponent)), i1.ɵdid(15, 49152, null, 0, i13.ButtonComponent, [i14.ButtonStore], { class: [0, "class"], loading: [1, "loading"] }, null), (_l()(), i1.ɵeld(16, 0, null, 0, 2, "app-text", [], null, null, null, i5.View_TextComponent_0, i5.RenderType_TextComponent)), i1.ɵdid(17, 114688, null, 0, i6.TextComponent, [i7.TextStore], { class: [0, "class"], loading: [1, "loading"] }, null), (_l()(), i1.ɵted(-1, 0, ["Continue "])), (_l()(), i1.ɵeld(19, 0, null, null, 3, "div", [["style", "width: 100%; margin-top: 24px"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelDiscount($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "app-text", [["class", "btn-cancel2"], ["style", "cursor: pointer"]], null, null, null, i5.View_TextComponent_0, i5.RenderType_TextComponent)), i1.ɵdid(21, 114688, null, 0, i6.TextComponent, [i7.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form.get("disCode"); var currVal_1 = "100%"; var currVal_2 = "Enter discount code..."; var currVal_3 = "signup-input"; var currVal_4 = _co.discountCode; _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.disable; _ck(_v, 10, 0, currVal_5); var currVal_6 = _co.disable; _ck(_v, 12, 0, currVal_6); var currVal_7 = "btn-orange"; var currVal_8 = _co.disable; _ck(_v, 15, 0, currVal_7, currVal_8); var currVal_9 = "btn-text-style2"; var currVal_10 = _co.disable; _ck(_v, 17, 0, currVal_9, currVal_10); var currVal_11 = "btn-text-cancel2"; _ck(_v, 21, 0, currVal_11); }, null); }
export function View_DiscountPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-discount-popup", [], null, null, null, View_DiscountPopupComponent_0, RenderType_DiscountPopupComponent)), i1.ɵdid(1, 245760, null, 0, i15.DiscountPopupComponent, [i16.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DiscountPopupComponentNgFactory = i1.ɵccf("app-discount-popup", i15.DiscountPopupComponent, View_DiscountPopupComponent_Host_0, { discountCode: "discountCode" }, { discountCancel: "discountCancel", discountContinue: "discountContinue" }, []);
export { DiscountPopupComponentNgFactory as DiscountPopupComponentNgFactory };
