<app-header *ngIf="parentData" (openProfile)="openProfile($event)" [parentData]="parentData"></app-header>

<div (click)="backPage()" class="back-div" *ngIf="currentPlanObj.planId">
  <img class="mr-8" src="../../../../assets/images/back-btn.svg" /> Back
</div>

<img *ngIf="!parentData" src="../../../assets/images/STEMWerkzLogo.png" />
<br *ngIf="!parentData" />

<div class="choice" *ngIf="currentPlanObj.planId && unsubscribe != 'true' && templateName != 'emptyFromStart'">
  Change plan
</div>
<div class="choice" *ngIf="!currentPlanObj.planId || templateName == 'emptyFromStart'">Choose your plan</div>
<div class="choice" *ngIf="unsubscribe == 'true'">You have unsubscribed</div>
<p class="highlight" *ngIf="unsubscribe != 'true'">Don't worry, you can add more child accounts later.</p>
<p class="highlight" *ngIf="unsubscribe == 'true'">
  Your subscription has ended. You can subscribe to the previous plan again or change to a new plan.
</p>
