/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home-signup-controller.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared-components/information/information.component.ngfactory";
import * as i3 from "../shared-components/information/information.component";
import * as i4 from "../shared-components/information/information.store";
import * as i5 from "../signup/signup-controller.component.ngfactory";
import * as i6 from "../signup/signup-controller.component";
import * as i7 from "@angular/forms";
import * as i8 from "../business-logic.requirements";
import * as i9 from "../signup/signup.store";
import * as i10 from "../../../lib/auth.service";
import * as i11 from "@angular/router";
import * as i12 from "../../../lib/shared-service";
import * as i13 from "../../../global-components/query-params-ls/query-params-ls.component.ngfactory";
import * as i14 from "../../../global-components/query-params-ls/query-params-ls.component";
import * as i15 from "../../../global-components/query-params-ls/query-params-ls.store";
import * as i16 from "./home-signup-controller.component";
import * as i17 from "./home-signup.store";
var styles_HomeSignupControllerComponent = [i0.styles];
var RenderType_HomeSignupControllerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeSignupControllerComponent, data: {} });
export { RenderType_HomeSignupControllerComponent as RenderType_HomeSignupControllerComponent };
export function View_HomeSignupControllerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "d-flex m-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-lg-7 home-left-side"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-information", [], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.buttonClickLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_InformationComponent_0, i2.RenderType_InformationComponent)), i1.ɵdid(3, 49152, null, 0, i3.InformationComponent, [i4.InformationStore], { buttonText: [0, "buttonText"] }, { buttonClick: "buttonClick" }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-lg-5 no-padding home-right-side"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-signup", [], null, [["document", "click"], [null, "keydown"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onDown($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_SignupControllerComponent_0, i5.RenderType_SignupControllerComponent)), i1.ɵdid(6, 638976, null, 0, i6.SignupControllerComponent, [i7.FormBuilder, i8.BusinessRequirementsInjectionToken, i9.SignupStore, i10.AuthService, i11.Router, i11.ActivatedRoute, i12.SharedService], { onClickLogin: [0, "onClickLogin"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-query-params-ls", [], null, null, null, i13.View_QueryParamsLsComponent_0, i13.RenderType_QueryParamsLsComponent)), i1.ɵdid(8, 114688, null, 0, i14.QueryParamsLsComponent, [i15.QueryParamsLsStore, i11.ActivatedRoute, i12.SharedService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Log in"; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.ClickLogin; _ck(_v, 6, 0, currVal_1); _ck(_v, 8, 0); }, null); }
export function View_HomeSignupControllerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home-signup", [], null, null, null, View_HomeSignupControllerComponent_0, RenderType_HomeSignupControllerComponent)), i1.ɵdid(1, 114688, null, 0, i16.HomeSignupControllerComponent, [i8.BusinessRequirementsInjectionToken, i17.HomeInformationStore, i11.Router, i10.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeSignupControllerComponentNgFactory = i1.ɵccf("app-home-signup", i16.HomeSignupControllerComponent, View_HomeSignupControllerComponent_Host_0, {}, {}, []);
export { HomeSignupControllerComponentNgFactory as HomeSignupControllerComponentNgFactory };
