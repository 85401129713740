import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GlobalComponentsModule } from "../../../global-components/global-components.module";
import { SharedComponentsModule } from "../shared-components/shared-components.module";
import { HomeSignupControllerComponent } from "./home-signup-controller.component";
// import {FeatherModule} from 'angular-feather';
import { RouterModule } from "@angular/router";
import { LoginModule } from "../login/login.module";
import { SignupModule } from "../signup/signup.module";

@NgModule({
  declarations: [HomeSignupControllerComponent],
  exports: [HomeSignupControllerComponent],
  imports: [
    CommonModule,
    RouterModule,
    GlobalComponentsModule,
    SharedComponentsModule,
    LoginModule,
    SignupModule,
  ],
})
export class HomeSignupModule {}
