import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/subscription-plan-list.mapper";
import * as i2 from "../data.requirements";
export class SubscriptionPlanListUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run() {
        return this.data.getAllPlans().pipe(this.mapper.map);
    }
}
SubscriptionPlanListUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubscriptionPlanListUseCase_Factory() { return new SubscriptionPlanListUseCase(i0.ɵɵinject(i1.SubscriptionPlanListMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: SubscriptionPlanListUseCase, providedIn: "root" });
