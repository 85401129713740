import { BusinessLogicRequirements } from '../presentation/business-logic.requirements';
import { NgModule } from '@angular/core';
import { DataRequirementsInjectionToken } from './data.requirements';
import { DataFacade } from '../data/data.facade';
import { Observable, of } from 'rxjs';
import { EditPlayerUseCase } from './use-cases/edit-player.use-case';
import { PlayerEditMapper } from './mappers/player-edit-mapper';
import { SubscriptionMapper } from './mappers/subscription-plans-mapper';
import { SubscriptionPlansUseCase } from './use-cases/subscription-plans.use-case';
import { PlayerDetailMapper } from './mappers/player-detail-mapper';
import { UnSubscribeUserUseCase } from './use-cases/un-subscribe-user.use-case';
import { GetPlayerDetailUseCase } from './use-cases/get-player-detail.use-case';
import { SubscribeUserUseCase } from './use-cases/subscribe-user.use-case';
import { SubscribeUserMapper } from './mappers/subscribe-user-mapper';
import { PublishKeyUseCase } from './use-cases/publish-key.use-case';
import { PaymentManagementUseCase } from './use-cases/payment-management.use-case';
import { resubscribePlanUseCase } from './use-cases/resubscribe.use-case';
import { GetCountriesUseCase } from './use-cases/get-countries.use-case';

@NgModule({
  imports: [DataFacade],

  providers: [
    {
      provide: DataRequirementsInjectionToken,
      useClass: DataFacade
    },
    PlayerEditMapper,
    SubscriptionMapper,
    PlayerDetailMapper,
    SubscribeUserMapper
  ]
})
export class BusinessLogicFacade implements BusinessLogicRequirements {
  constructor(
    private editPlayerUseCase: EditPlayerUseCase,
    private subscriptionPlansUseCase: SubscriptionPlansUseCase,
    private unSubscribeUserUseCase: UnSubscribeUserUseCase,
    private getPlayerDetailUseCase: GetPlayerDetailUseCase,
    private subscribeUserUseCase: SubscribeUserUseCase,
    private publishKeyUseCase: PublishKeyUseCase,
    private paymentManagementUseCase: PaymentManagementUseCase,
    private resubscribeUseCase: resubscribePlanUseCase,
    private getCountriesUseCase: GetCountriesUseCase
  ) {}
  getPlayerDetail(id: any): Observable<any> {
    return this.getPlayerDetailUseCase.run(id);
  }
  editPlayer(playerId, playerData): Observable<any> {
    // const playerObj = {
    //   password: "sc4zoeru3m9sc4zoeru3m9",
    // };
    // return of(playerObj);
    return this.editPlayerUseCase.run(playerId, playerData);
  }
  checkPlan(): Observable<any> {
    return this.subscriptionPlansUseCase.run();
  }
  unsubscribeUser(data): Observable<any> {
    return this.unSubscribeUserUseCase.run(data);
  }
  subscribeUser(data): Observable<any> {
    return this.subscribeUserUseCase.run(data);
  }
  getPublishKey(): Observable<any> {
    return this.publishKeyUseCase.run();
  }
  changePayment(data): Observable<any> {
    return this.paymentManagementUseCase.run(data);
  }
  resubscribePlan(uid, sid): Observable<any> {
    return this.resubscribeUseCase.run(uid, sid);
  }
  getCountries(): Observable<any> {
    return this.getCountriesUseCase.run();
  }
}
