<div class="main-div d-flex" *ngIf="player?.subscription.length > 0 && !onlyPaymentHistory">
  <app-subscription-history
    [currentPlan]="currentPlan"
    [parentData]="player"
    (subscribeBtn)="subscription($event)"
    (cancelSubscribe)="cancel($event)"
    (planChange)="changPlan()"
    (accountChange)="changeAccount()"
    (resubscribePlan)="resubscribePlan($event)"
    (blueDiv)="blueDiv($event)"
    (redDiv)="redDiv()"
    (boxTypeEmit)="boxTypeEmit($event)"
  ></app-subscription-history>
</div>
<div class="main-div d-flex" *ngIf="player?.payment.length > 0">
  <app-payment-history
    [player]="player"
    [onlyPaymentHistory]="onlyPaymentHistory"
    (clickedPayment)="clickedPayment()"
  ></app-payment-history>
</div>
