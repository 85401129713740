<app-redirect-popup *ngIf="showRedirectPopup" class="cover-all" [userName]="userName" [channelUrl]="channelUrl" [valleyUrl]="valleyUrl" [academyUrl]="academyUrl" [dashboardUrl]="dashboardUrl" [questUrl]="questUrl" (popupBtn)="onClickPoupBtn($event)" (logoutBtn)="onClickLogoutBtn($event)" (closePopup)="closeRedirectPopup()"></app-redirect-popup>
<div class="d-flex home-div">
  <div class="col-lg-7 home-left-side"><app-information [buttonText]="'Sign up'" (buttonClick)="buttonClickSignup()"></app-information></div>
  <div class="col-lg-5 no-padding home-right-side">
    <!-- <div *ngif="getInit=='login'">
        <app-login></app-login>
      </div> -->
    <!-- <app-signup></app-signup> -->
    <app-login (checkRedirectPopup)=checkRedirectPopup($event) [onClickSignup]="ClickSignup"></app-login>
  </div>
</div>
<app-query-params-ls></app-query-params-ls>
