import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import { CountryMapper } from '../mappers/country.mapper';

@Injectable({
    providedIn: 'root'
})
export class GetCountryListUseCase {
    constructor(
        private mapper: CountryMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run() {
      return this.data.getCountryList().pipe(this.mapper.map);
    }
}
