import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { HeaderStore } from "./header.store";
import { Router } from "@angular/router";
import { SharedService } from 'src/app/lib/shared-service';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent {
  @Input() parentData;
  @Input() createdCount;
  @Input() zoneList;
  @Input() explore = false;
  @Output() openProfile = new EventEmitter<any>();
  @Output() exploreClick = new EventEmitter<any>();
  @Output() portalClick = new EventEmitter<any>();
  @Output() changePlan = new EventEmitter<any>();

  public setting: boolean = false;
  public mobileView;
  public mobileViewService;
  public hamburger = "./assets/images/hamburger.svg";
  public cross = "./assets/images/cross_mobile.svg";
  public goto = "./assets/images/new_tab.svg";
  public clickedMenu=false;
  constructor(private router: Router, private shared: SharedService) {
    document.addEventListener('click', this.close.bind(this));
  }


  @HostListener('window:resize',['$event'])
    onResize(e){
      if(!this.mobileView){
        document.getElementById('mobile-profile').classList.add('hide');
        this.clickedMenu = false;
        this.hamburger = "./assets/images/hamburger.svg";
      }
    }

  ngOnInit() {
    console.log(this.parentData)
    this.mobileViewService = this.shared.GetMobileView.subscribe(data=>{
      this.mobileView = data;
    })
    console.log(this.mobileView)
  }
  ngAfterViewInit(){
    document.getElementById('mobile-profile').classList.add('hide');
  }
  settingDropdown() {
    this.setting = !this.setting;
  }
  Logout() {
    console.log("on click");
    var ref;
    if (localStorage.getItem('ref'))
      ref = localStorage.getItem('ref');
  localStorage.clear();
  localStorage.setItem('ref',ref);
    this.router.navigateByUrl(`/login`);
  }
  profilePopup($event){
    this.openProfile.emit($event);
    this.setting = false;

  }
  close($event){
    if(!$event.target.classList.contains('profile-pw') && !$event.target.classList.contains('setting-dropdown') && !$event.target.classList.contains('outline')){
      this.setting = false;
    }
  }
  clickedExplore(){
    this.exploreClick.emit();
  }
  clickedPortal(){
    this.goto = "./assets/images/new_tab_white.svg";
    this.portalClick.emit();
  }
  showMenu(){
    this.clickedMenu = !this.clickedMenu;
    document.getElementById('mobile-profile').classList.toggle('hide');
    if(this.clickedMenu){
      this.hamburger = this.cross;
    }else{
      this.hamburger = "./assets/images/hamburger.svg";
    }
  }

  planChange(e){
    console.log('header change')
    this.changePlan.emit(e);
  }

  clickedDetail(){
    this.router.navigateByUrl('/parent/detail');
  }

  ngOnDestroy() {
    this.mobileViewService.unsubscribe();
  }

  enter(){
    this.goto = "./assets/images/new_tab_white.svg";
  }

  leave(){
    this.goto = "./assets/images/new_tab.svg";
  }

}
