import { flatten } from '@angular/compiler';
import { Inject, Component, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { loadStripe } from '@stripe/stripe-js';
import { AuthService } from 'src/app/lib/auth.service';
import { SharedService } from 'src/app/lib/shared-service';
import { UserDetailModel } from 'src/app/models/user-detail.model';
import { BusinessLogicRequirements, BusinessRequirementsInjectionToken } from '../business-logic.requirements';
import { ParentDetailHomeStore } from './parent-detail-home.store';

@Component({
  selector: 'app-parent-detail-home',
  templateUrl: './parent-detail-home-controller.component.html',
  styleUrls: ['./parent-detail-home-controller.component.css']
})
export class ParentDetailHomeControllerComponent {
  public player = new UserDetailModel();
  // public player:any = [];
  public editFlag: boolean = false;
  public editFlagForParent: boolean = false;
  public resetFlag: boolean = false;
  public deleteFlag: boolean = false;
  public parentData;
  public profileFlag: boolean = false;
  public userType: any = '';
  public emailOnOff: boolean = true;
  public unsubscribePopup: boolean = false;
  public plan;
  public paymentLoading: boolean = true;

  public editLoading = false;

  private parentId;
  private planId;

  public currentPlan;
  public isBlue: boolean = false;
  public isRed: boolean = false;
  public blueDate;
  public onlyPaymentHistory = false;
  public isMobileView;
  public mobileViewService;
  // country
  public countriesList;
  public currentCountry;

  public isStartUser = false;
  public isPromoUser = false;
  public boxType;
  public mailto = false;

  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private store: ParentDetailHomeStore,
    private route: ActivatedRoute,
    private router: Router,
    private _authService: AuthService,
    private cdr: ChangeDetectorRef,
    private shared: SharedService
  ) {}
  ngOnInit() {
    this.getCountryList();
    this.mobileViewService = this.shared.GetMobileView.subscribe((data) => {
      this.isMobileView = data;
    });
    console.log('detail init');
    const isAuth = this._authService.isAuthenticated();
    console.log('this.authData ', isAuth);
    if (isAuth) {
      console.log(this._authService.getUserInfo());
      var encodedData = localStorage.getItem('parentObject');
      console.log(encodedData);
      this.parentData = JSON.parse(this._authService.decrypt(encodedData, null));
      this.parentId = this._authService.getUserInfo().userId;

      this.checkPlan();
    } else {
      this.router.navigateByUrl('');
    }
  }

  ngOnDestroy() {
    this.mobileViewService.unsubscribe();
  }

  openProfile($event) {
    this.router.navigateByUrl('/parent/detail');
  }

  editPlayer($event) {
    console.log('called', $event);
    this.editFlagForParent = true;
  }

  resetPlayer($event) {
    this.resetFlag = true;
  }

  closeEdit($event) {
    this.editFlag = false;
    this.editFlagForParent = false;
  }

  editPlayerApi(playerData) {
    this.editLoading = true;
    console.log(playerData);
    console.log('USER TYPE', this.userType);
    console.log(this.parentData);
    let id = '';
    id = this.parentData.id;
    console.log(id);
    this.business.editPlayer(id, playerData).subscribe(
      (res) => {
        console.log('detail detail ', res);
        this.editFlag = false;
        this.editFlagForParent = false;
        this.shared.toastForSuccess('Profile updated successfully!', '');
        setTimeout(() => {
          this.editLoading = false;
        }, 3000);
        localStorage.setItem('parentDetailObj', this._authService.encrypt(JSON.stringify(res[0]), null));
        this.playerDetailApi();
      },
      (err) => {
        console.log(err);
        this.editLoading = false;
        this.shared.toastForError('Profile update failed!', '');
      }
    );
  }

  public resetLoading: boolean = false;
  resetPassword(playerData) {
    this.resetLoading = true;
    const newPassword = {
      password: playerData.newPassword
    };
    console.log('this is reset password', newPassword);
    console.log('USER TYPE', this.userType);
    let id = '';

    id = this.parentData.id;
    console.log(id);
    this.business.editPlayer(id, newPassword).subscribe(
      (res) => {
        console.log('detail detail ', res);
        this.resetFlag = false;
        this.shared.toastForSuccess('Password updated successfully!', '');

        setTimeout(() => {
          this.resetLoading = false;
        }, 3000);

        this.playerDetailApi();
        localStorage.setItem('parentDetailObj', this._authService.encrypt(JSON.stringify(res[0]), null));
        this.parentData = res[0];
      },
      (err) => {
        console.log(err);
        this.resetLoading = false;
        this.shared.toastForError('Password update failed!', '');
      }
    );
  }

  playerDetailApi() {
    console.log('this is get detail');
    this.business.getPlayerDetail(this.parentId).subscribe(
      (res) => {
        console.log('detail ...................', res[0]);
        this.player = res[0];
        this.parentData = res[0];
        if (this.parentData.metadata.vendor && this.parentData.metadata.vendor.indexOf('PROMO_') > -1)
          this.isPromoUser = true;
        else if (this.parentData.metadata.registeredFrom == 'stemwerkz-start') this.isStartUser = true;
        this.parentId = this.parentData.id;
        console.log(this.parentData);
        console.log('sub...', this.player);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  backPage() {
    if (this.onlyPaymentHistory) this.onlyPaymentHistory = false;
    else this.router.navigateByUrl('/dashboard');
  }

  unSubscribe($event) {
    console.log('this is cancel');
    this.unsubscribePopup = true;
  }

  closeCancel($event) {
    this.unsubscribePopup = false;
  }

  unsubscribePlayerConfirm($event) {
    var data = {
      userId: this.parentData.id,
      subscriptionId: this.player.subscription[0].subscriptionId
    };
    this.business.unsubscribeUser(data).subscribe(
      (res) => {
        console.log(res);
        this.playerDetailApi();
        this.unsubscribePopup = false;
        // this.router.navigateByUrl('/dashboard?show-unsubscribe-success-message='+1);
        this.shared.toastForSuccess('Unsubscribed successfully!', '');
        setTimeout(() => {
          this.reloadComponent();
        }, 3000);

        localStorage.removeItem('isUpgrade');
      },
      (err) => {
        console.log(err);
        this.shared.toastForSuccess(err.error.message ? err.error.message : 'Unsubscribed failed', '');
        localStorage.removeItem('isUpgrade');
        this.unsubscribePopup = false;
      }
    );
  }

  upgradeSubscription($event) {
    this.callSubscriptionApi();
  }

  changePayment() {
    console.log('change payment');
    var data = {
      url: window.location.origin + '/parent/detail'
    };
    console.log(data);

    this.business.changePayment(data).subscribe(
      (res) => {
        console.log('changePayment', res);
        //window.open(res.url);
        location.href = res.url;
      },
      (err) => {
        console.log(err);
        this.shared.toastForError('Something went wrong!' + err.error.message, '');
      }
    );
  }

  checkPlan() {
    this.business.checkPlan().subscribe(
      (res) => {
        console.log('check plan', res);
        this.planId = res[0].planId;
        this.currentPlan = res[0];
        // this.playerLimit = res.playerSlot;
        this.paymentLoading = false;
        this.playerDetailApi();
      },
      (err) => {
        console.log(err);
        this.paymentLoading = false;
        this.playerDetailApi();
      }
    );
  }

  public subLoading: boolean = false;
  callSubscriptionApi() {
    this.subLoading = true;
    console.log('get');
    //publish key
    var publish_key = '';
    //session id
    var sessionId = '';
    var data = {
      userId: this.parentId,
      subscriptionId: this.planId,
      redirectURL: window.location.origin + '/parent/detail',
      type: 'paid'
    };
    console.log(data);

    this.business.subscribeUser(data).subscribe(
      (res) => {
        sessionId = res.sessionId;
        console.log('session id ', sessionId);
        this.business.getPublishKey().subscribe(async (res) => {
          publish_key = res.publishableKey;
          console.log('publish key', publish_key);
          const stripe = await loadStripe(publish_key);
          stripe
            .redirectToCheckout({
              sessionId: sessionId
            })
            .then(function (result) {
              console.log(result);
            });
        });
      },
      (err) => {
        console.log(err);
        this.subLoading = false;
      }
    );
  }

  changePlan() {
    //isStartUser
    //isPromo

    if (this.currentPlan.interval == 'year') {
      console.log('you cannot ');
      this.mailto = true;
    } else if (
      this.boxType == 'afterUnsubscribe' ||
      this.boxType == 'afterFromStart' ||
      this.boxType == 'afterGiftcard' ||
      this.boxType == 'afterNormal'
    )
      this.router.navigateByUrl(
        `/changeplan/${this.player.subscription[this.player.subscription.length - 1].subscriptionId}?unsubscribe=true`
      );
    else if (this.boxType == 'beforeUnsubscribe' || this.boxType == 'beforeGiftcard' || this.boxType == 'beforeNormal')
      this.router.navigateByUrl(
        `/changeplan/${this.player.subscription[this.player.subscription.length - 1].subscriptionId}?unsubscribe=false`
      );
    else if (
      this.boxType == 'beforeFromStart' &&
      !this.currentPlan.userSubInfo.stripeSubscriptionId &&
      this.isStartUser
    )
      this.router.navigateByUrl(`/plans?type=trial`);
    else if (
      this.currentPlan.userSubInfo.type == 'trial' &&
      !this.currentPlan.userSubInfo.stripeSubscriptionId &&
      this.isPromoUser
    )
      this.router.navigateByUrl(
        `/changeplan/${this.player.subscription[this.player.subscription.length - 1].subscriptionId}`
      );
    else
      this.router.navigateByUrl(
        `/changeplan/${this.player.subscription[this.player.subscription.length - 1].subscriptionId}`
      );
  }

  closeReset(e) {
    this.resetFlag = false;
  }

  resubscribe($event) {
    this.business.resubscribePlan(this.parentId, this.player.subscription[0].subscriptionId).subscribe(
      (data) => {
        console.log('res', data[0]);
        this.plan = data[0];
        console.log(this.plan);
        this.shared.toastForSuccess('Resubscribed successfully', '');
        setTimeout(() => {
          this.reloadComponent();
        }, 3000);
      },
      (err) => {
        console.log(err);
        this.shared.toastForError('Something went wrong' + err.error.message, '');
      }
    );
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  hideAll() {
    this.onlyPaymentHistory = true;
  }

  getCountryList() {
    this.business.getCountries().subscribe(
      (data) => {
        console.log(data);
        this.countriesList = data[0].countries;
        this.currentCountry = data[0].country;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  determineBoxType(boxType) {
    console.log('box type from parent detail ', boxType);
    this.boxType = boxType;
    this.cdr.detectChanges();
  }

  boxTypeClicked(boxType) {
    console.error('box type from clicked', boxType);
    switch (boxType) {
      case 'afterNormal':
        this.upgradeSubscription('');
        break;
      case 'afterUnsubscribe':
        this.router.navigateByUrl(`/plans/${this.currentPlan.planId}?change=true&unsubscribe=true`);
        break;
      case 'afterGiftcard':
        this.resubscribe('');
        break;
      case 'afterFromStart':
        this.changePlan();
        break;
      case 'beforeUnsubscribe':
        this.resubscribe('');
        break;
      case 'beforeGiftcard':
        this.resubscribe('');
        break;
      case 'beforeFromStart':
        this.changePlan();
        break;
    }
  }

  closeMailTo() {
    this.mailto = false;
  }
}
