import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../../../global-components/global-components.module';
import {SharedComponentsModule} from '../shared-components/shared-components.module';
import {GiftCardControllerComponent} from './gift-card-controller.component';
import {RouterModule} from '@angular/router';
import {OrderCardComponent} from './components/order-card/order-card.component';
import {PlanCardComponent} from './components/plan-card/plan-card.component';
import {PosterCardListComponent} from './components/poster-card-list/poster-card-list.component';
import {ListViewComponent} from './components/list-view/list-view.component';
import {InputBoxComponent} from './components/input-box/input-box.component';
import {LoaderComponent} from './components/loader/loader.component';
import {InputBoxCardComponent} from './components/input-box-card/input-box-card.component';

@NgModule({
    declarations: [
        GiftCardControllerComponent,
        OrderCardComponent,
        PlanCardComponent,
        PosterCardListComponent,
        ListViewComponent,
        InputBoxComponent,
        LoaderComponent,
        InputBoxCardComponent
    ],
    exports: [
        GiftCardControllerComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GlobalComponentsModule,
        SharedComponentsModule,
    ]
})
export class GiftCardModule {}
