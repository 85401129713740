import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PlanApiService {
    private baseUrl = environment.apiurl;
    constructor(private http: HttpClient) {}
    getPlan(id): Observable<any> {
        let url = this.baseUrl + "/api/v2/subscriptions/"+id;
        console.log(url);
        return this.http.get(url).pipe(
          map((res: Response) => {
            let result = [res];
            console.log(result);
            return result;
          })
        );
      }
    resubscribePlan(uid, sid): Observable<any> {
        let url = this.baseUrl + "/api/v2/subscriptions/resubscribe";
        let body = {
          userId: uid,
          subscriptionId: sid
        }
        console.log(url);
        return this.http.post(url,body).pipe(
          map((res: Response) => {
            let result = res;
            console.log(result);
            return result;
          })
        );
      }

      updatePayment(data){
        let url = this.baseUrl + "/api/v2/stripe/customer-portal";
        console.log(url);
        return this.http.post(url, data).pipe(
          map((res: Response) => {
            let result = res;
            console.log(result);
            return result;
          })
        );
      }
}
