import { DataRequirements, } from "../data.requirements";
import * as i0 from "@angular/core";
import * as i1 from "../data.requirements";
export class PublishKeyUseCase {
    constructor(data) {
        this.data = data;
    }
    run() {
        return this.data.getPublishKey();
    }
}
PublishKeyUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PublishKeyUseCase_Factory() { return new PublishKeyUseCase(i0.ɵɵinject(i1.DataRequirementsInjectionToken)); }, token: PublishKeyUseCase, providedIn: "root" });
