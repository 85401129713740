import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedService } from 'src/app/lib/shared-service';
import { WarningBoxStore } from './warning-box.store';

@Component({
  selector: 'app-warning-box',
  templateUrl: './warning-box.component.html',
  styleUrls: ['./warning-box.component.css']
})
export class WarningBoxComponent {
  @Input() usersubinfo;
  @Input() boxType;

  @Output() outputWarningBox = new EventEmitter<any>();

  public mobileViewService;
  public mobileView;
  constructor(private store: WarningBoxStore, private shared: SharedService) {}

  ngOnInit() {
    this.mobileViewService = this.shared.GetMobileView.subscribe((data) => {
      this.mobileView = data;
    });
    console.log('boxType ', this.boxType);
  }

  ngOnDestroy() {
    this.mobileViewService.unsubscribe();
  }

  btnClicked() {
    this.outputWarningBox.emit(this.boxType);
  }
}
