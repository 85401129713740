import { of } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PlayerDetailService {
    constructor(http) {
        this.http = http;
        this.baseUrl = environment.apiurl;
    }
    getPlayer(playerId) {
        let url = this.baseUrl + "/api/v2/users/" + playerId;
        console.log(url);
        return this.http.get(url).pipe(map((res) => {
            // let result = [];
            let result = res;
            console.log(result);
            return result;
        }));
    }
    checkEmptySub() {
        return of(false);
    }
}
PlayerDetailService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlayerDetailService_Factory() { return new PlayerDetailService(i0.ɵɵinject(i1.HttpClient)); }, token: PlayerDetailService, providedIn: "root" });
