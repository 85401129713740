import {InjectionToken} from '@angular/core';
import { Observable } from 'rxjs';

export interface DataRequirements {
  getAllPlans():Observable<any>;
  getAllGiftCards():Observable<any>;
  purchaseGift(data):Observable<any>;
  getPublishKey(): Observable<any>;
  getGiftCardDetail(id):Observable<any>;
}

export const DataRequirementsInjectionToken = new InjectionToken<DataRequirements>('gift-card-shop Data Requirements')
