import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderBarStore } from './header-bar.store';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.css']
})
export class HeaderBarComponent {
  @Input() parentData;
  @Input() currentPlanName;
  @Input() isChange;
  @Input() unsubscribe;
  @Input() giftCodeAdded;
  @Output() outputBack = new EventEmitter<any>();
  constructor(private store: HeaderBarStore, private router: Router) {}

  ngOnInit() {
    console.log('header bar from chosen . . . . . . . . . . ');
  }

  openProfile(value) {
    this.router.navigateByUrl('/parent/detail');
  }

  backPage() {
    this.outputBack.emit();
  }
}
