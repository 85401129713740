import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalComponentsModule } from '../../../global-components/global-components.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { DashboardHomeControllerComponent } from './dashboard-home-controller.component';
// import {FeatherModule} from 'angular-feather';
import { RouterModule } from '@angular/router';
import { PlayerProfilesComponent } from './components/player-profiles/player-profiles.component';
import { SingleProfilesComponent } from './components/single-profiles/single-profiles.component';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { EmptySlotComponent } from './components/empty-slot/empty-slot.component';
import { WarningBoxComponent } from './components/warning-box/warning-box.component';

@NgModule({
  declarations: [
    DashboardHomeControllerComponent,
    PlayerProfilesComponent,
    SingleProfilesComponent,
    EmptySlotComponent,
    WarningBoxComponent
  ],
  exports: [DashboardHomeControllerComponent],
  imports: [CommonModule, RouterModule, GlobalComponentsModule, SharedComponentsModule, ContentLoaderModule]
})
export class DashboardHomeModule {}
