import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../global-components/global-components.module';
import {SharedComponentsModule} from './presentation/shared-components/shared-components.module';
import {BusinessLogicFacade} from './business-logic/business-logic.facade';
import {BusinessRequirementsInjectionToken} from './presentation/business-logic.requirements';
import { SchoolSignupModule } from './presentation/school-signup/school-signup.module';
import { SignupModule } from './presentation/signup/signup.module';
import { LoginModule } from './presentation/login/login.module';
import { SchoolLoginModule } from './presentation/school-login/school-login.module';


@NgModule({
    imports: [
        BusinessLogicFacade,
        CommonModule,
        GlobalComponentsModule,
        SharedComponentsModule,
        SchoolSignupModule,
        SignupModule,
        LoginModule,
        SchoolLoginModule
    ],
    providers: [
        {
            provide: BusinessRequirementsInjectionToken,
            useClass: BusinessLogicFacade
        }
    ],
    exports: []
})
export class SchoolFeatureModule {}
