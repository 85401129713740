<div class="header-wrapper bg-s0">
  <div class="d-flex align-items-center bg-s0 header-bar">
    <!-- <a [href]="stemwerkzUrl">
      <app-image
        class="cursor"
        [src]="'./assets/images/STEMWerkzLogo.png'"
        [width]="'175px'"
        [height]="'42px'"
      ></app-image>
    </a> -->
    <app-logo [class]="'store'"></app-logo>
  </div>
</div>
<router-outlet></router-outlet>
<app-query-params-ls></app-query-params-ls>
