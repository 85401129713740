import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
// import { ParentModel } from "../../models/parent.model";

export interface BusinessLogicRequirements {
  signupParent(parentData: object): Observable<any>;
  signupParentNew(parentData: object): Observable<any>;
  loginParent(): Observable<any>;
  getCountryList(): Observable<any>;
  getPublishKey(): Observable<any>;
  updateUser(parentData: object): Observable<any>;
}

export const BusinessRequirementsInjectionToken = new InjectionToken<BusinessLogicRequirements>(
  'home Business Requirements'
);
