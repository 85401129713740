import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../data.requirements";
export class emailCopyUseCase {
    constructor(data) {
        this.data = data;
    }
    run(id, pw) {
        return this.data.emailCopy(id, pw);
    }
}
emailCopyUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function emailCopyUseCase_Factory() { return new emailCopyUseCase(i0.ɵɵinject(i1.DataRequirementsInjectionToken)); }, token: emailCopyUseCase, providedIn: "root" });
