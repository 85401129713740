import { Inject, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BusinessLogicRequirements, BusinessRequirementsInjectionToken } from '../business-logic.requirements';
import { ForgotPasswordHomeStore } from './forgot-password-home.store';
import { environment } from '../../../../environments/environment';
import { SharedService } from 'src/app/lib/shared-service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-forgot-password-home',
  templateUrl: './forgot-password-home-controller.component.html',
  styleUrls: ['./forgot-password-home-controller.component.css']
})
export class ForgotPasswordHomeControllerComponent {
  constructor(
    @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements,
    private store: ForgotPasswordHomeStore,
    private fb: FormBuilder,
    private _shared: SharedService,
    private route: ActivatedRoute
  ) {}

  public backIcon = './assets/images/back-arrow.svg';
  public headerBg = './assets/images/yellow-bg.png';
  // public headerBgMobile = "./assets/images/STEMWerkzLogoMobile.png"
  public logo = '../assets/images/STEMWerkzLogo.png';
  public feedbackSuccess = './assets/images/feedback_success.svg';
  public cancelBtn = './assets/images/cancel_white.svg';
  public feedbackFail = './assets/images/feedback_fail.svg';
  // public logoMobile: any = "./assets/images/STEMWerkzMobile.png";
  public logoMobile: any = './assets/images/STEMWerkzLogoMobile.png'; //updated
  public alertURL = './assets/images/alert-icon.svg';
  public tickURL = './assets/images/tick-icon.svg';
  public emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  public form: any;
  public form2: any;
  public email = '';
  public btnStatus = 'btn-disable';
  public btnTextStatus = 'btn-disable-text';
  public btnText = 'Reset password';

  public show = true;
  public hide = false;
  public emailClass = 'signup-input';

  public feedbackFlag = false;
  public feedbackTitle = '';
  public tickIcon = false;
  public feedbackBody = '';
  public feedbackBtn = {
    status: false,
    text: 'Dismiss'
  };

  public title = environment.titleForgotPassword;
  public isSuccess: boolean = false;
  public resetError = false;

  ngOnInit() {
    this._shared.setDocTitle(this.title);
    this.route.queryParams.subscribe((data) => {
      if (data.expire && data.expire == 'true') {
        this.feedbackTitle = 'Your password reset link has expired, or already used.';
        this.tickIcon = false;
        this.isSuccess = false;
        this.feedbackFlag = true;
        this.resetError = true;
      }
    });
    this.form = this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.pattern(this.emailPattern)])
    });

    this.form.get('email').valueChanges.subscribe((value) => {
      console.log(value == '');
      if (value == '' || value == undefined || value == null) {
        this.btnStatus = 'btn-disable';
        this.btnTextStatus = 'btn-disable-text';
      } else {
        this.btnStatus = 'btn-orange';
        this.btnTextStatus = 'btn-text-style2';
        this.hideFeedback();
      }
      this.email = value;
    });
  }

  blurInput($event) {
    console.log('get blur event');
  }

  sentMail() {
    if (!this.form.get('email').errors) {
      console.log('sentmail');
      var body = {
        email: this.email,
        url: window.origin + '/reset'
      };
      this.btnText = 'Resetting...';
      this.btnTextStatus = 'btn-disable-text';
      this.btnStatus = 'btn-disable';
      this.business.sentMail(body).subscribe(
        (data) => {
          console.log(data.message);
          this.feedbackTitle = data.message;
          this.isSuccess = true;
          this.feedbackFlag = true;
          document.getElementById('feedback_wrapper').style.bottom = '24px';
          this.btnText = 'Reset password';
          this.btnTextStatus = 'btn-disable-text';
          this.btnStatus = 'btn-disable';
          // this.btnStatus = 'btn-orange';
          // this.btnTextStatus = 'btn-text-style2';
        },
        (err) => {
          console.log(err);
          // this.feedbackTitle = err.error.message;
          this.feedbackTitle = 'The email you have entered is invalid. Please try again.';
          this.isSuccess = false;
          this.feedbackFlag = true;
          document.getElementById('feedback_wrapper').style.bottom = '24px';

          this.btnText = 'Reset password';
          // this.btnStatus = 'btn-orange';
          // this.btnTextStatus = 'btn-text-style2';
        }
      );
    } else {
      this.emailClass = 'input-err';
      console.log(this.form.errors);
    }
  }

  dimissFeedbackBtn(event) {
    this.feedbackFlag = false;
  }

  hideFeedback() {
    document.getElementById('feedback_wrapper').style.bottom = '-370px';
    setTimeout(() => {
      this.feedbackFlag = false;
      this.btnStatus = 'btn-orange';
      this.btnTextStatus = 'btn-text-style2';
    }, 500);
  }
}
