import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {ButtonStore} from './button.store'

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.css']
})
export class ButtonComponent {
    @Input() disabled = false;
    @Input() class;
    @Input() width;
    @Input() height;
    @Input() loading=false;
    @Output() btnClick = new EventEmitter<any>();

    private getVal;
    private tmpArr;

    onButtonClick($event) {
        this.tmpArr = this.class.split(' ');
        if(this.tmpArr[0] == 'btn-gender'){
            this.getVal = this.tmpArr[1];
            console.log(this.getVal);
            $('.btn-gender').removeClass('btn-select');
            $('.'+this.getVal).addClass('btn-select');
            this.btnClick.emit(this.getVal);
        }
        else
            this.btnClick.emit($event);
    }
    constructor(private store: ButtonStore) {}
}
