import { PlayerModel } from '../../../models/player.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class PlayerListMapper extends ItemMapper<PlayerModel> {
    public imgPrefix = 'https://d1vf2jccr14y1d.cloudfront.net/covers/';
    protected mapFromItem(item): PlayerModel {
        const PlayerListModel = new PlayerModel()
        console.log(item);
        PlayerListModel.playerEmail = item.email;
        PlayerListModel.playerID = item._id;
        PlayerListModel.playerInitial = item.generatedProfileImage.initials;
        PlayerListModel.playerName = item.username;
        PlayerListModel.profileImage = item.generatedProfileImage.profileImage;
        PlayerListModel.firstName = item.firstName;
        PlayerListModel.lastName = item.lastName;
        PlayerListModel.showName =  item.firstName + ' ' + item.lastName[0].toUpperCase() + '.';
        PlayerListModel.subscription = item.subscription;
        PlayerListModel.lastActive = item.lastActive? item.lastActive : '';

        return PlayerListModel;
    }
}
