import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PaymentInfoStore {

}

export enum PaymentInfoStoreKeys {

}
