import {MessageModel} from '../../../models/message.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class PublishKeyMapper extends ItemMapper<MessageModel> {
  protected mapFromItem(item): MessageModel {
      const messageObj = new MessageModel();
      messageObj.code = item.publishableKey;

      return messageObj;
  }
}
