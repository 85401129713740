/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mail-to.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../global-components/text/text.component.ngfactory";
import * as i3 from "../../../../../global-components/text/text.component";
import * as i4 from "../../../../../global-components/text/text.store";
import * as i5 from "../../../../../global-components/image/image.component.ngfactory";
import * as i6 from "../../../../../global-components/image/image.component";
import * as i7 from "../../../../../global-components/image/image.store";
import * as i8 from "../../../../../global-components/button/button.component.ngfactory";
import * as i9 from "../../../../../global-components/button/button.component";
import * as i10 from "../../../../../global-components/button/button.store";
import * as i11 from "./mail-to.component";
import * as i12 from "./mail-to.store";
var styles_MailToComponent = [i0.styles];
var RenderType_MailToComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MailToComponent, data: {} });
export { RenderType_MailToComponent as RenderType_MailToComponent };
export function View_MailToComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 27, "div", [["class", "mail-box-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 26, "div", [["class", "mail-box"], ["id", "mail-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "app-text", [], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(4, 114688, null, 0, i3.TextComponent, [i4.TextStore], null, null), (_l()(), i1.ɵted(-1, 0, ["Oops!"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-image", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ImageComponent_0, i5.RenderType_ImageComponent)), i1.ɵdid(7, 114688, null, 0, i6.ImageComponent, [i7.ImageStore], { src: [0, "src"], class: [1, "class"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "app-text", [], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(10, 114688, null, 0, i3.TextComponent, [i4.TextStore], null, null), (_l()(), i1.ɵted(-1, 0, ["We are having an issue to process changing plan for a while. Please contact our customer service "])), (_l()(), i1.ɵeld(12, 0, null, 0, 1, "span", [["class", "text-accent500"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["support@amdon.com"])), (_l()(), i1.ɵted(-1, 0, [" to continue. Lovely to assit you!"])), (_l()(), i1.ɵeld(15, 0, null, null, 12, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 4, "app-button", [["class", "btn-width"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ButtonComponent_0, i8.RenderType_ButtonComponent)), i1.ɵdid(17, 49152, null, 0, i9.ButtonComponent, [i10.ButtonStore], { class: [0, "class"], height: [1, "height"] }, null), (_l()(), i1.ɵeld(18, 0, null, 0, 2, "app-text", [["class", "btn-text"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(19, 114688, null, 0, i3.TextComponent, [i4.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(-1, 0, ["Close"])), (_l()(), i1.ɵeld(21, 0, null, null, 6, "div", [["class", "btn-width"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 5, "a", [["class", "btn-text"], ["href", "mailto:support@amdon.com?Subject=Yearly Plan Change Request"], ["target", "_top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 4, "app-button", [["class", "btn-width"]], null, null, null, i8.View_ButtonComponent_0, i8.RenderType_ButtonComponent)), i1.ɵdid(24, 49152, null, 0, i9.ButtonComponent, [i10.ButtonStore], { class: [0, "class"], height: [1, "height"] }, null), (_l()(), i1.ɵeld(25, 0, null, 0, 2, "app-text", [], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(26, 114688, null, 0, i3.TextComponent, [i4.TextStore], null, null), (_l()(), i1.ɵted(-1, 0, ["Contact"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_0 = _co.cross; var currVal_1 = "cursor"; _ck(_v, 7, 0, currVal_0, currVal_1); _ck(_v, 10, 0); var currVal_2 = "btn-cancel "; var currVal_3 = "48px"; _ck(_v, 17, 0, currVal_2, currVal_3); var currVal_4 = "btn-text"; _ck(_v, 19, 0, currVal_4); var currVal_5 = "btn-orange text-s0"; var currVal_6 = "48px"; _ck(_v, 24, 0, currVal_5, currVal_6); _ck(_v, 26, 0); }, null); }
export function View_MailToComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mail-to", [], null, null, null, View_MailToComponent_0, RenderType_MailToComponent)), i1.ɵdid(1, 114688, null, 0, i11.MailToComponent, [i12.MailToStore], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MailToComponentNgFactory = i1.ɵccf("app-mail-to", i11.MailToComponent, View_MailToComponent_Host_0, {}, { closeMailTo: "closeMailTo" }, []);
export { MailToComponentNgFactory as MailToComponentNgFactory };
