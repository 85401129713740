import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { GiftCardShopHomeControllerComponent } from '../gift-card-shop/presentation/gift-card-shop-home/gift-card-shop-home-controller.component';
import { GiftCardControllerComponent } from '../gift-card-shop/presentation/gift-card/gift-card-controller.component';
import { GiftcardPurchaseProcessingControllerComponent } from '../gift-card-shop/presentation/giftcard-purchase-processing/giftcard-purchase-processing-controller.component';

const routes: Routes = [
  {
    path: '',
    component: GiftCardShopHomeControllerComponent,
    children: [
      {
        path: '',
        component: GiftCardControllerComponent
      },
      {
        path: 'purchase-processing',
        component: GiftcardPurchaseProcessingControllerComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GiftCardShopRoutingModule {}
