import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../global-components/global-components.module';
import {SharedComponentsModule} from './presentation/shared-components/shared-components.module';
import {BusinessLogicFacade} from './business-logic/business-logic.facade';
import {BusinessRequirementsInjectionToken} from './presentation/business-logic.requirements';
import { BusinessLogicRequirementsFake } from '../dashboard/presentation/business-logic.requirements.fake';
import { ParentDetailHomeModule } from './presentation/parent-detail-home/parent-detail-home.module';


@NgModule({
    imports: [
        BusinessLogicFacade,
        CommonModule,
        GlobalComponentsModule,
        SharedComponentsModule,
        ParentDetailHomeModule
    ],
    providers: [
        {
            provide: BusinessRequirementsInjectionToken,
            useClass: BusinessLogicFacade
            // useClass: BusinessLogicRequirementsFake
        }
    ],
    exports: []
})
export class ParentDetailFeatureModule {}
