import { AuthService } from '../../../lib/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, share } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PlanApiService {
    constructor(
        private httpClient: HttpClient,
        private http: HttpClient,
        private _authService: AuthService
    ) {
        this.userInfo = this._authService.getUserInfo();
    }

    private baseUrl = environment.apiurl;
    private userInfo = {
        username: '',
        password: '',
        api_key: ''
    }

    getPlanList(): Observable<any> {
      let url = this.baseUrl + "/api/v1/subscriptions?interval=year";
      console.log(url);
      return this.http.get(url).pipe(
        map((res: Response) => {
          let result = res;
          console.log(result);
          return result;
        })
      );

    }

    getPlan(id): Observable<any> {
      let url = this.baseUrl + "/api/v1/subscriptions/"+id;
      console.log(url);
      return this.http.get(url).pipe(
        map((res: Response) => {
          let result = [res];
          console.log(result);
          return result;
        })
      );
    }

    getSubscription(): Observable<any>{
      let url = this.baseUrl + "/api/v2/users/current-subscription";
    return this.http.get(url).pipe(
      map((res: Response) => {
        let result = res;
        console.log(result,'ahhhhhhhhhhhhhhhhh');
        return result;
      },err=>{
        console.log('errrrrrrrrrrr',err)
      })
    );
    }

    subscribeUser(data) {
      let url = this.baseUrl + "/api/v2/subscriptions/subscribe";
      console.log(url);
      return this.http.post(url, data).pipe(
        map((res: Response) => {
          let result = res;
          console.log(result);
          return result;
        })
      );
    }
    getPublishKey() {
      let url = this.baseUrl + "/stripe/publish-key";
      console.log(url);
      return this.http.get(url).pipe(
        map((res: Response) => {
          let result = res;
          console.log(result);
          return result;
        })
      );
    }
    getGiftInfo(id) {
      let url = this.baseUrl + "/api/v1/gift-cards/"+id;
      console.log(url);
      return this.http.get(url).pipe(
        map((res: Response) => {
          let result = res;
          console.log("zha giftcard",result);
          return [result];
        })
      );
    }
    claimGiftCard(id, opt, redir) {
      let body = {
        code: id,
        option: opt,
        redirectURL: redir
      }
      let url = this.baseUrl + "/api/v2/gift-cards/claim";
      console.log(url);
      return this.http.post(url, body).pipe(
        map((res: Response) => {
          let result = res;
          console.log("giftcard claim",result);
          return [result];
        })
      );
    }
}
