import {Inject, Component} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/lib/auth.service';
import { SharedService } from 'src/app/lib/shared-service';
import { environment } from 'src/environments/environment';
import {BusinessLogicRequirements, BusinessRequirementsInjectionToken} from '../business-logic.requirements';
import {SchoolSignupStore} from './school-signup.store'

@Component({
    selector: 'app-school-signup',
    templateUrl: './school-signup-controller.component.html',
    styleUrls: ['./school-signup-controller.component.css']
})
export class SchoolSignupControllerComponent {
  public getInit = environment.initState;
  public ClickLogin = false;
  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private store: SchoolSignupStore,
    private router: Router,
    private _authService: AuthService,
    private _shared: SharedService
  ) {}

  ngOnInit() {
    console.log(this.getInit);
    const isAuth = this._authService.isAuthenticated();
    console.log("this.authData ", isAuth);
    if (isAuth) {
      const userInfo = this._authService.getUserInfo();
      if(userInfo.UserType == 3)
        this.router.navigateByUrl("/school-login");
      else
        this.router.navigateByUrl(`/school-plans/`+this._shared.getCurrentPlanId()+'?discountcode='+this._shared.getSchoolDiscountCode());
    }
  }
  buttonClickLogin() {
    this.ClickLogin = true;
  }
}
