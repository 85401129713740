<div
  *ngIf="boxType"
  class="d-flex"
  [ngClass]="{
    'align-item-center': !mobileView,
    'align-items-start': mobileView,
    'flex-column': mobileView,
    'dashboard-warning': boxType == 'beforeNoPayment' || boxType == 'beforeTrial' || boxType == 'beforeUnsubscribe',
    'dashboard-warning-red': boxType == 'afterUnsubscribe' || boxType == 'afterTrial'
  }"
>
  <div class="d-flex w-80" [ngClass]="{ 'w-80': !mobileView, 'w-100': mobileView, 'align-items-start': mobileView }">
    <div class="alert-circle">
      <img
        *ngIf="boxType == 'beforeNoPayment' || boxType == 'beforeTrial' || boxType == 'beforeUnsubscribe'"
        src="../../../assets/images/alert_circle.svg"
      />
      <img
        *ngIf="boxType == 'afterUnsubscribe' || boxType == 'afterTrial'"
        src="../../../assets/images/alert_circle_red.svg"
      />
    </div>
    <div class="warning-body yellow-warning" *ngIf="boxType == 'beforeNoPayment'">
      Your subscription is ending soon. Please resubscribe before
      <b>{{ usersubinfo.expiryDate | date: 'd MMM yyyy' }}</b> to continue enjoying STEMWerkz.
    </div>
    <div class="warning-body yellow-warning" *ngIf="boxType == 'beforeUnsubscribe'">
      Your subscription is ending soon. Please update your payment information before
      <b>{{ usersubinfo.expiryDate | date: 'd MMM yyyy' }}</b> to continue enjoying STEMWerkz.
    </div>
    <div class="warning-body yellow-warning" *ngIf="boxType == 'beforeTrial'">
      Your subscription is ending soon. Please subscribe before
      <b>{{ usersubinfo.expiryDate | date: 'd MMM yyyy' }}</b> to continue enjoying STEMWerkz.
    </div>
    <div class="warning-body red-warning" *ngIf="boxType == 'afterUnsubscribe'">
      Your subscription has ended. You can subscribe to the previous plan again or change to a new plan.
    </div>
    <div class="warning-body red-warning" *ngIf="boxType == 'afterTrial'">
      Your subscription has ended. Please subscribe to continue enjoying STEMWerkz.
    </div>
  </div>
  <div class="resubscribe" (click)="btnClicked()">
    <app-button [class]="boxType == 'afterUnsubscribe' || boxType == 'afterTrial' ? 'btn-red' : 'btn-yellow'">
      <app-text [class]="'btn-text-style2'" *ngIf="boxType == 'beforeNoPayment'">Manage payment</app-text>
      <app-text [class]="'btn-text-style2'" *ngIf="boxType == 'beforeTrial' || boxType == 'afterTrial'"
        >Subscribe</app-text
      >
      <app-text [class]="'btn-text-style2'" *ngIf="boxType == 'beforeUnsubscribe'">Resubscribe</app-text>
      <app-text [class]="'btn-text-style2'" *ngIf="boxType == 'afterUnsubscribe'">Manage subscription</app-text>
    </app-button>
  </div>
</div>
