import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GlobalComponentsModule } from "../global-components/global-components.module";
import { SharedComponentsModule } from "./presentation/shared-components/shared-components.module";
import { BusinessLogicFacade } from "./business-logic/business-logic.facade";
import { BusinessRequirementsInjectionToken } from "./presentation/business-logic.requirements";
import { HomeSignupModule } from "./presentation/home-signup/home-signup.module";
import { LoginModule } from "./presentation/login/login.module";
import { SignupModule } from "./presentation/signup/signup.module";
import { HomeLoginModule } from "./presentation/home-login/home-login.module";
import { from } from "rxjs";

@NgModule({
  imports: [
    BusinessLogicFacade,
    CommonModule,
    GlobalComponentsModule,
    SharedComponentsModule,
    HomeSignupModule,
    LoginModule,
    SignupModule,
    HomeLoginModule,
  ],
  providers: [
    {
      provide: BusinessRequirementsInjectionToken,
      useClass: BusinessLogicFacade,
    },
  ],
  exports: [HomeSignupModule, HomeLoginModule],
})
export class HomeFeatureModule {}
