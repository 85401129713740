<div class="player-info-box">
  <div style="height: 127.44px;">
    <app-image [src]="'../../../assets/images/player-detail.svg'"></app-image>
  </div>
  <div class="profile-outer-circle d-flex justify-content-center align-item-center">
    <div
      class="profile-circle d-flex justify-content-center align-item-center"
      [ngStyle]="{
        'background-image': 'url(' + playerDetail.profileImage + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover'
      }"
    >
      <app-text class="shortName">{{ playerDetail.shortName }}</app-text>
    </div>
  </div>
  <div class="text-div">
    <app-text class="big-name">{{ playerDetail.fullName }}</app-text>
    <app-text class="email">{{ playerDetail.username }}</app-text>
    <app-text (click)="editPlayer($event)" class="edit">Edit profile</app-text>
    <app-text (click)="resetPlayer($event)" class="reset">Reset password</app-text>
    <app-text style="margin-bottom: 40px;"><a class="reset" routerLink="/store" target="_blank">Purchase gift cards</a></app-text>
    <!-- <app-text (click)="deletePlayer($event)" class="delete"
      >Delete account</app-text
    > -->
  </div>
</div>
