/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loader.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./loader.component";
var styles_LoaderComponent = [i0.styles];
var RenderType_LoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoaderComponent, data: {} });
export { RenderType_LoaderComponent as RenderType_LoaderComponent };
export function View_LoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "spin-loader"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "width": 0, "height": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "edge edge-left bg-s60"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "edge edge-right bg-s60"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.width, _co.height); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, View_LoaderComponent_0, RenderType_LoaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.LoaderComponent, [], null, null)], null, null); }
var LoaderComponentNgFactory = i1.ɵccf("app-loader", i3.LoaderComponent, View_LoaderComponent_Host_0, { width: "width", height: "height" }, {}, []);
export { LoaderComponentNgFactory as LoaderComponentNgFactory };
