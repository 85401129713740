import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InfoCardStore } from './info-card.store';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.css']
})
export class InfoCardComponent {
  @Input() giftCodeAdded;
  @Input() loading;
  @Input() correctDiscountCode;
  @Input() isAuth;
  @Input() isEmpty;
  @Input() unsubscribe;
  @Input() plan;
  @Input() amount;
  @Input() giftExpiry; // means gift card expire date
  @Input() templateName;
  @Input() discountText;
  @Input() discountCode;

  @Output() btnClicked = new EventEmitter<any>();
  constructor(private store: InfoCardStore) {}

  ngOnInit() {
    console.log('template Name ,', this.templateName);
  }

  ngOnChanges() {}

  /*
  setupPayment() 
  expireChange(plan.planId)
  signUp()
  skipPayment()
  addGift()
  cancelPayment()
  addDiscount()
  */
  clickedBtn(value) {
    var temp = {
      type: 'type',
      value: value
    };
    switch (value) {
      case 'setup':
        temp.type = 'setupPayment';
        break;
      case 'expire':
        temp.type = 'expireChange';
        temp.value = value;
        break;
      case 'signup':
        temp.type = 'signUp';
        break;
      case 'gift':
        temp.type = 'addGift';
        break;
      case 'cancel':
        temp.type = 'cancelPayment';
        break;
      case 'skip':
        temp.type = 'skipPayment';
        break;
      case 'discount':
        temp.type = 'addDiscount';
        break;
      default:
        temp.value = value;
        temp.type = 'expireChange';
    }

    this.btnClicked.emit(temp);
  }

  setupPayment() {
    this.clickedBtn('setup');
  }

  redeemWithPayment() {
    if (this.isAuth && this.isEmpty) this.clickedBtn('setup');
    else if ((this.isAuth && !this.isEmpty) || !this.isAuth) this.clickedBtn('signup');
  }

  redeemWithoutPayment() {
    this.clickedBtn('skip');
  }

  cancelPayment() {
    this.clickedBtn('cancel');
  }

  addGift() {
    this.clickedBtn('gift');
  }

  expireChange(value) {
    this.clickedBtn(value);
  }

  addDiscount() {
    this.clickedBtn('discount');
  }
}
