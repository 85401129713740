import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../../../global-components/global-components.module';
import {SharedComponentsModule} from '../shared-components/shared-components.module';
import {SchoolLoginControllerComponent} from './school-login-controller.component';
import {RouterModule} from '@angular/router';
import { LoginModule } from '../login/login.module';

@NgModule({
    declarations: [
        SchoolLoginControllerComponent
    ],
    exports: [
        SchoolLoginControllerComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GlobalComponentsModule,
        SharedComponentsModule,
        LoginModule
    ]
})
export class SchoolLoginModule {}
