import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import { GlobalComponentsModule } from 'src/app/global-components/global-components.module';
import { RedeemLoadingComponent } from './redeem-loading/redeem-loading.component';
import { LoaderComponent } from './loader/loader.component';

const components = [
  RedeemLoadingComponent,LoaderComponent
]

@NgModule({
    declarations: components,
    exports: components,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        GlobalComponentsModule
    ]
})
export class SharedComponentsModule {}
