import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/purchase-gift.mapper";
import * as i2 from "../data.requirements";
export class PurchaseGiftCardUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(data) {
        return this.data.purchaseGift(data).pipe(this.mapper.map);
    }
}
PurchaseGiftCardUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PurchaseGiftCardUseCase_Factory() { return new PurchaseGiftCardUseCase(i0.ɵɵinject(i1.PurchaseGiftMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: PurchaseGiftCardUseCase, providedIn: "root" });
