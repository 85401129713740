import { InjectionToken } from "@angular/core";
import { Observable, of } from "rxjs";

export interface DataRequirements {
  getPlayers(id): Observable<any>;
  createPlayer(userData): Observable<any>;
  getParentDetail(parentId): Observable<any>;
  getPlayerDetail(playerId): Observable<any>;
  editParent(parentId, parentData): Observable<any>
  checkPlan(): Observable<any>;
  deletePlayer(playerId): Observable<any>;
  getPlan(id): Observable<any>;
  resubscribePlan(uid, sid): Observable<any>;
  emailCopy(id, pw): Observable<any>;
  changePayment(data): Observable<any>;
}

export const DataRequirementsInjectionToken = new InjectionToken<
  DataRequirements
>("dashboard Data Requirements");
