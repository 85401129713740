import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-empty-slot',
  templateUrl: './empty-slot.component.html',
  styleUrls: ['./empty-slot.component.css']
})
export class EmptySlotComponent implements OnInit {
  @Output() addSlot = new EventEmitter<any>();
  @Input() slot;
  @Input() player;
  constructor() { }

  ngOnInit() {
    console.log("ggg", this.slot);
    console.log("hhh", this.player);

  }
  addNewSlot($event){
    this.addSlot.emit(this.slot);
  }
}
