export class PlayerModel {
  playerName: string = 'Dummy Name';
  playerInitial: string = 'DN';
  playerEmail: string = 'dummy@email.com';
  playerID: number = 1234567890;
  profileImage: string = '';
  firstName: string = 'first';
  lastName: string ='last';
  showName: string = 'Dummy N.';
  subscription = [];
  lastActive: any = '';
}
