import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/gift-card-detail.mapper";
import * as i2 from "../data.requirements";
export class GiftCardDetailsUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(id) {
        return this.data.getGiftCardDetail(id).pipe(this.mapper.map);
    }
}
GiftCardDetailsUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GiftCardDetailsUseCase_Factory() { return new GiftCardDetailsUseCase(i0.ɵɵinject(i1.GiftCardDetailMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: GiftCardDetailsUseCase, providedIn: "root" });
