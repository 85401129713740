import { Inject, Component, ChangeDetectorRef } from '@angular/core';
import { BusinessLogicRequirements, BusinessRequirementsInjectionToken } from '../business-logic.requirements';
import { DashboardHomeStore } from './dashboard-home.store';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../lib/auth.service';
import { SharedService } from '../../../lib/shared-service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home-controller.component.html',
  styleUrls: ['./dashboard-home-controller.component.css']
})
export class DashboardHomeControllerComponent {
  public playerPopup: boolean = false;
  public players = [];
  public errmsg = null;
  public userInfo: any;
  public profileFlag: boolean = false;
  public resetFlag: boolean = false;
  public editFlag: boolean = false;
  public emailOnOff: boolean = true;
  public today = new Date();
  public curHr = this.today.getHours();
  public timeRange = 'morning';
  public showPlayers: any = 'loading';
  public playerLimit: any;
  public deleteFlag: boolean = false;
  public parentData = {
    shortName: '',
    profileImage: '',
    fullName: ''
  };
  public newSlot = '';
  public createdCount = 0;
  public loader: boolean = true;
  public isDowngrade = false;
  public changePlanId = null;
  public plan: any;
  public showContinue = false;
  public needToDelete = 0;
  public expire: boolean = false;
  public unsubscribe: boolean = false;
  public date = new Date();
  public momentToday = moment(this.date);
  public hooray: boolean = false;
  public emailSuccessFlag: boolean = false;
  public emailSent: boolean = false;

  public isExplore = true;
  public exploreClicked = false;

  public zoneList = [];
  public zone = false;
  public zonConfirm = false;
  public selectedZone = {};
  public mobileView;
  public mobileViewService;
  public createBtnLoading = false;
  public showWarningBox = false;
  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private store: DashboardHomeStore,
    private router: Router,
    private route: ActivatedRoute,
    private _authService: AuthService,
    public shared: SharedService,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnInit() {
    this.zoneList = [
      {
        image: '../../assets/images/dashboard_icon.png',
        link: this._authService.getRedirectDashboardUrl(),
        name: 'Dashboard'
      },
      {
        image: '../../assets/images/ZoneValley.png',
        link: this._authService.getRedirectValleyUrl(),
        name: 'STEAMValley'
      },
      {
        image: '../../assets/images/ZoneChannel.png',
        link: this._authService.getRedirectChannelUrl(),
        name: 'STEMWerkz Channel'
      },
      {
        image: '../../assets/images/ZoneAcademy.png',
        link: this._authService.getRedirectAcademyUrl(),
        name: 'STEMWerkz Academy'
      },
      {
        image: '../../assets/images/STEMQuest.png',
        link: this._authService.getRedirectQuestUrl(),
        name: 'STEMWerkz Quest'
      }
    ];

    this.mobileViewService = this.shared.GetMobileView.subscribe((data) => {
      this.mobileView = data;
    });

    this.route.queryParams.subscribe((data) => {
      if (data['show-redeem-success-message'] == '1') {
        this.shared.toastForSuccess(
          'Woohoo!',
          'You have successfully redeemed your gift card. Enjoy exploring STEMWerkz! '
        );
      }
      if (data['show-unsubscribe-success-message'] == '1') {
        this.shared.toastForSuccess('Successfully unsubscribed', '');
      }
      if (data['show-purchase-success-message'] == '1') {
        var planType = localStorage.getItem('planType');
        if (planType == null || planType == 'null') planType = 'promotion';
        var planForPixel = JSON.parse(localStorage.getItem('planForPixel'));
        if (environment.fbPixel) {
          (window as any).fbq('init', '3446777188690523');
          (window as any).fbq('track', 'PageView');
          (window as any).fbq('track', 'Purchase', {
            content_name: planForPixel.planName,
            content_category: planForPixel.interval,
            currency: planForPixel.currency,
            value: planForPixel.interval == 'month' ? planForPixel.planMonthly : planForPixel.planYear
          });
        }

        this.shared.toastForSuccess(
          'Yippie! ',
          `You have successfully purchased the ` +
            planType +
            ` plan! Add your child's account to start exploring STEMWerkz!`
        );
        localStorage.removeItem('planType');
      }
    });

    //stripe
    const paramValue: string = this.route.snapshot.queryParamMap.get('status');
    console.log('firstParam', paramValue);
    const urlValue = this.route.url['_value'][1];
    console.log(urlValue);
    if (urlValue && urlValue.path == 'downgrade') {
      this.isDowngrade = true;
    }
    if (paramValue) {
      const status = paramValue.toLocaleLowerCase();
      if (status == 'failed') {
        this.shared.toastForError('Subscription unsuccessful! Please try again later.', '');
      }
    }
    //stripe
    this.route.params.subscribe((data) => {
      console.log(data.id);
      this.changePlanId = data.id;
    });

    //Calculate when
    console.log('curHr ', this.curHr);
    if (this.curHr >= 5 && this.curHr < 12) {
      this.timeRange = 'morning';
    } else if (this.curHr >= 12 && this.curHr < 17) {
      this.timeRange = 'afternoon';
    } else {
      this.timeRange = 'evening';
    }

    const isAuth = this._authService.isAuthenticated();
    console.log('this.authData ', isAuth);
    if (isAuth) {
      this.userInfo = this._authService.getUserInfo();
      console.log('this.userInfo ', this.userInfo);
      if (this.userInfo.UserType == 3) this.router.navigateByUrl('/login');
      else this.callParentDetail();
      // else if (this.changePlanId) {
      //   this.getPlan(this.changePlanId);
      // }
      // this.checkPlan();
    } else {
      this.router.navigateByUrl('');
    }
  }

  public isStartUser = false;

  callParentDetail() {
    this.business.getParentDetail(this.userInfo.userId).subscribe(
      (res) => {
        console.log('detail detail ', res[0]);
        this.parentData = res[0];
        if (res[0].metadata.registeredFrom == 'stemwerkz-start') this.isStartUser = true;
        else this.isStartUser = false;
        // I can get parent data;
        localStorage.setItem('parentDetailObj', this._authService.encrypt(JSON.stringify(this.parentData), null));
        if (this.changePlanId) this.getPlan(this.changePlanId);
        this.checkPlan();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getChildList() {
    this.business.getPlayers(this.userInfo.userId).subscribe(
      (res) => {
        console.log(res);
        this.players = res;
        if (this.players.length == 0) {
          this.isExplore = false;
        } else {
          this.isExplore = true;
        }
        this.loader = false;
        //for testing
        this.createdCount = this.players.length;
        if (this.isDowngrade == true && this.plan) {
          this.needToDelete = this.createdCount - this.plan.playerSlot;
          this.showContinue = true;
        }
        if (this.players.length < this.playerLimit) {
          console.log('@@@@@@@@@@@@@@@@ you need to create empty array');
          console.log(this.players.length, this.playerLimit);
          console.log(this.players);
          if (this.isDowngrade != true) {
            for (var i = 0; i < this.playerLimit - this.createdCount; i++) {
              this.players.push({ test: 1 });
            }
          }
          console.log(this.players);
        }
        //for testing
        this.showPlayers = this.createdCount > 0 ? 'players' : 'noplayers';
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onButtonClick($event) {
    console.log('on click');
    var ref;
    if (localStorage.getItem('ref')) ref = localStorage.getItem('ref');
    localStorage.clear();
    localStorage.setItem('ref', ref);
    this.router.navigateByUrl(`/login`);
  }

  createPlayer(event) {
    console.log(event, 'slot number I got it');
    this.newSlot = event;
    this.playerPopup = !this.playerPopup;
  }

  CloseForm($event) {
    console.log('caught in dashboard');
    this.playerPopup = false;
    this.errmsg = null;
  }
  public playerDataTemp: any;
  public createdPlayerInfo: any;
  create(playerData) {
    this.createBtnLoading = true;
    this.playerDataTemp = playerData;
    console.log('create');
    console.log(playerData);
    const encryptedPwd = btoa(playerData.password);
    const pInfo = {
      firstName: playerData.firstName,
      lastName: playerData.lastName,
      email: playerData.email,
      username: playerData.username,
      password: encryptedPwd,
      usertype: 3,
      yearOfBirth: playerData.yearOfBirth,
      gender: playerData.gender
    };
    //you have to pass newSlot in pInfo
    console.log(pInfo);
    this.errmsg = null;
    this.business.createUser(pInfo).subscribe(
      (status) => {
        this.createBtnLoading = false;
        console.log('status ', status);
        this.playerPopup = false;
        this.getChildList();
        this.hooray = true;
        this.createdPlayerInfo = status;
      },
      (err) => {
        console.log('err ', err);
        if (
          err.error.message == '[Pagewerkz] There is already an account with this username. If this is you log in now.'
        ) {
          console.log('if');
          this.errmsg = 'This username is not available.';
        }
        this.shared.toastForError(err.error.message, '');
        this.createBtnLoading = false;
      }
    );
  }

  openProfile($event) {
    // this.profileFlag = true;
    this.router.navigateByUrl(`/parent/detail`);
  }

  closeProfile($event) {
    this.profileFlag = false;
  }

  closeReset($event) {
    this.resetFlag = false;
  }

  UserPasswordChange($event) {
    this.profileFlag = false;
    this.resetFlag = true;
  }
  public resetLoading: boolean = false;
  resetPassword(parentData) {
    this.resetLoading = true;
    const newPassword = {
      password: parentData.newPassword
    };
    console.log('this is reset password', newPassword);
    console.log(this.playerData.playerID);
    this.business.editParent(this.playerData.playerID, newPassword).subscribe(
      (res) => {
        console.log('detail detail ', res);
        this.resetFlag = false;
        this.shared.toastForSuccess('Password updated successfully!', '');
        setTimeout(() => {
          this.resetLoading = false;
        }, 3000);
        this.callParentDetail();
      },
      (err) => {
        console.log(err);
        this.shared.toastForSuccess('Password updated failed!', '');
        this.resetLoading = false;
      }
    );
  }

  closeEdit($event) {
    this.editFlag = false;
  }

  editUser($event) {
    this.profileFlag = false;
    // this.editFlag = true;
    this.playerDetailApi(this.playerData.playerID);
  }
  public editLoading: boolean = false;
  editParentApi(parentData) {
    this.editLoading = true;
    console.log(this.parentData['id']);
    console.log(parentData);
    this.business.editParent(this.playerData.playerID, parentData).subscribe(
      (res) => {
        console.log('detail detail ', res);
        this.editFlag = false;
        this.shared.toastForSuccess('Profile updated successfully!', '');
        setTimeout(() => {
          this.editLoading = false;
        }, 3000);
        this.getChildList();
      },
      (err) => {
        console.log(err);
        this.shared.toastForSuccess('Profile update failed!', '');
        this.editLoading = false;
      }
    );
  }
  public usersubinfo: any;
  public dateDiff: any;
  public subId;
  checkPlan() {
    this.business.checkPlan().subscribe(
      (res) => {
        console.log('check plan', res);
        if (res) {
          this.subId = res._id;
          this.playerLimit = res.playerSlot;
          this.getChildList();
          this.usersubinfo = res.userSubInfo;

          var expireDate = moment(res.userSubInfo.expiryDate);
          this.dateDiff = expireDate.diff(this.momentToday);
          var days = (this.dateDiff / (1000 * 60 * 60 * 24)).toFixed(1);
          this.dateDiff = days;

          console.log('zha test date', this.dateDiff);

          if (this.usersubinfo.status == 'UNSUBSCRIBED') {
            this.unsubscribe = true;
          }
          if (this.usersubinfo.status == 'ACTIVE') {
            this.unsubscribe = false;
          }
          if (this.date.getTime() - new Date(this.usersubinfo.expiryDate).getTime() + 10 > 0) {
            this.expire = true;
          }
          this.determineBoxType();
        }
      },
      (err) => {
        console.log(err);
        this.router.navigateByUrl('/plans');
      }
    );
  }
  public playerData: any;
  openplayerProfile(data) {
    console.log('open player ', data);
    if (this.isDowngrade != true) {
      this.playerData = data;
      this.profileFlag = true;
    }
  }
  public playerDetail;
  playerDetailApi(playerId) {
    console.log('getId ', playerId);
    this.business.getPlayerDetail(playerId).subscribe(
      (res) => {
        console.log('zha testing detail', res[0]);
        this.playerDetail = res[0];
        this.editFlag = true;
        // this.players = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  playerDetailApi2(playerId) {
    console.log('getId ', playerId);
    this.business.getPlayerDetail(playerId).subscribe(
      (res) => {
        console.log('zha testing detail', res[0]);
        this.playerDetail = res[0];
        this.deleteFlag = true;
        // this.players = res;
      },
      (err) => {
        console.log(err);
        console.log('hee');
      }
    );
  }
  deleteUser(e) {
    this.profileFlag = false;
    console.log('delete');
    this.playerDetailApi2(this.playerData.playerID);
  }
  closeDelete($event) {
    this.deleteFlag = false;
  }
  deletePlayerApi() {
    console.log('call delete ');
    const pId = this.playerData.playerID;
    console.log(this.playerData);
    this.business.deletePlayer(pId).subscribe(
      (res) => {
        console.log('detail detail ', res);
        this.deleteFlag = false;
        if (this.isDowngrade == true) this.router.navigateByUrl(`/plans/downgrade/${this.changePlanId}`);
        else this.router.navigateByUrl(`/dashboard`);
        this.getChildList();
        this.shared.toastForSuccess('Account successfully deleted!', '');
        setTimeout(() => {
          this.resetLoading = false;
        }, 3000);
      },
      (err) => {
        console.log(err);
        this.deleteFlag = false;
        this.shared.toastForError('Something wrong!', '');
        this.resetLoading = false;
      }
    );
  }

  changePlan(e) {
    console.log('goto');
    if (this.usersubinfo.type == 'trial') this.router.navigateByUrl(`/plans?type=trial`);
    else this.router.navigateByUrl(`/changeplan/${this.subId}`);
  }

  removePlayer(playerData) {
    console.log('remove ', playerData);
    this.playerData = playerData;
    this.deleteUser('');
  }
  continue() {
    if (this.needToDelete == 0) {
      this.router.navigateByUrl(`/plans/${this.changePlanId}?change=${true}&downgrade=${true}`);
    }
  }
  getPlan(id) {
    this.business.getPlan(id).subscribe(
      (data) => {
        console.log('plan', data[0]);
        this.plan = data[0];
        console.log(this.plan);
        // this.renderData();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  backPage() {
    console.log('back');
    const currentPlanId = this.shared.getCurrentPlanId();
    this.router.navigateByUrl(`/changeplan/${currentPlanId}`);
  }
  manageSub() {
    console.log('$$$$$$$$$$$$$$$$$$$');
    this.router.navigateByUrl(`/plans/${this.subId}?change=true&unsubscribe=true`);
  }
  resubscribe() {
    this.business.resubscribePlan(this.userInfo.userId, this.subId).subscribe(
      (data) => {
        console.log('res', data[0]);
        this.plan = data[0];
        console.log(this.plan);
        this.shared.toastForSuccess('Resubscribed successfully', '');
        this.checkPlan();
      },
      (err) => {
        console.log(err);
        this.shared.toastForError('Something went wrong' + err.error.message, '');
      }
    );
  }
  closeHooray() {
    this.hooray = false;
    this.zone = false;
  }
  explore() {
    this.hooray = true;
    this.zone = true;
  }

  portal(){
    console.log('portal clicked');
    window.open(this._authService.getRedirectParentPortalUrl(), "_blank");
    // window.location.href = this._authService.getRedirectParentPortalUrl();
  }

  confirmZone(event) {
    console.log(event);
    this.zonConfirm = true;
    this.selectedZone = event;
    this.hooray = false;
    this.zone = false;
    console.log('make changes here');
  }

  backConfirm($event) {
    this.zonConfirm = false;
    this.hooray = true;
    this.zone = true;
  }

  goZone(event) {
    var ref;
    if (localStorage.getItem('ref')) ref = localStorage.getItem('ref');
    localStorage.clear();
    localStorage.setItem('ref', ref);
    this.router.navigateByUrl('/login');
    window.open(event.link);
  }
  emailCopyApi() {
    console.log('send email', this.createdPlayerInfo);
    const encryptedPwd = btoa(this.playerDataTemp.password);
    this.business.emailCopy(this.createdPlayerInfo._id, encryptedPwd).subscribe(
      (data) => {
        console.log('res', data);
        this.hooray = false;
        this.emailSuccessFlag = true;
        this.emailSent = true;
        setTimeout(() => {
          this.emailSuccessFlag = false;
        }, 3000);
      },
      (err) => {
        console.log(err);
        this.hooray = false;
        this.emailSuccessFlag = true;
        this.emailSent = false;
      }
    );
  }
  ngOnDestroy() {
    this.mobileViewService.unsubscribe();
  }
  managePayment() {
    console.log('change payment');
    var data = {
      url: window.location.origin + '/parent/detail'
    };
    console.log(data);

    this.business.changePayment(data).subscribe(
      (res) => {
        console.log('dashboard change payment', res);
        window.open(res.url);
        // location.href = res.url;
      },
      (err) => {
        console.log(err);
        this.shared.toastForError('Something went wrong!' + err.error.message, '');
      }
    );
  }

  public boxType;
  determineBoxType() {
    if (this.expire) {
      if (this.unsubscribe && this.usersubinfo.stripeSubscriptionId && this.usersubinfo.type != 'trial')
        this.boxType = 'afterUnsubscribe';
      else if (!this.unsubscribe && !this.usersubinfo.stripeSubscriptionId && this.usersubinfo.type == 'trial')
        this.boxType = 'afterTrial';
      else this.boxType = null;
      this.showWarningBox = true;
    } else if (this.dateDiff <= 7 && this.dateDiff > 0) {
      if (this.unsubscribe) {
        this.boxType = 'beforeUnsubscribe';
      } else {
        if (!this.usersubinfo.stripeSubscriptionId && this.usersubinfo.type != 'trial')
          this.boxType = 'beforeNoPayment';
        else if (this.isStartUser && this.usersubinfo.type == 'trial' && !this.usersubinfo.stripeSubscriptionId)
          this.boxType = 'beforeTrial';
        else this.boxType = null;
      }
      this.showWarningBox = true;
      // this.cdr.detectChanges();
    }
  }

  warningBoxClicked(value) {
    switch (value) {
      case 'beforeNoPayment':
        this.managePayment();
        break;
      case 'beforeTrial':
        this.changePlan(value);
        break;
      case 'beforeUnsubscribe':
        this.resubscribe();
        break;
      case 'afterUnsubscribe':
        this.manageSub();
        break;
      case 'afterTrial':
        this.changePlan(value);
        break;
    }
  }
}
