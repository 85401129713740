import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PlayerApiService {
    constructor(http) {
        this.http = http;
        this.baseUrl = environment.apiurl;
    }
    getUser(id) {
        let url = this.baseUrl + "/api/v2/users/" + id + "/players";
        return this.http.get(url).pipe(map((res) => {
            let result = res;
            return result;
        }));
    }
    createUser(userData) {
        console.log(userData);
        let url = this.baseUrl + "/api/v2/users";
        return this.http.post(url, userData).pipe(map((res) => {
            let result = res;
            // console.log(res);
            return result;
        }));
    }
    getPlayer(playerId) {
        let url = this.baseUrl + "/api/v2/users/" + playerId;
        console.log(url);
        return this.http.get(url).pipe(map((res) => {
            // let result = [];
            let result = res;
            console.log(result);
            return result;
        }));
    }
    deletePlayer(playerId) {
        let url = this.baseUrl + "/api/v2/users/" + playerId;
        console.log(url);
        return this.http.delete(url).pipe(map((res) => {
            let result = res;
            console.log(result);
            return result;
        }));
    }
}
PlayerApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlayerApiService_Factory() { return new PlayerApiService(i0.ɵɵinject(i1.HttpClient)); }, token: PlayerApiService, providedIn: "root" });
