import { Inject, Injectable } from "@angular/core";
import {
  DataRequirements,
  DataRequirementsInjectionToken,
} from "../data.requirements";
import { SubscribeUserMapper } from "../mappers/subscribe-user-mapper";

@Injectable({
  providedIn: "root",
})
export class SubscribeUserUseCase {
  constructor(
    private mapper: SubscribeUserMapper,
    @Inject(DataRequirementsInjectionToken) private data: DataRequirements
  ) {}

  run(data) {
    return this.data.subscribeUser(data);
  }
}
