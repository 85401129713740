<div class="mail-box-wrapper">
  <div class="mail-box" id="mail-box">
    <div class="header">
      <app-text>Oops!</app-text>
      <app-image [src]="cross" [class]="'cursor'" (click)="close()"></app-image>
    </div>
    <div class="body">
      <app-text
        >We are having an issue to process changing plan for a while. Please contact our customer service
        <span class="text-accent500">support@amdon.com</span> to continue. Lovely to assit you!</app-text
      >
    </div>
    <div class="footer">
      <app-button class="btn-width" [class]="'btn-cancel '" [height]="'48px'" (click)="close()"
        ><app-text class="btn-text">Close</app-text></app-button
      >
      <div class="btn-width">
        <a class="btn-text" href="mailto:support@amdon.com?Subject=Yearly Plan Change Request" target="_top">
          <app-button class="btn-width" [class]="'btn-orange text-s0'" [height]="'48px'"
            ><app-text>Contact</app-text></app-button
          >
        </a>
      </div>
    </div>
  </div>
</div>
