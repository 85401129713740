import * as tslib_1 from "tslib";
import { BusinessLogicRequirements } from '../business-logic.requirements';
import * as moment from 'moment';
import { SubscriptionPlanModel } from 'src/app/models/subscription-plan.model';
import { loadStripe } from '@stripe/stripe-js';
export class PlansControllerControllerComponent {
    constructor(business, router, route, _authService, _sharedService, shared) {
        this.business = business;
        this.router = router;
        this.route = route;
        this._authService = _authService;
        this._sharedService = _sharedService;
        this.shared = shared;
        this.planList = [];
        this.selectedPlan = '';
        this.selectedPlanId = null;
        this.selectedPrice = '';
        this.selectedSlot = null;
        this.currentSlot = null;
        this.currentPlanObj = new SubscriptionPlanModel();
        this.isUpgrade = null;
        this.giftFlag = false;
        this.giftCode = '';
        this.date = new Date();
        this.momentToday = moment(this.date);
        this.invalidCode = false;
        this.loading = true;
        this.paymentType = '';
        this.isEmpty = false;
        this.isPromo = false;
        this.subLoading = false;
        this.loaderToggle = 'display-flex';
        this.imageToggle = 'display-none';
        this.tableloading = true;
        this.remainTrialDay = null;
    }
    //newly added variable after refactor
    ngOnInit() {
        this.mobileViewService = this.shared.GetMobileView.subscribe((data) => {
            this.mobileView = data;
        });
        this.parseParams();
        this.pareseQueryParams();
        this.checkActivePlan();
    }
    parseParams() {
        this.route.params.subscribe((data) => {
            this.urlPlanId = data.id;
            this.shared.setCurrentPlanId(this.urlPlanId);
        });
    }
    pareseQueryParams() {
        this.route.queryParams.subscribe((data) => {
            if (data.giftcode == 'fail') {
                alert('Gift code fail');
            }
            if (data.unsubscribe) {
                this.unsubscribe = data.unsubscribe;
            }
            if (data.type) {
                this.typeFromParams = data.type;
            }
        });
    }
    checkActivePlan() {
        this.business.getSubscription().subscribe((data) => {
            console.log('active plan exists', data[0]);
            this.isEmpty = false;
            this.currentPlanObj = data[0];
            this.paymentType = this.currentPlanObj.userSubInfo.type;
            this.expiryDate = this.currentPlanObj.userSubInfo.expiryDate;
            this.selectedPrice = this.currentPlanObj.planMonthly; //default;
            this.mobilePlan = this.currentPlanObj.planName;
            // active plan exists then go to dashboard
            if (!this.urlPlanId && this.typeFromParams != 'trial')
                this.dashboard();
            // plan change or trial user want to subscribe plan
            else {
                this.planLoad();
                this.currentSlot = this.currentPlanObj.playerSlot;
            }
        }, (err) => {
            console.log(err);
            console.log(err.error.message ? err.error.message : 'Something went wrong!');
            this.isEmpty = true;
            // No active plan then show plan list!
            this.planLoad();
        });
    }
    ngOnDestroy() {
        this.mobileViewService.unsubscribe();
    }
    planLoad() {
        const isAuth = this._authService.isAuthenticated();
        console.log('this.authData ', isAuth);
        if (isAuth) {
            this.userInfo = this._authService.getUserInfo();
            if (this.userInfo.UserType == 3)
                this.router.navigateByUrl('/login');
            else {
                // I don't remember isUpgrade is what for
                if (this.currentPlanObj.planId)
                    this.isUpgrade = true;
                this.callParentDetail();
            }
        }
        else {
            this.router.navigateByUrl('');
        }
    }
    callParentDetail() {
        this.business.getParentDetail(this.userInfo.userId).subscribe((res) => {
            // I want to define user upon this api
            // vendor, promo, normal, start
            console.log('detail detail ', res[0]);
            this.parentData = res[0];
            console.log(this.parentData.metadata.vendor);
            localStorage.setItem('parentDetailObj', this._authService.encrypt(JSON.stringify(this.parentData), null));
            if (this.parentData.metadata.vendor && this.parentData.metadata.vendor.slice(0, 6) == 'PROMO_')
                this.userType = 'promo';
            else if (this.parentData.metadata.vendor && this.parentData.metadata.vendor.slice(0, 6) != 'PROMO_')
                this.userType = 'vendor';
            else if (this.parentData.metadata.registeredFrom) {
                this.userType = 'start';
            }
            else
                this.userType = 'normal';
            this.callApiUponUserType();
        }, (err) => {
            console.log(err);
        });
    }
    callApiUponUserType() {
        if (this.userType == 'promo') {
            this.momentNext = moment(this.date).add(3, 'months');
            if (!this.currentPlanObj.planId)
                this.getPromoInfo();
            else {
                // this.tableloading = false;
                this.getPlanList();
            }
        }
        else {
            // this.tableloading = false;
            this.getPlanList();
        }
        // console.log('next pay date', this.momentNext);
    }
    getPlanList() {
        this.business.getPlanList().subscribe((data) => {
            console.log('plan list is here', data);
            this.planList = data;
            this.trialDays = this.planList[0].trialDays;
            if (this.trialDays == 14) {
                this.trialDaysText = 'fourteen';
            }
            else if (this.trialDays == 7) {
                this.trialDaysText = 'seven';
            }
            else {
                this.trialDaysText = '';
            }
            if (!this.currentPlanObj.planId) {
                this.selectedPlan = this.mobilePlan = this.planList[0].planName;
                this.selectedPlanId = this.planList[0].planId;
            }
            this.tableloading = false;
            this.calculateMomentNext();
            this.checkConditionForTerms();
        }, (err) => {
            this.tableloading = false;
            console.log(err);
        });
    }
    calculateMomentNext() {
        console.log('calculateMomentNext', this.parentData.metadata);
        // vendor means may be school
        if (this.parentData.metadata.vendor || this.parentData.metadata.registeredFrom) {
            console.log('i am hs');
            if (!this.currentPlanObj.planId && this.paymentType == 'trial') {
                // show remain trial days
                this.momentNext = moment(this.expiryDate);
                this.remainTrialDay = this.momentNext.diff(this.momentToday, 'days');
                if (this.remainTrialDay > 0)
                    this.momentNext = moment(this.expiryDate);
                else
                    this.momentNext = this.momentToday.format();
                console.log('remain ', this.remainTrialDay);
            }
            else if (this.currentPlanObj.planId && this.paymentType == 'trial' && this.parentData.metadata.registeredFrom) {
                // show remain trial days
                this.momentNext = moment(this.expiryDate);
                this.remainTrialDay = this.momentNext.diff(this.momentToday, 'days');
                if (this.remainTrialDay > 0)
                    this.momentNext = moment(this.expiryDate);
                else
                    this.momentNext = this.momentToday.format();
                console.log('remain ', this.remainTrialDay);
            }
            else if (!this.currentPlanObj.planId) {
                if (this.parentData.metadata.vendor)
                    this.momentNext = this.momentToday.format();
                else {
                    this.momentToday.add(this.trialDays, 'days');
                    this.momentNext = this.momentToday.format();
                }
            }
            else {
                this.momentNext = moment(this.expiryDate);
                console.log('hs');
            }
        }
        else {
            if ((this.paymentType == 'trial' && !this.currentPlanObj.planId) || this.isEmpty) {
                this.momentToday.add(this.trialDays, 'days');
                this.momentNext = this.momentToday.format();
                console.log('trial or empty');
            }
            else if (this.paymentType == 'trial' && this.currentPlanObj.planId) {
                this.momentNext = moment(this.expiryDate);
                console.log('plan change');
            }
            else if (this.paymentType != 'trial' && this.currentPlanObj.planId) {
                // this.momentNext = this.momentToday.format();
                //full stop
                this.momentNext = moment(this.expiryDate);
                console.log('expired');
            }
            //
        }
    }
    openProfile($event) {
        this.router.navigateByUrl('/parent/detail');
    }
    backPage() {
        this.router.navigateByUrl('/dashboard');
    }
    checkConditionForTerms() {
        console.log('empty ', this.isEmpty, this.parentData.metadata.registeredFrom);
        if ((this.paymentType == 'trial' && !this.currentPlanObj.planId) || this.isEmpty) {
            if (this.parentData.metadata.vendor)
                this.templateName = 'emptyVendor';
            else
                this.templateName = 'emptyNormal';
        }
        else if ((this.paymentType == 'trial' && !this.currentPlanObj.planId) || this.isEmpty) {
            if (this.parentData.metadata.registeredFrom)
                this.templateName = 'emptyFromStart';
            else if (this.parentData.metadata.vendor)
                this.templateName = 'emptyVendor';
            else
                this.templateName = 'emptyNormal';
        }
        else if (this.currentPlanObj.planId && this.paymentType == 'trial') {
            if (this.parentData.metadata.registeredFrom)
                this.templateName = 'emptyFromStart';
            else
                this.templateName = 'changeTrial';
        }
        else if (this.currentPlanObj.planId && this.paymentType != 'trial')
            this.templateName = 'changePaid';
        console.log('tem plate name ', this.templateName);
    }
    getPromoInfo() {
        this.business.getPromoInfo(this.parentData.metadata.vendor).subscribe((data) => {
            console.log('promo info is here', data[0]);
            this.planInfo = data[0];
            this.selectedPrice = this.planInfo.planMonthly;
            this.selectedPlanId = this.planInfo.planId;
        }, (err) => {
            this.invalidPromoMessage = err.error.message;
            console.log(err.message);
        });
    }
    upgradeSubscription() {
        this.subLoading = true;
        console.log('get');
        var publish_key = '';
        var sessionId = '';
        var data = {
            userId: this.userInfo.userId,
            subscriptionId: this.selectedPlanId,
            redirectURL: window.location.origin + '/dashboard',
            type: 'paid'
        };
        delete data['couponCode'];
        console.log(data);
        this.business.subscribeUser(data).subscribe((res) => {
            sessionId = res.sessionId;
            console.log('session id ', sessionId);
            this.business.getPublishKey().subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (localStorage.getItem('isUpgrade'))
                    localStorage.removeItem('isUpgrade');
                publish_key = res.publishableKey;
                console.log('publish key', publish_key);
                const stripe = yield loadStripe(publish_key);
                stripe
                    .redirectToCheckout({
                    sessionId: sessionId
                })
                    .then(function (result) {
                    console.log(result);
                });
            }));
        }, (err) => {
            if (localStorage.getItem('isUpgrade'))
                localStorage.removeItem('isUpgrade');
            console.log(err.error.message.slice(0, 14));
            if (err.error.message)
                this.shared.toastForError(err.error.message.slice(0, 14) == 'No such coupon' ? 'Invalid discount code' : err.error.message, '');
            else
                this.shared.toastForError('Something went wrong!', '');
            console.log(err);
            this.loading = false;
        });
    }
    continue() {
        console.log('select id', this.selectedPlanId, this.userType, this.typeFromParams);
        // plan change
        if ((this.selectedPlanId && this.currentPlanObj.planId && this.selectedPlanId != this.currentPlanObj.planId) ||
            (this.userType == 'start' && this.typeFromParams == 'trial')) {
            this.determineDowngradeOrUpgrade();
        }
        // promo plan list
        else if (this.userType == 'promo') {
            // console.error('promo');
            this.upgradeSubscription();
        }
        // active plan then go to dashboard
        else if (this.selectedPlanId == this.currentPlanObj.planId)
            this.dashboard();
        // then plan chosen
        else if (this.selectedPlanId)
            this.router.navigateByUrl(`/plans/${this.selectedPlanId}`);
    }
    determineDowngradeOrUpgrade() {
        if (this.selectedSlot < this.currentSlot || this.currentPlanObj.interval == 'year') {
            console.log('downgrade');
            // current slot is active plan slot and selectedslot is choose plan slot
            // then have to compare actual slot
            if (this.parentData.players.length > this.selectedSlot)
                this.getPlayers();
            else {
                if (this.unsubscribe == 'true')
                    this.router.navigateByUrl(`/plans/${this.selectedPlanId}?change=${true}&downgrade=${true}&unsubscribe=${true}`);
                else
                    this.router.navigateByUrl(`/plans/${this.selectedPlanId}?change=${true}&downgrade=${true}`);
            }
        }
        else {
            console.log('upgrade');
            if (this.typeFromParams == 'trial') {
                this.router.navigateByUrl(`/plans/${this.selectedPlanId}`);
            }
            else if (this.unsubscribe == 'true')
                this.router.navigateByUrl(`/plans/${this.selectedPlanId}?change=${true}&downgrade=${false}&unsubscribe=${true}`);
            else
                this.router.navigateByUrl(`/plans/${this.selectedPlanId}?change=${true}&downgrade=${false}`);
        }
    }
    getPlayers() {
        // determine child removing process
        this.business.getPlayers(this.parentData.id).subscribe((res) => {
            if (this.unsubscribe == 'true')
                this.router.navigateByUrl(`/plans/downgrade/${this.selectedPlanId}?unsubscribe=${true}`);
            else
                this.router.navigateByUrl(`/plans/downgrade/${this.selectedPlanId}`);
        }, (err) => {
            console.log(err);
        });
    }
    giftContinue(e) {
        this.loading = true;
        console.log('gift code', e);
        if (e != '') {
            this.giftCode = e;
            this.business.getGiftInfo(e).subscribe((res) => {
                console.log('gift info', res);
                this._sharedService.setGiftInfo(res);
                this.router.navigateByUrl('/claim-giftcard?giftcode=' + e);
            }, (err) => {
                this.invalidCode = true;
                console.log(err);
                this.loading = false;
            });
        }
        else {
            console.log('empty');
            this.giftFlag = false;
        }
    }
    dashboard() {
        this.router.navigateByUrl(`/dashboard`);
    }
    selectedPlanData(obj) {
        this.selectedPrice = obj.selectedPrice;
        this.selectedPlanId = obj.selectedPlanId;
        this.selectedSlot = obj.selectedSlot;
    }
    addGift() {
        this.giftFlag = true;
    }
    giftCancel(e) {
        this.giftFlag = false;
        this.invalidCode = false;
        this.loading = true;
    }
    imageLoad() {
        setTimeout(() => {
            this.imageToggle = 'display-flex';
            this.loaderToggle = 'display-none';
        }, 500);
    }
}
