import {BusinessLogicRequirements} from '../presentation/business-logic.requirements';
import {NgModule} from '@angular/core';
import {DataRequirementsInjectionToken} from './data.requirements';
import {DataFacade} from '../data/data.facade';
import { SentMailUseCase } from './use-cases/sent-mail.use-case';
import { Observable } from 'rxjs';
import { CheckMailUseCase } from './use-cases/check-mail.use-case';
import { ResetPasswordUseCase } from './use-cases/reset-password.use-case';

@NgModule({
    imports: [DataFacade],

    providers: [
        {
            provide: DataRequirementsInjectionToken,
            useClass: DataFacade
        }
    ]
})
export class BusinessLogicFacade implements BusinessLogicRequirements {
    constructor(
        private sentMailUseCase : SentMailUseCase,
        private checkMailUseCase : CheckMailUseCase,
        private resetPasswordUseCase : ResetPasswordUseCase
    ) {}

    sentMail(mail) : Observable<any>{
        return this.sentMailUseCase.run(mail);
    }
    checkMail(token) : Observable<any>{
        return this.checkMailUseCase.run(token);
    }
    resetPassword(token,body) : Observable<any>{
        return this.resetPasswordUseCase.run(token,body);
    }
}
