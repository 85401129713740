/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./redirect-home-controller.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./redirect-home-controller.component";
import * as i3 from "../../../lib/auth.service";
import * as i4 from "../../../lib/shared-service";
import * as i5 from "@angular/router";
import * as i6 from "../business-logic.requirements";
import * as i7 from "./redirect-home.store";
var styles_RedirectHomeControllerComponent = [i0.styles];
var RenderType_RedirectHomeControllerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RedirectHomeControllerComponent, data: {} });
export { RenderType_RedirectHomeControllerComponent as RenderType_RedirectHomeControllerComponent };
export function View_RedirectHomeControllerComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_RedirectHomeControllerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-redirect-home", [], null, null, null, View_RedirectHomeControllerComponent_0, RenderType_RedirectHomeControllerComponent)), i1.ɵdid(1, 114688, null, 0, i2.RedirectHomeControllerComponent, [i3.AuthService, i4.SharedService, i5.ActivatedRoute, i5.Router, i6.BusinessRequirementsInjectionToken, i7.RedirectHomeStore], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RedirectHomeControllerComponentNgFactory = i1.ɵccf("app-redirect-home", i2.RedirectHomeControllerComponent, View_RedirectHomeControllerComponent_Host_0, {}, {}, []);
export { RedirectHomeControllerComponentNgFactory as RedirectHomeControllerComponentNgFactory };
