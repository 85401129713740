/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./code.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../global-components/image/image.component.ngfactory";
import * as i3 from "../../../../../global-components/image/image.component";
import * as i4 from "../../../../../global-components/image/image.store";
import * as i5 from "../../../../../global-components/text/text.component.ngfactory";
import * as i6 from "../../../../../global-components/text/text.component";
import * as i7 from "../../../../../global-components/text/text.store";
import * as i8 from "../../../../../global-components/input/input.component.ngfactory";
import * as i9 from "../../../../../global-components/input/input.component";
import * as i10 from "../../../../../global-components/input/input.store";
import * as i11 from "@angular/common";
import * as i12 from "../../../../../global-components/button/button.component.ngfactory";
import * as i13 from "../../../../../global-components/button/button.component";
import * as i14 from "../../../../../global-components/button/button.store";
import * as i15 from "./code.component";
import * as i16 from "@angular/forms";
import * as i17 from "../../../business-logic.requirements";
import * as i18 from "./code.store";
import * as i19 from "../../../../../lib/auth.service";
import * as i20 from "@angular/router";
var styles_CodeComponent = [i0.styles];
var RenderType_CodeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CodeComponent, data: {} });
export { RenderType_CodeComponent as RenderType_CodeComponent };
function View_CodeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image", [], null, null, null, i2.View_ImageComponent_0, i2.RenderType_ImageComponent)), i1.ɵdid(1, 114688, null, 0, i3.ImageComponent, [i4.ImageStore], { src: [0, "src"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tickURL; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CodeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image", [], null, null, null, i2.View_ImageComponent_0, i2.RenderType_ImageComponent)), i1.ɵdid(1, 114688, null, 0, i3.ImageComponent, [i4.ImageStore], { src: [0, "src"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alertURL; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CodeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-text", [], null, null, null, i5.View_TextComponent_0, i5.RenderType_TextComponent)), i1.ɵdid(1, 114688, null, 0, i6.TextComponent, [i7.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(2, 0, [" ", " "]))], function (_ck, _v) { var currVal_0 = "form-error-label"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.errText; _ck(_v, 2, 0, currVal_1); }); }
function View_CodeComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["style", "margin-top:8px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-text", [], null, null, null, i5.View_TextComponent_0, i5.RenderType_TextComponent)), i1.ɵdid(2, 114688, null, 0, i6.TextComponent, [i7.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(3, 0, [" Discount for ", " applied. "]))], function (_ck, _v) { var currVal_0 = "form-red-label"; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.schoolName; _ck(_v, 3, 0, currVal_1); }); }
export function View_CodeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 29, "div", [["class", "signup-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "signup-header-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "app-text", [], null, null, null, i5.View_TextComponent_0, i5.RenderType_TextComponent)), i1.ɵdid(3, 114688, null, 0, i6.TextComponent, [i7.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(-1, 0, ["Welcome to STEMWerkz"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "app-text", [["style", "margin-top: 8px;"]], null, null, null, i5.View_TextComponent_0, i5.RenderType_TextComponent)), i1.ɵdid(7, 114688, null, 0, i6.TextComponent, [i7.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(-1, 0, ["Enter the code you received from your school to unlock special discount"])), (_l()(), i1.ɵeld(9, 0, null, null, 12, "form", [], null, [[null, "keyup.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup.enter" === en)) {
        $event.preventDefault();
        var pd_0 = (_co.onUnlock($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 11, "div", [["class", "signup-input-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "app-text", [], null, null, null, i5.View_TextComponent_0, i5.RenderType_TextComponent)), i1.ɵdid(12, 114688, null, 0, i6.TextComponent, [i7.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(-1, 0, ["Code"])), (_l()(), i1.ɵeld(14, 0, null, null, 5, "app-input", [], null, [[null, "blurEvent"], [null, "keydown"], [null, "paste"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("paste" === en)) {
        var pd_1 = (i1.ɵnov(_v, 15).onPaste($event) !== false);
        ad = (pd_1 && ad);
    } if (("blurEvent" === en)) {
        var pd_2 = (_co.blurInput($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i8.View_InputComponent_0, i8.RenderType_InputComponent)), i1.ɵdid(15, 114688, null, 0, i9.InputComponent, [i10.InputStore, i11.DOCUMENT, i1.ElementRef], { control: [0, "control"], width: [1, "width"], hint: [2, "hint"], class: [3, "class"] }, { blurEvent: "blurEvent" }), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_CodeComponent_1)), i1.ɵdid(17, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_CodeComponent_2)), i1.ɵdid(19, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CodeComponent_3)), i1.ɵdid(21, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CodeComponent_4)), i1.ɵdid(23, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(24, 0, null, null, 5, "div", [["class", "signup-btn-div"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUnlock($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 4, "app-button", [], null, null, null, i12.View_ButtonComponent_0, i12.RenderType_ButtonComponent)), i1.ɵdid(26, 49152, null, 0, i13.ButtonComponent, [i14.ButtonStore], { class: [0, "class"], loading: [1, "loading"] }, null), (_l()(), i1.ɵeld(27, 0, null, 0, 2, "app-text", [], null, null, null, i5.View_TextComponent_0, i5.RenderType_TextComponent)), i1.ɵdid(28, 114688, null, 0, i6.TextComponent, [i7.TextStore], { class: [0, "class"], loading: [1, "loading"] }, null), (_l()(), i1.ɵted(29, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "signup-header"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "signup-text5"; _ck(_v, 7, 0, currVal_1); var currVal_2 = "signup-label"; _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.form.get("code"); var currVal_4 = "100%"; var currVal_5 = "Enter the code given by your school"; var currVal_6 = _co.codeClass; _ck(_v, 15, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = ((!_co.form.get("code").errors && _co.uTick) || (_co.clickBtn && _co.appliedCode)); _ck(_v, 17, 0, currVal_7); var currVal_8 = ((_co.form.get("code").errors && _co.clickBtn) || ((_co.clickBtn && _co.errText) && !_co.appliedCode)); _ck(_v, 19, 0, currVal_8); var currVal_9 = ((_co.errText && !_co.appliedCode) && _co.clickBtn); _ck(_v, 21, 0, currVal_9); var currVal_10 = ((!_co.errText && _co.appliedCode) && _co.clickBtn); _ck(_v, 23, 0, currVal_10); var currVal_11 = "btn-orange"; var currVal_12 = _co.pleasewait; _ck(_v, 26, 0, currVal_11, currVal_12); var currVal_13 = "btn-text-style2"; var currVal_14 = _co.pleasewait; _ck(_v, 28, 0, currVal_13, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_15 = (_co.pleasewait ? "Please wait..." : _co.btnText); _ck(_v, 29, 0, currVal_15); }); }
export function View_CodeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-code", [], null, null, null, View_CodeComponent_0, RenderType_CodeComponent)), i1.ɵdid(1, 638976, null, 0, i15.CodeComponent, [i16.FormBuilder, i17.BusinessRequirementsInjectionToken, i18.CodeStore, i19.AuthService, i20.Router, i20.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CodeComponentNgFactory = i1.ɵccf("app-code", i15.CodeComponent, View_CodeComponent_Host_0, { schoolName: "schoolName", errText: "errText", appliedCode: "appliedCode" }, { discountCode: "discountCode" }, []);
export { CodeComponentNgFactory as CodeComponentNgFactory };
