import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";


@Component({
  selector: 'app-email-success',
  templateUrl: './email-success.component.html',
  styleUrls: ['./email-success.component.css']
})
export class EmailSuccessComponent implements OnInit {
  @Input() parentData;
  @Input() success;
  @Output() tryAgainEmail = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
   document.getElementsByTagName("body")[0].style.overflow = "auto";
  }

  tryAgain(){
    this.tryAgainEmail.emit();
  }

}
