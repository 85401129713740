import { Inject, Component, HostListener } from '@angular/core';
import { BusinessLogicRequirements, BusinessRequirementsInjectionToken } from '../business-logic.requirements';
import { ActivatedRoute, Router } from "@angular/router";
import { style } from '@angular/animations';
import { AuthService } from 'src/app/lib/auth.service';
import * as moment from 'moment';
import { SharedService } from '../../../lib/shared-service';
import { SubscriptionPlanModel } from 'src/app/models/subscription-plan.model';
import { SchoolDiscountCodeModel } from 'src/app/models/school-discount-code.model';

@Component({
  selector: 'app-plans-controller',
  templateUrl: './school-plans-controller.component.html',
  styleUrls: ['./school-plans-controller.component.css']
})
export class SchoolPlansControllerComponent {
  constructor(
    @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements,
    private router: Router,
    private route: ActivatedRoute,
    private _authService: AuthService,
    private _sharedService: SharedService,
    public shared: SharedService
  ) { }

  public planList: any = [];
  public noramlTick='assets/images/normal-tick.svg'
  public selectedTick='assets/images/selected-tick.svg'
  public currentTick='assets/images/current-tick.svg'
  public cross='assets/images/alert-icon.svg'
  public selectedBg="assets/images/selected-bg.svg"
  public hoverBg="assets/images/hover-bg.svg"
  public selectedPlan = '';
  public selectedPlanId = null;
  public selectedPrice = 0;
  public selectedPriceOriginal = '';
  public selectedPriceDiscounted = '';
  public selectedSlot = null;
  public currentSlot = null;

  // public currentPlanId = null;
  // public currentPlan:any;
  public currentPlanObj=new SubscriptionPlanModel();
  public parentData:any;
  public isUpgrade = null;
  public giftFlag = false;
  public giftCode = '';

  public date = new Date();
  public momentToday = moment(this.date);
  public momentNext;
  public invalidCode:boolean = false;
  public loading: boolean = true;
  public expiryDate:any;

  public paymentType = '';
  public isEmpty=false;

  public mobileView;
  public mobileViewService;
  public mobilePlan;
  public unsubscribe;

  public discountInfo:any;
  public trialDays;
  public trialDaysText;

  @HostListener('window:resize',['$event'])
  onResize(e){
    this.renderUiForCurrentPlan();
  }


  ngOnInit() {
    this.mobileViewService = this.shared.GetMobileView.subscribe(data=>{
      this.mobileView = data;
    });
    this.discountInfo = this.shared.getSchoolDiscountInfo();
    if(!this.discountInfo) this.router.navigateByUrl('/school/signup')
    this.getPlanList();


  }

  ngOnDestroy(){
    this.mobileViewService.unsubscribe();
  }

  dashboard() {
    this.router.navigateByUrl(`/dashboard`);
  }

  continue() {
    const isAuth = this._authService.isAuthenticated();
    if(this.selectedPlanId && !isAuth) this.router.navigateByUrl(`/school-plans/${this.selectedPlanId}`);
    else if(this.selectedPlanId && isAuth) this.router.navigateByUrl(`/school-plans/${this.selectedPlanId}`+'?discountcode='+this.shared.getSchoolDiscountCode());
  }

  getPlanList(){
    this.loading = true;
    console.log('get plan')
    this.business.getPlanList().subscribe(
      (data) => {
        console.log(data);
        this.planList = data;
        this.trialDays = this.planList[0].trialDays;
        if (this.trialDays == 14){
          this.trialDaysText = 'fourteen';
        }else if (this.trialDays == 7){
          this.trialDaysText = 'seven';
        }else {
          this.trialDaysText = '';
        }

        // this.momentToday.add(365+14, 'days');
        this.momentToday.add(this.trialDays, 'days');
        this.momentNext = this.momentToday.format();

        this.loading = false;
        if(!this.currentPlanObj.planId){
          this.selectedPlan = this.mobilePlan = this.planList[0].planName;
          this.selectedPlanId = this.planList[0].planId;
        }
        this.selectedPrice = this.planList[0].planYear; //default price
        console.log(this.selectedPrice,'selectedPrice . . . . . . . . . ')
        // this.selectedPriceOriginal = this.planList[0].planYear; //default price
        // this.selectedPriceDiscounted = this.plan
        // this.checkSubscribed(this.planList);
        setTimeout(() => {
          if(!this.mobileView) this.renderUiForCurrentPlan();
        }, 500);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  headerHover(price,rect,value){
    var height = document.getElementsByClassName('table')[0].clientHeight-85;
    var width = document.getElementById(price).clientWidth;
    document.getElementById(rect).style.height = height + 'px';
    document.getElementById(rect).style.width = width + 'px';
    document.getElementById(rect).style.display = 'block';
    document.getElementById(value).style.background = 'url(assets/images/hover-bg.svg)';
    document.getElementById(value).style.backgroundRepeat = 'no-repeat';
    document.getElementById(value).style.backgroundPositionX = 'center';
  }

  headerClicked(price,rect,value){
    this.planList.map(plan=>{if(plan.planName==value)
    this.selectedPrice = plan.planYear
    })
    var height = document.getElementsByClassName('table')[0].clientHeight-85;
    var width = document.getElementById(price).clientWidth;
    document.getElementById(rect).style.height = height + 'px';
    document.getElementById(rect).style.width = width + 'px';
    document.getElementById(rect).style.display = 'block';
    document.getElementById(value).style.background = 'url(assets/images/selected-bg.svg)';
    document.getElementById(value).style.backgroundRepeat = 'no-repeat';
    document.getElementById(value).style.backgroundPositionX = 'center';
  }

  headerNormal(rect,value){
    document.getElementById(rect).style.height = 0 + 'px';
    document.getElementById(rect).style.width = 0 + 'px';
    document.getElementById(rect).style.display = '';
    console.log(document.getElementById(value).style.background)
    document.getElementById(value).style.background = '';
    document.getElementById(value).style.backgroundRepeat = '';
  }

  headerEnter(value){
    if(value == 'Solo' && this.selectedPlan != 'Solo' && this.currentPlanObj.planName != 'Solo'){
      this.headerHover('solo-price','solo-rect',value);
    }else if(value == 'Duo' && this.selectedPlan != 'Duo' && this.currentPlanObj.planName != 'Duo'){
      this.headerHover('duo-price','duo-rect',value);
    }else if(value == 'Family' && this.selectedPlan != 'Family' && this.currentPlanObj.planName != 'Family'){
      this.headerHover('family-price','family-rect',value);
    }else{
      console.log('Something wrong!!!')
    }
  }

  headerClick(value){
    if(!this.mobileView) this.clearSelected(value)
    this.selectedPlan = value;
    this.mobilePlan = value;
    console.log(this.selectedPlan)
    console.log(this.currentPlanObj.planName)
    if(!this.mobileView){
      if(this.currentPlanObj.planName)
      localStorage.setItem('isUpgrade',this.currentPlanObj.planName);
    if(value == 'Solo'){
      this.headerClicked('solo-price','solo-rect',value);
    }else if(value == 'Duo'){
      this.headerClicked('duo-price','duo-rect',value);
    }else if(value == 'Family'){
      this.headerClicked('family-price','family-rect',value);
    }else{
      console.log('Something wrong!!!')
    }
    }

  }

  headerLeave(value){
    if(value == 'Solo' && this.selectedPlan != 'Solo' && this.currentPlanObj.planName != 'Solo'){
      this.headerNormal('solo-rect',value);
    }else if(value == 'Duo' && this.selectedPlan != 'Duo' && this.currentPlanObj.planName != 'Duo'){
      this.headerNormal('duo-rect',value);
    }else if(value == 'Family' && this.selectedPlan != 'Family' && this.currentPlanObj.planName != 'Family'){
      this.headerNormal('family-rect',value);
    }else{
      console.log('Something wrong!!!')
    }
  }

  showDetail(planName,planId,planPrice,playerSlot){
    if(planName != this.currentPlanObj.planName){
      this.selectedPlanId=planId;
      this.selectedPrice = planPrice;
      this.selectedSlot = playerSlot;
      console.log('you select '+planName+' plan')
      this.headerClick(planName);
    }
  }

  clearSelected(value){
    console.log('clear')
    if(!this.currentPlanObj.planName){
      switch(value){
        case 'Solo' : {
          this.headerNormal('duo-rect','Duo');
          this.headerNormal('family-rect','Family');
        }
        break;
        case 'Duo' : {
          this.headerNormal('solo-rect','Solo');
          this.headerNormal('family-rect','Family');
        }
        break;
        case 'Family' : {
          this.headerNormal('duo-rect','Duo');
          this.headerNormal('solo-rect','Solo');
        }
        break;
        default : {
          this.headerNormal('duo-rect','Duo');
          this.headerNormal('family-rect','Family');
        }
      }
    }else{
      switch(value){
        case 'Solo' : {
          if(this.currentPlanObj.planName != 'Duo')
            this.headerNormal('duo-rect','Duo');
          else
          this.headerNormal('family-rect','Family');
        }
        break;
        case 'Duo' : {
          if(this.currentPlanObj.planName != 'Solo')
            this.headerNormal('solo-rect','Solo');
          else
            this.headerNormal('family-rect','Family');
        }
        break;
        case 'Family' : {
          if(this.currentPlanObj.planName != 'Duo')
            this.headerNormal('duo-rect','Duo');
          else
            this.headerNormal('solo-rect','Solo');
        }
        break;
        default : {
          if(this.currentPlanObj.planName != 'Duo')
            this.headerNormal('duo-rect','Duo');
          else
          this.headerNormal('family-rect','Family');
        }
      }
    }
  }

  backPage(){
    this.router.navigateByUrl('/parent/detail');
  }

  openProfile(event){
    this.router.navigateByUrl('/parent/detail');
  }


  renderUiForCurrentPlan(){
    console.log(this.currentPlanObj.planName,this.currentPlanObj.planId)
    if(this.currentPlanObj.planName && this.currentPlanObj.planId){
      document.getElementById(this.currentPlanObj.planName).style.background = 'url(assets/images/hover-bg.svg)';
      document.getElementById(this.currentPlanObj.planName).style.backgroundRepeat = 'no-repeat';
      document.getElementById(this.currentPlanObj.planName).style.backgroundPositionX = 'center';
    }else{
      this.showDetail(this.planList[0].planName,this.planList[0].planId,this.planList[0].planYear,this.planList[0].playerSlot);
    }
  }

  //getting info for profile
  public userInfo: any;
  // callParentDetail() {
  //   this.business.getParentDetail(this.userInfo.userId).subscribe(
  //     (res) => {
  //       console.log("detail detail ", res[0]);
  //       this.parentData = res[0];
  //       console.log(this.parentData.metadata.vendor)
  //       localStorage.setItem("parentDetailObj", this._authService.encrypt(JSON.stringify(this.parentData),null));

  //       if(this.parentData.metadata.vendor){
  //         if(!this.currentPlanObj.planId) this.momentNext = this.momentToday.format();
  //         else this.momentNext = moment(this.expiryDate);
  //       }else{
  //         if((this.paymentType == 'trial' && !this.currentPlanObj.planId) || this.isEmpty){
  //           this.momentToday.add(this.trialDays, 'days');
  //           this.momentNext = this.momentToday.format();
  //         }else if(this.paymentType == 'trial' && this.currentPlanObj.planId){
  //           this.momentNext = moment(this.expiryDate);
  //         }else if(this.paymentType != 'trial' && this.currentPlanObj.planId){
  //           // this.momentNext = this.momentToday.format();
  //           //full stop
  //         }
  //       }

  //       console.log("next pay date", this.momentNext);

  //     },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }



}
