import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import { SubscriptionMapper } from "../mappers/subscription-plans-mapper";
@Injectable({
    providedIn: 'root'
})
export class GetPromoInfoUseCase {
    constructor(
      private mapper: SubscriptionMapper,
      @Inject(DataRequirementsInjectionToken) private data: DataRequirements
  ) {}

  run(tag) {
      // console.log('usecase', this.data.getPlanList().pipe(this.mapper.map))
      return this.data.getPromoInfo(tag).pipe(this.mapper.map);
      // return this.data.getPromoInfo(tag);
      //return this.data.getGAuth(url);
  }
}
