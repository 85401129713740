/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./plan-card.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../global-components/text/text.component.ngfactory";
import * as i4 from "../../../../../global-components/text/text.component";
import * as i5 from "../../../../../global-components/text/text.store";
import * as i6 from "./plan-card.component";
var styles_PlanCardComponent = [i0.styles];
var RenderType_PlanCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PlanCardComponent, data: {} });
export { RenderType_PlanCardComponent as RenderType_PlanCardComponent };
function View_PlanCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "plan-card bg-s0 d-flex flex-column cursor mb-2 text-c300"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickCard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "body-l font-weight-bold mb-1 d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "radio-box mr-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-text", [], null, null, null, i3.View_TextComponent_0, i3.RenderType_TextComponent)), i1.ɵdid(6, 114688, null, 0, i4.TextComponent, [i5.TextStore], null, null), (_l()(), i1.ɵted(7, 0, ["A gift for a parent with ", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "light-body-m text-c200 d-flex align-items-baseline ml-36"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "app-text", [], null, null, null, i3.View_TextComponent_0, i3.RenderType_TextComponent)), i1.ɵdid(10, 114688, null, 0, i4.TextComponent, [i5.TextStore], null, null), (_l()(), i1.ɵted(11, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "plan-card bg-s0 d-flex flex-column cursor mb-2 text-c300"; var currVal_1 = (_co.plan.selected ? "selected-card bg-accent500 text-s0" : "non-selected-card"); _ck(_v, 2, 0, currVal_0, currVal_1); _ck(_v, 6, 0); _ck(_v, 10, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.plan.playerSlot > 1) ? (_co.plan.playerSlot + " kids") : (_co.plan.playerSlot + " kid")); _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.plan.planName; _ck(_v, 11, 0, currVal_3); }); }
export function View_PlanCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanCardComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.plan; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PlanCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-plan-card", [], null, null, null, View_PlanCardComponent_0, RenderType_PlanCardComponent)), i1.ɵdid(1, 49152, null, 0, i6.PlanCardComponent, [], null, null)], null, null); }
var PlanCardComponentNgFactory = i1.ɵccf("app-plan-card", i6.PlanCardComponent, View_PlanCardComponent_Host_0, { plan: "plan" }, { clickCard: "clickCard" }, []);
export { PlanCardComponentNgFactory as PlanCardComponentNgFactory };
