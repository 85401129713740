/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./delete-popup.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../button/button.component.ngfactory";
import * as i4 from "../button/button.component";
import * as i5 from "../button/button.store";
import * as i6 from "../text/text.component.ngfactory";
import * as i7 from "../text/text.component";
import * as i8 from "../text/text.store";
import * as i9 from "./delete-popup.component";
var styles_DeletePopupComponent = [i0.styles];
var RenderType_DeletePopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeletePopupComponent, data: {} });
export { RenderType_DeletePopupComponent as RenderType_DeletePopupComponent };
export function View_DeletePopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 27, "div", [["class", "overlay d-flex justify-content-center align-items-baseline"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 26, "div", [["class", "outer-frame d-flex flex-column bg-s0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "d-flex justify-content-between"], ["style", "margin-bottom: 32px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "head-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirm delete?"])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "img", [["class", "close-btn"], ["src", "../../assets/images/close.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDelete($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(8, { "cursor-disable": 0 }), (_l()(), i1.ɵeld(9, 0, null, null, 18, "div", [["class", "inner-frame d-flex align-item-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "warning"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Are you sure you want to delete "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "player-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["'s account? Deleting an account cannot be undone. "])), (_l()(), i1.ɵeld(15, 0, null, null, 5, "div", [["class", "btn-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 4, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deletePlayer($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i1.ɵdid(17, 49152, null, 0, i4.ButtonComponent, [i5.ButtonStore], { class: [0, "class"], loading: [1, "loading"] }, null), (_l()(), i1.ɵeld(18, 0, null, 0, 2, "app-text", [], null, null, null, i6.View_TextComponent_0, i6.RenderType_TextComponent)), i1.ɵdid(19, 114688, null, 0, i7.TextComponent, [i8.TextStore], { class: [0, "class"], loading: [1, "loading"] }, null), (_l()(), i1.ɵted(20, 0, [" ", " "])), (_l()(), i1.ɵeld(21, 0, null, null, 6, "div", [["class", "d-flex justify-content-center align-item-center"], ["style", "cursor: pointer;width: 100%;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDelete($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 5, "app-text", [], null, null, null, i6.View_TextComponent_0, i6.RenderType_TextComponent)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(24, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(25, { "btn-disable": 0, "btn-cancel2": 1 }), i1.ɵdid(26, 114688, null, 0, i7.TextComponent, [i8.TextStore], { class: [0, "class"], loading: [1, "loading"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "close-btn"; var currVal_1 = _ck(_v, 8, 0, _co.pleaseWait); _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_3 = "btn-red"; var currVal_4 = _co.pleaseWait; _ck(_v, 17, 0, currVal_3, currVal_4); var currVal_5 = "btn-text-style2"; var currVal_6 = _co.pleaseWait; _ck(_v, 19, 0, currVal_5, currVal_6); var currVal_8 = "btn-text-cancel2"; var currVal_9 = _ck(_v, 25, 0, _co.pleaseWait, !_co.pleaseWait); _ck(_v, 24, 0, currVal_8, currVal_9); var currVal_10 = "btn-text-cancel2"; var currVal_11 = _co.pleaseWait; _ck(_v, 26, 0, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.playerDetail.fullName; _ck(_v, 13, 0, currVal_2); var currVal_7 = (_co.pleaseWait ? "Deleting..." : "Delete account"); _ck(_v, 20, 0, currVal_7); }); }
export function View_DeletePopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-delete-popup", [], null, null, null, View_DeletePopupComponent_0, RenderType_DeletePopupComponent)), i1.ɵdid(1, 245760, null, 0, i9.DeletePopupComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeletePopupComponentNgFactory = i1.ɵccf("app-delete-popup", i9.DeletePopupComponent, View_DeletePopupComponent_Host_0, { playerDetail: "playerDetail" }, { closeBtn: "closeBtn", deletePlayerBtn: "deletePlayerBtn" }, []);
export { DeletePopupComponentNgFactory as DeletePopupComponentNgFactory };
