import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DropDownSearchStore {

}

export enum DropDownSearchStoreKeys {

}
