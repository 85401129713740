import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import {GiftCardDetailMapper} from '../mappers/gift-card-detail.mapper';

@Injectable({
    providedIn: 'root'
})
export class GiftCardDetailsUseCase {
    constructor(
        private mapper: GiftCardDetailMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run(id) {
      return this.data.getGiftCardDetail(id).pipe(this.mapper.map)
    }
}
