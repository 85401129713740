import { DataRequirements, } from "../data.requirements";
import * as i0 from "@angular/core";
import * as i1 from "../data.requirements";
export class CreatePlayerUseCase {
    constructor(data) {
        this.data = data;
    }
    run(userData) {
        return this.data.createPlayer(userData);
    }
}
CreatePlayerUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreatePlayerUseCase_Factory() { return new CreatePlayerUseCase(i0.ɵɵinject(i1.DataRequirementsInjectionToken)); }, token: CreatePlayerUseCase, providedIn: "root" });
