import {DataRequirements} from '../business-logic/data.requirements';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserApiService } from './api-services/user-api.service';

@NgModule({
    imports: [HttpClientModule]
})
export class DataFacade implements DataRequirements {
  constructor(private userApi: UserApiService) {}
  registerParent(parentData): Observable<any> {
    // return of(true);
    return this.userApi.parentSignup(parentData);
  }
  loginParent(): Observable<any> {
    // return of(true);
    return this.userApi.parentLogin();
  }
  getCountryList(): Observable<any> {
    return this.userApi.getCountries();
  }
}
