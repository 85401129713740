import { DataRequirements, } from "../data.requirements";
import * as i0 from "@angular/core";
import * as i1 from "../mappers/subscription-plans-mapper";
import * as i2 from "../data.requirements";
export class SubscriptionPlansUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run() {
        return this.data.checkPlan().pipe(this.mapper.map);
        // return this.data.checkPlan();
        // return of (true);
    }
}
SubscriptionPlansUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubscriptionPlansUseCase_Factory() { return new SubscriptionPlansUseCase(i0.ɵɵinject(i1.SubscriptionMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: SubscriptionPlansUseCase, providedIn: "root" });
