import { BusinessLogicRequirements } from '../presentation/business-logic.requirements';
import { NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { DataRequirementsInjectionToken } from './data.requirements';
import { DataFacade } from '../data/data.facade';
import { GetPlanListUsecase } from './use-cases/getPlanList.usecase';
import { GetSubscriptionUsecase } from './use-cases/getSubscription.usecase';
import { SubscribeUserUseCase } from './use-cases/subscribe-user.use-case';
import { SubscriptionMapper } from './mappers/subscription-plans-mapper';
import { GetPlanUsecase } from './use-cases/get-plan.usecase';
import { PlayerDetailMapper } from './mappers/player-detail-mapper';
import { GetPlayerDetailUseCase } from './use-cases/get-player-detail.use-case';
import { GetGiftInfoUsecase } from './use-cases/getGiftInfo.usecase';
import { CheckEmptySubscriptionUsecase } from './use-cases/check-empty-subscription.usecas';
import { GiftcardMapper } from './mappers/giftcard-info.mapper';
import { GetPlayersUseCase } from './use-cases/get-players.use-case';
import { PlayerListMapper } from './mappers/player-list.mapper';
import { ClaimGiftCardUsecase } from './use-cases/claimGiftCard.usecase';
import { GetParentDetailUseCase } from './use-cases/get-parent-detail.use-case';
import { GetPromoInfoUseCase } from './use-cases/get-promo-info.use-case';
import { PublishKeyUseCase } from 'src/app/parent-detail/business-logic/use-cases/publish-key.use-case';
import { SubscribeUserMapper } from './mappers/subscribe-user-mapper';
import { ParentDetailMapper } from './mappers/parent-detail.mapper';

@NgModule({
  imports: [DataFacade],

  providers: [
    {
      provide: DataRequirementsInjectionToken,
      useClass: DataFacade
    },
    SubscribeUserMapper,
    SubscriptionMapper,
    PlayerDetailMapper,
    GiftcardMapper,
    PlayerListMapper,
    ParentDetailMapper
  ]
})
export class BusinessLogicFacade implements BusinessLogicRequirements {
  constructor(
    private getPlanListUseCase: GetPlanListUsecase,
    private getSubscriptionUseCase: GetSubscriptionUsecase,
    private SubscribeUserUseCase: SubscribeUserUseCase,
    private publishKeyUseCase: PublishKeyUseCase,
    private getPlanUseCase: GetPlanUsecase,
    private getPlayerDetailUseCase: GetPlayerDetailUseCase,
    private getGiftInfoUseCase: GetGiftInfoUsecase,
    private claimGiftCardUseCase: ClaimGiftCardUsecase,
    private checkEmptySubUseCase: CheckEmptySubscriptionUsecase,
    private getPlayersUseCase: GetPlayersUseCase,
    private getParentDetailUseCase: GetParentDetailUseCase,
    private getPromoInfoUseCase: GetPromoInfoUseCase
  ) {}

  getPlanList() {
    return this.getPlanListUseCase.run();
  }
  getPromoInfo(tag) {
    return this.getPromoInfoUseCase.run(tag);
  }
  getSubscription() {
    return this.getSubscriptionUseCase.run();
  }
  subscribeUser(data): Observable<any> {
    return this.SubscribeUserUseCase.run(data);
  }
  getPublishKey(): Observable<any> {
    return this.publishKeyUseCase.run();
  }
  getPlan(id): Observable<any> {
    return this.getPlanUseCase.run(id);
  }
  getPlyerDetail(id): Observable<any> {
    return this.getPlayerDetailUseCase.run(id);
  }
  getGiftInfo(id): Observable<any> {
    return this.getGiftInfoUseCase.run(id);
  }
  claimGiftCard(id, opt, redir): Observable<any> {
    return this.claimGiftCardUseCase.run(id, opt, redir);
  }
  checkEmptySub(): Observable<any> {
    return this.checkEmptySubUseCase.run();
  }
  getPlayers(id): Observable<any> {
    return this.getPlayersUseCase.run(id);
  }
  getParentDetail(id): Observable<any> {
    return this.getParentDetailUseCase.run(id);
  }
}
