import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ConfirmStore} from './confirm.store'

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent {
    @Input() selectedZone;
    @Output() redirectZone = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();
    constructor(private store: ConfirmStore) {}

    ngOnInit(){
      let body = (document.getElementsByTagName("body")[0].style.overflow =
    "hidden");
  window.scrollTo(0, 0);
    }

    ngOnDestroy() {
      let body = (document.getElementsByTagName("body")[0].style.overflow =
        "auto");
    }

    goZone(zone){
        this.redirectZone.emit(zone);
    }
    back(){
        this.close.emit();
    }
}
