import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/giftcard-info.mapper";
import * as i2 from "../data.requirements";
export class ClaimGiftCardUsecase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(id, opt, redir) {
        console.log('usecase');
        return this.data.claimGiftCard(id, opt, redir);
        //return this.data.getGAuth(url);
    }
}
ClaimGiftCardUsecase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClaimGiftCardUsecase_Factory() { return new ClaimGiftCardUsecase(i0.ɵɵinject(i1.GiftcardMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: ClaimGiftCardUsecase, providedIn: "root" });
