<div
  *ngIf="boxType != 'undefined' && boxType != undefined"
  [ngClass]="{
    'top-div':
      boxType == 'afterNormal' ||
      boxType == 'afterFromStart' ||
      boxType == 'afterUnsubscribe' ||
      boxType == 'afterGiftcard',
    'blue-div': boxType == 'beforeUnsubscribe' || boxType == 'beforeFromStart' || boxType == 'beforeGiftcard'
  }"
>
  <div
    [ngClass]="{
      'red-title2': boxType == 'afterNormal' || boxType == 'afterUnsubscribe' || boxType == 'afterGiftcard',
      'red-title': boxType == 'afterFromStart',
      'blue-title': boxType == 'beforeUnsubscribe' || boxType == 'beforeFromStart' || boxType == 'beforeGiftcard'
    }"
  >
    <app-text *ngIf="boxType == 'afterNormal'">Update your payment information to continue</app-text>
    <app-text *ngIf="boxType == 'afterUnsubscribe' || boxType == 'beforeUnsubscribe'">You have unsubscribed</app-text>
    <app-text *ngIf="boxType == 'afterFromStart'">Your trial is expired</app-text>
    <app-text *ngIf="boxType == 'beforeFromStart'">You have no active subscription.</app-text>
    <app-text *ngIf="boxType == 'beforeGiftcard'">Your giftcard subscription will be expired soon</app-text>
    <app-text *ngIf="boxType == 'afterGiftcard'">Your giftcard subscription is expired</app-text>
  </div>
  <div
    [ngClass]="{
      unable:
        boxType == 'afterNormal' ||
        boxType == 'afterFromStart' ||
        boxType == 'afterUnsubscribe' ||
        boxType == 'afterGiftcard',
      'blue-body': boxType == 'beforeUnsubscribe' || boxType == 'beforeFromStart' || boxType == 'beforeGiftcard'
    }"
  >
    <app-text *ngIf="boxType == 'afterNormal'"
      >We were unable to process your last payment. Please update your payment information to continue enjoying
      STEMWerkz.</app-text
    >
    <app-text *ngIf="boxType == 'afterUnsubscribe' || boxType == 'afterGiftcard'"
      >Your subscription has ended. You can subscribe to the previous plan again or change to a new plan.</app-text
    >
    <app-text *ngIf="boxType == 'afterFromStart'"
      >Your subscription has ended. Please subscribe to continue enjoying STEMWerkz.</app-text
    >
    <app-text *ngIf="boxType == 'beforeUnsubscribe' || boxType == 'beforeGiftcard'"
      >Your subscription will be cancelled at the end of your current billing period on
      <app-text style="font-weight: bold"
        >{{ currentPlan.userSubInfo.expiryDate | date: 'd MMM yyyy' }}.</app-text
      ></app-text
    >
    <app-text *ngIf="boxType == 'beforeFromStart'"
      >Your trial will be ended on
      <app-text style="font-weight: bold"
        >{{ currentPlan.userSubInfo.expiryDate | date: 'd MMM yyyy' }}.</app-text
      ></app-text
    >
  </div>
  <div class="btn-div long" (click)="clickedBoxBtn()">
    <app-button
      [class]="
        boxType == 'afterNormal' ||
        boxType == 'afterFromStart' ||
        boxType == 'afterUnsubscribe' ||
        boxType == 'afterGiftcard'
          ? 'btn-red'
          : 'btn-blue'
      "
    >
      <app-text [class]="'btn-text-style2'" *ngIf="boxType == 'afterNormal'">Update payment</app-text>
      <app-text [class]="'btn-text-style2'" *ngIf="boxType == 'afterUnsubscribe'">Manage subscription</app-text>
      <app-text [class]="'btn-text-style2'" *ngIf="boxType == 'afterFromStart' || boxType == 'beforeFromStart'"
        >Subscribe</app-text
      >
      <app-text
        [class]="'btn-text-style2'"
        *ngIf="boxType == 'beforeUnsubscribe' || boxType == 'beforeGiftcard' || boxType == 'afterGiftcard'"
        >Resubscribe</app-text
      >
    </app-button>
  </div>
</div>
