import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalComponentsModule } from '../../../global-components/global-components.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { ParentDetailHomeControllerComponent } from './parent-detail-home-controller.component';
// import {FeatherModule} from 'angular-feather';
import { RouterModule } from '@angular/router';
import { PlayerInfoComponent } from './components/player-info/player-info.component';
import { PaymentInfoComponent } from './components/payment-info/payment-info.component';
import { SubscriptionHistoryComponent } from './components/subscription-history/subscription-history.component';
import { PaymentHistoryComponent } from './components/payment-history/payment-history.component';
import { WarningboxComponent } from './components/warningbox/warningbox.component';
import { MailToComponent } from './components/mail-to/mail-to.component';
@NgModule({
  declarations: [
    ParentDetailHomeControllerComponent,
    PlayerInfoComponent,
    PaymentInfoComponent,
    SubscriptionHistoryComponent,
    PaymentHistoryComponent,
    WarningboxComponent,
    MailToComponent
  ],
  exports: [ParentDetailHomeControllerComponent],
  imports: [CommonModule, RouterModule, GlobalComponentsModule, SharedComponentsModule]
})
export class ParentDetailHomeModule {}
