import { DataRequirements } from '../business-logic/data.requirements';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { PlayerApiService } from './api-services/player-api.service';
import { ParentApiService } from './api-services/parent-api.service';
import { PlanApiService } from './api-services/plan-api.service';

@NgModule({
  imports: [HttpClientModule]
})
export class DataFacade implements DataRequirements {
  constructor(
    private playerApi: PlayerApiService,
    private parentApiService: ParentApiService,
    private planApiService: PlanApiService
  ) {}
  getPlayers(id): Observable<any> {
    return this.playerApi.getUser(id);
  }
  createPlayer(userData): Observable<any> {
    return this.playerApi.createUser(userData);
  }
  getParentDetail(parentId): Observable<any> {
    return this.parentApiService.getParent(parentId);
  }
  editParent(parentId, parentData): Observable<any> {
    return this.parentApiService.editParent(parentId, parentData);
  }
  checkPlan(): Observable<any> {
    return this.parentApiService.checkPlan();
  }
  getPlayerDetail(playerId): Observable<any> {
    return this.playerApi.getPlayer(playerId);
  }
  deletePlayer(playerId): Observable<any> {
    return this.playerApi.deletePlayer(playerId);
  }
  getPlan(id): Observable<any> {
    return this.planApiService.getPlan(id);
  }
  resubscribePlan(uid, sid): Observable<any> {
    return this.planApiService.resubscribePlan(uid, sid);
  }
  emailCopy(id, pw): Observable<any> {
    return this.parentApiService.emailCopy(id, pw);
  }
  changePayment(data): Observable<any> {
    return this.planApiService.updatePayment(data);
  }
}
