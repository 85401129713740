import * as tslib_1 from "tslib";
import { BusinessLogicRequirements } from '../business-logic.requirements';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { loadStripe } from '@stripe/stripe-js';
export class SignupControllerComponent {
    constructor(fb, business, store, _authService, router, // private parentData: ParentModel
    route, shared) {
        this.fb = fb;
        this.business = business;
        this.store = store;
        this._authService = _authService;
        this.router = router;
        this.route = route;
        this.shared = shared;
        //   public form: FormGroup;
        this.env = environment;
        this.emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.width = '100%';
        this.alertURL = './assets/images/alert-icon.svg';
        this.pwdlengthErr = false;
        this.clickBtn = false;
        this.sameUser = false;
        this.togglePassword = 'password';
        this.show = true;
        this.hide = false;
        this.pleasewait = false;
        this.btnStatus = 'btn-orange';
        this.tickURL = './assets/images/tick-icon.svg';
        this.fNameClass = 'signup-input';
        this.lNameClass = 'signup-input';
        this.emailClass = 'signup-input';
        this.usernameClass = 'signup-input';
        this.passwordClass = 'signup-input';
        this.fTick = false;
        this.lTick = false;
        this.uTick = false;
        this.eTick = false;
        this.pTick = false;
        this.discount = '';
        this.giftCode = '';
        this.disableKeyboard = false;
        this.openCountry = false;
        this.form = this.fb.group({
            firstName: this.fb.control('', [Validators.required]),
            lastName: this.fb.control('', [Validators.required]),
            username: this.fb.control('', [Validators.required]),
            email: this.fb.control('', [Validators.required, Validators.pattern(this.emailPattern)]),
            password: this.fb.control('', [Validators.required, Validators.minLength(8)]),
            country: this.fb.control('', [Validators.required])
        });
        this.planId = '';
        this.skip = '';
        this.countriesList = null;
        this.isShowCountryBox = false;
        this.vendor = null;
        this.isPromo = false;
        this.keyword = '';
        this.userupdate = '';
    }
    ngOnInit() {
        this.route.queryParams.subscribe((data) => {
            console.log('queryParmas data ', data);
            if (data.planId)
                this.planId = data.planId;
            console.log(this.planId);
            this.discount = data.discountcode;
            if (data.giftcode)
                this.giftCode = data.giftcode;
            if (data.skip)
                this.skip = data.skip;
            if (data.vendor) {
                this.vendor = data.vendor;
                if (this.vendor && this.vendor.slice(0, 6) == 'PROMO_')
                    this.isPromo = true;
                else
                    this.isPromo = false;
            }
            if (data.userupdate)
                this.userupdate = data.userupdate;
        });
        this.form.valueChanges.subscribe((value) => {
            this.tmpData = value;
            console.log(this.tmpData);
        });
        this.form.get('firstName').valueChanges.subscribe(() => {
            this.fTick = true;
            this.clearFormErrors();
        });
        this.form.get('lastName').valueChanges.subscribe(() => {
            this.lTick = true;
            this.clearFormErrors();
        });
        this.form.get('username').valueChanges.subscribe(() => {
            this.uTick = true;
            this.clearFormErrors();
        });
        this.form.get('email').valueChanges.subscribe(() => {
            this.eTick = true;
            this.clearFormErrors();
        });
        this.form.get('password').valueChanges.subscribe(() => {
            this.pTick = true;
            this.clearFormErrors();
        });
        this.form.get('country').valueChanges.subscribe(() => {
            if (this.isShowCountryBox) {
                this.keyword = this.form.get('country').value;
            }
        });
        this.getCountry();
    }
    clearFormErrors() {
        this.fNameClass = 'signup-input';
        this.lNameClass = 'signup-input';
        this.emailClass = 'signup-input';
        this.usernameClass = 'signup-input';
        this.passwordClass = 'signup-input';
        this.clickBtn = false;
        this.sameUser = false;
    }
    getCountry() {
        this.business.getCountryList().subscribe((res) => {
            this.countriesList = res[0].countries;
            this.form.controls['country'].setValue(res[0].currentCountry);
            this.currentCountry = res[0].currentCountry;
        });
    }
    chosenCountry(value) {
        this.selectedCountry = value;
    }
    // searchCountry(){
    //   this.countriesList = this.countriesList.filter(country =>
    //     country.name.includes(this.keyword)
    //   );
    //   // console.log(countriesList)
    // }
    ngOnChanges() {
        if (this.onClickLogin) {
            this.showLogin(true);
        }
    }
    blurInput($event) {
        console.log('get blur event');
        this.fTick = false;
        this.lTick = false;
        this.uTick = false;
        this.eTick = false;
        this.pTick = false;
    }
    showPassword($event) {
        this.togglePassword = 'text';
        this.show = false;
        this.hide = true;
    }
    hidePassword($event) {
        this.togglePassword = 'password';
        this.show = true;
        this.hide = false;
    }
    showLogin($event) {
        console.log('showLogin');
        // environment.initState = "login";
        if (this.discount != '' && this.planId)
            this.router.navigateByUrl(`/login?planId=` + this.planId + `&discountcode=` + this.discount);
        else if (this.giftCode != '' && this.skip == 'true')
            this.router.navigateByUrl(`/login?&giftcode=` + this.giftCode + '&skip=true');
        else if (this.giftCode != '' && this.skip == 'false')
            this.router.navigateByUrl(`/login?&giftcode=` + this.giftCode + '&skip=false');
        else if (this.planId)
            this.router.navigateByUrl(`/login?planId=` + this.planId);
        else
            this.router.navigateByUrl(`/login`);
    }
    termsOfService($event) {
        console.log('termsOfService');
        // https://www.stemwerkz.org/end-user-agreement
        window.open('https://www.stemwerkz.org/end-user-agreement', '_blank');
    }
    privacyPolicy($event) {
        window.open('https://www.stemwerkz.org/end-user-agreement', '_blank');
    }
    onSignup($event) {
        this.pleasewait = true;
        this.btnStatus = 'btn-orange waiting not-allowed';
        this.clickBtn = true;
        this.fTick = false;
        this.lTick = false;
        this.uTick = false;
        this.eTick = false;
        this.pTick = false;
        if (this.form.valid) {
            const getPwd = this.tmpData['password'];
            console.log(getPwd);
            const encryptedPwd = btoa(this.tmpData['password']);
            console.log('encrypted ', encryptedPwd);
            let parentData = {
                firstName: this.tmpData['firstName'].trim(),
                lastName: this.tmpData['lastName'].trim(),
                email: this.tmpData['email'],
                username: this.tmpData['username'].trim(),
                password: encryptedPwd,
                usertype: 5,
                country: this.selectedCountry ? this.selectedCountry : this.currentCountry
            };
            console.log('parentData ');
            if (localStorage.getItem('ref'))
                parentData['referer'] = localStorage.getItem('ref');
            if (this.vendor != null) {
                //vendor exist
                parentData['vendor'] = this.vendor;
                if (this.isPromo) {
                    parentData['redirectURL'] = window.origin + '/dashboard';
                    this.signupApi(parentData);
                }
                else {
                    this.signupApi(parentData);
                }
            }
            else {
                //no vendor
                if (this.userupdate)
                    this.updateUser(parentData);
                else
                    this.signupApi(parentData);
            }
        }
        else {
            console.log('missing form', this.form);
            this.pleasewait = false;
            this.btnStatus = 'btn-orange';
            if (this.form.get('firstName').errors) {
                this.fNameClass += ' input-err';
                console.log(this.fNameClass);
            }
            if (this.form.get('lastName').errors) {
                this.lNameClass += ' input-err';
                console.log(this.lNameClass);
            }
            if (this.form.get('email').errors) {
                this.emailClass += ' input-err';
                console.log(this.emailClass);
            }
            if (this.form.get('username').errors) {
                this.usernameClass += ' input-err';
                console.log(this.usernameClass);
            }
            if (this.form.get('password').errors) {
                this.passwordClass += ' input-err';
                console.log(this.passwordClass);
            }
        }
    }
    signupApi(parentData) {
        console.log('old');
        this.business.signupParent(parentData).subscribe((status) => {
            console.log('status ', status);
            if (status.message == 'Success') {
                const parentObject = {
                    username: this.tmpData['username'],
                    email: this.tmpData['email'],
                    password: this.tmpData['password']
                };
                localStorage.removeItem('ref');
                // localStorage.setItem("parentObject", JSON.stringify(parentObject));
                localStorage.setItem('parentObject', this._authService.encrypt(JSON.stringify(parentObject), null));
                this.business.loginParent().subscribe((res) => {
                    console.log(res);
                    localStorage.setItem('authData', this._authService.encrypt(JSON.stringify(res), null));
                    if (this.discount != '' && this.planId && this.giftCode == '')
                        this.router.navigateByUrl(`/plans/` + this.planId + `?discountcode=` + this.discount);
                    else if (this.giftCode != '' && this.skip == 'true')
                        this.router.navigateByUrl(`/claim-giftcard?giftcode=` + this.giftCode + '&skip=true');
                    else if (this.giftCode != '' && this.skip == 'false')
                        this.router.navigateByUrl(`/claim-giftcard?giftcode=` + this.giftCode + '&skip=false');
                    else if (this.planId)
                        this.router.navigateByUrl(`/plans/` + this.planId);
                    else
                        this.router.navigateByUrl(`/plans`);
                }, (err) => {
                    console.log(err);
                });
            }
        }, (err) => {
            this.pleasewait = false;
            this.btnStatus = 'btn-orange';
            console.log('err ', err);
            console.log(err.error.message);
            this.shared.toastForError(err.error.message, '');
            if (err.error.message == '[Pagewerkz] There is already an account with this username. If this is you log in now.') {
                console.log('if');
                this.sameUser = true;
                this.usernameClass = 'input-err';
            }
        });
    }
    signupApiNew(parentData) {
        // parentData['vendor'] = this.vendor;
        console.log(parentData);
        this.business.signupParentNew(parentData).subscribe((status) => {
            console.log('status ', status, status.message);
            this.sessionId = status.sessionId;
            if (status.message == 'Success') {
                const parentObject = {
                    username: this.tmpData['username'],
                    email: this.tmpData['email'],
                    password: this.tmpData['password']
                };
                // localStorage.setItem("parentObject", JSON.stringify(parentObject));
                localStorage.setItem('parentObject', this._authService.encrypt(JSON.stringify(parentObject), null));
                this.business.loginParent().subscribe((res) => {
                    console.log(res);
                    localStorage.setItem('authData', this._authService.encrypt(JSON.stringify(res), null));
                    // this.paymentCheckout();
                    this.goToStripe();
                    // if(this.discount != '' && this.planId && this.giftCode == '')
                    //   this.router.navigateByUrl(`/plans/`+this.planId+`?discountcode=`+this.discount);
                    // else if(this.giftCode != '' && this.skip == 'true')
                    //   this.router.navigateByUrl(`/claim-giftcard?giftcode=`+this.giftCode+"&skip=true");
                    // else if(this.giftCode != '' && this.skip == 'false')
                    // this.router.navigateByUrl(`/claim-giftcard?giftcode=`+this.giftCode+"&skip=false");
                    // else if(this.planId)
                    //   this.router.navigateByUrl(`/plans/`+this.planId);
                    // else
                    //   this.router.navigateByUrl(`/plans`);
                }, (err) => {
                    console.log(err);
                });
            }
        }, (err) => {
            this.pleasewait = false;
            this.btnStatus = 'btn-orange';
            console.log('err ', err);
            console.log(err.error.message);
            this.shared.toastForError(err.error.message, '');
            if (err.error.message == '[Pagewerkz] There is already an account with this username. If this is you log in now.') {
                console.log('if');
                this.sameUser = true;
                this.usernameClass = 'input-err';
            }
        });
    }
    chooseCountry(name) {
        this.form.controls['country'].setValue(name);
        this.selectedCountry = name;
        this.keyword = '';
    }
    enterEvent(e) {
        if (this.openCountry)
            return;
        else
            this.onSignup(e);
    }
    isOpenCountry(value) {
        this.openCountry = value == 'true' ? true : false;
    }
    onClick(e) {
        var target = e.target;
        console.log(target);
        if (!target.closest('.country-select-input')) {
            this.isShowCountryBox = false;
        }
        else {
            this.isShowCountryBox = !this.isShowCountryBox;
        }
        if (!this.isShowCountryBox && !this.selectedCountry) {
            this.form.controls['country'].setValue(this.currentCountry);
            this.keyword = '';
        }
        else if (this.isShowCountryBox) {
            document.getElementById('country-select-input').focus();
            console.log('focus');
        }
    }
    goToStripe() {
        this.business.getPublishKey().subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(res, res[0]);
            this.publish_key = res[0].code;
            console.log('I got publish key', this.publish_key);
            const stripe = yield loadStripe(this.publish_key);
            stripe
                .redirectToCheckout({
                sessionId: this.sessionId
            })
                .then(function (result) {
                console.log(result);
            });
        }));
    }
    updateUser(parentData) {
        console.log(this._authService.getUserInfo());
        parentData['registeredFrom'] = 'stemwerkz-start';
        this.business.updateUser(parentData).subscribe((data) => {
            console.log(data);
            const parentObject = {
                username: this.tmpData['username'],
                email: this.tmpData['email'],
                password: this.tmpData['password']
            };
            localStorage.removeItem('ref');
            // localStorage.setItem("parentObject", JSON.stringify(parentObject));
            localStorage.setItem('parentObject', this._authService.encrypt(JSON.stringify(parentObject), null));
            this.business.loginParent().subscribe((res) => {
                console.log(res);
                localStorage.setItem('authData', this._authService.encrypt(JSON.stringify(res), null));
                this.router.navigateByUrl(`/plans`);
            }, (err) => {
                this.pleasewait = false;
                this.btnStatus = 'btn-orange';
                console.log('err ', err);
                console.log(err.error.message);
                this.shared.toastForError(err.error.message, '');
                if (err.error.message ==
                    '[Pagewerkz] There is already an account with this username. If this is you log in now.') {
                    console.log('if');
                    this.sameUser = true;
                    this.usernameClass = 'input-err';
                }
            });
        }, (err) => {
            this.pleasewait = false;
            this.btnStatus = 'btn-orange';
            console.log('err ', err);
            console.log(err.error.message);
            this.shared.toastForError(err.error.message, '');
            if (err.error.message == '[Pagewerkz] There is already an account with this username. If this is you log in now.') {
                console.log('if');
                this.sameUser = true;
                this.usernameClass = 'input-err';
            }
        });
    }
    onDown(e) {
        if (this.isShowCountryBox && e.keyCode == 40) {
            //down
            $('.country-list:not(:last-child).country-list-hover')
                .removeClass('country-list-hover')
                .next()
                .addClass('country-list-hover');
            var top;
            var el = document.querySelector('.country-list-hover');
            if (el instanceof HTMLElement)
                top = el.offsetTop;
            var height = document.querySelector('.country-box').clientHeight;
            console.log(top, 144, top - height);
            $('.country-box').scrollTop(0);
            $('.country-box').scrollTop(top - 163);
        }
        else if (this.isShowCountryBox && e.keyCode == 38) {
            //up
            $('.country-list:not(:first-child).country-list-hover')
                .removeClass('country-list-hover')
                .prev()
                .addClass('country-list-hover');
            var top;
            var el = document.querySelector('.country-list-hover');
            if (el instanceof HTMLElement)
                top = el.offsetTop;
            var height = document.querySelector('.country-box').clientHeight;
            console.log(top, 144, top + height);
            $('.country-box').scrollTop(0);
            $('.country-box').scrollTop(top - 163);
        }
    }
}
