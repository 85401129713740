import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-order-card',
    templateUrl: './order-card.component.html',
    styleUrls: ['./order-card.component.css']
})
export class OrderCardComponent {
  @Input() OrderSummary;
  @Input() plan;

}
