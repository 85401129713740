import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../../../global-components/global-components.module';
import {SharedComponentsModule} from '../shared-components/shared-components.module';
import {GiftcardPurchaseProcessingControllerComponent} from './giftcard-purchase-processing-controller.component';
import {RouterModule} from '@angular/router';
import {GiftSuccessComponent} from './components/gift-success/gift-success.component';
import {GiftFailComponent} from './components/gift-fail/gift-fail.component';
import { from } from 'rxjs';

@NgModule({
    declarations: [
        GiftcardPurchaseProcessingControllerComponent,
        GiftSuccessComponent,
        GiftFailComponent
    ],
    exports: [
        GiftcardPurchaseProcessingControllerComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GlobalComponentsModule,
        SharedComponentsModule,
    ]
})
export class GiftcardPurchaseProcessingModule {}
