import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-delete-popup",
  templateUrl: "./delete-popup.component.html",
  styleUrls: ["./delete-popup.component.css"],
})
export class DeletePopupComponent implements OnInit {
  @Output() closeBtn = new EventEmitter<any>();
  @Output() deletePlayerBtn = new EventEmitter<any>();
  @Input() playerDetail;
  public pleaseWait = false;
  public btnStatus = "btn-red";

  constructor() {}

  ngOnInit() {
    console.log("kkkkkk", this.playerDetail);

    let body = (document.getElementsByTagName("body")[0].style.overflow =
    "hidden");
  window.scrollTo(0, 0);
  }
  ngOnDestroy() {
    let body = (document.getElementsByTagName("body")[0].style.overflow =
      "auto");
  }
  closeDelete($event) {
    if(!this.pleaseWait)
      this.closeBtn.emit($event);
  }
  deletePlayer($event) {
    this.pleaseWait = true;
    this.btnStatus = "btn-red waiting not-allowed"
    console.log("this is delete");
    this.deletePlayerBtn.emit();
  }
}
