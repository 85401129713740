import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { AuthService } from "src/app/lib/auth.service";
import { Router } from "@angular/router";
import { map } from 'rxjs/operators';
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserApiService {
  private baseUrl = environment.apiurl;
  private nodmaUrl = environment.nodmaApi;
  private userInfo = {
    username: '',
    password: '',
    api_key: ''
  }
  constructor(
    private http: HttpClient,
    private _router: Router,
    private _authService: AuthService
  ) {
    this.userInfo = this._authService.getUserInfo();
    // this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    console.log("School DataFacade",this.userInfo)
  }
  parentSignup(parentData) {
    // const usersFromLocalStorage = this.getUsersLS()

    // usersFromLocalStorage.push({...user, imageURL: 'https://cdn1.iconfinder.com/data/icons/social-media-vol-1-1/24/_github-512.png'})

    // localStorage.setItem('users', JSON.stringify(usersFromLocalStorage))
    let url = this.baseUrl + "/register";
    return this.http.post(url, parentData).pipe(
      map((res: Response) => {
        let result = res;
        // console.log(res);
        return result;
      })
    );

    // return of(true);
  }
  parentLogin() {
    // const usersFromLocalStorage = this.getUsersLS()

    // usersFromLocalStorage.push({...user, imageURL: 'https://cdn1.iconfinder.com/data/icons/social-media-vol-1-1/24/_github-512.png'})

    // localStorage.setItem('users', JSON.stringify(usersFromLocalStorage))
    let url = this.baseUrl + "/login";
    return this.http.get(url).pipe(
      map((res: Response) => {
        let result = res;
        // console.log(res);
        return result;
      })
    );

    // return of(true);
  }

  getCountries() {
    console.log('hello--->')

    let url = this.nodmaUrl + '/api/v1/country';
    return this.http.get(url).pipe(
      map((res: Response) => {
        let result = [res];
        return result;
      })
    );
  }
}
