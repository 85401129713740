export class GiftCardDetailModel {
  "_id":string = "5fc5fc37a08a9b212c5027ce";
  "posterImageUrl":string = "https://brainlitz-dev.s3.amazonaws.com/temp/posters/gift-card-1.png";
  "message":string = "testing....";
  "amount":number = 2;
  "recipientName":string = "HeiN 2";
  "recipientEmail":string = "heinlinaung@amdon.com";
  "senderName":string = "HeiN 1";
  "senderEmail":string = "heinlinaung@amdon.com";
  "planId":string = "5f5e30933cab921ec3a3cb2e";
  "planName":string = "Solo";
  "planInterval":string = "month";
  "playerSlot":number = 1;
}
