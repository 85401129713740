import { Inject, Injectable } from '@angular/core';
import { DataRequirementsInjectionToken, DataRequirements } from '../data.requirements';
import { SubscriptionMapper } from '../mappers/subscription-plans-mapper';

@Injectable({
  providedIn: 'root'
})
export class GetSubscriptionUsecase {
  constructor(
    @Inject(DataRequirementsInjectionToken) private data: DataRequirements,
    private mapper: SubscriptionMapper
  ) {}

  run() {
    console.log('usecase');
    return this.data.getSubscription().pipe(this.mapper.map);
  }
}
