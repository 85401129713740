import { Inject, Component, HostListener, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/lib/auth.service';
import { SharedService } from 'src/app/lib/shared-service';
import { ParentModel } from 'src/app/models/parent.model';
import { environment } from 'src/environments/environment';
import { BusinessLogicRequirements, BusinessRequirementsInjectionToken } from '../business-logic.requirements';
import { SignupStore } from './signup.store';

@Component({
  selector: 'app-signup',
  templateUrl: './signup-controller.component.html',
  styleUrls: ['./signup-controller.component.css']
})
export class SignupControllerComponent {
  //   public form: FormGroup;
  private env = environment;
  private emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public width = '100%';
  public tmpData: Observable<ParentModel>;
  public alertURL = './assets/images/alert-icon.svg';
  public pwdlengthErr = false;
  public clickBtn = false;
  public sameUser = false;
  public togglePassword: string = 'password';
  public show = true;
  public hide = false;
  public pleasewait = false;
  public btnStatus = 'btn-orange';
  public tickURL = './assets/images/tick-icon.svg';
  public fNameClass = 'signup-input';
  public lNameClass = 'signup-input';
  public emailClass = 'signup-input';
  public usernameClass = 'signup-input';
  public passwordClass = 'signup-input';
  public fTick = false;
  public lTick = false;
  public uTick = false;
  public eTick = false;
  public pTick = false;
  //  public discount = '';
  public schooldiscount = '';
  public disableKeyboard = false;
  public currentCountry;
  public openCountry = false;
  @Input() onClickLogin;

  public schoolName;

  constructor(
    private fb: FormBuilder,
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private store: SignupStore,
    private _authService: AuthService,
    private router: Router, // private parentData: ParentModel
    private route: ActivatedRoute,
    private _shared: SharedService
  ) {}

  public form = this.fb.group({
    firstName: this.fb.control('', [Validators.required]),
    lastName: this.fb.control('', [Validators.required]),
    username: this.fb.control('', [Validators.required]),
    email: this.fb.control('', [Validators.required, Validators.pattern(this.emailPattern)]),
    password: this.fb.control('', [Validators.required, Validators.minLength(8)]),
    country: this.fb.control('', [Validators.required])
  });

  public planId = '';
  public countriesList = null;
  public isShowCountryBox = false;
  public selectedCountry;
  public keyword = '';

  ngOnInit() {
    this.schoolName = this._shared.getSchoolDiscountInfo().name;
    this.route.queryParams.subscribe((data) => {
      console.log('queryParmas data ', data);
      if (data.planId) this.planId = data.planId;
      console.log(this.planId);
      if (data.schooldiscountcode) this.schooldiscount = data.schooldiscountcode;
    });
    this.form.valueChanges.subscribe((value) => {
      this.tmpData = value;
      console.log(this.tmpData);
    });
    this.form.get('firstName').valueChanges.subscribe(() => {
      this.fTick = true;
      this.clearFormErrors();
    });
    this.form.get('lastName').valueChanges.subscribe(() => {
      this.lTick = true;
      this.clearFormErrors();
    });
    this.form.get('username').valueChanges.subscribe(() => {
      this.uTick = true;
      this.clearFormErrors();
    });
    this.form.get('email').valueChanges.subscribe(() => {
      this.eTick = true;
      this.clearFormErrors();
    });
    this.form.get('password').valueChanges.subscribe(() => {
      this.pTick = true;
      this.clearFormErrors();
    });
    this.form.get('country').valueChanges.subscribe(() => {
      if (this.isShowCountryBox) {
        this.keyword = this.form.get('country').value;
      }
    });
    this.getCountry();
  }

  clearFormErrors() {
    this.fNameClass = 'signup-input';
    this.lNameClass = 'signup-input';
    this.emailClass = 'signup-input';
    this.usernameClass = 'signup-input';
    this.passwordClass = 'signup-input';
    this.clickBtn = false;
    this.sameUser = false;
  }

  getCountry() {
    this.business.getCountryList().subscribe((res) => {
      this.countriesList = res[0].countries;
      this.form.controls['country'].setValue(res[0].currentCountry);
      this.currentCountry = res[0].currentCountry;
    });
  }

  chosenCountry(value) {
    this.selectedCountry = value;
  }

  ngOnChanges() {
    if (this.onClickLogin) {
      this.showLogin(true);
    }
  }

  blurInput($event) {
    console.log('get blur event');
    this.fTick = false;
    this.lTick = false;
    this.uTick = false;
    this.eTick = false;
    this.pTick = false;
  }

  showPassword($event) {
    this.togglePassword = 'text';
    this.show = false;
    this.hide = true;
  }
  hidePassword($event) {
    this.togglePassword = 'password';
    this.show = true;
    this.hide = false;
  }
  showLogin($event) {
    console.log('showLogin');
    this.router.navigateByUrl(`/school-login?planId=` + this.planId + `&discountcode=` + this.schooldiscount);
  }
  termsOfService($event) {
    console.log('termsOfService');
    window.open('https://www.stemwerkz.org/end-user-agreement', '_blank');
  }
  privacyPolicy($event) {
    window.open('https://www.stemwerkz.org/end-user-agreement', '_blank');
  }

  onSignup($event) {
    this.pleasewait = true;
    this.btnStatus = 'btn-orange waiting not-allowed';
    this.clickBtn = true;
    this.fTick = false;
    this.lTick = false;
    this.uTick = false;
    this.eTick = false;
    this.pTick = false;
    if (this.form.valid) {
      const getPwd = this.tmpData['password'];
      console.log(getPwd);
      const encryptedPwd = btoa(this.tmpData['password']);
      console.log('encrypted ', encryptedPwd);

      let parentData = {
        firstName: this.tmpData['firstName'].trim(),
        lastName: this.tmpData['lastName'].trim(),
        email: this.tmpData['email'],
        username: this.tmpData['username'].trim(),
        password: encryptedPwd,
        usertype: 5,
        country: this.selectedCountry ? this.selectedCountry : this.currentCountry
      };
      console.log('parentData ', localStorage.getItem('ref'));
      if (localStorage.getItem('ref')) parentData['referer'] = localStorage.getItem('ref');
      this.signupApi(parentData);
    } else {
      console.log('missing form', this.form);
      this.pleasewait = false;
      this.btnStatus = 'btn-orange';

      if (this.form.get('firstName').errors) {
        this.fNameClass += ' input-err';
        console.log(this.fNameClass);
      }
      if (this.form.get('lastName').errors) {
        this.lNameClass += ' input-err';
        console.log(this.lNameClass);
      }
      if (this.form.get('email').errors) {
        this.emailClass += ' input-err';
        console.log(this.emailClass);
      }
      if (this.form.get('username').errors) {
        this.usernameClass += ' input-err';
        console.log(this.usernameClass);
      }
      if (this.form.get('password').errors) {
        this.passwordClass += ' input-err';
        console.log(this.passwordClass);
      }
    }
  }

  signupApi(parentData) {
    console.log('old');
    parentData['school'] = this.schoolName;
    parentData['vendor'] = this.schooldiscount;
    console.error('parentData', parentData);
    this.business.signupParent(parentData).subscribe(
      (status) => {
        console.log('status ', status, status.message);
        if (status.message == 'Success') {
          const parentObject = {
            username: this.tmpData['username'],
            email: this.tmpData['email'],
            password: this.tmpData['password']
          };
          localStorage.removeItem('ref');
          // localStorage.setItem("parentObject", JSON.stringify(parentObject));
          localStorage.setItem('parentObject', this._authService.encrypt(JSON.stringify(parentObject), null));
          this.business.loginParent().subscribe(
            (res) => {
              console.log(res);
              localStorage.setItem('authData', this._authService.encrypt(JSON.stringify(res), null));
              if (this.schooldiscount != '' && this.planId)
                this.router.navigateByUrl(`/school-plans/` + this.planId + `?discountcode=` + this.schooldiscount);
              else this.router.navigateByUrl('/dashboard');
            },
            (err) => {
              console.log(err);
            }
          );
        }
      },
      (err) => {
        this.pleasewait = false;
        this.btnStatus = 'btn-orange';
        console.log('err ', err);
        console.log(err.error.message);
        this._shared.toastForError(err.error.message, '');
        if (
          err.error.message == '[Pagewerkz] There is already an account with this username. If this is you log in now.'
        ) {
          console.log('if');
          this.sameUser = true;
          this.usernameClass = 'input-err';
        }
      }
    );
  }

  chooseCountry(name) {
    this.form.controls['country'].setValue(name);
    this.selectedCountry = name;
    this.keyword = '';
  }

  enterEvent(e) {
    if (this.openCountry) return;
    else this.onSignup(e);
  }

  isOpenCountry(value) {
    this.openCountry = value == 'true' ? true : false;
  }

  @HostListener('document:click', ['$event'])
  onClick(e) {
    var target = e.target;
    console.log(target);
    if (!target.closest('.country-select-input')) {
      this.isShowCountryBox = false;
    } else {
      this.isShowCountryBox = !this.isShowCountryBox;
    }
    if (!this.isShowCountryBox && !this.selectedCountry) {
      this.form.controls['country'].setValue(this.currentCountry);
      this.keyword = '';
    } else if (this.isShowCountryBox) {
      document.getElementById('country-select-input').focus();
      console.log('focus');
    }
  }

  @HostListener('keydown', ['$event'])
  onDown(e) {
    if (this.isShowCountryBox && e.keyCode == 40) {
      //down
      $('.country-list:not(:last-child).country-list-hover')
        .removeClass('country-list-hover')
        .next()
        .addClass('country-list-hover');
      var top;
      var el = document.querySelector('.country-list-hover');
      if (el instanceof HTMLElement) top = el.offsetTop;
      var height = document.querySelector('.country-box').clientHeight;
      console.log(top, 144, top - height);
      $('.country-box').scrollTop(0);
      $('.country-box').scrollTop(top - 163);
    } else if (this.isShowCountryBox && e.keyCode == 38) {
      //up
      $('.country-list:not(:first-child).country-list-hover')
        .removeClass('country-list-hover')
        .prev()
        .addClass('country-list-hover');
      var top;
      var el = document.querySelector('.country-list-hover');
      if (el instanceof HTMLElement) top = el.offsetTop;
      var height = document.querySelector('.country-box').clientHeight;
      console.log(top, 144, top + height);
      $('.country-box').scrollTop(0);
      $('.country-box').scrollTop(top - 163);
    }
  }
}
