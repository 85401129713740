import { DataRequirements, } from "../data.requirements";
import * as i0 from "@angular/core";
import * as i1 from "../mappers/player-detail-mapper";
import * as i2 from "../data.requirements";
// import { UserDetailModel } from "../../../models/user-detail.model";
export class GetPlayerDetailUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(playerId) {
        return this.data.getPlayerDetail(playerId).pipe(this.mapper.map);
    }
}
GetPlayerDetailUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetPlayerDetailUseCase_Factory() { return new GetPlayerDetailUseCase(i0.ɵɵinject(i1.PlayerDetailMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: GetPlayerDetailUseCase, providedIn: "root" });
