import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentInfoStore } from './payment-info.store';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.css']
})
export class PaymentInfoComponent implements OnInit {
  @Output() subscribeClick = new EventEmitter<any>();
  @Output() unsubscribeClick = new EventEmitter<any>();
  @Output() accountManagement = new EventEmitter<any>();
  @Output() planChange = new EventEmitter<any>();
  @Output() resubscribe = new EventEmitter<any>();
  @Output() showBlue = new EventEmitter<any>();
  @Output() showRed = new EventEmitter<any>();
  @Output() hideAll = new EventEmitter<any>();
  @Output() outputBoxType = new EventEmitter<any>();
  @Input() playerDetail;
  @Input() loading;
  @Input() player;
  @Input() currentPlan;
  @Input() onlyPaymentHistory = false;

  constructor(private store: PaymentInfoStore) {}
  subscription($event) {
    console.log('subscription');
    this.subscribeClick.emit($event);
  }
  cancel($event) {
    this.unsubscribeClick.emit($event);
  }

  ngOnInit() {
    console.log('current plan console ', this.currentPlan);
    console.log('payment info payment info', this.player);
    console.log('interval', this.currentPlan.interval);
  }
  changPlan() {
    this.planChange.emit();
  }
  changeAccount() {
    this.accountManagement.emit();
  }
  resubscribePlan($event) {
    this.resubscribe.emit($event);
  }
  blueDiv($event) {
    this.showBlue.emit($event);
    console.log('blue in payment-info');
  }
  redDiv() {
    this.showRed.emit();
    console.log('blue in payment-info');
  }
  clickedPayment() {
    this.onlyPaymentHistory = true;
    this.hideAll.emit();
  }
  boxTypeEmit(boxType) {
    this.outputBoxType.emit(boxType);
  }
}
