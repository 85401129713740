import {Component, EventEmitter, Input, Output} from '@angular/core';
import { Router } from '@angular/router';
import {PlayerInfoStore} from './player-info.store'

@Component({
    selector: 'app-player-info',
    templateUrl: './player-info.component.html',
    styleUrls: ['./player-info.component.css']
})
export class PlayerInfoComponent {
    constructor(private store: PlayerInfoStore,private router: Router) {}
    @Input() playerDetail;
    @Output() editPlayercall = new EventEmitter<any>();
    @Output() deletePlayercall = new EventEmitter<any>();
    @Output() resetPlayercall = new EventEmitter<any>();
    public colorCode;

    ngOnInit(){
      console.log("playerDetail", this.playerDetail);
    }
    editPlayer($event){
      console.log("step1");
      this.editPlayercall.emit($event);

    }

    deletePlayer($event){
      this.deletePlayercall.emit($event);
    }

    resetPlayer($event){
      this.resetPlayercall.emit($event);
    }
}
