import {Component, EventEmitter, HostListener, Inject, Input, Output} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/lib/auth.service';
import { ParentModel } from 'src/app/models/parent.model';
import { environment } from 'src/environments/environment';
import { BusinessLogicRequirements, BusinessRequirementsInjectionToken } from '../../../business-logic.requirements';
import {CodeStore} from './code.store'

@Component({
    selector: 'app-code',
    templateUrl: './code.component.html',
    styleUrls: ['./code.component.css']
})
export class CodeComponent {
 //   public form: FormGroup;
 private env = environment;
//  private emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
 public width = "100%";
 public alertURL = "./assets/images/alert-icon.svg";
 public clickBtn = false;
 public togglePassword: string = "password";
 public show = true;
 public hide = false;
 public pleasewait = false;
 public btnStatus = "btn-orange";
 public tickURL = "./assets/images/tick-icon.svg";
 public codeClass = "signup-input";

 public uTick = false;
//  public discount = '';
//  public giftCode = '';
 public disableKeyboard = false;
//  public currentCountry;
//  public openCountry = false;

 public btnText = 'Unlock school discount';

 @Input() schoolName;

 @Input() errText;
 @Input() appliedCode;
  @Output() discountCode = new EventEmitter<any> ();

 constructor(
   private fb: FormBuilder,
   @Inject(BusinessRequirementsInjectionToken)
   private business: BusinessLogicRequirements,
   private store: CodeStore,
   private _authService: AuthService,
   private router: Router, // private parentData: ParentModel
   private route: ActivatedRoute,
 ) {}

 public form = this.fb.group({
   code: this.fb.control("", [Validators.required])
 });

 ngOnInit() {
   this.form.valueChanges.subscribe((value) => {
    this.clearFormErrors();
   });
 }

 clearFormErrors(){
   this.codeClass = "signup-input";
   this.clickBtn = false;
  //  this.appliedCode = false;
   this.btnText = 'Unlock school discount';
  //  this.errText = '';
 }

 ngOnChanges() {
  //  if(this.onClickLogin) {
  //    this.showLogin(true);
  //  }
  console.log('changes',this.errText,this.appliedCode)
  if(this.errText){
    this.pleasewait = false;
  }
  if(!this.errText && this.appliedCode){
    console.log('second')
    this.btnText = 'Choose plan';
    this.pleasewait = false;
  }
 }

 blurInput($event) {
   console.log("get blur event");
   this.uTick = false;
 }

//  enterEvent(e){
//    console.log('enter event ££££££££££££££££')
//  }

 onUnlock($event) {
   this.pleasewait = true;
   this.btnStatus = "btn-orange waiting not-allowed";
   this.clickBtn = true;
   this.uTick = false;
  //  this.appliedCode = false;
   if (this.form.valid) {
    console.log('valid');
    if(!this.appliedCode || this.btnText == 'Unlock school discount') this.discountCode.emit(this.form.get('code').value.trim());
    else this.router.navigateByUrl('/school-plans');
   } else {
     console.log("missing form", this.form);
     this.pleasewait = false;
     this.btnStatus = "btn-orange";
     this.codeClass += " input-err";
   }
 }
}
