import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-input-box-card',
    templateUrl: './input-box-card.component.html',
    styleUrls: ['./input-box-card.component.css']
})
export class InputBoxCardComponent {
  @Input() title;
  @Input() note;

}
