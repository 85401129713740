import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface DataRequirements {
  editPlayer(playerId, playerData): Observable<any>;
  getPlyerDetail(id): Observable<any>;
  unsubscribeUser(data): Observable<any>;
  checkPlan(): Observable<any>;
  subscribeUser(data): Observable<any>;
  getPublishKey(): Observable<any>;
  changePayment(data): Observable<any>;
  resubscribePlan(uid, sid): Observable<any>;
  getCountries(): Observable<any>;
}

export const DataRequirementsInjectionToken = new InjectionToken<DataRequirements>('parent-detail Data Requirements');
