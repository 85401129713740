/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./signup-header.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../global-components/text/text.component.ngfactory";
import * as i3 from "../../../../../global-components/text/text.component";
import * as i4 from "../../../../../global-components/text/text.store";
import * as i5 from "./signup-header.component";
import * as i6 from "./signup-header.store";
import * as i7 from "@angular/router";
var styles_SignupHeaderComponent = [i0.styles];
var RenderType_SignupHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignupHeaderComponent, data: {} });
export { RenderType_SignupHeaderComponent as RenderType_SignupHeaderComponent };
export function View_SignupHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "signup-tap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-text", [["class", "desktop-view"]], null, [[null, "textClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("textClick" === en)) {
        var pd_0 = (_co.showLogin($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(2, 114688, null, 0, i3.TextComponent, [i4.TextStore], { class: [0, "class"] }, { textClick: "textClick" }), (_l()(), i1.ɵted(-1, 0, [" Log in "]))], function (_ck, _v) { var currVal_0 = "signup-text"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SignupHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-signup-header", [], null, null, null, View_SignupHeaderComponent_0, RenderType_SignupHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i5.SignupHeaderComponent, [i6.SignupHeaderStore, i7.Router], null, null)], null, null); }
var SignupHeaderComponentNgFactory = i1.ɵccf("app-signup-header", i5.SignupHeaderComponent, View_SignupHeaderComponent_Host_0, {}, { goLogin: "goLogin" }, []);
export { SignupHeaderComponentNgFactory as SignupHeaderComponentNgFactory };
