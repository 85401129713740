import {Component, EventEmitter, Input, Output} from '@angular/core';
import {InformationStore} from './information.store'

@Component({
    selector: 'app-information',
    templateUrl: './information.component.html',
    styleUrls: ['./information.component.css']
})
export class InformationComponent {
  constructor(private store: InformationStore) {}
}
