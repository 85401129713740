import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlayerDetailService {
  private baseUrl = environment.apiurl;
  private nodmaUrl = environment.nodmaApi;
  constructor(private http: HttpClient) {}
  getPlayer(playerId) {
    let url = this.baseUrl + '/api/v2/users/' + playerId;
    console.log(url);
    return this.http.get(url).pipe(
      map((res: Response) => {
        // let result = [];
        let result = res;
        console.log(result);
        return result;
      })
    );
  }
  editPlayer(playerId, playerData) {
    let url = this.baseUrl + '/api/v2/users/' + playerId;
    console.log(url);
    console.log(playerData);
    return this.http.put(url, playerData).pipe(
      map((res: Response) => {
        let result = [];
        result[0] = res;
        console.log(result);
        return result;
      })
    );
    // return of (true);
  }
  deletePlayer(playerId) {
    let url = this.baseUrl + '/api/v2/users/' + playerId;
    console.log(url);
    return this.http.delete(url).pipe(
      map((res: Response) => {
        let result = res;
        console.log(result);
        return result;
      })
    );
  }

  getCountries() {
    console.log('country--->');
    let url = this.nodmaUrl + '/api/v1/country';
    return this.http.get(url).pipe(
      map((res: Response) => {
        let result = [res];
        return result;
      })
    );
  }
}
