import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../../../global-components/global-components.module';
import {SharedComponentsModule} from '../shared-components/shared-components.module';
import {LoginControllerComponent} from './login-controller.component';
import {RouterModule} from '@angular/router';
import { LoginHeaderComponent } from './components/login-header/login-header.component';

@NgModule({
    declarations: [
        LoginControllerComponent,
        LoginHeaderComponent
    ],
    exports: [
        LoginControllerComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GlobalComponentsModule,
        SharedComponentsModule,
    ]
})
export class LoginModule {}
