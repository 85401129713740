import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../../../global-components/global-components.module';
import {SharedComponentsModule} from '../shared-components/shared-components.module';
import {CodeVerificationControllerComponent} from './code-verification-controller.component';
import {RouterModule} from '@angular/router';
import { InformationComponent } from './components/information/information.component';
import { CodeComponent } from './components/code/code.component';

@NgModule({
    declarations: [
        CodeVerificationControllerComponent,
        InformationComponent,
        CodeComponent
    ],
    exports: [
        CodeVerificationControllerComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GlobalComponentsModule,
        SharedComponentsModule,
    ]
})
export class CodeVerificationModule {}
