import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/parent-detail.mapper";
import * as i2 from "../data.requirements";
export class GetParentDetailUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run(id) {
        console.log('dude is here');
        return this.data.getParentDetail(id).pipe(this.mapper.map);
    }
}
GetParentDetailUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetParentDetailUseCase_Factory() { return new GetParentDetailUseCase(i0.ɵɵinject(i1.ParentDetailMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: GetParentDetailUseCase, providedIn: "root" });
