import {Inject, Injectable} from '@angular/core';
import { DataRequirementsInjectionToken, DataRequirements } from '../data.requirements';

@Injectable({
    providedIn: 'root'
})
export class GetSubscriptionUsecase {
    constructor(
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run() {
        console.log('usecase')
        return this.data.getSubscription()
        //return this.data.getGAuth(url);
    }
}
