import {
  Component,
  OnInit,
  Input,
  HostListener,
  Inject,
  NgModule,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { SharedService } from "src/app/lib/shared-service";
@Component({
  selector: "app-create-playerpopup",
  templateUrl: "./create-playerpopup.component.html",
  styleUrls: ["./create-playerpopup.component.css"],
})
export class CreatePlayerpopupComponent implements OnInit {
  @Output() closePlayerPopup = new EventEmitter<any>();
  @Output() createPlayerBtn = new EventEmitter<any>();
  @Input() errMsg;
  @Input() pleaseWait;
  // @Output() playerData = new EventEmitter<any>();

  private emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public playerData = {};
  public clickBtn = false;
  public alertURL = "./assets/images/alert-icon.svg";
  public show = true;
  public hide = false;
  public togglePassword: string = "password";
  // public pleaseWait = false;
  public tickURL = "./assets/images/tick-icon.svg";
  public fNameClass = "signup-input";
  public lNameClass = "signup-input";
  public emailClass = "signup-input";
  public passwordClass = "signup-input";
  public fTick = false;
  public lTick = false;
  public uTick = false;
  public eTick = false;
  public pTick = false;
  public isMobileView;
  public mobileViewService;
  public dobText;
  public dobUrl='./assets/images/chevron_down_l.svg';
  public showYearBox = false;
  public selectedYear;
  public yearOld;
  public yearList = [];
  public minAge = 3;
  public maxAge = 70;
  public date = new Date();
  public dobError = false;
  public selectedGender;
  public emptygender = false;

  constructor(private fb: FormBuilder, private shared: SharedService) {
    // this.isMobileView = this.shared.getMobileView();
  }

  public form = this.fb.group({
    firstName: this.fb.control("", [Validators.required]),
    lastName: this.fb.control("", [Validators.required]),
    username: this.fb.control("", [Validators.required]),
    year: this.fb.control("", []),
    email: this.fb.control("", [Validators.pattern(this.emailPattern)]),
    password: this.fb.control("", [
      Validators.required,
      Validators.minLength(8),
    ]),
  });
  public emailFlag: boolean = false;
  public keyword = '';

  ngOnInit() {
    this.getYearList();
    this.dobText = 'Select year';
    this.mobileViewService = this.shared.GetMobileView.subscribe(data=>{
      this.isMobileView = data;
    })
    let body = (document.getElementsByTagName("body")[0].style.overflow =
      "hidden");
    window.scrollTo(0, 0);
    this.form.valueChanges.subscribe((value) => {
      console.log(value);
      this.playerData = value;
      this.playerData['yearOfBirth'] = this.selectedYear ? this.selectedYear.toString() : '';
      this.clickBtn = false;
      this.errMsg = null;
      // this.pleaseWait = false;
      this.fNameClass = "signup-input";
      this.lNameClass = "signup-input";
      this.emailClass = "signup-input";
      this.passwordClass = "signup-input";
      this.emptygender = false;
    });
    this.form.get("firstName").valueChanges.subscribe(() => {
      this.fTick = true;
    });
    this.form.get("lastName").valueChanges.subscribe(() => {
      this.lTick = true;
    });
    this.form.get("username").valueChanges.subscribe(() => {
      this.uTick = true;
    });
    this.form.get("email").valueChanges.subscribe(() => {
      this.eTick = true;
    });
    this.form.get("password").valueChanges.subscribe(() => {
      this.pTick = true;
    });
    this.form.get("year").valueChanges.subscribe(() => {
      if(this.showYearBox) {
        this.keyword = this.form.get("year").value;
      }
    });
  }
  blurInput($event) {
    console.log("get blur event");
    this.fTick = false;
    this.lTick = false;
    this.uTick = false;
    this.eTick = false;
    this.pTick = false;
  }
  showPassword($event) {
    this.togglePassword = "text";
    this.show = false;
    this.hide = true;
  }
  hidePassword($event) {
    this.togglePassword = "password";
    this.show = true;
    this.hide = false;
  }
  ngOnDestroy() {
    let body = (document.getElementsByTagName("body")[0].style.overflow =
      "auto");
    this.mobileViewService.unsubscribe();
  }
  closePopup($event) {
    console.log("popup closed");
    if(!this.pleaseWait)
      this.closePlayerPopup.emit($event);
  }
  createPlayer($event) {
    // this.pleaseWait = true;
    this.clickBtn = true;
    this.fTick = false;
    this.lTick = false;
    this.uTick = false;
    this.eTick = false;
    this.pTick = false;
    const getEmail = this.playerData["email"];
    if (this.form.valid) {
      console.log('before',this.playerData)
      this.playerData['firstName'] = this.playerData['firstName'].trim();
      this.playerData['lastName'] = this.playerData['lastName'].trim();
      this.playerData['username'] = this.playerData['username'].trim();
      console.log('after',this.playerData)
      if (getEmail != null && getEmail.trim() == "") {
        delete this.playerData["email"];
      }
      console.log(this.dobText)
      if(this.selectedYear) this.playerData['yearOfBirth'] = this.selectedYear.toString();
      else delete this.playerData['yearOfBirth'];

      if (this.selectedGender) {
        this.playerData['gender'] = this.selectedGender;
        // this.createPlayerBtn.emit(this.playerData);
      }
      // else {
        // this.emptygender = true;
        this.createPlayerBtn.emit(this.playerData);
      // }

      // if(this.dobText !== 'Select year' && this.dobText !== '') this.createPlayerBtn.emit(this.playerData);
      // else this.dobError=true;
    } else {
      console.log("form error ");
      // this.pleaseWait = false;
      if (this.form.get("firstName").errors) {
        this.fNameClass = "input-err";
      }
      if (this.form.get("lastName").errors) {
        this.lNameClass = "input-err";
      }
      if (this.form.get("email").errors) {
        this.emailClass = "input-err";
      }
      if (this.form.get("password").errors) {
        this.passwordClass = "input-err";
      }
      if(!this.selectedYear){
        this.emptygender = true;
      }
    }
  }
  emailOption() {
      this.emailFlag = true;
  }

  choseYear(year){
    this.form.controls['year'].setValue(year);
    this.selectedYear = year;
    this.dobText = year;
    // var date = new Date();
    this.yearOld = this.date.getFullYear() - year;
    console.log(this.playerData);
    this.showYearBox = false;
    this.dobError = false;
    this.keyword = '';
  }

  selectYear(year){
    this.selectedYear = year;
    this.yearOld = this.date.getFullYear() - year;
  }

  public openYear = false;

  isOpenYear(value){
    this.openYear = value == 'true' ? true : false;
  }

  getYearList(){
    for(var i= this.minAge; i<= this.maxAge ; i++){
      this.yearList.push((this.date.getFullYear()-i).toString());
    }
    // this.yearList.reverse();
  }

  @HostListener('document:click',['$event'])
  onClick(e){
      var target = e.target;
      if (!target.closest(".dob-select")) {
        this.showYearBox = false;
      }else{
        this.showYearBox= !this.showYearBox;
      }
      if(!this.showYearBox && !this.selectedYear){
        // this.form.controls['year'].setValue('');
        this.keyword = '';
      }
  }

  @HostListener('keydown',['$event'])
  onDown(e){
    if(this.showYearBox && e.keyCode == 40) { //down
        $('.year-list:not(:last-child).year-list-hover').removeClass('year-list-hover').next().addClass('year-list-hover');
        var top;
        var el = document.querySelector('.year-list-hover')
        if(el instanceof HTMLElement) top = el.offsetTop - 359;
        var height = document.querySelector('.year-box').clientHeight;
        console.log(top,height,top-height)
        $('.year-box').scrollTop(0);
        $('.year-box').scrollTop(top-48);
    }else if(this.showYearBox && e.keyCode == 38) { //up
        $('.year-list:not(:first-child).year-list-hover').removeClass('year-list-hover').prev().addClass('year-list-hover');
        var top;
        var el = document.querySelector('.year-list-hover')
        if(el instanceof HTMLElement) top = el.offsetTop -359-48;
        var height = document.querySelector('.year-box').clientHeight;
        console.log(top,height,top-105)
        $('.year-box').scrollTop(0);
        $('.year-box').scrollTop(top);
    }
  }
  selectGenter(ret){
    console.log('return ',ret);
    this.selectedGender = ret;
    this.emptygender = false;
  }

}
