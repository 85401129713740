import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-feedback',
  templateUrl: './custom-feedback.component.html',
  styleUrls: ['./custom-feedback.component.css']
})
export class CustomFeedbackComponent implements OnInit {
  @Input() feedbackTitle = '';
  @Input() feedbackBody = '';
  @Input() feedbackBtn = {
    status: false,
    text: ''
  };
  @Input() tickIcon = true;
  @Output() dimissFeedbackBtn = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    console.log('custom');
  }

  dimissFeedback($event) {
    this.dimissFeedbackBtn.emit($event);
  }
}
