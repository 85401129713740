<div class="overlay d-flex justify-content-center align-items-center">
  <div class="d-flex discount" id="discount">
    <div class="title">Claim gift card</div>
    <div style="margin-bottom: 32px">
      <span class="label">Gift card code</span>
      <app-input
        [hint]="'XXXX-XXXX-XXXX'"
        [control]="form.get('giftCode')"
        [class]="'signup-input'"
        [width]="'100%'"
        [angularMask]="mask"
      >
        <app-image *ngIf="invalidCode" [src]="alertURL"></app-image>
      </app-input>

      <span class="invalid" *ngIf="invalidCode">Invalid gift card code.</span>
      <!-- <app-text
        *ngIf="showerr == true"
        [class]="'form-error-label'"
        >Invalid discount code
        </app-text> -->
    </div>
    <div>
      <!-- <app-button [class]="'btn-orange'" (click)="continueGift($event)">
          <app-text [class]="'btn-text-style2'">
            Continue
          </app-text>
        </app-button> -->
      <app-button [class]="'btn-orange'" [loading]="loading" (click)="continueGift($event)">
        <app-text [class]="'btn-text-style2'" [loading]="loading"> {{ loading ? 'Continue' : 'Continue' }} </app-text>
      </app-button>
      <div (click)="cancelGift($event)" style="width: 100%; margin-top: 24px">
        <app-text style="cursor: pointer" class="btn-cancel2" [class]="'btn-text-cancel2'">Cancel</app-text>
      </div>
      <!-- <div class="d-flex justify-content-center" style="width: 100%; margin-top: 24px;" (click)="cancelGift($event)">
          <app-text [ngClass]="{'btn-disable' : loading , 'btn-cancel2' : !loading} " [class]="'btn-text-cancel2'" [loading]="loading">Cancel</app-text>
          </div> -->
    </div>
  </div>
</div>
