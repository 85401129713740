import { DataRequirements, } from "../data.requirements";
import * as i0 from "@angular/core";
import * as i1 from "../data.requirements";
export class UnSubscribeUserUseCase {
    constructor(data) {
        this.data = data;
    }
    run(data) {
        return this.data.unsubscribeUser(data);
    }
}
UnSubscribeUserUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UnSubscribeUserUseCase_Factory() { return new UnSubscribeUserUseCase(i0.ɵɵinject(i1.DataRequirementsInjectionToken)); }, token: UnSubscribeUserUseCase, providedIn: "root" });
