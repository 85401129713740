/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input-box.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../global-components/text/text.component.ngfactory";
import * as i3 from "../../../../../global-components/text/text.component";
import * as i4 from "../../../../../global-components/text/text.store";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../global-components/input/input.component.ngfactory";
import * as i7 from "../../../../../global-components/input/input.component";
import * as i8 from "../../../../../global-components/input/input.store";
import * as i9 from "./input-box.component";
import * as i10 from "@angular/forms";
var styles_InputBoxComponent = [i0.styles];
var RenderType_InputBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputBoxComponent, data: {} });
export { RenderType_InputBoxComponent as RenderType_InputBoxComponent };
function View_InputBoxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-text", [["class", "h4-strong text-c300"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(1, 114688, null, 0, i3.TextComponent, [i4.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(-1, 0, ["*"]))], function (_ck, _v) { var currVal_0 = "h4-strong text-c300"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_InputBoxComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-text", [], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(1, 114688, null, 0, i3.TextComponent, [i4.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = "form-error-label"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.error; _ck(_v, 2, 0, currVal_1); }); }
export function View_InputBoxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "app-text", [["class", "h4-strong text-c300"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(3, 114688, null, 0, i3.TextComponent, [i4.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(4, 0, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputBoxComponent_1)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "app-text", [["class", "body-m text-c100"]], null, null, null, i2.View_TextComponent_0, i2.RenderType_TextComponent)), i1.ɵdid(8, 114688, null, 0, i3.TextComponent, [i4.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(9, 0, ["\u00A0", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-input", [], null, [[null, "blurEvent"], [null, "keydown"], [null, "paste"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("paste" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onPaste($event) !== false);
        ad = (pd_1 && ad);
    } if (("blurEvent" === en)) {
        var pd_2 = (_co.blurInput() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i6.View_InputComponent_0, i6.RenderType_InputComponent)), i1.ɵdid(11, 114688, null, 0, i7.InputComponent, [i8.InputStore, i5.DOCUMENT, i1.ElementRef], { multiline: [0, "multiline"], type: [1, "type"], control: [2, "control"], width: [3, "width"], hint: [4, "hint"], class: [5, "class"] }, { blurEvent: "blurEvent" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputBoxComponent_2)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "h4-strong text-c300"; _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.required; _ck(_v, 6, 0, currVal_2); var currVal_3 = "body-m text-c100"; _ck(_v, 8, 0, currVal_3); var currVal_5 = _co.textArea; var currVal_6 = _co.type; var currVal_7 = _co.form.get("value"); var currVal_8 = "100%"; var currVal_9 = _co.placeholder; var currVal_10 = (_co.textArea ? "textarea-input" : "signup-input"); _ck(_v, 11, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.error; _ck(_v, 13, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.inputLevel; _ck(_v, 4, 0, currVal_1); var currVal_4 = _co.note; _ck(_v, 9, 0, currVal_4); }); }
export function View_InputBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-input-box", [], null, null, null, View_InputBoxComponent_0, RenderType_InputBoxComponent)), i1.ɵdid(1, 573440, null, 0, i9.InputBoxComponent, [i10.FormBuilder], null, null)], null, null); }
var InputBoxComponentNgFactory = i1.ɵccf("app-input-box", i9.InputBoxComponent, View_InputBoxComponent_Host_0, { inputLevel: "inputLevel", note: "note", type: "type", required: "required", textArea: "textArea", error: "error", value: "value", placeholder: "placeholder" }, { onInputChange: "onInputChange" }, []);
export { InputBoxComponentNgFactory as InputBoxComponentNgFactory };
