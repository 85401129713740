import {GiftCardPosterModel} from '../../../models/gift-card-poster.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class GiftCardListMapper extends ItemMapper<GiftCardPosterModel> {
  protected mapFromItem(item): GiftCardPosterModel {
      console.log(item);
      let giftCardList:any = [];

      item.map(data => {
        const giftCardModel = new GiftCardPosterModel();
        giftCardModel._id = data._id;
        giftCardModel.posterImageUrl = data.posterImageUrl;
        giftCardModel.description = data.description;
        giftCardList.push(giftCardModel);
      })
      return giftCardList;
  }
}
