import {Component, Input, Output, EventEmitter, OnInit, AfterViewInit} from '@angular/core';

@Component({
    selector: 'app-poster-card-list',
    templateUrl: './poster-card-list.component.html',
    styleUrls: ['./poster-card-list.component.css']
})
export class PosterCardListComponent implements AfterViewInit {
  @Input() posterList;
  @Input() currentIndex;
  @Input() currentPlan;

  @Output() clickLeft = new EventEmitter<any>();
  @Output() clickRight = new EventEmitter<any>();
  @Output() clickImage = new EventEmitter<any>();

  public scrollWidth;
  public imageWidth;
  public isImgClick = false;

  onClickLeftButton() {
    this.isImgClick = false;
    this.clickLeft.emit(this.currentIndex);
  }

  onClickRighttButton() {
    this.isImgClick = false;
    this.clickRight.emit(this.currentIndex);
  }

  onClickImage(e) {
    this.isImgClick = true;
    this.clickImage.emit(e);
  }

  ngAfterViewInit(){
    var scrollBar = document.getElementById('image-scroll');
    console.log('scorll bar--->', scrollBar)

  }

  ngOnChanges() {
    var scrollBar = document.getElementById('image-scroll');
    this.scrollWidth = scrollBar? scrollBar.offsetWidth : null;
    var currentImg = document.getElementsByClassName('image-current');
    this.imageWidth = currentImg && currentImg[0]? currentImg[0].clientWidth + 20 : null;
    if(this.scrollWidth && this.imageWidth && !this.isImgClick){
      var visibleImgCount = parseInt((this.scrollWidth/this.imageWidth).toString());

      if(visibleImgCount > this.currentIndex) {
        scrollBar.scrollLeft = 0;
        console.log('scroll left 0')
      }else {
        scrollBar.scrollLeft = this.imageWidth * (this.currentIndex+1 - visibleImgCount);
        console.log('scroll left', (this.imageWidth * (this.currentIndex+1 - visibleImgCount)))
      }
    }
  }

}
