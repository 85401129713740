import { BusinessLogicRequirements } from '../presentation/business-logic.requirements';
import { NgModule } from '@angular/core';
import { DataRequirementsInjectionToken } from './data.requirements';
import { DataFacade } from '../data/data.facade';
import { Observable, of } from 'rxjs';
import { SignupUseCase } from './use-cases/signup.use-case';
import { LoginUseCase } from './use-cases/login.use-case';
import { GetCountryListUseCase } from './use-cases/get-country-list.use-case';
import { CountryMapper } from './mappers/country.mapper';
import { SignupNewUseCase } from './use-cases/signup-new.use-case';
import { GetPublishKeyUseCase } from './use-cases/get-publish-key.use-case';
import { PublishKeyMapper } from './mappers/publish-key.mapper';
import { UpdateUserUseCase } from './use-cases/update-user.use-case';
@NgModule({
  imports: [DataFacade],

  providers: [
    {
      provide: DataRequirementsInjectionToken,
      useClass: DataFacade
    },
    CountryMapper,
    PublishKeyMapper
  ]
})
export class BusinessLogicFacade implements BusinessLogicRequirements {
  constructor(
    private signupUseCase: SignupUseCase,
    private signupNewUseCase: SignupNewUseCase,
    private loginUseCase: LoginUseCase,
    private getCountryUseCase: GetCountryListUseCase,
    private getPublishKeyUseCase: GetPublishKeyUseCase,
    private updateUserUseCase: UpdateUserUseCase
  ) {}
  signupParent(parentData: object): Observable<any> {
    // const parentObj = {
    //   password: "sc4zoeru3m9sc4zoeru3m9",
    // };
    // return of(parentObj);
    return this.signupUseCase.run(parentData);
  }
  signupParentNew(parentData: object): Observable<any> {
    // const parentObj = {
    //   password: "sc4zoeru3m9sc4zoeru3m9",
    // };
    // return of(parentObj);
    return this.signupNewUseCase.run(parentData);
  }
  loginParent(): Observable<any> {
    // const parentObj = {
    //   password: "sc4zoeru3m9sc4zoeru3m9",
    // };
    // return of(true);
    return this.loginUseCase.run();
  }

  getCountryList(): Observable<any> {
    return this.getCountryUseCase.run();
  }

  getPublishKey(): Observable<any> {
    return this.getPublishKeyUseCase.run();
  }
  updateUser(parentData: object): Observable<any> {
    return this.updateUserUseCase.run(parentData);
  }
}
