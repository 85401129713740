import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AuthService } from "src/app/lib/auth.service";
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-hooray',
  templateUrl: './hooray.component.html',
  styleUrls: ['./hooray.component.css']
})
export class HoorayComponent implements OnInit {
  @Input() playerData;
  @Input() parentData;
  @Output() closeHooray = new EventEmitter<any>();
  public confirm:boolean = false;
  public howToSignInLink = environment.howToSignInLink;
  public guidedTourLink = environment.guidedTourLink;
  @Output() confirmZone = new EventEmitter<any>();
  @Output() emailCopyApi = new EventEmitter<any>();

  @Input() zone=false;
  @Input() zoneList;
  constructor(private authService: AuthService) { }

  public rightArrow = '../../assets/images/right_arrow.svg';
  public valley = '../../assets/images/steam-vally.svg';
  public parentPortalLink;
  public arrow = '../../assets/images/chevron_right_s.svg';
  ngOnInit() {
    this.parentPortalLink = this.authService.getRedirectParentPortalUrl();
    console.log("zha hooray", this.parentData);

    let body = (document.getElementsByTagName("body")[0].style.overflow =
    "hidden");
  window.scrollTo(0, 0);
  }
  ngOnDestroy() {
    let body = (document.getElementsByTagName("body")[0].style.overflow =
      "auto");
  }
  close(){
    this.closeHooray.emit();
  }
  emailCopy(){
    this.confirm = true;
  }
  zoneConfirm(zone){
    this.confirmZone.emit(zone);
  }
  copyEmailApi(){
    this.emailCopyApi.emit();
  }

  clickedLink(event){
    if(event == 'guide') window.open(this.guidedTourLink, "_blank");
    else window.open(this.howToSignInLink, "_blank");
  }
}
