/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./email-success.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../global-components/button/button.component.ngfactory";
import * as i3 from "../../../../global-components/button/button.component";
import * as i4 from "../../../../global-components/button/button.store";
import * as i5 from "../../../../global-components/text/text.component.ngfactory";
import * as i6 from "../../../../global-components/text/text.component";
import * as i7 from "../../../../global-components/text/text.store";
import * as i8 from "@angular/common";
import * as i9 from "./email-success.component";
var styles_EmailSuccessComponent = [i0.styles];
var RenderType_EmailSuccessComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmailSuccessComponent, data: {} });
export { RenderType_EmailSuccessComponent as RenderType_EmailSuccessComponent };
function View_EmailSuccessComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "success"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "d-flex justify-content-center"], ["style", "margin-bottom: 24px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "../../../../../assets/images/emailSuccess.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Successfully sent your child login information to "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["style", "font-weight: bold;word-break: break-all;"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.parentData.email; _ck(_v, 6, 0, currVal_0); }); }
function View_EmailSuccessComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "failure"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "d-flex justify-content-center"], ["style", "margin-bottom: 24px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "../../../../../assets/images/emailFailure.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "oops"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Oops!"])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" We were unable to send your child's account information to "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["style", "font-weight: bold;word-break: break-all;"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [". How about we give it another go? "])), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["class", "btn-div"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.tryAgain() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "app-button", [], null, null, null, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(12, 49152, null, 0, i3.ButtonComponent, [i4.ButtonStore], { class: [0, "class"] }, null), (_l()(), i1.ɵeld(13, 0, null, 0, 2, "app-text", [], null, null, null, i5.View_TextComponent_0, i5.RenderType_TextComponent)), i1.ɵdid(14, 114688, null, 0, i6.TextComponent, [i7.TextStore], { class: [0, "class"] }, null), (_l()(), i1.ɵted(-1, 0, [" Try again "]))], function (_ck, _v) { var currVal_1 = "btn-orange"; _ck(_v, 12, 0, currVal_1); var currVal_2 = "btn-text-style2"; _ck(_v, 14, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.parentData.email; _ck(_v, 8, 0, currVal_0); }); }
export function View_EmailSuccessComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "overlay d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmailSuccessComponent_1)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmailSuccessComponent_2)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.success; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.success; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_EmailSuccessComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-email-success", [], null, null, null, View_EmailSuccessComponent_0, RenderType_EmailSuccessComponent)), i1.ɵdid(1, 245760, null, 0, i9.EmailSuccessComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmailSuccessComponentNgFactory = i1.ɵccf("app-email-success", i9.EmailSuccessComponent, View_EmailSuccessComponent_Host_0, { parentData: "parentData", success: "success" }, { tryAgainEmail: "tryAgainEmail" }, []);
export { EmailSuccessComponentNgFactory as EmailSuccessComponentNgFactory };
