import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SharedService } from 'src/app/lib/shared-service';

@Component({
  selector: 'app-reset-pwpopup',
  templateUrl: './reset-pwpopup.component.html',
  styleUrls: ['./reset-pwpopup.component.css']
})
export class ResetPwpopupComponent implements OnInit {
  @Output() closeBtn = new EventEmitter<any>();
  @Input() loading;
  public togglePassword: string = 'password';
  public show = true;
  public hide = false;
  @Output() resetPasswordBtn = new EventEmitter<any>();
  public playerData = {};
  public clickBtn = false;
  public alertURL = './assets/images/alert-icon.svg';
  public tickURL = './assets/images/tick-icon.svg';
  public misPass = false;
  public passwordClass = 'signup-input';
  public confirmPClass = 'signup-input';
  public pTick = false;
  public cTick = false;
  public isMobileView;
  public mobileViewService;

  constructor(private fb: FormBuilder, private shared: SharedService) {}

  public form = this.fb.group({
    newPassword: this.fb.control('', [Validators.required, Validators.minLength(8)]),
    confirmPassword: this.fb.control('', [Validators.required, Validators.minLength(8)])
  });

  ngOnInit() {
    this.mobileViewService = this.shared.GetMobileView.subscribe((data) => {
      this.isMobileView = data;
    });
    let body = (document.getElementsByTagName('body')[0].style.overflow = 'hidden');
    window.scrollTo(0, 0);
    this.form.valueChanges.subscribe((value) => {
      console.log(value);
      this.playerData = value;
      this.clickBtn = false;
      this.passwordClass = 'signup-input';
      this.confirmPClass = 'signup-input';
    });
    this.form.get('newPassword').valueChanges.subscribe(() => {
      this.pTick = true;
    });
    this.form.get('confirmPassword').valueChanges.subscribe(() => {
      this.cTick = true;
    });
  }

  blurInput($event) {
    console.log('get blur event');
    this.pTick = false;
    this.cTick = false;
  }

  ngOnDestroy() {
    let body = (document.getElementsByTagName('body')[0].style.overflow = 'auto');
    this.mobileViewService.unsubscribe();
  }

  closeEdit($event) {
    if (!this.loading) {
      document.getElementById('outer-frame').classList.add('outer-frame-close');
      setTimeout(() => {
        this.closeBtn.emit($event);
      }, 100);
    }
  }

  showPassword($event) {
    this.togglePassword = 'text';
    this.show = false;
    this.hide = true;
  }
  hidePassword($event) {
    this.togglePassword = 'password';
    this.show = true;
    this.hide = false;
  }
  resetPassword($event) {
    this.clickBtn = true;
    this.pTick = false;
    this.cTick = false;
    if (this.form.valid) {
      console.log(this.playerData);
      if (this.form.value.newPassword == this.form.value.confirmPassword) {
        this.misPass = false;
        this.resetPasswordBtn.emit(this.playerData);
      } else {
        console.log('password not same');
        this.misPass = true;
        this.confirmPClass = 'input-err';
      }
    } else {
      console.log('form error ');
      if (this.form.get('newPassword').errors) {
        this.passwordClass = 'input-err';
      }
      if (this.form.get('confirmPassword').errors) {
        this.confirmPClass = 'input-err';
      }
    }
  }
}
