import { Inject, Injectable } from '@angular/core';
import { DataRequirements, DataRequirementsInjectionToken } from '../data.requirements';

@Injectable({
  providedIn: 'root'
})
export class emailCopyUseCase {
  constructor(
    @Inject(DataRequirementsInjectionToken) private data: DataRequirements
  ) {}

  run(id, pw) {
    return this.data.emailCopy(id, pw);
  }
}
