import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { type } from 'jquery';
import { parse } from 'querystring';
import { SharedService } from 'src/app/lib/shared-service';
import { DropDownSearchStore } from './drop-down-search.store';

@Component({
  selector: 'app-drop-down-search',
  templateUrl: './drop-down-search.component.html',
  styleUrls: ['./drop-down-search.component.css']
})
export class DropDownSearchComponent {
  constructor(private store: DropDownSearchStore, private fb: FormBuilder, private shared: SharedService) {}

  @Input() itemList;
  @Input() hint;
  @Input() defaultValue;
  @Input() pure = false;
  @Input() create = false;
  @Input() emailFlag = false;

  @Output() isOpen = new EventEmitter<any>();
  @Output() comebackData = new EventEmitter<any>();

  public showBox = false;
  public keyword = '';
  public selectedCountry;
  public tempList = [];

  public form = this.fb.group({
    listItem: this.fb.control('', []),
    searchItem: this.fb.control('', [])
  });

  public isMobileView;
  public mobileViewService;

  ngOnInit() {
    this.mobileViewService = this.shared.GetMobileView.subscribe((data) => {
      this.isMobileView = data;
    });
    if (this.defaultValue) this.hint = this.defaultValue;
    this.tempList = this.itemList;
    this.form.controls['listItem'].setValue(this.defaultValue);
    this.form.get('searchItem').valueChanges.subscribe(() => {
      // var regex = /[0-9]*/
      // var test=value.match(regex)
      // console.log(test,typeof(test))
      // this.form.controls['searchItem'].setValue(value[0].match(/[0-9]*/).input)
      var value = this.form.get('searchItem').value;
      if (this.showBox) {
        // this.keyword = parseInt(this.form.get("searchItem").value).toString();

        this.keyword = this.form.get('searchItem').value;

        // if(this.keyword !='')
        this.getSearchList();
      }
      this.getSearchList();
    });
  }
  ngOnDestroy() {
    this.mobileViewService.unsubscribe();
  }
  isNaN(value) {
    console.log(value);
    // var str = this.form.get("searchItem").value
    // if(value){
    //   str = str.substring(0, str.length - 1);
    //   this.form.controls['searchItem'].setValue(str);
    // }
  }

  clearKeyword() {
    this.keyword = '';
    this.isOpen.emit('false');
  }

  getSearchList() {
    if (this.pure)
      this.tempList = this.itemList.filter((item) => item.toLowerCase().includes(this.keyword.toLocaleLowerCase()));
    else
      this.tempList = this.itemList.filter((item) =>
        item.name.toLowerCase().includes(this.keyword.toLocaleLowerCase())
      );
    // $('.item-selected').scrollTop
  }

  @HostListener('document:click', ['$event'])
  onClick(e) {
    var target = e.target;
    console.log(target);
    this.tempList = this.itemList;
    if (target.closest('.list-select-input')) {
      this.showBox = !this.showBox;
    } else if (target.closest('.search-select-input')) {
      this.showBox = true;
    } else if (target.closest('.numonly') || target.closest('.country')) {
      this.showBox = true;
    } else {
      this.showBox = false;
    }
    this.keyword = '';
    this.isOpen.emit(this.showBox.toString());
    if (this.showBox) {
      $('.overlay').removeClass('overflow-change');

      if (this.defaultValue || this.selectedCountry) {
        console.log('get selected');
        setTimeout(() => {
          var myEl: any = document.querySelector('.bg-b100');
          var myTop = 0;
          console.log(document.querySelector('.bg-b100'));
          if (myEl instanceof HTMLElement) myTop = myEl.offsetTop;
          console.log(
            'selected top',
            myTop,
            this.emailFlag,
            this.create,
            this.pure,
            this.isMobileView,
            myTop - 462 + 45
          );
          if (this.getBrowserName() == 'safari') this.forSafari(myTop);
          else this.forChrome(myTop);
          // if(!this.emailFlag){
          //   if(!this.pure) $('.list-box').scrollTop(myTop-this.countryUp);
          //   else if(this.create && this.pure && this.isMobileView) $('.list-box').scrollTop(myTop-462-135);
          //   else if(this.create && this.pure && !this.isMobileView) $('.list-box').scrollTop(myTop-450);
          //   else if(!this.create && this.pure && this.isMobileView) $('.list-box').scrollTop(myTop-732);
          //   else if(!this.create && this.pure && !this.isMobileView) $('.list-box').scrollTop(myTop-540);
          // }else{
          //   if(!this.pure) $('.list-box').scrollTop(myTop-this.countryUp);
          //   else if(this.create && this.pure && this.isMobileView) $('.list-box').scrollTop(myTop-462-135);
          //   else if(this.create && this.pure && !this.isMobileView) $('.list-box').scrollTop(myTop-450);
          //   else if(!this.create && this.pure && this.isMobileView) $('.list-box').scrollTop(myTop-732);
          //   else if(!this.create && this.pure && !this.isMobileView) $('.list-box').scrollTop(myTop-540+95);
          // }
        }, 100);
      } else {
        setTimeout(() => {
          console.log(document.querySelector('.first-row'));
          console.log('first child');
          document.querySelector('.first-row').classList.add('bg-b100');
          document.querySelector('.first-row').classList.add('text-s0');
        }, 100);
      }
    } else $('.overlay').addClass('overflow-change');
  }

  forSafari(myTop) {
    if (this.isMobileView && this.create) myTop += 5;
    else if (!this.isMobileView && this.create) myTop -= 5;
    else if (this.isMobileView && !this.create) myTop += 5;
    else if (!this.isMobileView && !this.create) myTop -= 5;
    // myTop = this.isMobileView ? myTop+155 : myTop-90;
    // myTop = this.isMobileView && this.create ? myTop-127 : myTop;
    if (!this.emailFlag) {
      if (!this.pure) $('.list-box').scrollTop(myTop - this.countryUp);
      else if (this.create && this.pure && this.isMobileView) $('.list-box').scrollTop(myTop - 462 - 135);
      else if (this.create && this.pure && !this.isMobileView) $('.list-box').scrollTop(myTop - 450);
      else if (!this.create && this.pure && this.isMobileView) $('.list-box').scrollTop(myTop - 732);
      else if (!this.create && this.pure && !this.isMobileView) $('.list-box').scrollTop(myTop - 540);
    } else {
      if (!this.pure) $('.list-box').scrollTop(myTop - this.countryUp);
      else if (this.create && this.pure && this.isMobileView) $('.list-box').scrollTop(myTop - 462 - 135);
      else if (this.create && this.pure && !this.isMobileView) $('.list-box').scrollTop(myTop - 450);
      else if (!this.create && this.pure && this.isMobileView) $('.list-box').scrollTop(myTop - 732 + 180 - 25);
      else if (!this.create && this.pure && !this.isMobileView) $('.list-box').scrollTop(myTop - 540 + 95);
    }
  }

  forChrome(myTop) {
    if (!this.emailFlag) {
      if (!this.pure) $('.list-box').scrollTop(myTop - this.countryUp);
      else if (this.create && this.pure && this.isMobileView) $('.list-box').scrollTop(myTop - 462 - 135);
      else if (this.create && this.pure && !this.isMobileView) $('.list-box').scrollTop(myTop - 450);
      else if (!this.create && this.pure && this.isMobileView) $('.list-box').scrollTop(myTop - 732);
      else if (!this.create && this.pure && !this.isMobileView) $('.list-box').scrollTop(myTop - 540);
    } else {
      if (!this.pure) $('.list-box').scrollTop(myTop - this.countryUp);
      else if (this.create && this.pure && this.isMobileView) $('.list-box').scrollTop(myTop - 462 - 135);
      else if (this.create && this.pure && !this.isMobileView) $('.list-box').scrollTop(myTop - 450);
      else if (!this.create && this.pure && this.isMobileView) $('.list-box').scrollTop(myTop - 732);
      else if (!this.create && this.pure && !this.isMobileView) $('.list-box').scrollTop(myTop - 540 + 95);
    }
  }

  public countryUp = 160;
  public dobUp = 359;
  public dobDown = 359 + 48;

  @HostListener('keydown', ['$event'])
  onDown(e) {
    var top, el: any;
    if (this.showBox && e.keyCode == 40) {
      //down
      // $('.item-list:not(:last-child).item-list-hover').removeClass('item-list-hover').next().addClass('item-list-hover');
      console.log($('.item-list:not(:last-child).item-list-hover').text());
      //check there is current hover
      if ($('.item-list:not(:last-child).item-list-hover').text()) {
        console.log('current hover exist', $('.item-list:not(:last-child).item-list-hover').text());
        //check next item is selected or not
        if (!$('.item-list:not(:last-child).item-list-hover').next().hasClass('bg-b100')) {
          $('.item-list:not(:last-child).item-list-hover')
            .removeClass('item-list-hover')
            .next()
            .addClass('item-list-hover');
          el = document.querySelector('.item-list-hover');
        } else {
          $('.item-list:not(:last-child).item-list-hover').removeClass('item-list-hover');
          el = document.querySelector('.bg-b100');
        }
      } else {
        console.log('current hover doesnot not exist');
        if ($('.item-list:last-child.item-list-hover').text()) return;
        else $('.item-list:not(:last-child).bg-b100').next().addClass('item-list-hover');
        el = document.querySelector('.item-list-hover');
      }

      if (el instanceof HTMLElement) top = el.offsetTop;
      var height = document.querySelector('.list-box').clientHeight;
      console.log(top, 144, top - 144);
      $('.list-box').scrollTop(0);
      // $('.list-box').scrollTop(myTop - 541);
      if (!this.pure) $('.list-box').scrollTop(top - this.countryUp);
      else $('.list-box').scrollTop(top - 541);
    } else if (this.showBox && e.keyCode == 38) {
      //up
      // $('.item-list:not(:first-child).item-list-hover').removeClass('item-list-hover').prev().addClass('item-list-hover');

      //check there is current hover
      if ($('.item-list:not(:first-child).item-list-hover').text()) {
        console.log('current hover exist', $('.item-list:not(:last-child).item-list-hover').text());
        //check next item is selected or not
        if (!$('.item-list:not(:first-child).item-list-hover').prev().hasClass('bg-b100')) {
          $('.item-list:not(:first-child).item-list-hover')
            .removeClass('item-list-hover')
            .prev()
            .addClass('item-list-hover');
          el = document.querySelector('.item-list-hover');
        } else {
          $('.item-list:not(:first-child).item-list-hover').removeClass('item-list-hover');
          el = document.querySelector('.bg-b100');
        }
      } else {
        console.log('current hover doesnot not exist');
        if ($('.item-list:first-child.item-list-hover').text()) return;
        else $('.item-list:not(:last-child).bg-b100').prev().addClass('item-list-hover');
        el = document.querySelector('.item-list-hover');
      }

      if (el instanceof HTMLElement) top = el.offsetTop;
      var height = document.querySelector('.list-box').clientHeight;
      console.log(top, 144, top + height);
      $('.list-box').scrollTop(0);
      if (!this.pure) $('.list-box').scrollTop(top - this.countryUp);
      else $('.list-box').scrollTop(top - 541);
    }
  }

  chooseItem(item) {
    if (this.pure) {
      this.form.controls['listItem'].setValue(item);
      this.selectedCountry = item;
    } else {
      this.form.controls['listItem'].setValue(item.name);
      this.selectedCountry = item.name;
    }

    this.keyword = '';
    this.showBox = false;
    this.comebackData.emit(this.selectedCountry);
  }

  checkSelected(item) {
    if (this.pure) {
      return item == this.form.get('listItem').value;
    } else {
      return item['name'] == this.form.get('listItem').value;
    }
  }

  dosomething() {
    var node = document.querySelector('.item-list-hover');
    var string;
    if (node instanceof HTMLElement) string = node.textContent === undefined ? node.innerText : node.textContent;
    console.log(string);
    if (string != '' || string != undefined) {
      this.form.controls['listItem'].setValue(string);
      this.selectedCountry = string;
      this.keyword = '';
      this.showBox = false;
    }
    this.comebackData.emit(this.selectedCountry);
  }

  blurInput(e) {}

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
}
