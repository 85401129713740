<div class="overlay d-flex justify-content-center align-items-baseline">
  <div class="fix-div d-flex flex-column bg-s0" *ngIf="!confirm">
    <div class="d-flex justify-content-between" style="align-items: start;">
      <!-- <div [ngClass]="{'hooray' : !zone , 'zone' : zone}">{{!zone? 'Hooray!' : 'STEMWerkz Zones'}}</div> -->
      <div class="title">
        {{!zone ? 'Your child’s account has been created. Explore STEMWerkz with the following details:' :
        'Log in with your child’s account to access the following zones'}}
      </div>
      <img (click)="close()"
      class="close-btn cursor"
      src="../../assets/images/cancel_fill.svg"
    />
    </div>
    <!-- <div class="title">
      {{!zone ? 'Your child’s account has been created. Explore STEMWerkz with the following details:' :
      'Log in with your child’s account to access the following zones'}}
    </div> -->
    <div class="body" *ngIf="!zone">
      <div class="label">
        {{playerData.firstName}}’s username
      </div>
      <div class="name">
        {{playerData.username}}
      </div>
      <div class="label">
        {{playerData.firstName}}’s password
      </div>
      <div class="name">
        {{playerData.password}}
      </div>
      <div class="tour">
        Need a guided tour for your child to access STEMWerkz? <span class="cursor" style="color:#3394AB;" (click)="clickedLink('guide')">Click here!</span>
      </div>
    </div>
    <div *ngIf="zone" class="dashboard">
      <a class="dashboard-link" [href]="zoneList[0].link" target="_blank">
        <div class="d-flex">
          <app-image [src]="zoneList[0].image"></app-image>
          <div class="d-flex justify-content-between w-100 align-items-center">
            <div class="d-flex flex-column" style="margin-left: 12px">
              <div class="d-text">Dashboard</div>
              <div class="">Go to main Dashboard</div>
            </div>
            <app-image [src]="arrow"></app-image>
          </div>
        </div>
      </a>
    </div>
    <a class="dashboard-link" [href]="parentPortalLink" target="_blank">
      <div class="parent-portal d-flex justify-content-between">
        <app-text class="parent-portal-text">Go to Parent Portal</app-text>
        <app-image [src]="arrow"></app-image>
      </div>
    </a>
    <div *ngIf="zone" class="zone-wrapper d-flex flex-column">
      <div class="d-flex justify-content-between" style="margin-bottom: 16px;">
        <a style="height: 64px;width: 48%;" [href]="zoneList[1].link" target="_blank"><div class="zone-image">  <app-image [src]="zoneList[1].image"></app-image></div></a>
        <a style="height: 64px;width: 48%;" [href]="zoneList[2].link" target="_blank"><div class="zone-image"><app-image [src]="zoneList[2].image"></app-image></div></a>
      </div>
      <div class="d-flex justify-content-between" style="padding-bottom: 16px; border-bottom: 1px solid #EDEEF0;">
        <a style="height: 64px;width: 48%;" [href]="zoneList[3].link" target="_blank"><div class="zone-image"><app-image [src]="zoneList[3].image"></app-image></div></a>
        <a style="height: 64px;width: 48%;"[href]="zoneList[4].link" target="_blank"><div class="zone-image"><app-image [src]="zoneList[4].image"></app-image></div></a>
      </div>
    </div>
    <!-- <div *ngIf="zone" class="zone-wrapper">
      <ng-container *ngFor="let zone of zoneList">
        <a [href]="zone.link" target="_blank">
          <div class="d-flex justify-content-between align-items-center zone-list cursor">
              <app-image [src]="zone.image" [height]="'48px'"></app-image>
              <app-image [src]="'../../assets/images/right_arrow_zone.svg'" [width]="'8px'" [height]="'14px'"></app-image>
          </div>
        </a>
      </ng-container>

    </div> -->
    <div class="btn-div" *ngIf="!zone" (click)="emailCopy()">
      <app-button [class]="'btn-orange'">
        <app-text [class]="'btn-text-style2'"> Email me a copy </app-text>
      </app-button>
    </div>
    <div *ngIf="zone" class="guide cursor">
      <a (click)="clickedLink('signin')">How to sign in with a child’s account?</a>
    </div>
    <div (click)="close()" style="width: 100%;" class="d-flex justify-content-center align-items-center">
      <app-text style="width: 100%;" [class]="'btn-text-cancel2-15 btn-cancel2'">Close</app-text>
    </div>

  </div>
  <div class="confirm" *ngIf="confirm">
    <div class="d-flex justify-content-between">
      <div class="confirm-header">Confirm</div>
      <img (click)="close()"
      class="close-btn cursor"
      src="../../assets/images/close.svg"
    />
    </div>
    <div class="confirm-body">
      The information will be sent to your email {{parentData.email}}
    </div>
    <div class="btn-div" (click)="copyEmailApi()">
      <app-button [class]="'btn-orange'">
        <app-text [class]="'btn-text-style2'"> Okay </app-text>
      </app-button>
    </div>
  </div>
</div>
