<!-- <app-discount-popup *ngIf="discountFlag" class="cover-all" (discountCancel)="discountCancel($event)" (discountContinue)="discountContinue($event)" [discountCode]="discountCode"></app-discount-popup> -->
<!-- <app-gift-card *ngIf="giftFlag" [invalidCode]="invalidCode" class="cover-all" (giftCancel)="giftCancel($event)" (giftContinue)="giftContinue($event)"></app-gift-card> -->
<!-- <app-redeem-loading *ngIf="redeeming"></app-redeem-loading> -->
<div class="container">
  <app-header
    *ngIf="currentPlanName && !giftCodeAdded"
    (openProfile)="openProfile($event)"
    [parentData]="parentData"
  ></app-header>

  <div (click)="backPage()" class="back-div" *ngIf="isChange == 'true' && unsubscribe != 'true'">
    <img class="mr-8" src="../../../../assets/images/back-btn.svg" /> Back
  </div>
  <img
    *ngIf="!currentPlanName || giftCodeAdded"
    [src]="mobileView ? '../../../assets/images/STEMWerkzMobile.png' : '../../../assets/images/STEMWerkzLogo.png'"
    [ngClass]="mobileView ? 'p-3' : ''"
  />
  <br *ngIf="isChange == 'false'" />
  <label class="choice" *ngIf="isChange == 'false' && unsubscribe != 'true'">{{
    giftCodeAdded ? 'Claim Gift Card' : 'Good Choice'
  }}</label>
  <label class="choice" *ngIf="isChange == 'true' && unsubscribe != 'true'">Change Plan</label>
  <label class="choice" *ngIf="unsubscribe == 'true'">You have unsubscribed</label>
  <p class="highlight" *ngIf="!isAuth && unsubscribe != 'true'">
    Sign up and proceed to check out to claim your school discount
  </p>
  <p class="highlight" *ngIf="unsubscribe == 'true'">
    Your subscription has ended. You can subscribe to the previous plan again or change to a new plan.
  </p>
  <p class="highlight" *ngIf="isAuth && unsubscribe != 'true'">Proceed to check out to claim your school discount</p>
  <div *ngIf="mobileView" class="plan-title">
    <app-text>STEMWerkz {{ plan?.planName ? plan?.planName : 'Plan Name' }}</app-text>
  </div>
  <div class="d-flex plan-detail" [ngClass]="{ 'justify-content-between': !mobileView, 'flex-column': mobileView }">
    <div class="left-div">
      <app-loader *ngIf="loader" [spinColor]="'#b1b1b3'" [height]="'300px'" [width]="'100%'"></app-loader>
      <div *ngIf="!loader" [ngClass]="{ 'mt-40': !mobileView, 'mt-16': mobileView }">
        <table class="table">
          <tr *ngIf="!mobileView">
            <th></th>
            <th
              id="value"
              class="plan-type solo"
              style="
                background: url(assets/images/selected-bg.svg);
                background-repeat: no-repeat;
                background-position-x: center;
              "
            >
              {{ plan?.planName ? plan?.planName : 'Plan Name' }}
            </th>
            <app-loader *ngIf="loader" [spinColor]="'#b1b1b3'" [height]="'10px'" [width]="'100px'"></app-loader>
          </tr>
          <tr>
            <td style="vertical-align: middle"><span class="td-title">Yearly plan</span></td>
            <td id="s-price" class="plan-content" [ngClass]="{ 'line-through': giftCodeAdded && plan?.planMonthly }">
              <app-text class="original">{{ plan?.planYear }} {{ plan?.currency | uppercase }}</app-text
              ><br />
              <app-text class="discount" *ngIf="discountInfo?.percent_off"
                >{{ (plan?.planYear - plan?.planYear * (discountInfo?.percent_off / 100)).toFixed(2) }}
                {{ plan?.currency | uppercase }}</app-text
              >
              <app-text class="discount" *ngIf="!discountInfo?.percent_off && discountInfo.amount_off"
                >{{ plan?.planYear - discountInfo?.amount_off }} {{ plan?.currency | uppercase }}</app-text
              >
            </td>
          </tr>
          <tr>
            <td class="td-title">Child accounts</td>
            <td id="s-price" class="plan-content">{{ plan?.playerSlot ? plan?.playerSlot : '---' }}</td>
          </tr>
          <tr>
            <td class="td-title">1,000+ engaging STEM lessons</td>
            <td id="s-content" class="plan-content">
              <app-image [src]="selectedTick"></app-image>
            </td>
          </tr>
          <tr *ngIf="isChange != 'true' || unsubscribe == 'true'">
            <td class="td-title" [ngClass]="{ 'line-through': parentData?.metadata.vendor }">
              {{ trialDays }} days free trial
            </td>
            <ng-container *ngIf="!parentData?.metadata.vendor">
              <td id="s-content" class="plan-content">
                <app-image [src]="selectedTick" *ngIf="unsubscribe != 'true'"></app-image>
                <app-text *ngIf="unsubscribe == 'true'" style="color: #a40e68">-</app-text>
              </td>
            </ng-container>
            <ng-container *ngIf="parentData?.metadata.vendor">
              <td id="s-content" class="plan-content">
                <app-text style="color: #a40e68">-</app-text>
              </td>
            </ng-container>
          </tr>
          <tr *ngIf="isChange == 'true' && unsubscribe != 'true'">
            <td class="td-title">Status</td>
            <td id="s-price" class="plan-content">
              <app-text *ngIf="downgrade != 'true'">Upgrade</app-text>
              <app-text *ngIf="downgrade == 'true'">Downgrade</app-text>
            </td>
          </tr>
        </table>
      </div>
      <div class="paragraph" *ngIf="!loader">
        <span *ngIf="discountInfo?.percent_off">
          FREE TRIAL TERMS AND CONDITIONS:<br />
          This Free Trial is valid only for NEW subscribers for a period of {{ trialDaysText }} ({{ trialDays }}) days
          upon the creation of a Parent Account. At the end of this period, we will deduct, from your credit card, a fee
          of US${{ (plan?.planYear - plan?.planYear * (discountInfo?.percent_off / 100)).toFixed(2) }} yearly starting
          from {{ momentNext | date: 'd LLL  yyyy' }}. <br />However, should you decide to make changes or upgrade your
          subscription plan at any time during this {{ trialDaysText }} ({{ trialDays }})-day period, the free trial
          will end and your first yearly fee will be deducted immediately. Please
          <a
            target="_blank"
            style="color: #007d49; text-decoration: none"
            href="https://www.stemwerkz.org/end-user-agreement"
            >click here</a
          >
          for more details on the payment terms and subscription cancellation.
        </span>
        <span *ngIf="!discountInfo?.percent_off && discountInfo.amount_off">
          FREE TRIAL TERMS AND CONDITIONS:<br />
          This Free Trial is valid only for NEW subscribers for a period of {{ trialDaysText }} ({{ trialDays }}) days
          upon the creation of a Parent Account. At the end of this period, we will deduct, from your credit card, a fee
          of US${{ plan?.planYear - discountInfo?.amount_off }} yearly starting from
          {{ momentNext | date: 'd LLL  yyyy' }}. <br />However, should you decide to make changes or upgrade your
          subscription plan at any time during this {{ trialDaysText }} ({{ trialDays }})-day period, the free trial
          will end and your first yearly fee will be deducted immediately. Please
          <a
            target="_blank"
            style="color: #007d49; text-decoration: none"
            href="https://www.stemwerkz.org/end-user-agreement"
            >click here</a
          >
          for more details on the payment terms and subscription cancellation.
        </span>
      </div>
    </div>

    <div *ngIf="!loader" id="right-div" [ngClass]="{ 'h-auto': giftCodeAdded }">
      <div class="plan">{{ giftCodeAdded ? 'Your gift card' : 'Payment' }} information</div>
      <div class="latter">
        <div class="inform">
          <div class="pink-title">Discount for {{ discountInfo.name }} applied.</div>
        </div>
        <div class="inform">
          <div *ngIf="discountInfo.percent_off">
            {{ discountInfo.percent_off }}% off for first year. ${{ plan?.planYear }}/year thereafter.
          </div>
          <div *ngIf="!discountInfo.percent_off">
            {{ discountInfo.amount_off }}{{ plan?.currency | uppercase }} off for first year. ${{ plan?.planYear }}/year
            thereafter.
          </div>
        </div>
        <app-button
          [class]="'btn-orange'"
          [loading]="loading"
          (click)="setupPayment()"
          *ngIf="isAuth && !giftCodeAdded && unsubscribe != 'true'"
        >
          <app-text [class]="'btn-text-style2'" [loading]="loading">
            {{ loading ? 'Setting up... ' : 'Set up payment' }}
          </app-text>
        </app-button>

        <app-button
          [class]="'btn-orange'"
          [loading]="loading"
          (click)="setupPayment()"
          *ngIf="isAuth && unsubscribe == 'true'"
        >
          <app-text [class]="'btn-text-style2'" [loading]="loading">
            {{ loading ? 'Subscribing... ' : 'Subscribe' }}
          </app-text>
        </app-button>

        <app-button [class]="'btn-orange'" (click)="signUp()" *ngIf="!isAuth || (isAuth && !isEmpty && giftCodeAdded)">
          <app-text [class]="'btn-text-style2'"> Sign up </app-text>
        </app-button>

        <div
          *ngIf="!giftCodeAdded"
          class="d-flex justify-content-center"
          style="width: 100%; margin-top: 24px; margin-bottom: 34px; cursor: pointer"
          (click)="cancelPayment()"
        >
          <app-text
            [ngClass]="{ 'btn-disable': loading, 'btn-cancel2': !loading }"
            [class]="'btn-text-cancel2'"
            [loading]="loading"
            >{{ unsubscribe == 'true' ? 'Back' : 'Cancel' }}</app-text
          >
        </div>
      </div>
    </div>
    <div *ngIf="loader" id="right-div" [ngClass]="{ 'h-auto': giftCodeAdded, 'h-455': !giftCodeAdded }">
      <div class="plan"><app-loader [spinColor]="'#b1b1b3'" [height]="'16px'" [width]="'50%'"></app-loader></div>
      <div class="latter">
        <div class="inform">
          <app-loader [spinColor]="'#b1b1b3'" [height]="'16px'" [width]="'50%'"></app-loader>
        </div>
        <app-loader [spinColor]="'#b1b1b3'" [height]="'48px'" [width]="'100%'"></app-loader>
        <app-loader [spinColor]="'#b1b1b3'" [height]="'48px'" [width]="'100%'"></app-loader>
      </div>
    </div>
  </div>
</div>
<app-query-params-ls></app-query-params-ls>
