export const environment = {
  production: true,
  apiurl: "https://sv-api.stemwerkz.org",
  apikey: "bc77612ecd1a2e7f6ace383b4cee7848",
  secretkey: "bc776",
  orgName: "steamwerkz-master",
  initState: "signup",
  environmentName: 'master',
  dashboardurl: "https://student.stemwerkz.org/",
  channelurl: "https://channel.stemwerkz.org/",
  valleyurl: "https://sv.stemwerkz.org/",
  academyurl: "https://channel.stemwerkz.org/",
  questurl: "https://quest.stemwerkz.org/",
  claimURL: "https://account.stemwerkz.org/claim-giftcard",
  parenturl: 'https://parent.stemwerkz.org/',
  // valley: "https://sv.stemwerkz.org",
  // channel: "https://channel.stemwerkz.org/login",
  // academy: "https://channel.stemwerkz.org/liveclasses",
  howToSignInLink: "https://www.stemwerkz.org/howto",
  guidedTourLink: "https://www.stemwerkz.org/howto",
  stemwerkzLink: "https://www.stemwerkz.org/",
  nodmaApi: 'https://app.pagewerkz.com',
  titleForgotPassword: 'Forgot your password? | STEMWerkz',
  titleResetPassword: 'Reset your password | STEMWerkz',
  titleStore: 'Store | STEMWerkz',
  fbPixel: true
};
