import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-redeem-loading',
  templateUrl: './redeem-loading.component.html',
  styleUrls: ['./redeem-loading.component.css']
})
export class RedeemLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
   document.getElementsByTagName("body")[0].style.overflow = "auto";
  }


}
