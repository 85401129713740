import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { InformationComponent } from "./information/information.component";
import { from } from "rxjs";
import { GlobalComponentsModule } from "../../../global-components/global-components.module";

const components = [InformationComponent];

@NgModule({
  declarations: components,
  exports: components,
  imports: [CommonModule, ReactiveFormsModule, GlobalComponentsModule],
})
export class SharedComponentsModule {}
