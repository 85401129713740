import { Inject, Injectable } from "@angular/core";
import {
  DataRequirements,
  DataRequirementsInjectionToken,
} from "../data.requirements";
import { PlayerDetailMapper } from "../mappers/player-detail-mapper";
// import { UserDetailModel } from "../../../models/user-detail.model";

@Injectable({
  providedIn: "root",
})
export class GetPlayerDetailUseCase {
  constructor(
    private mapper: PlayerDetailMapper,
    @Inject(DataRequirementsInjectionToken) private data: DataRequirements
  ) {}

  run(playerId) {
    return this.data.getPlayerDetail(playerId).pipe(this.mapper.map);
  }
}
