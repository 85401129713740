/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-feedback.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./custom-feedback.component";
var styles_CustomFeedbackComponent = [i0.styles];
var RenderType_CustomFeedbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomFeedbackComponent, data: {} });
export { RenderType_CustomFeedbackComponent as RenderType_CustomFeedbackComponent };
function View_CustomFeedbackComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "d-flex body-text ml-36 mb-8 mt-8 word-break"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.feedbackBody; _ck(_v, 1, 0, currVal_0); }); }
function View_CustomFeedbackComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "d-flex ml-36 feedback-btn justify-content-center align-item-center cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dimissFeedback($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.feedbackBtn.text; _ck(_v, 1, 0, currVal_0); }); }
function View_CustomFeedbackComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "feedback-div cursor"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "../../../assets/images/feedback_tick.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "feedback-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomFeedbackComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomFeedbackComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.feedbackBody; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.feedbackBtn.status; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.feedbackTitle; _ck(_v, 4, 0, currVal_0); }); }
function View_CustomFeedbackComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "d-flex body-text ml-36 mb-8 mt-8 word-break"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.feedbackBody; _ck(_v, 1, 0, currVal_0); }); }
function View_CustomFeedbackComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "d-flex ml-36 feedback-btn justify-content-center align-item-center cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dimissFeedback($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.feedbackBtn.text; _ck(_v, 1, 0, currVal_0); }); }
function View_CustomFeedbackComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "feedback-div2 cursor"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "../../../assets/images/alert-icon.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "feedback-title2"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomFeedbackComponent_5)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomFeedbackComponent_6)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.feedbackBody; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.feedbackBtn.status; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.feedbackTitle; _ck(_v, 4, 0, currVal_0); }); }
export function View_CustomFeedbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomFeedbackComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomFeedbackComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tickIcon; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.tickIcon; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_CustomFeedbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-feedback", [], null, null, null, View_CustomFeedbackComponent_0, RenderType_CustomFeedbackComponent)), i1.ɵdid(1, 114688, null, 0, i3.CustomFeedbackComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomFeedbackComponentNgFactory = i1.ɵccf("app-custom-feedback", i3.CustomFeedbackComponent, View_CustomFeedbackComponent_Host_0, { feedbackTitle: "feedbackTitle", feedbackBody: "feedbackBody", feedbackBtn: "feedbackBtn", tickIcon: "tickIcon" }, { dimissFeedbackBtn: "dimissFeedbackBtn" }, []);
export { CustomFeedbackComponentNgFactory as CustomFeedbackComponentNgFactory };
