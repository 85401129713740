import { DataRequirements, } from "../data.requirements";
import * as i0 from "@angular/core";
import * as i1 from "../data.requirements";
export class SignupUseCase {
    constructor(data) {
        this.data = data;
    }
    run(parentData) {
        return this.data.registerParent(parentData);
    }
}
SignupUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SignupUseCase_Factory() { return new SignupUseCase(i0.ɵɵinject(i1.DataRequirementsInjectionToken)); }, token: SignupUseCase, providedIn: "root" });
