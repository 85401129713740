<div class="order-card mb-3 mt-3" [ngClass]="OrderSummary? 'bg-s0 summary-box' : 'bg-s20'">
    <app-text *ngIf="OrderSummary" class="d-flex justify-content-center caption-u text-c100">Order summary</app-text>
    <div *ngIf="plan" class="d-flex justify-content-between align-items-center">
        <div class="d-flex flex-column mr-2">
            <app-text class="h4-strong mb-2 text-c300">
                A gift for a parent with {{plan.playerSlot>1? plan.playerSlot + ' kids' : plan.playerSlot + ' kid'}}
            </app-text>
            <div class="d-flex align-items-center text-c100 light-body-m">
                <app-text> {{plan.planName}}</app-text>
                <div class="dot ml-2 mr-2"></div>
                <app-text> {{plan.playerSlot>1? plan.playerSlot + ' child accounts' : plan.playerSlot + ' child account'}}</app-text>
            </div>

        </div>
        <div class="d-flex flex-column">
            <app-text class="mb-2 body-m text-c100"> {{plan.totalMonth>1? plan.totalMonth + ' months': plan.totalMonth + ' month'}}</app-text>
            <div>
                <app-text class="h2-strong text-c300"> {{plan.totalPrice}} </app-text>
                <app-text class="text-caption-u light-body-m text-c300">{{plan.currency}}</app-text>
            </div>
        </div>
    </div>
</div>
