import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../mappers/publish-key.mapper";
import * as i2 from "../data.requirements";
export class GetPublishKeyUseCase {
    constructor(mapper, data) {
        this.mapper = mapper;
        this.data = data;
    }
    run() {
        return this.data.getPublishKey().pipe(this.mapper.map);
    }
}
GetPublishKeyUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetPublishKeyUseCase_Factory() { return new GetPublishKeyUseCase(i0.ɵɵinject(i1.PublishKeyMapper), i0.ɵɵinject(i2.DataRequirementsInjectionToken)); }, token: GetPublishKeyUseCase, providedIn: "root" });
