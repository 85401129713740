<div class="player-profiles">
  <div id="qinfo" *ngIf="!mobileView">
    <div class="q-wrapper">
      <app-text class="q-text">These are your child accounts. Only a child account has access to STEMWerkzChannel and STEAMValley game</app-text>
    </div>
    <div class="caret">
  </div>
  </div>
  <div *ngIf="isDowngrade != true" class="d-flex justify-content-between">
    <!-- <app-text class="profile-heading">{{createdCount}} / {{playerLimit}} player accounts created</app-text> -->
    <app-text class="profile-heading">Your Child Accounts <app-image [src]="'../../../../assets/images/question_mark_s.svg'" (mouseenter)="hover()" (mouseleave)="normal()" (click)="clicked()" style="cursor: pointer;"></app-image></app-text>
    <app-text (click)="changePlan()" class="slot cursor" *ngIf="createdCount != 0 && !mobileView">Need more accounts?</app-text>
  </div>
  <div *ngIf="isDowngrade == true" class="d-flex justify-content-between">
    <app-text class="profile-heading">Your downgrading from a bigger plan. You will need to remove some of the player accounts to continue.</app-text>
  </div>

  <div id="qinfo-mobile" *ngIf="mobileView">
    <div class="q-wrapper">
      <app-text class="q-text">These are your child accounts. Only a child account has access to STEMWerkzChannel and STEAMValley game</app-text>
    </div>
  </div>

  <!-- <div class="d-flex flex-wrap" [ngClass]="{'justify-content-center' :  isDowngrade == true}" style="margin-top: 48px;" *ngIf="players.length > 0 && !loader"> -->
  <div class="d-flex flex-wrap justify-content-center" style="margin-top: 48px;" *ngIf="players.length > 0 && !loader">
    <app-single-profiles
      class="single-class"
      *ngFor="let player of players;let index=index"
      [player]="player"
      [limit]='playerLimit'
      [order]="index"
      [isDowngrade]="isDowngrade"
      (playerProfile)="playerProfile($event)"
      (addSlot)="addNewSlot($event)"
      (deletePlayer)="deletePlayer($event)"
      [needToDelete]="needToDelete"
    ></app-single-profiles>
  </div>
  <div *ngIf="loader" class="d-flex flex-wrap" style="margin-top: 48px;" [ngClass]="{'justify-content-center': mobileView}">
  <app-loader style="margin: 0px 28px;" [spinColor]="'#b1b1b3'" [height]="'250px'" [width]="'200px'"></app-loader>
  <app-loader style="margin: 0px 28px;" [spinColor]="'#b1b1b3'" [height]="'250px'" [width]="'200px'"></app-loader>
  <app-loader style="margin: 0px 28px;" [spinColor]="'#b1b1b3'" [height]="'250px'" [width]="'200px'"></app-loader>
  <app-loader style="margin: 0px 28px;" [spinColor]="'#b1b1b3'" [height]="'250px'" [width]="'200px'"></app-loader>
  </div>
  <!-- <div class="d-flex flex-wrap" style="margin-top: 48px;" *ngIf="players.length ==0">
    <app-empty-slot *ngFor="let limit of limitList;let index=index" (addSlot)="addNewSlot($event)" [slot]="index"
      class="single-class"
    ></app-empty-slot>
  </div> -->
</div>
