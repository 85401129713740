import {Inject, Component, OnInit} from '@angular/core';
import {Router,ActivatedRoute,ParamMap} from '@angular/router';
import {BusinessLogicRequirements, BusinessRequirementsInjectionToken} from '../business-logic.requirements';

@Component({
    selector: 'app-giftcard-purchase-processing',
    templateUrl: './giftcard-purchase-processing-controller.component.html',
    styleUrls: ['./giftcard-purchase-processing-controller.component.css']
})
export class GiftcardPurchaseProcessingControllerComponent implements OnInit {
    public success = null;
    public giftCardInfo;
    public backToHomeUrl = 'https://www.stemwerkz.org';

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements
    ) {}

    ngOnInit(){
      const paramValue: string = this.route.snapshot.queryParamMap.get('status');
      console.log("paramValue",paramValue);
      const giftcardId = this.route.snapshot.queryParamMap.get('giftId');
      console.log("gift id",giftcardId)
      this.success = paramValue.toLocaleLowerCase() == 'success' ? true : false;
      this.getGiftCardDetails(giftcardId);
    }

    backToGiftShop(){
      this.router.navigate(['/store'])
    }

    getGiftCardDetails(id){
      this.business.getGiftCardDetail(id).subscribe(data => {
        console.log("getGiftCardDetails",data);
        this.giftCardInfo = data[0];
        console.log(this.giftCardInfo)
      })
    }
}
