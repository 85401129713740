import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { LogoComponent } from "./logo/logo.component";
import { TextComponent } from "./text/text.component";
import { InputComponent } from "./input/input.component";
import { ButtonComponent } from "./button/button.component";
import { from } from "rxjs";
import { ImageComponent } from "./image/image.component";
import { CreatePlayerpopupComponent } from "./create-playerpopup/create-playerpopup.component";
import { SubscriptionPlanComponent } from "./subscription-plan/subscription-plan.component";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { DeletePopupComponent } from "./delete-popup/delete-popup.component";
import { ResetPwpopupComponent } from "./reset-pwpopup/reset-pwpopup.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { UnsubscribePopupComponent } from "./unsubscribe-popup/unsubscribe-popup.component";
import { CustomFeedbackComponent } from "./custom-feedback/custom-feedback.component";
import { RedirectPopupComponent } from "./redirect-popup/redirect-popup.component";
import { HeaderComponent } from './header/header.component';
import { DiscountPopupComponent } from './discount-popup/discount-popup.component';
import { GiftCardComponent } from './gift-card/gift-card.component';
import { FormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { MobileProfileComponent } from "./mobile-profile/mobile-profile.component";
import { DropDownSearchComponent } from "./drop-down-search/drop-down-search.component";
import { QueryParamsLsComponent } from "./query-params-ls/query-params-ls.component";

const components = [
  LogoComponent,
  TextComponent,
  InputComponent,
  ButtonComponent,
  ImageComponent,
  CreatePlayerpopupComponent,
  SubscriptionPlanComponent,
  EditProfileComponent,
  DeletePopupComponent,
  ResetPwpopupComponent,
  UserProfileComponent,
  UnsubscribePopupComponent,
  CustomFeedbackComponent,
  RedirectPopupComponent,
  HeaderComponent,
  DiscountPopupComponent,
  GiftCardComponent,
  MobileProfileComponent,
  DropDownSearchComponent,
  QueryParamsLsComponent
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, TextMaskModule],
})
export class GlobalComponentsModule {}
