import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class PasswordApiService {
  private baseUrl = environment.apiurl;
  constructor(private http: HttpClient) {}

  sentMail(mail) {
    let url = this.baseUrl + "/forgot-password";
    return this.http.post(url,mail).pipe(
      map((res: Response) => {
        // let result = [];
        let result = res;
        console.log(result);
        return result;
      })
    );
  }

  checkMail(token) {
    console.log('api')
    let url = this.baseUrl + "/reset-password?resetPasswordToken="+token;
    return this.http.get(url).pipe(
      map((res: Response) => {
        // let result = [];
        let result = res;
        console.log(result);
        return result;
      })
    );
  }

  resetPassword(token,body) {
    console.log('api')
    let url = this.baseUrl + "/reset-password?resetPasswordToken="+token;
    return this.http.post(url,body).pipe(
      map((res: Response) => {
        // let result = [];
        let result = res;
        console.log(result);
        return result;
      })
    );
  }

}
