import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import { PlayerDetailMapper } from "../mappers/player-detail-mapper";

@Injectable({
    providedIn: 'root'
})
export class GetPlayerDetailUseCase {
    constructor(
        private mapper: PlayerDetailMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run(id) {
        return this.data.getPlyerDetail(id).pipe(this.mapper.map);
    }
}
