export class SubscriptionPlanModel {
  planId: string = "";
  planName: string = "";
  planMonthly: any = "";
  planYear: any = "";
  playerSlot: number = 0;
  stripePriceId: any = "";
  subscribed: boolean = false;
  currency: any = "";
  interval: any = "";
  apikey: any = "";
  createdAt: any = "";
  active: boolean = false;
  monthlyPrice: number = 3.99;
  userSubInfo : any = {};
  metadata: any = {};
  trialDays: number = 7;
}
