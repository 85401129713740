<table class="table">
  <tr *ngIf="!mobileView">
    <th></th>
    <th
      id="value"
      class="plan-type solo"
      style="
        background: url(assets/images/selected-bg.svg);
        background-repeat: no-repeat;
        background-position-x: center;
      "
    >
      {{ plan?.planName ? plan?.planName : 'Plan Name' }}
    </th>
    <app-loader *ngIf="loader" [spinColor]="'#b1b1b3'" [height]="'10px'" [width]="'100px'"></app-loader>
  </tr>
  <tr>
    <td><span class="td-title">Monthly price</span></td>
    <td id="s-price" class="plan-content" [ngClass]="{ 'line-through': giftCodeAdded && plan?.planMonthly }">
      {{ plan?.planMonthly ? plan?.planMonthly : '---' }} {{ plan?.currency | uppercase }}
    </td>
  </tr>
  <tr>
    <td class="td-title">Child accounts</td>
    <td id="s-price" class="plan-content">{{ plan?.playerSlot ? plan?.playerSlot : '---' }}</td>
  </tr>
  <tr>
    <td class="td-title">1,000+ engaging STEM lessons</td>
    <td id="s-content" class="plan-content">
      <app-image [src]="selectedTick"></app-image>
    </td>
  </tr>
  <tr *ngIf="isChange != 'true' || unsubscribe == 'true'">
    <td
      class="td-title"
      [ngClass]="{ 'line-through': parentData?.metadata.vendor || (remainTrialDay && remainTrialDay <= 0) }"
    >
      {{ remainTrialDay && remainTrialDay > 0 ? remainTrialDay : trialDays }} days free trial
    </td>
    <ng-container *ngIf="!parentData?.metadata.vendor">
      <td id="s-content" class="plan-content">
        <app-image [src]="selectedTick" *ngIf="unsubscribe != 'true'"></app-image>
        <app-text *ngIf="unsubscribe == 'true'" style="color: #a40e68">-</app-text>
      </td>
    </ng-container>
    <ng-container *ngIf="parentData?.metadata.vendor">
      <td id="s-content" class="plan-content">
        <app-text style="color: #a40e68">-</app-text>
      </td>
    </ng-container>
  </tr>
  <tr *ngIf="isChange == 'true' && unsubscribe != 'true'">
    <td class="td-title">Status</td>
    <td id="s-price" class="plan-content">
      <app-text *ngIf="downgrade && downgrade != 'true'">Upgrade</app-text>
      <app-text *ngIf="downgrade == 'true' || !downgrade">Downgrade</app-text>
      <!-- not downgrade means no value in downgrade then it must comes from schoolDowngrade -->
    </td>
  </tr>
</table>
