import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class PlayerApiService {
  private baseUrl = environment.apiurl;
  constructor(private http: HttpClient) {}
  getUser(id) {

    let url = this.baseUrl + "/api/v2/users/"+id+"/players";
    return this.http.get(url).pipe(
      map((res: Response) => {
        let result = res;
        return result;
      })
    );
  }
  createUser(userData) {
    console.log(userData)
    let url = this.baseUrl + "/api/v2/users";
    return this.http.post(url, userData).pipe(
      map((res: Response) => {
        let result = res;
        // console.log(res);
        return result;
      })
    );
  }
  getPlayer(playerId) {
    let url = this.baseUrl + "/api/v2/users/" + playerId;
    console.log(url);
    return this.http.get(url).pipe(
      map((res: Response) => {
        // let result = [];
        let result = res;
        console.log(result);
        return result;
      })
    );
  }
  deletePlayer(playerId) {
    let url = this.baseUrl + "/api/v2/users/" + playerId;
    console.log(url);
    return this.http.delete(url).pipe(
      map((res: Response) => {
        let result = res;
        console.log(result);
        return result;
      })
    );
  }
}
