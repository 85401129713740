/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./information.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../global-components/logo/logo.component.ngfactory";
import * as i3 from "../../../../../global-components/logo/logo.component";
import * as i4 from "../../../../../global-components/logo/logo.store";
import * as i5 from "../../../../../lib/shared-service";
import * as i6 from "./information.component";
import * as i7 from "./information.store";
var styles_InformationComponent = [i0.styles];
var RenderType_InformationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InformationComponent, data: {} });
export { RenderType_InformationComponent as RenderType_InformationComponent };
export function View_InformationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "info-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "img-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "assets/images/stemwerkz_may.jpg"], ["style", "\n  width: 100%;\n"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "info-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-logo", [], null, null, null, i2.View_LogoComponent_0, i2.RenderType_LogoComponent)), i1.ɵdid(5, 245760, null, 0, i3.LogoComponent, [i4.LogoStore, i5.SharedService], null, null)], function (_ck, _v) { _ck(_v, 5, 0); }, null); }
export function View_InformationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-information", [], null, null, null, View_InformationComponent_0, RenderType_InformationComponent)), i1.ɵdid(1, 49152, null, 0, i6.InformationComponent, [i7.InformationStore], null, null)], null, null); }
var InformationComponentNgFactory = i1.ɵccf("app-information", i6.InformationComponent, View_InformationComponent_Host_0, {}, {}, []);
export { InformationComponentNgFactory as InformationComponentNgFactory };
