import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BusinessLogicRequirements } from '../business-logic.requirements';
import { loadStripe } from '@stripe/stripe-js';
import * as moment from 'moment';
export class SchoolPlansChosenControllerComponent {
    constructor(business, router, route, _authService, _sharedService) {
        this.business = business;
        this.router = router;
        this.route = route;
        this._authService = _authService;
        this._sharedService = _sharedService;
        this.planIndex = 'Solo';
        this.selectedTick = 'assets/images/selected-tick.svg';
        this.discountCode = '';
        this.correctDiscountCode = false;
        this.discountFlag = false;
        this.giftFlag = false;
        this.giftCode = '';
        // public showerr = false;
        this.discountText = 'Add discount code';
        this.currentPlanName = null;
        this.loading = false;
        this.isChange = 'false';
        this.date = new Date();
        this.momentToday = moment(this.date);
        this.giftmomentToday = moment(this.date);
        this.giftCodeAdded = false;
        this.isAuth = false;
        this.isEmpty = true;
        this.invalidCode = false;
        this.downgrade = '';
        this.unsubscribe = '';
        this.planType = '';
        this.skipFlag = null;
        this.redeeming = false;
        this.loader = true;
        this.subLoading = false;
    }
    ngOnInit() {
        this.mobileViewService = this._sharedService.GetMobileView.subscribe((data) => {
            this.mobileView = data;
        });
        this.isAuth = this._authService.isAuthenticated();
        console.log('isAuth', this.isAuth);
        this.discountInfo = this._sharedService.getSchoolDiscountInfo();
        if (!this.discountInfo)
            this.router.navigateByUrl('/school/signup');
        this.route.params.subscribe((data) => {
            console.log(data.id);
            this.planId = data.id;
        });
        this.route.queryParams.subscribe((data) => {
            if (data.discountcode) {
                this.discountCode = data.discountcode;
                this.discountText = 'Remove discount code';
                this.correctDiscountCode = true;
            }
        });
        console.log('planId', this.planId);
        this._sharedService.setCurrentPlanId(this.planId);
        this.getPlan(this.planId); //planchosen or changeplan
        this.giftCodeAdded = false;
        this.userInfo = this._authService.getUserInfo();
        console.log('auth', this.userInfo);
        console.log('Already Login');
        var encodedData = localStorage.getItem('parentDetailObj');
        if (encodedData)
            this.parentData = JSON.parse(this._authService.decrypt(encodedData, null));
    }
    ngOnDestory() {
        this.mobileViewService.unsubscribe();
    }
    setupPayment() {
        localStorage.setItem('planType', this.plan.planName);
        this.loading = true;
        this.upgradeSubscription();
    }
    signUp() {
        console.log('signUp and clear storage', this.giftCode);
        if (this.isAuth && this.isEmpty && this.giftCodeAdded) {
            console.log('claim with setup');
        }
        else {
            var ref;
            if (localStorage.getItem('ref'))
                ref = localStorage.getItem('ref');
            localStorage.clear();
            localStorage.setItem('ref', ref);
            this.router.navigateByUrl('/school-signup?schooldiscountcode=' + this._sharedService.getSchoolDiscountCode() + '&planId=' + this.planId);
        }
    }
    cancelPayment() {
        this.router.navigateByUrl('/school/signup');
    }
    getPlan(id) {
        console.log('get plan');
        this.business.getPlan(id).subscribe((data) => {
            console.log('plan', data[0]);
            this.plan = data[0];
            this.trialDays = this.plan.trialDays;
            if (this.trialDays == 14) {
                this.trialDaysText = 'fourteen';
            }
            else if (this.trialDays == 7) {
                this.trialDaysText = 'seven';
            }
            else {
                this.trialDaysText = '';
            }
            // this.momentToday.add(365+14, 'days');
            this.momentToday.add(this.trialDays, 'days');
            this.momentNext = this.momentToday.format();
            console.log(this.plan);
            this.loader = false;
            // this.renderData();
        }, (err) => {
            console.log(err);
            this.loader = false;
        });
    }
    renderData() {
        switch (this.planId) {
            case 'solo':
                this.planIndex = 'Solo';
                break;
            case 'duo':
                this.planIndex = 'Duo';
                break;
            case 'family':
                this.planIndex = 'Family';
                break;
            default:
                this.planIndex = 'Solo';
        }
    }
    upgradeSubscription() {
        this.subLoading = true;
        console.log('get');
        //publish key
        var publish_key = '';
        //session id
        var sessionId = '';
        var data = {
            userId: this.userInfo.userId,
            subscriptionId: this.planId,
            redirectURL: window.location.origin + '/dashboard',
            type: 'paid'
        };
        data['couponCode'] = this.discountCode;
        console.log(data);
        this.business.subscribeUser(data).subscribe((res) => {
            sessionId = res.sessionId;
            console.log('session id ', sessionId);
            this.business.getPublishKey().subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (localStorage.getItem('isUpgrade'))
                    localStorage.removeItem('isUpgrade');
                publish_key = res.publishableKey;
                console.log('publish key', publish_key);
                const stripe = yield loadStripe(publish_key);
                stripe
                    .redirectToCheckout({
                    sessionId: sessionId
                })
                    .then(function (result) {
                    console.log(result);
                });
            }));
        }, (err) => {
            if (localStorage.getItem('isUpgrade'))
                localStorage.removeItem('isUpgrade');
            console.log(err.error.message.slice(0, 14));
            if (err.error.message)
                this._sharedService.toastForError(err.error.message.slice(0, 14) == 'No such coupon' ? 'Invalid discount code' : err.error.message, '');
            else
                this._sharedService.toastForError('Something went wrong!', '');
            setTimeout(() => {
                var userInfo = this._authService.getUserInfo();
                if (userInfo.UserType == 3) {
                    localStorage.clear(); //for child
                    this.router.navigateByUrl('/school/signup');
                }
            }, 1500);
            console.log(err);
            this.loading = false;
        });
    }
    openProfile(event) {
        this.router.navigateByUrl('/parent/detail');
    }
    backPage() {
        const curPlanId = this._sharedService.getCurrentPlanId();
        this.router.navigateByUrl(`/changeplan/${curPlanId}`);
    }
}
