<div class="signup-box">
  <app-login-header (signUp)="showSignup($event)"></app-login-header>
  <div class="signup-header-div">
    <app-text [class]="'signup-header'">Welcome to STEMWerkz</app-text>
  </div>
  <div class="signup-textdiv">
    <app-text [class]="'signup-text2'"
      >Log in to your account or <span class="greentext" (click)="showSignup($event)">create an account</span></app-text
    >
  </div>
  <form (keyup.enter)="$event.preventDefault(); onLogin($event)">
    <div class="signup-input-div">
      <app-text [class]="'signup-label'">Username</app-text>
      <app-input
        [hint]="'Type your username'"
        [value]="usernameFromUrl"
        [control]="form.get('username')"
        [width]="'100%'"
        [class]="usernameClass"
        (blurEvent)="blurInput($event)"
      >
        <app-image *ngIf="!form.get('username').errors && uTick" [src]="tickURL"></app-image>
        <app-image *ngIf="(existUser || form.get('username').errors) && clickBtn" [src]="alertURL"></app-image>
      </app-input>
      <app-text *ngIf="form.get('username').errors && clickBtn" [class]="'form-error-label'"
        >Enter your username</app-text
      >
      <app-text *ngIf="existUser && clickBtn" [class]="'form-error-label'">
        We could not find an account with this username. Please check the username or
        <a style="text-decoration: none; color: #3394ab" routerLink="/signup">Sign up.</a>
      </app-text>
    </div>
    <div class="signup-input-div">
      <div class="create-password">
        <app-text [class]="'signup-label'">Password</app-text>
        <app-text *ngIf="show" [class]="'showPswd'" (textClick)="showPassword($event)"> Show </app-text>
        <app-text *ngIf="hide" [class]="'showPswd'" (textClick)="hidePassword($event)"> Hide </app-text>
      </div>
      <app-input
        [hint]="'Type your password'"
        [control]="form.get('password')"
        [width]="'100%'"
        [class]="passwordClass"
        [type]="togglePassword"
        (blurEvent)="blurInput($event)"
      >
        <app-image *ngIf="!form.get('password').errors && pTick" [src]="tickURL"></app-image>
        <app-image *ngIf="(misPassword || form.get('password').errors) && clickBtn" [src]="alertURL"></app-image>
      </app-input>
      <app-text *ngIf="form.get('password').errors && clickBtn" [class]="'form-error-label'"
        >Enter your password</app-text
      >
      <app-text *ngIf="misPassword && clickBtn" [class]="'form-error-label'">
        Incorrect password. We can help you recover your password.
      </app-text>
    </div>
  </form>

  <div class="signup-btn-div" (click)="onLogin($event)">
    <app-button [class]="'btn-orange'" [loading]="pleasewait">
      <app-text [class]="'btn-text-style2'" [loading]="pleasewait">
        {{ pleasewait ? 'Please wait...' : 'Log in' }}
      </app-text>
    </app-button>
  </div>
  <div class="forgotPass-div" routerLink="/forgot-password">
    <app-text [class]="'forgotPassword'">Forgot your password?</app-text>
  </div>
</div>
