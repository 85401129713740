import {InjectionToken} from '@angular/core';
import { Observable } from 'rxjs';

export interface BusinessLogicRequirements {
  getPlanList(): Observable<any>;
  getSubscription(): Observable<any>;
  subscribeUser(data): Observable<any>;
  getPublishKey(): Observable<any>;
  getPlan(id): Observable<any>;
  getPlyerDetail(id): Observable<any>;
  getGiftInfo(id): Observable<any>;
  claimGiftCard(id, opt, redir): Observable<any>;
  checkEmptySub():Observable<any>;
  getPlayers(id): Observable<any>;
  getParentDetail(id): Observable<any>;
}

export const BusinessRequirementsInjectionToken = new InjectionToken<BusinessLogicRequirements>('user-plans Business Requirements')
