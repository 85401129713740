import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../data.requirements";
import * as i2 from "../mappers/subscription-plans-mapper";
export class GetSubscriptionUsecase {
    constructor(data, mapper) {
        this.data = data;
        this.mapper = mapper;
    }
    run() {
        console.log('usecase');
        return this.data.getSubscription().pipe(this.mapper.map);
    }
}
GetSubscriptionUsecase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetSubscriptionUsecase_Factory() { return new GetSubscriptionUsecase(i0.ɵɵinject(i1.DataRequirementsInjectionToken), i0.ɵɵinject(i2.SubscriptionMapper)); }, token: GetSubscriptionUsecase, providedIn: "root" });
