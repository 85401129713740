import {DataRequirements} from '../business-logic/data.requirements';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import { Observable } from 'rxjs';

import {GiftCardShopService} from './api-services/gift-card-shop.service';

@NgModule({
    imports: [HttpClientModule]
})
export class DataFacade implements DataRequirements {
    constructor(
      private giftCardShopService: GiftCardShopService
    ) {}

    getAllPlans():Observable<any>{
      return this.giftCardShopService.getAllPlans();
    }

    getAllGiftCards():Observable<any>{
      return this.giftCardShopService.getAllGiftCardPoster();
    }

    purchaseGift(data):Observable<any>{
      return this.giftCardShopService.generateGiftCard(data);
    }

    getPublishKey(): Observable<any> {
      return this.giftCardShopService.getPublishKey();
    }

    getGiftCardDetail(id):Observable<any>{
      return this.giftCardShopService.getGiftCardDetails(id);
    }
}
