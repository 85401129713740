<div class="overlay d-flex justify-content-center align-items-center">
  <div class="d-flex discount" id="discount">
    <div class="title">Add discount code</div>
    <div style="margin-bottom: 32px">
      <span class="label">Discount code</span>
      <app-input
        [hint]="'Enter discount code...'"
        [control]="form.get('disCode')"
        [class]="'signup-input'"
        [width]="'100%'"
        [value]="discountCode"
      >
        <app-image *ngIf="disable" [src]="alertURL"></app-image>
      </app-input>
      <app-text *ngIf="disable" [class]="'form-error-label'">Invalid discount code </app-text>
    </div>
    <div>
      <app-button (click)="continueDiscount($event)" [class]="'btn-orange'" [loading]="disable">
        <app-text [class]="'btn-text-style2'" [loading]="disable">Continue </app-text>
      </app-button>
      <div (click)="cancelDiscount($event)" style="width: 100%; margin-top: 24px">
        <app-text style="cursor: pointer" class="btn-cancel2" [class]="'btn-text-cancel2'">Cancel</app-text>
      </div>
    </div>
  </div>
</div>
