<div class="overlay d-flex justify-content-center">
  <div class="success" *ngIf="success">
    <div class="d-flex justify-content-center" style="margin-bottom: 24px;">
      <img src="../../../../../assets/images/emailSuccess.svg" />
    </div>
    <div class="body">
      Successfully sent your child login information to <span style="font-weight: bold;word-break: break-all;">{{parentData.email}}</span>
    </div>
  </div>
  <div class="failure" *ngIf="!success">
    <div class="d-flex justify-content-center" style="margin-bottom: 24px;">
      <img src="../../../../../assets/images/emailFailure.svg" />
    </div>
    <div class="oops">Oops!</div>
    <div class="body">
      We were unable to send your child's account information to <span style="font-weight: bold;word-break: break-all;">{{parentData.email}}</span>. How about we give it another go?
    </div>
    <div class="btn-div" (click)="tryAgain()">
      <app-button [class]="'btn-orange'">
        <app-text [class]="'btn-text-style2'"> Try again </app-text>
      </app-button>
    </div>
  </div>
</div>
