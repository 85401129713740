import {Component, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-gift-fail',
    templateUrl: './gift-fail.component.html',
    styleUrls: ['./gift-fail.component.css']
})
export class GiftFailComponent {
  @Output() onButtonClick = new EventEmitter<any>();

  onTryAgainClick(){
    this.onButtonClick.emit();
  }
}
