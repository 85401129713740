<!-- i remove order < limit && as requirement -->
<!-- if requirement is changed, add that condition -->
<div class="mb-40 player-style" *ngIf="!player.test" (click)="detailRoute()" [ngClass]="{'single-player' :   isDowngrade != true}">
  <div *ngIf="isDowngrade == true && needToDelete > 0" class="delete-profile" (click)="deleteProfile()"><img src="../../../assets/images/delete-profile.svg" /></div>
  <div class="circle-outer-div d-flex justify-content-center align-item-center" [ngClass]="{'align-profile' :  isDowngrade == true}">
    <div class="circle-div d-flex justify-content-center align-item-center"
    [ngStyle]="{
      'background-image': 'url(' + player.profileImage + ')',
      'background-repeat': 'no-repeat',
      'background-size': 'cover'
    }"
    >
      <app-text class="short-name">{{player.playerInitial}}</app-text>
    </div>
  </div>
  <div class="d-flex justify-content-center align-item-center flex-column" style="width: 100%;padding: 10px 20px;">
    <app-text class="player-name">{{player.showName}}</app-text>
    <div class="last-active"><app-text>Last active: {{newTimeFormat}}</app-text></div>
    <!-- <div class="last-active"><app-text>Last active: {{hourAgo}}{{horD}} ago</app-text></div> -->
    <!-- <div *ngIf="player.subscription.length == 0 " routerLink="/subscription/{{player.playerID}}" (click)="Subscribe" class="btn-div">
      <app-button [class]="'btn-orange'">
        <app-text [class]="'btn-text-style2'"> Subscribe </app-text>
      </app-button>
    </div>
    <div *ngIf="expire" routerLink="/subscription/{{player.playerID}}" (click)="Subscribe" class="btn-div long">
      <app-button [class]="'btn-red'">
        <app-text [class]="'btn-text-style2'">Update payment</app-text>
      </app-button>
    </div> -->
  </div>
</div>
<app-empty-slot (addSlot)="addNewSlot($event)" *ngIf="player.test" style="width: 100%;" [slot]='order'
class="single-class"></app-empty-slot>



