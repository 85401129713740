import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PasswordApiService {
    constructor(http) {
        this.http = http;
        this.baseUrl = environment.apiurl;
    }
    sentMail(mail) {
        let url = this.baseUrl + "/forgot-password";
        return this.http.post(url, mail).pipe(map((res) => {
            // let result = [];
            let result = res;
            console.log(result);
            return result;
        }));
    }
    checkMail(token) {
        console.log('api');
        let url = this.baseUrl + "/reset-password?resetPasswordToken=" + token;
        return this.http.get(url).pipe(map((res) => {
            // let result = [];
            let result = res;
            console.log(result);
            return result;
        }));
    }
    resetPassword(token, body) {
        console.log('api');
        let url = this.baseUrl + "/reset-password?resetPasswordToken=" + token;
        return this.http.post(url, body).pipe(map((res) => {
            // let result = [];
            let result = res;
            console.log(result);
            return result;
        }));
    }
}
PasswordApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PasswordApiService_Factory() { return new PasswordApiService(i0.ɵɵinject(i1.HttpClient)); }, token: PasswordApiService, providedIn: "root" });
