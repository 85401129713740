<div class="bg-s0 gift-wrap">
    <div *ngIf="posterList && selectedPlan" class="center-wrap d-flex flex-column">
        <app-text class="title-text text-c300">STEMWerkz Gift Cards</app-text>
        <app-text class="width-55 light-body-l text-c300">
            Education is the gift that keeps giving, and the best gifts come in small packages. Give STEMWerkz as a gift for any occassion! Available in various designs.
        </app-text>
        <div class="d-flex col-rev">
          <div class="col-md-6 pad-responsive">
              <app-list-view [title]="'Choose plan'" [planList]="planList" [isListView]="false" (clickCard)="choosePlan($event)"></app-list-view>
              <div class="split-line mb-4 mt-2"></div>
              <app-list-view [title]="'Duration (month)'" [durationList]="durationList" [selectedMonth]="selectedPlan?.totalMonth" [isListView]="true" (clickMonth)="chooseDuration($event)"></app-list-view>

              <app-order-card [OrderSummary]="false" [plan]="selectedPlan"></app-order-card>

              <app-input-box-card [title]="'from'" [note]="'(Provide your information)'">
                  <app-input-box [inputLevel]="'Your name'" [type]="'text'" [placeholder]="'Type sender name'" [value]="selectedPlan?.yourName" (onInputChange)="onInputChange($event, 'your-name')"></app-input-box>
                  <app-input-box [inputLevel]="'Your email'" [type]="'email'" [placeholder]="'Type sender email'" [value]="selectedPlan?.yourEmail" [error]="your_emailError" (onInputChange)="onInputChange($event, 'your-email')"></app-input-box>
              </app-input-box-card>

              <app-input-box-card [title]="'send to'">
                  <app-input-box [inputLevel]="'Recipient’s name'" [type]="'text'" [placeholder]="'Type recipient name'" [value]="selectedPlan?.recipientName" (onInputChange)="onInputChange($event, 'recipient-name')"></app-input-box>
                  <app-input-box [inputLevel]="'Recipient’s email'" [type]="'email'" [placeholder]="'Type recipient email'" [value]="selectedPlan?.recipientEmail" [error]="recipient_emailError" (onInputChange)="onInputChange($event, 'recipient-email')"></app-input-box>
                  <app-input-box [inputLevel]="'Share a message'" [note]="'(Optional)'" [type]="'message'" [value]="selectedPlan?.message" [placeholder]="'Write gift message...'" [required]="false" [textArea]="true" (onInputChange)="onInputChange($event, 'message')"></app-input-box>
              </app-input-box-card>

              <app-order-card [OrderSummary]="true" [plan]="selectedPlan"></app-order-card>

              <div class="mt-2 mb-4">
                  <app-button [class]="selectedPlan.recipientName && selectedPlan.recipientEmail && selectedPlan.yourName && selectedPlan.yourEmail && !recipient_emailError && !your_emailError? 'btn-primary-l' : 'btn-disable'"
                  [width]="'200px'" [height]="'48px'" [loading]="loadingPurchaseButton" (btnClick)="onPurchaseGift()">
                      <app-text class="body-l text-s0">{{ loadingPurchaseButton? 'Purchasing...' : 'Purchase gift' }}</app-text>
                  </app-button>
              </div>
              <div class="body-l mb-107">
                  <app-text class="text-c100">Purchasing gift cards in bulk?</app-text>
                  <app-text class="text-accent500 cursor" (click)="contactUs()">&nbsp;Contact us!</app-text>
              </div>

          </div>
          <div class="col-md-6">
              <div class="poster-img">
                  <app-poster-card-list [posterList]="posterList" [currentIndex]="currentPreviewImgIndex" [currentPlan]="selectedPlan" (clickLeft)="onClickLeft($event)" (clickRight)="onClickRight($event)" (clickImage)="onClickImage($event)"></app-poster-card-list>
              </div>
          </div>
        </div>
    </div>
    <div *ngIf="posterList==null || planList==null" class="loading-cover d-flex align-items-center justify-content-center">
        <app-loader [width]="'72px'" [height]="'72px'"></app-loader>
    </div>
    <div *ngIf="posterList?.length==0 && planList?.length==0" class="center-wrap p-4">
      <app-text class="body-l text-c300">Something went wrong!</app-text>
    </div>
</div>
