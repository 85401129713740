import { Routes } from '@angular/router';
import { HomeSignupControllerComponent } from './home/presentation/home-signup/home-signup-controller.component';
import { HomeLoginControllerComponent } from './home/presentation/home-login/home-login-controller.component';
import { DashboardHomeControllerComponent } from './dashboard/presentation/dashboard-home/dashboard-home-controller.component';
import { SchoolPlansControllerComponent } from './school-plans/presentation/school-plans/school-plans-controller.component';
import { PlansChosenControllerComponent } from './user-plans/presentation/plans-chosen/plans-chosen-controller.component';
import { RedirectHomeControllerComponent } from './redirect/presentation/redirect-home/redirect-home-controller.component';
import { ParentDetailHomeControllerComponent } from './parent-detail/presentation/parent-detail-home/parent-detail-home-controller.component';
import { ForgotPasswordHomeControllerComponent } from './forgot-password/presentation/forgot-password-home/forgot-password-home-controller.component';
import { ResetPasswordHomeControllerComponent } from './forgot-password/presentation/reset-password-home/reset-password-home-controller.component';
import { PageNotFoundHomeControllerComponent } from './page-not-found/presentation/page-not-found-home/page-not-found-home-controller.component';
import { SchoolSignupControllerComponent } from './school/presentation/school-signup/school-signup-controller.component';
import { CodeVerificationControllerComponent } from './code-verification/presentation/code-verification/code-verification-controller.component';
import { SchoolPlansChosenControllerComponent } from './school-plans/presentation/school-plans-chosen/school-plans-chosen-controller.component';
import { SchoolLoginControllerComponent } from './school/presentation/school-login/school-login-controller.component';
import { PlansControllerControllerComponent } from './user-plans/presentation/plans-controller/plans-controller-controller.component';
const ɵ0 = () => import("./gift-card-shop/gift-card-shop.module.ngfactory").then((m) => m.GiftCardShopFeatureModuleNgFactory);
const routes = [
    {
        path: '',
        component: HomeSignupControllerComponent
    },
    // { path: '', pathMatch: 'full', redirectTo: 'redirect'},
    {
        path: 'redirect',
        component: RedirectHomeControllerComponent
    },
    {
        path: 'dashboard',
        component: DashboardHomeControllerComponent
    },
    {
        path: 'login',
        component: HomeLoginControllerComponent
    },
    {
        path: 'signup',
        component: HomeSignupControllerComponent
    },
    {
        path: 'plans',
        component: PlansControllerControllerComponent
    },
    {
        path: 'plans/:id',
        component: PlansChosenControllerComponent
    },
    {
        path: 'plans/downgrade/:id',
        component: DashboardHomeControllerComponent
    },
    {
        path: 'changeplan/:id',
        component: PlansControllerControllerComponent
    },
    {
        path: 'claim-giftcard',
        component: PlansChosenControllerComponent
    },
    {
        path: 'parent/detail',
        component: ParentDetailHomeControllerComponent
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordHomeControllerComponent
    },
    {
        path: 'reset',
        component: ResetPasswordHomeControllerComponent
    },
    {
        path: 'store',
        loadChildren: ɵ0
    },
    {
        path: 'school-signup',
        component: SchoolSignupControllerComponent
    },
    {
        path: 'school/signup',
        component: CodeVerificationControllerComponent
    },
    {
        path: 'school-login',
        component: SchoolLoginControllerComponent
    },
    {
        path: 'school-plans',
        component: SchoolPlansControllerComponent
    },
    {
        path: 'school-plans/:id',
        component: SchoolPlansChosenControllerComponent
    },
    {
        path: '**',
        component: PageNotFoundHomeControllerComponent
    }
];
export class AppRoutingModule {
}
export { ɵ0 };
