<app-mail-to *ngIf="mailto" (closeMailTo)="closeMailTo()"></app-mail-to>
<app-edit-profile
  [loading]="editLoading"
  [emailOnOff]="emailOnOff"
  *ngIf="editFlagForParent"
  [playerDetail]="parentData"
  [countriesList]="countriesList"
  [currentCountry]="currentCountry"
  [child]="false"
  (closeBtn)="closeEdit($event)"
  (editPlayerBtn)="editPlayerApi($event)"
  class="cover-all"
></app-edit-profile>

<app-reset-pwpopup
  [loading]="resetLoading"
  *ngIf="resetFlag"
  class="cover-all"
  (closeBtn)="closeReset($event)"
  (resetPasswordBtn)="resetPassword($event)"
></app-reset-pwpopup>

<app-unsubscribe-popup
  *ngIf="unsubscribePopup"
  [playerDetail]="parentData"
  class="cover-all"
  (closeBtn)="closeCancel($event)"
  (unsubscribePlayerBtn)="unsubscribePlayerConfirm($event)"
  [expireDate]="currentPlan.userSubInfo.expiryDate"
></app-unsubscribe-popup>

<div class="detail-container">
  <app-header (openProfile)="openProfile($event)" [parentData]="parentData"></app-header>
  <div (click)="backPage()" class="back-div">
    <img class="mr-8" src="../../../../assets/images/back-btn.svg" /> Back
  </div>

  <app-warningbox
    [boxType]="boxType"
    [currentPlan]="currentPlan"
    (outputBoxType)="boxTypeClicked($event)"
    *ngIf="isMobileView"
  ></app-warningbox>
  <div class="d-flex history-div">
    <div class="profile-detail" *ngIf="!onlyPaymentHistory">
      <app-player-info
        [playerDetail]="parentData"
        (editPlayercall)="editPlayer($event)"
        (resetPlayercall)="resetPlayer($event)"
      ></app-player-info>
    </div>
    <div class="payment-detail">
      <div *ngIf="paymentLoading" style="width: 100%; height: 100%">
        <div>
          <app-loader [spinColor]="'#b1b1b3'" [height]="'30px'" [width]="'150px'"></app-loader>
          <div class="d-flex">
            <div style="margin-right: 40px">
              <app-loader [spinColor]="'#b1b1b3'" [height]="'100px'" [width]="'150px'"></app-loader>
            </div>
            <div>
              <app-loader [spinColor]="'#b1b1b3'" [height]="'100px'" [width]="'250px'"></app-loader>
            </div>
          </div>
          <div class="d-flex">
            <div style="margin-right: 40px">
              <app-loader [spinColor]="'#b1b1b3'" [height]="'100px'" [width]="'150px'"></app-loader>
            </div>
            <div>
              <app-loader [spinColor]="'#b1b1b3'" [height]="'100px'" [width]="'250px'"></app-loader>
            </div>
          </div>
        </div>
        <div style="margin-top: 80px">
          <app-loader [spinColor]="'#b1b1b3'" [height]="'30px'" [width]="'150px'"></app-loader>
          <app-loader [spinColor]="'#b1b1b3'" [height]="'80px'" [width]="'100%'"></app-loader>
        </div>
      </div>
      <app-payment-info
        *ngIf="!paymentLoading"
        [playerDetail]="parentData"
        [currentPlan]="currentPlan"
        [loading]="subLoading"
        [player]="player"
        [onlyPaymentHistory]="onlyPaymentHistory"
        (subscribeClick)="upgradeSubscription($event)"
        (unsubscribeClick)="unSubscribe($event)"
        (planChange)="changePlan()"
        (accountManagement)="changePayment()"
        (resubscribe)="resubscribe($event)"
        (hideAll)="hideAll()"
        (outputBoxType)="determineBoxType($event)"
      ></app-payment-info>
    </div>
  </div>
</div>
<app-query-params-ls></app-query-params-ls>
