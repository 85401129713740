import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SharedService } from 'src/app/lib/shared-service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  @Output() closeBtn = new EventEmitter<any>();
  @Output() editPlayerBtn = new EventEmitter<any>();
  @Input() playerDetail;
  @Input() emailOnOff;
  @Input() loading;
  @Input() child = true;
  @Input() countriesList;
  @Input() currentCountry;

  private emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public clickBtn = false;
  public alertURL = './assets/images/alert-icon.svg';
  public playerData = {};
  public emailFlag: boolean = false;
  public tickURL = './assets/images/tick-icon.svg';
  public fNameClass = 'signup-input';
  public lNameClass = 'signup-input';
  public emailClass = 'signup-input';
  public fTick = false;
  public lTick = false;
  public eTick = false;
  public colorCode;
  public isMobileView;
  public mobileViewService;
  public dobUrl = './assets/images/chevron_down_l.svg';
  public showYearBox = false;
  public yearList = [];
  public yearOld;
  public date = new Date();
  public minAge = 3;
  public maxAge = 70;
  public keyword = '';
  public openYear = false;
  public gender = {
    boy: 'btn-gender boy',
    girl: 'btn-gender girl',
    i_prefer_not_to_say: 'btn-gender i-prefer-not-to-say'
  };
  private getGender = '';
  // added country
  // public countriesList;
  // public currentCountry;
  public selectedCountry;

  ngOnDestroy() {
    let body = (document.getElementsByTagName('body')[0].style.overflow = 'auto');
    this.mobileViewService.unsubscribe();
  }

  public form;
  constructor(private fb: FormBuilder, private shared: SharedService) {}

  @HostListener('window:resize', ['$event'])
  onResize(e) {
    if (this.playerDetail.email != '') {
      this.emailFlag = true;
      let outerframe = document.querySelectorAll<HTMLElement>('.outer-frame');
      if (this.isMobileView && !this.showYearBox) outerframe[0].style.height = '905px';
      else if (this.isMobileView && this.showYearBox) outerframe[0].style.height = '1150px';
      else if (!this.isMobileView && !this.showYearBox) outerframe[0].style.height = '680px';
      else outerframe[0].style.height = '905px';
    }
  }

  ngOnChanges() {
    let outerframe = document.querySelectorAll<HTMLElement>('.outer-frame');
    if (this.playerDetail.email != '') {
      this.emailFlag = true;
      if (this.isMobileView && !this.openYear) outerframe[0].style.height = '905px';
      else if (this.isMobileView && this.openYear) outerframe[0].style.height = '1150px';
      else if (!this.isMobileView && !this.openYear) outerframe[0].style.height = '680px';
      else outerframe[0].style.height = '905px';
    }
  }

  ngOnInit() {
    this.getYearList();
    this.mobileViewService = this.shared.GetMobileView.subscribe((data) => {
      this.isMobileView = data;
    });
    if (this.playerDetail.email != '') {
      this.emailFlag = true;
      let outerframe = document.querySelectorAll<HTMLElement>('.outer-frame');
      if (this.isMobileView && !this.showYearBox) outerframe[0].style.height = '1076px';
      else if (this.isMobileView && this.showYearBox) outerframe[0].style.height = '1150px';
      else if (!this.isMobileView && !this.showYearBox) outerframe[0].style.height = '770px';
      else outerframe[0].style.height = '905px';
    }
    console.log(this.playerDetail);
    if (this.playerDetail.gender) {
      console.log(this.playerDetail.gender);
      this.getGender = this.playerDetail.gender.split('-').join('_');
      console.log(this.getGender);
      this.gender[this.getGender] = this.gender[this.getGender] + ' btn-select';
    }
    this.form = this.fb.group({
      firstName: this.fb.control(this.playerDetail.firstName, [Validators.required]),
      lastName: this.fb.control(this.playerDetail.lastName, [Validators.required]),
      year: this.fb.control(this.playerDetail.metadata.yearOfBirth, []),
      email: this.fb.control(this.playerDetail.email, [Validators.pattern(this.emailPattern)])
    });

    if (this.emailOnOff == true) {
      this.emailFlag = true;
      let outerframe = document.querySelectorAll<HTMLElement>('.outer-frame');
      if (this.isMobileView && !this.showYearBox) outerframe[0].style.height = '905px';
      else if (this.isMobileView && this.showYearBox) outerframe[0].style.height = '1150px';
      else if (!this.isMobileView && !this.showYearBox) outerframe[0].style.height = '680px';
      else outerframe[0].style.height = '905px';
      this.form = this.fb.group({
        firstName: this.fb.control(this.playerDetail.firstName, [Validators.required]),
        lastName: this.fb.control(this.playerDetail.lastName, [Validators.required]),
        year: this.fb.control(this.playerDetail.metadata.yearOfBirth, []),
        email: this.fb.control(this.playerDetail.email, [Validators.required, Validators.pattern(this.emailPattern)])
      });
    }

    let body = (document.getElementsByTagName('body')[0].style.overflow = 'hidden');
    window.scrollTo(0, 0);
    this.form.valueChanges.subscribe((value) => {
      console.log(value);
      this.playerData = value;
      this.clickBtn = false;
      this.fNameClass = 'signup-input';
      this.lNameClass = 'signup-input';
      this.emailClass = 'signup-input';
    });
    this.form.get('firstName').valueChanges.subscribe(() => {
      this.fTick = true;
    });
    this.form.get('lastName').valueChanges.subscribe(() => {
      this.lTick = true;
    });
    this.form.get('email').valueChanges.subscribe(() => {
      this.eTick = true;
    });
    this.form.get('year').valueChanges.subscribe(() => {
      if (this.showYearBox) {
        this.keyword = this.form.get('year').value;
      }
    });

    console.log(this.playerDetail);
    this.yearOld = this.date.getFullYear() - Number(this.playerDetail.metadata.yearOfBirth);
    // console.log(this.date.getFullYear(),this.playerDetail.metadata,this.date.getFullYear() - Number(this.playerDetail.metadata.dateOfBirth))
  }

  blurInput($event) {
    console.log('get blur event');
    this.fTick = false;
    this.lTick = false;
    this.eTick = false;
  }

  closeEdit($event) {
    if (!this.loading) {
      document.getElementById('outer-frame').classList.add('outer-frame-close');
      setTimeout(() => {
        this.closeBtn.emit($event);
      }, 100);
    }
  }

  emailOption() {
    let outerframe = document.querySelectorAll<HTMLElement>('.outer-frame');
    if (this.isMobileView && !this.showYearBox) outerframe[0].style.height = '1076px';
    else if (this.isMobileView && this.showYearBox) outerframe[0].style.height = '1300px';
    else if (!this.isMobileView && !this.showYearBox) outerframe[0].style.height = '770px';
    else outerframe[0].style.height = '985px';
    setTimeout(() => {
      this.emailFlag = true;
    }, 500);
  }

  editPlayer($event) {
    this.clickBtn = true;
    this.fTick = false;
    this.lTick = false;
    this.eTick = false;
    console.log(this.form.value);
    this.playerData = this.form.value;
    if (this.playerDetail.metadata.yearOfBirth)
      this.playerData['yearOfBirth'] = this.playerDetail.metadata.yearOfBirth.toString();
    if (this.playerDetail.gender) this.playerData['gender'] = this.playerDetail.gender;
    if (this.selectedCountry) this.playerData['country'] = this.selectedCountry;
    if (this.form.valid) {
      console.log(this.playerData['email']);
      const getEmail = this.playerData['email'];
      if (getEmail.trim() == '') {
        delete this.playerData['email'];
      }

      console.log(this.playerData);
      this.editPlayerBtn.emit(this.playerData);
    } else {
      console.log('form error ');
      if (this.form.get('firstName').errors) {
        this.fNameClass = 'input-err';
      }
      if (this.form.get('lastName').errors) {
        this.lNameClass = 'input-err';
      }
      if (this.form.get('email').errors) {
        this.emailClass = 'input-err';
      }
    }
  }

  selectGenter(ret) {
    console.log(ret);
    this.playerDetail.gender = ret;
  }

  choseYear(year) {
    this.form.controls['year'].setValue(year);
    this.playerDetail.metadata.yearOfBirth = year.toString();
    this.yearOld = this.date.getFullYear() - year;
    this.showYearBox = false;
    this.keyword = '';
  }

  selectedYear(year) {
    this.playerDetail.metadata.yearOfBirth = year.toString();
    this.yearOld = this.date.getFullYear() - year;
    console.log(this.playerDetail.metadata.yearOfBirth, this.yearOld);
  }

  getYearList() {
    for (var i = this.minAge; i <= this.maxAge; i++) {
      this.yearList.push((this.date.getFullYear() - i).toString());
    }
    // this.yearList.reverse();
  }

  checkOpen(e) {
    if (this.openYear) return;
    else this.editPlayer(e);
  }

  chosenCountry(value) {
    this.selectedCountry = value;
    this.playerData['country'] = this.selectedCountry;
    console.log('selected country ', this.playerData['country']);
    console.log(this.playerData);
  }

  isOpenYear(value) {
    this.openYear = value == 'false' ? false : true;
    let outerframe = document.querySelectorAll<HTMLElement>('.outer-frame');
    if (this.openYear && this.emailFlag) {
      console.log('OO pp ee nn');

      if (this.isMobileView) outerframe[0].style.height = '1300px';
      else outerframe[0].style.height = '985px';
    } else if (!this.openYear && this.emailFlag) {
      if (this.isMobileView) outerframe[0].style.height = '1076px';
      else outerframe[0].style.height = '770px';
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(e) {
    var target = e.target;
    if (!target.closest('.dob-select')) {
      console.log('first', target);
      this.showYearBox = false;
    } else {
      console.log('second');
      this.showYearBox = !this.showYearBox;
    }
    if (!this.showYearBox && !this.playerDetail.metadata.yearOfBirth) {
      console.log('third');
      // this.form.controls['year'].setValue('');
      this.keyword = '';
    }
  }
}
