<div class="header-bg">
    <app-image [src]="headerBg" [class]="'forgot-banner'"></app-image>
</div>
<div class="body-wrapper w-100">
    <div class="d-flex align-items-center flex-column padding-mobile">
        <div style="margin-top: 24px;">
            <app-image class="desktop-view" [src]="logo"></app-image>
            <app-image class="mobile-view" [src]="logoMobile"></app-image>
        </div>
        <div class="password-card bg-s0 d-flex flex-column" (keyup.enter)="$event.preventDefault(); submit()">
            <app-text class="card-title">Reset your password</app-text>
            <div class="mb-32">
                <div class="d-flex align-item-center justify-content-between">
                  <div class="title">New Password</div>
                  <div class="create-password">
                    <app-text
                      *ngIf="show"
                      [class]="'showPswd'"
                      (textClick)="showPassword($event)"
                    >
                      Show
                    </app-text>
                    <app-text
                      *ngIf="hide"
                      [class]="'showPswd'"
                      (textClick)="hidePassword($event)"
                    >
                      Hide
                    </app-text>
                  </div>
                </div>
                <app-input
                  [hint]="'Type your password'"
                  [control]="form.get('newPassword')"
                  [width]="'100%'"
                  [class]="passwordClass"
                  [type]="togglePassword"
                  (blurEvent)="blurInput($event)"
                >
                  <app-image
                    *ngIf="!form.get('newPassword').errors && pTick"
                    [src]="tickURL"
                  ></app-image>
                  <app-image
                    *ngIf="form.get('newPassword').errors && clickBtn"
                    [src]="alertURL"
                  ></app-image>
                </app-input>
                <app-text
                  *ngIf="form.get('newPassword').errors && clickBtn"
                  [class]="'form-error-label'"
                  >Passwords must be 8 characters or more</app-text
                >
                <div
                  *ngIf="!form.get('newPassword').errors || !clickBtn"
                  class="warning"
                >
                  Passwords must be 8 characters or more
                </div>
              </div>
              <div class="mb-32">
                <div class="title">Confirm Password</div>
                <app-input
                  [hint]="'Type your password'"
                  [control]="form.get('confirmPassword')"
                  [width]="'100%'"
                  [class]="confirmPClass"
                  [type]="togglePassword"
                  (blurEvent)="blurInput($event)"
                >
                  <app-image
                    *ngIf="!form.get('confirmPassword').errors && cTick"
                    [src]="tickURL"
                  ></app-image>
                  <app-image
                    *ngIf="
                      (form.get('confirmPassword').errors || misPass) && clickBtn && !cTick
                    "
                    [src]="alertURL"
                  ></app-image>
                </app-input>
                <app-text
                  *ngIf="( misPass) && clickBtn"
                  [class]="'form-error-label'"
                  >Passwords do not match</app-text
                >
              </div>



              <app-button [class]="btnStatus" (click)="submit()">
                <app-text [class]="btnTextStatus">
                    {{btnText}}
                </app-text>
              </app-button>

        </div>
    </div>
</div>

<app-custom-feedback *ngIf="feedbackFlag" [feedbackTitle]="feedbackTitle" [tickIcon]="tickIcon" [feedbackBody]="feedbackBody" [feedbackBtn]="feedbackBtn" (dimissFeedbackBtn)="dimissFeedbackBtn($event)"></app-custom-feedback>
<app-query-params-ls></app-query-params-ls>
