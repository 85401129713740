import {DataRequirements} from '../business-logic/data.requirements';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {PlanApiService} from './api-services/plan-api.services';
import { Observable } from 'rxjs';
import { PlayerDetailService } from './api-services/player-detail.service';
import { PlayersService } from './api-services/players.service';

@NgModule({
    imports: [HttpClientModule]
})
export class DataFacade implements DataRequirements {
    constructor(
      private planApi: PlanApiService,
      private playerDetailService: PlayerDetailService,
      private playersService: PlayersService
    ) {}

    getPlanList() : Observable<any>{
      return this.planApi.getPlanList();
    }
    getPromoInfo(tag) : Observable<any>{
      return this.planApi.getPromoInfo(tag);
    }
    getSubscription(): Observable<any>{
      return this.planApi.getSubscription();
    }
    subscribeUser(data): Observable<any> {
      return this.planApi.subscribeUser(data);
    }
    getPublishKey(): Observable<any> {
      return this.planApi.getPublishKey();
    }
    getPlan(id): Observable<any>{
      return this.planApi.getPlan(id);
    }
    getPlyerDetail(id): Observable<any>{
      return this.playerDetailService.getPlayer(id);
    }
    getGiftInfo(id): Observable<any>{
      return this.planApi.getGiftInfo(id);
    }
    claimGiftCard(id, opt, redir): Observable<any>{
      return this.planApi.claimGiftCard(id, opt, redir);
    }
    checkEmptySub():Observable<any>{
      return this.playerDetailService.checkEmptySub();

    }
    getPlayers(id): Observable<any> {
      return this.playersService.getUser(id);
    }

    getParentDetail(id): Observable<any> {
      return this.playersService.getParent(id);
    }
}
