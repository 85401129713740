import {Component, EventEmitter, Input, Output} from '@angular/core';
import {InformationStore} from './information.store'

@Component({
    selector: 'app-information',
    templateUrl: './information.component.html',
    styleUrls: ['./information.component.css']
})
export class InformationComponent {
  @Output() buttonClick = new EventEmitter;
  @Input() buttonText;
  constructor(private store: InformationStore) {}

  onClick($event) {
    this.buttonClick.emit();
  }
}
