<app-create-playerpopup
  class="cover-all"
  *ngIf="playerPopup"
  (closePlayerPopup)="CloseForm($event)"
  (createPlayerBtn)="create($event)"
  [errMsg]="errmsg"
  [pleaseWait]="createBtnLoading"
></app-create-playerpopup>

<app-user-profile
  *ngIf="profileFlag"
  class="cover-all"
  (closeProfile)="closeProfile($event)"
  (editUser)="editUser($event)"
  (deleteUser)="deleteUser($event)"
  (UserPasswordChange)="UserPasswordChange($event)"
  [parentData]="playerData"
></app-user-profile>

<app-delete-popup
  [playerDetail]="playerDetail"
  *ngIf="deleteFlag"
  class="cover-all"
  (closeBtn)="closeDelete($event)"
  (deletePlayerBtn)="deletePlayerApi()"
></app-delete-popup>

<app-edit-profile
  [loading]="editLoading"
  *ngIf="editFlag"
  [playerDetail]="playerDetail"
  (closeBtn)="closeEdit($event)"
  (editPlayerBtn)="editParentApi($event)"
  class="cover-all"
></app-edit-profile>

<app-reset-pwpopup
  [loading]="resetLoading"
  *ngIf="resetFlag"
  class="cover-all"
  (closeBtn)="closeReset($event)"
  (resetPasswordBtn)="resetPassword($event)"
></app-reset-pwpopup>

<app-hooray
  *ngIf="hooray"
  [parentData]="parentData"
  [playerData]="playerDataTemp"
  (emailCopyApi)="emailCopyApi()"
  (closeHooray)="closeHooray()"
  (confirmZone)="confirmZone($event)"
  class="cover-all"
  [zone]="zone"
  [zoneList]="zoneList"
></app-hooray>

<app-email-success
  *ngIf="emailSuccessFlag"
  [success]="emailSent"
  [parentData]="parentData"
  (tryAgainEmail)="emailCopyApi()"
></app-email-success>

<div class="dashboard-container">
  <app-header
    [parentData]="parentData"
    (openProfile)="openProfile($event)"
    [explore]="isExplore"
    (exploreClick)="explore()"
    (portalClick)="portal()"
    (changePlan)="changePlan($event)"
    [createdCount]="createdCount"
    [zoneList]="zoneList"
  ></app-header>
  <div (click)="backPage()" class="back-div" *ngIf="isDowngrade == true">
    <img class="mr-8" src="../../../../assets/images/back-btn.svg" /> Back
  </div>
  <div class="mobile-plr-16" *ngIf="isDowngrade != true">
    <app-text class="greeting-heading">Good {{ timeRange }}, {{ parentData.fullName }} </app-text>
  </div>
  <div class="mobile-plr-16" *ngIf="isDowngrade == true">
    <app-text class="greeting-heading">Before we continue</app-text>
  </div>
  <div class="profiles-container d-flex flex-column justify-content-center">
    <app-warning-box
      *ngIf="showWarningBox"
      [usersubinfo]="usersubinfo"
      [boxType]="boxType"
      (outputWarningBox)="warningBoxClicked($event)"
    ></app-warning-box>

    <app-player-profiles
      style="width: 100%"
      [players]="players"
      [loader]="loader"
      [createdCount]="createdCount"
      [playerLimit]="playerLimit"
      [isDowngrade]="isDowngrade"
      [needToDelete]="needToDelete"
      (addSlot)="createPlayer($event)"
      (openplayerProfile)="openplayerProfile($event)"
      (planChange)="changePlan($event)"
      class="d-flex justify-content-center"
      (removePlayer)="removePlayer($event)"
    ></app-player-profiles>
  </div>

  <div *ngIf="showPlayers == 'noplayers'" class="no-player d-flex justify-content-center" style="text-align: center">
    <app-text>
      No child account created.<br />
      Click <span style="font-weight: bold">Add new child account</span> to create one.
    </app-text>
  </div>
  <div *ngIf="showContinue == true && needToDelete > 0" class="d-flex justify-content-center">
    <app-text *ngIf="needToDelete > 1" class="delete-text"
      >Delete {{ needToDelete }} more accounts to continue</app-text
    >
    <app-text *ngIf="needToDelete == 1" class="delete-text"
      >Delete {{ needToDelete }} more account to continue</app-text
    >
  </div>
  <div *ngIf="showContinue == true" class="d-flex justify-content-center delete-btn">
    <button
      type="submit"
      class="cursor d-flex justify-content-center align-item-center"
      (click)="continue()"
      [ngClass]="{ continueButton: needToDelete == 0, 'btn-disable': needToDelete > 0 }"
    >
      <app-text [class]="'btn-text-style2'">Continue </app-text>
    </button>
  </div>
</div>
<app-query-params-ls></app-query-params-ls>
