<div class="d-flex justify-content-center align-items-center w-100 gift-fail-wrapper bg-s0">
  <div>
    <div class="d-flex justify-content-center">
      <div class="img-wrapper">
        <app-image [src]="'./assets/images/gift-fail.svg'" [width]="'100%'" [height]="'100%'"></app-image>
      </div>
    </div>
    <div class="pt-4">
      <app-text class="gift-fail-title text-c300">Oh no! Something went wrong!</app-text>
    </div>
    <div class="gift-fail-text">
      <app-text class="text-c300 light-body-l">Your purchase could not be completed.
        How about we give it another go?</app-text>
    </div>
    <div class="d-flex justify-content-center">
      <app-button [class]="'btn-primary-l'" (btnClick)="onTryAgainClick()">
        <app-text class="text-s0 h4-strong">Try again</app-text>
      </app-button>
    </div>
  </div>
</div>
