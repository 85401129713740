<div *ngIf="posterList && currentPlan" class="d-flex flex-column posterWrap">
    <app-text class="h4-strong text-c300">Pick your design</app-text>
    <div class="mt-2">
        <div class="review-image bg-s30">
            <app-image [src]="posterList[currentIndex].posterImageUrl" [width]="'100%'" [borderRadius]="'24px'"></app-image>
            <div class="switch-button bg-c100 left-switch cursor d-flex justify-content-center align-items-center" (click)="onClickLeftButton()">
                <app-image [src]="'./assets/images/left_arrow.svg'"></app-image>
            </div>
            <div class="switch-button bg-c100 right-switch cursor d-flex justify-content-center align-items-center" (click)="onClickRighttButton()">
                <app-image [src]="'./assets/images/right_arrow.svg'"></app-image>
            </div>
            <div class="plan-brush">
                <app-image [src]="'./assets/images/planType_brush.svg'"></app-image>
                <app-text class="h4-strong" [ngClass]="currentPlan.planName=='Solo'? 'text-secondary700' : currentPlan.planName=='Duo'? 'text-accent500' : 'text-main700 left-15'">{{currentPlan.planName}}</app-text>
            </div>
            <div class="plan-month bg-s0">
                <app-text class="h2-strong text-c300">{{currentPlan.totalMonth}} <app-text class="body-m">{{currentPlan.totalMonth>1? ' months' : ' month'}}</app-text></app-text>
              </div>
        </div>
    </div>
    <div id="image-scroll" class="image-list">
      <div *ngFor="let poster of posterList; let i = index" class="image-card bg-s30 cursor" [ngClass]="i==currentIndex? 'image-current' : ''" (click)="onClickImage(i)">
          <app-image [src]="poster.posterImageUrl" [borderRadius]="'16px'" [width]="i==currentIndex? '152px' : '156px'" [height]="i==currentIndex? '84px' : '88px'"></app-image>
      </div>
    </div>
    <app-text class="body-l text-c300 mb-2">{{posterList[currentIndex].description}}</app-text>
</div>
