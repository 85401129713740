import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PlayersService {
    constructor(http) {
        this.http = http;
        this.baseUrl = environment.apiurl;
    }
    getUser(id) {
        let url = this.baseUrl + '/api/v2/users/' + id + '/players';
        return this.http.get(url).pipe(map((res) => {
            let result = res;
            return result;
        }));
    }
    getParent(parentId) {
        let url = this.baseUrl + "/api/v2/users/" + parentId;
        console.log(url);
        return this.http.get(url).pipe(map((res) => {
            // let result = [];
            let result = res;
            console.log(result);
            return result;
        }));
    }
}
PlayersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlayersService_Factory() { return new PlayersService(i0.ɵɵinject(i1.HttpClient)); }, token: PlayersService, providedIn: "root" });
