import { Component, EventEmitter, Output } from "@angular/core";
import { LoginHeaderStore } from "./login-header.store";
import { Router } from "@angular/router";

@Component({
  selector: "app-login-header",
  templateUrl: "./login-header.component.html",
  styleUrls: ["./login-header.component.css"],
})
export class LoginHeaderComponent {
  constructor(private store: LoginHeaderStore, private router: Router) {}
  @Output() signUp = new EventEmitter;
  showSignup($event) {
    console.log("showSignup");
    // environment.initState = "login";
    // this.router.navigateByUrl("signup");
    this.signUp.emit();
  }
}
