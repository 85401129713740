import { Inject, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/lib/shared-service';
import { environment } from 'src/environments/environment';
import { BusinessLogicRequirements, BusinessRequirementsInjectionToken } from '../business-logic.requirements';
import { ResetPasswordHomeStore } from './reset-password-home.store';

@Component({
  selector: 'app-reset-password-home',
  templateUrl: './reset-password-home-controller.component.html',
  styleUrls: ['./reset-password-home-controller.component.css']
})
export class ResetPasswordHomeControllerComponent {
  constructor(
    @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements,
    private store: ResetPasswordHomeStore,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _shared: SharedService
  ) {}

  public headerBg = '../assets/images/yellow-bg.png';
  public logo = '../assets/images/STEMWerkzLogo.png';
  public logoMobile: any = './assets/images/STEMWerkzLogoMobile.png';
  public alertURL = './assets/images/alert-icon.svg';
  public tickURL = './assets/images/tick-icon.svg';

  public form: any;
  public btnStatus = 'btn-disable';
  public btnTextStatus = 'btn-disable-text';
  public showText = true;
  public togglePassword = 'password';
  public newPassword = '';
  public confirmPassword = '';
  public misPass = false;
  public cTick = false;
  public pTick = false;
  public clickBtn = false;
  public passwordClass = 'signup-input';
  public confirmPClass = 'signup-input';
  public show = true;
  public hide = false;
  public btnText = 'Reset password';

  public feedbackFlag = false;
  public feedbackTitle = '';
  public tickIcon = false;
  public feedbackBody = '';
  public feedbackBtn = {
    status: false,
    text: 'Dismiss'
  };
  public token = '';
  public title = environment.titleResetPassword;

  ngOnInit() {
    this._shared.setDocTitle(this.title);
    this.route.queryParams.subscribe((data) => {
      console.log(data.resetPasswordToken);
      this.token = data.resetPasswordToken;
      localStorage.setItem('token', this.token);
    });
    this.clickBtn = false;
    this.form = this.fb.group({
      newPassword: this.fb.control('', [Validators.required, Validators.minLength(8)]),
      confirmPassword: this.fb.control('', [Validators.required, Validators.minLength(8)])
    });

    this.form.get('newPassword').valueChanges.subscribe((value) => {
      this.misPass = false;
      this.newPassword = value;
      console.log(this.newPassword.length, this.confirmPassword.length);
      if (this.newPassword.length != this.confirmPassword.length) {
        this.newPassword = value;
        this.pTick = false;
        this.btnStatus = 'btn-disable';
        this.btnTextStatus = 'btn-disable-text';
      } else {
        this.pTick = true;
        this.btnStatus = 'btn-orange';
        this.btnTextStatus = 'btn-text-style2';
      }
    });
    this.form.get('confirmPassword').valueChanges.subscribe((value) => {
      this.misPass = false;
      this.confirmPassword = value;
      if (this.newPassword.length != this.confirmPassword.length) {
        this.confirmPassword = value;
        this.cTick = false;
        this.btnStatus = 'btn-disable';
        this.btnTextStatus = 'btn-disable-text';
      } else {
        this.cTick = true;
        this.btnStatus = 'btn-orange';
        this.btnTextStatus = 'btn-text-style2';
      }
    });
  }

  blurInput($event) {
    console.log('get blur event');
    this.pTick = false;
    this.cTick = false;
  }

  showPassword($event) {
    this.togglePassword = 'text';
    this.show = false;
    this.hide = true;
  }

  hidePassword($event) {
    this.togglePassword = 'password';
    this.show = true;
    this.hide = false;
  }

  dimissFeedbackBtn(event) {
    this.feedbackFlag = false;
  }

  submit() {
    this.clickBtn = true;
    if (this.form.valid) {
      if (this.newPassword !== this.confirmPassword) {
        this.misPass = true;
        this.cTick = false;
        this.pTick = false;
      } else {
        console.log('can emit');
        this.btnText = 'Resetting...';
        this.business.checkMail(this.token).subscribe(
          (data) => {
            console.log('check email');
            var body = {
              password: this.newPassword
            };
            this.business.resetPassword(this.token, body).subscribe(
              (data) => {
                this.feedbackTitle = data.message;
                this.feedbackBody = '';
                this.feedbackBtn = {
                  status: false,
                  text: 'Dismiss'
                };
                this.tickIcon = true;
                this.feedbackFlag = true;
                setTimeout(() => {
                  this.feedbackFlag = false;
                  this.router.navigateByUrl('/login');
                }, 3000);
                this.btnText = 'Reset password';
                this.btnStatus = 'btn-orange';
                this.btnTextStatus = 'btn-text-style2';
              },
              (err) => {
                console.log(err);
                console.log(err.statusCode == 406, err.statusCode == '406');
                this.feedbackTitle = err.error.message ? err.error.message : 'Something wrong';
                this.feedbackBody = '';
                this.feedbackBtn = {
                  status: false,
                  text: 'Dismiss'
                };
                this.tickIcon = false;
                this.feedbackFlag = true;
                setTimeout(() => {
                  this.feedbackFlag = false;
                }, 3000);
                this.btnText = 'Submit';
                this.btnStatus = 'btn-orange';
                this.btnTextStatus = 'btn-text-style2';
              }
            );
          },
          (err) => {
            console.log('check email error', err.status == '406');
            if (err.status == 406) this.router.navigateByUrl('/forgot-password?expire=true');
            this.feedbackTitle = err.error.message ? err.error.message : 'Something wrong';
            this.feedbackBody = '';
            this.feedbackBtn = {
              status: false,
              text: 'Dismiss'
            };
            this.tickIcon = false;
            this.feedbackFlag = true;
            setTimeout(() => {
              this.feedbackFlag = false;
              // if(err.status == 406){
              //   this.router.navigateByUrl('/forgot-password?expire=true');
              // }
            }, 3000);
            this.btnText = 'Submit';
            this.btnStatus = 'btn-orange';
            this.btnTextStatus = 'btn-text-style2';
          }
        );
      }
    } else {
      console.log('form error ');
      if (this.form.get('newPassword').errors) {
        this.passwordClass = 'input-err';
      }
      if (this.form.get('confirmPassword').errors) {
        this.confirmPClass = 'input-err';
      }
    }
  }
}
