import {Component, EventEmitter, Input, Output} from '@angular/core';
import { AuthService } from 'src/app/lib/auth.service';
import { SharedService } from 'src/app/lib/shared-service';
import {MobileProfileStore} from './mobile-profile.store'

@Component({
    selector: 'app-mobile-profile',
    templateUrl: './mobile-profile.component.html',
    styleUrls: ['./mobile-profile.component.css']
})
export class MobileProfileComponent {
  @Input() parentData;
  @Input() createdCount;
  @Input() clickedMenu;
  @Input() zoneList;
  @Output() planChange = new EventEmitter<any>();
  @Output() clickedExplore = new EventEmitter<any>();
  @Output() clickedDetail = new EventEmitter <any> ();
  @Output() clickedLogout = new EventEmitter <any> ();
  public c_right = "./assets/images/mobile_chevron_right.svg";
  public c_left = "./assets/images/mobile_chevron_left.svg";
  public profileDetail = false;
  public mobileView;
  public mobileViewService;
    constructor(private store: MobileProfileStore, private shared: SharedService, private authService: AuthService) {}
    public parentPortalLink;
    public arrow = '../../assets/images/chevron_right_s.svg';
    ngOnInit() {
      this.parentPortalLink = this.authService.getRedirectParentPortalUrl();
      console.log("zhzhzhzhzhzhz", this.zoneList);

      this.mobileViewService = this.shared.GetMobileView.subscribe(data=>{
        this.mobileView = data;
      })
      this.profileDetail = false;
    }

    ngOnDestory(){
      this.mobileViewService.unsubscribe();
    }

    ngOnChanges(){
      // if(!this.clickedMenu) this.profileDetail = false;
    }

    changePlan(){
      this.planChange.emit(true);
    }

    explore(){
      this.clickedExplore.emit();
    }

    detail(){
      this.clickedDetail.emit();
    }

    logout(){
      this.clickedLogout.emit();
    }

}
