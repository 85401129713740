<!-- <div class="plan">{{ giftCodeAdded ? 'Your gift card' : 'Payment' }} information</div>

<div class="latter">
  <div class="inform" *ngIf="!correctDiscountCode && !giftCodeAdded">Proceed to payment to continue.</div>
  <div class="inform" *ngIf="correctDiscountCode">
    <div class="pink-title">Discount code “{{ discountCode }}” applied.</div>
  </div>
  <div class="inform" *ngIf="giftCodeAdded">
    <div class="pink-title">
      You are about to redeem your “STEMWerkz {{ plan?.planName ? plan?.planName : '---' }} {{ amount }}
      {{ amount > 1 ? 'months' : 'month' }}” gift card!
    </div>
    <div class="text-c300" style="margin-top: 8px">
      To ensure a seamless experience on STEMWerkz, please set up your payment details. Deduction of
      {{ plan?.planMonthly ? plan?.planMonthly : '---' }} {{ plan?.currency | uppercase }}/month will only be made after
      the gift card claim period, from <span style="font-weight: bold" class="text-c300" *ngIf="!giftExpiry">---</span
      ><span style="font-weight: bold" class="text-c300" *ngIf="giftExpiry">{{ giftExpiry | date: 'd LLL  yyyy' }}</span
      >. Don’t worry, if for some reason you don’t love STEMWerkz anymore, you may cancel at any time.
    </div>
  </div>

  <app-button
    [class]="'btn-orange'"
    [loading]="loading"
    (click)="setupPayment()"
    *ngIf="
      (isAuth && isEmpty && giftCodeAdded && unsubscribe != 'true') ||
      (isAuth && !giftCodeAdded && unsubscribe != 'true')
    "
  >
    <app-text [class]="'btn-text-style2'" [loading]="loading" *ngIf="!giftCodeAdded && !correctDiscountCode">
      {{ loading ? 'Proceeding... ' : 'Proceed' }}
    </app-text>
    <app-text [class]="'btn-text-style2'" [loading]="loading" *ngIf="giftCodeAdded">
      {{ loading ? 'Setting up... ' : 'Redeem and setup payment details' }}
    </app-text>
    <app-text [class]="'btn-text-style2'" [loading]="loading" *ngIf="!giftCodeAdded && correctDiscountCode">
      {{ loading ? 'Setting up... ' : 'Setup payment' }}
    </app-text>
  </app-button>

  <app-button
    [class]="'btn-orange'"
    [loading]="loading"
    (click)="setupPayment()"
    *ngIf="isAuth && unsubscribe == 'true'"
  >
    <app-text [class]="'btn-text-style2'" [loading]="loading">
      {{ loading ? 'Subscribing... ' : 'Subscribe' }}
    </app-text>
  </app-button>

  <div *ngIf="isAuth && unsubscribe == 'true'" style="margin-top: 24px">
    <app-button [class]="'btn-cancel'" (click)="expireChange(plan.planId)">
      <app-text [class]="'btn-text-cancel'" [loading]="loading">Change plan</app-text>
    </app-button>
  </div>

  <app-button [class]="'btn-orange'" (click)="signUp()" *ngIf="!isAuth || (isAuth && !isEmpty && giftCodeAdded)">
    <app-text [class]="'btn-text-style2'"> Redeem and setup payment details </app-text>
  </app-button>

  <div *ngIf="giftCodeAdded" style="margin-top: 24px">
    <app-button [class]="'btn-cancel'" (click)="skipPayment()">
      <app-text [class]="'btn-text-cancel'" [loading]="loading">Redeem without payment setup</app-text>
    </app-button>
  </div>

  <div
    *ngIf="giftCodeAdded"
    class="d-flex justify-content-center"
    style="width: 100%; margin-top: 24px; margin-bottom: 34px; cursor: pointer"
    (click)="addGift()"
  >
    <app-text
      [ngClass]="{ 'btn-disable': loading, 'btn-cancel2': !loading }"
      [class]="'btn-text-cancel2'"
      [loading]="loading"
      >Change giftcard</app-text
    >
  </div>

  <div
    *ngIf="!giftCodeAdded"
    class="d-flex justify-content-center"
    style="width: 100%; margin-top: 24px; margin-bottom: 34px; cursor: pointer"
    (click)="cancelPayment()"
  >
    <app-text
      [ngClass]="{ 'btn-disable': loading, 'btn-cancel2': !loading }"
      [class]="'btn-text-cancel2'"
      [loading]="loading"
      >{{ unsubscribe == 'true' ? 'Back' : 'Cancel' }}</app-text
    >
  </div>
  <p class="skip cursor" *ngIf="giftCodeAdded" (click)="cancelPayment()" [ngClass]="{ 'cursor-disable': loading }">
    <strong>Cancel</strong>
  </p>
  <p
    class="code cursor"
    *ngIf="!giftCodeAdded"
    (click)="addDiscount()"
    [ngClass]="{ 'cursor-disable': loading, 'mt-m': unsubscribe == 'true', 'mt-16': unsubscribe != 'true' }"
  >
    <strong>{{ discountText }}</strong>
  </p>
</div> -->

<!-- gift card claim flow -->
<ng-container *ngIf="templateName == 'giftCardClaim' || templateName == 'giftCardClaimWithAuth'">
  <div class="plan">Gift card information</div>
  <div class="latter">
    <div class="inform">
      <div class="pink-title">
        You are about to redeem your “STEMWerkz {{ plan?.planName ? plan?.planName : '---' }} {{ amount }}
        {{ amount > 1 ? 'months' : 'month' }}” gift card!
      </div>
      <div class="text-c300" style="margin-top: 8px">
        To ensure a seamless experience on STEMWerkz, please set up your payment details. Deduction of
        {{ plan?.planMonthly ? plan?.planMonthly : '---' }} {{ plan?.currency | uppercase }}/month will only be made
        after the gift card claim period, from
        <span style="font-weight: bold" class="text-c300" *ngIf="!giftExpiry">---</span>
        <span style="font-weight: bold" class="text-c300" *ngIf="giftExpiry">{{
          giftExpiry | date: 'd LLL  yyyy'
        }}</span
        >. Don’t worry, if for some reason you don’t love STEMWerkz anymore, you may cancel at any time.
      </div>
    </div>

    <app-button [class]="'btn-orange'" (click)="redeemWithPayment()">
      <app-text [class]="'btn-text-style2'" [loading]="loading"> Redeem and setup payment details </app-text>
    </app-button>

    <div style="margin-top: 24px">
      <app-button [class]="'btn-cancel'" (click)="redeemWithoutPayment()">
        <app-text [class]="'btn-text-cancel'" [loading]="loading">Redeem without payment setup</app-text>
      </app-button>
    </div>
    <div
      class="d-flex justify-content-center"
      style="width: 100%; margin-top: 24px; margin-bottom: 34px; cursor: pointer"
      (click)="addGift()"
    >
      <app-text
        [ngClass]="{ 'btn-disable': loading, 'btn-cancel2': !loading }"
        [class]="'btn-text-cancel2'"
        [loading]="loading"
        >Change giftcard</app-text
      >
    </div>
    <p class="skip cursor" *ngIf="giftCodeAdded" (click)="cancelPayment()" [ngClass]="{ 'cursor-disable': loading }">
      <strong>Cancel</strong>
    </p>
  </div>
</ng-container>

<!-- empty normal -->
<ng-container
  *ngIf="
    templateName == 'emptyNormal' ||
    templateName == 'changeTrial' ||
    templateName == 'changePaid' ||
    templateName == 'emptyVendor' ||
    templateName == 'emptyFromStart'
  "
>
  <div class="plan">Payment information</div>

  <div class="latter">
    <div class="inform" *ngIf="!correctDiscountCode">Proceed to payment to continue.</div>
    <div class="inform" *ngIf="correctDiscountCode">
      <div class="pink-title">Discount code “{{ discountCode }}” applied.</div>
    </div>
    <app-button [class]="'btn-orange'" [loading]="loading" (click)="setupPayment()">
      <app-text [class]="'btn-text-style2'" [loading]="loading">
        {{ loading ? 'Proceeding... ' : 'Proceed' }}
      </app-text>
    </app-button>
    <div *ngIf="isAuth && unsubscribe == 'true'" style="margin-top: 24px">
      <app-button [class]="'btn-cancel'" (click)="expireChange(plan.planId)">
        <app-text [class]="'btn-text-cancel'" [loading]="loading">Change plan</app-text>
      </app-button>
    </div>
    <div
      class="d-flex justify-content-center"
      style="width: 100%; margin-top: 24px; margin-bottom: 34px; cursor: pointer"
      (click)="cancelPayment()"
    >
      <app-text
        [ngClass]="{ 'btn-disable': loading, 'btn-cancel2': !loading }"
        [class]="'btn-text-cancel2'"
        [loading]="loading"
        >Cancel</app-text
      >
    </div>
    <p
      class="code cursor"
      (click)="addDiscount()"
      [ngClass]="{ 'cursor-disable': loading, 'mt-m': unsubscribe == 'true', 'mt-16': unsubscribe != 'true' }"
    >
      <strong>{{ discountText }}</strong>
    </p>
  </div>
</ng-container>
