import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-unsubscribe-popup',
  templateUrl: './unsubscribe-popup.component.html',
  styleUrls: ['./unsubscribe-popup.component.css']
})
export class UnsubscribePopupComponent implements OnInit {
  @Output() closeBtn = new EventEmitter<any>();
  @Output() unsubscribePlayerBtn = new EventEmitter<any>();
  @Input() playerDetail;
  @Input() expireDate;
  public pleaseWait = false;
  public btnStatus = 'btn-red';

  constructor() {}

  ngOnInit() {
    let body = (document.getElementsByTagName('body')[0].style.overflow = 'hidden');
    window.scrollTo(0, 0);
  }
  ngOnDestroy() {
    let body = (document.getElementsByTagName('body')[0].style.overflow = 'auto');
  }
  closeDelete($event) {
    document.getElementById('outer-frame').classList.add('outer-frame-close');
    setTimeout(() => {
      this.closeBtn.emit($event);
    }, 100);
  }
  deletePlayer($event) {
    this.pleaseWait = true;
    this.btnStatus = 'btn-red waiting not-allowed';
    console.log('this is delete');
    this.unsubscribePlayerBtn.emit();
  }
}
