import {BusinessLogicRequirements} from '../presentation/business-logic.requirements';
import {NgModule} from '@angular/core';
import {DataRequirementsInjectionToken} from './data.requirements';
import {DataFacade} from '../data/data.facade';
import { GetDiscounntInfoUseCase } from './use-cases/get-discount-info.use-case';
import { Observable, of } from 'rxjs';
import { DiscountCodeMapper } from './mappers/discountcode.mapper';

@NgModule({
    imports: [DataFacade],

    providers: [
        {
            provide: DataRequirementsInjectionToken,
            useClass: DataFacade
        },
        DiscountCodeMapper
    ]
})
export class BusinessLogicFacade implements BusinessLogicRequirements {
  constructor(
    private getDiscounntInfoUseCase: GetDiscounntInfoUseCase
  ) {}
  getDiscountInfo(code): Observable<any>{
    return this.getDiscounntInfoUseCase.run(code);
  }
}
