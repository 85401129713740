<div class="overlay d-flex justify-content-center">
  <div class="success">
    <div class="d-flex justify-content-center" style="margin-bottom: 24px;">
      <div class="spinner-border text-success" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="body">
      Please wait for the processing to complete.
    </div>
  </div>
</div>
