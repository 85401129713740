import { DataRequirements } from '../data.requirements';
import * as i0 from "@angular/core";
import * as i1 from "../data.requirements";
import * as i2 from "../mappers/player-list.mapper";
export class GetPlayersUseCase {
    constructor(data, playermap) {
        this.data = data;
        this.playermap = playermap;
    }
    run(id) {
        return this.data.getPlayers(id).pipe(this.playermap.map);
    }
}
GetPlayersUseCase.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetPlayersUseCase_Factory() { return new GetPlayersUseCase(i0.ɵɵinject(i1.DataRequirementsInjectionToken), i0.ɵɵinject(i2.PlayerListMapper)); }, token: GetPlayersUseCase, providedIn: "root" });
