import { Component, OnInit, Input, HostListener, Inject, NgModule, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-discount-popup',
  templateUrl: './discount-popup.component.html',
  styleUrls: ['./discount-popup.component.css']
})
export class DiscountPopupComponent implements OnInit {
  @Output() discountCancel = new EventEmitter<any>();
  @Output() discountContinue = new EventEmitter<any>();
  public alertURL = './assets/images/alert-icon.svg';
  @Input() discountCode = '';
  constructor(private fb: FormBuilder) {}
  public form = this.fb.group({
    disCode: this.fb.control('', [Validators.required])
  });
  public disable = false;

  ngOnInit() {
    let body = (document.getElementsByTagName('body')[0].style.overflow = 'hidden');
    window.scrollTo(0, 0);
    this.form.setValue({
      disCode: this.discountCode
    });
    this.form.valueChanges.subscribe((value) => {
      console.log(value);
    });
  }
  cancelDiscount($event) {
    console.log('cancel');
    document.getElementById('discount').classList.add('discount-close');
    setTimeout(() => {
      this.discountCancel.emit();
    }, 100);
  }
  continueDiscount($event) {
    console.log(this.form.get('disCode').value);
    // this.disable = true;
    // if(this.discountCode == this.form.get("disCod").value)
    this.discountContinue.emit(this.form.get('disCode').value);
    // else{
    // this.disable = false;
    // }
  }
  ngOnDestroy() {
    let body = (document.getElementsByTagName('body')[0].style.overflow = 'auto');
  }
}
