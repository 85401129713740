import { Inject, Injectable } from '@angular/core';

import { DataRequirements, DataRequirementsInjectionToken } from '../data.requirements';
import { ParentDetailMapper } from '../mappers/parent-detail.mapper';

@Injectable({
  providedIn: 'root'
})
export class GetParentDetailUseCase {
  constructor(
    private mapper: ParentDetailMapper,
    @Inject(DataRequirementsInjectionToken) private data: DataRequirements
  ) {}

  run(id) {
    console.log('dude is here');
    return this.data.getParentDetail(id).pipe(this.mapper.map);
  }
}
