import { Inject, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/lib/shared-service';
import { BusinessLogicRequirements, BusinessRequirementsInjectionToken } from '../business-logic.requirements';
import { PageNotFoundHomeStore } from './page-not-found-home.store';

@Component({
  selector: 'app-page-not-found-home',
  templateUrl: './page-not-found-home-controller.component.html',
  styleUrls: ['./page-not-found-home-controller.component.css']
})
export class PageNotFoundHomeControllerComponent {
  constructor(
    @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements,
    private store: PageNotFoundHomeStore,
    private router: Router,
    private route: ActivatedRoute,
    private shared: SharedService
  ) {}

  public param;
  public url;

  ngOnInit() {
    console.log(this.router.url);
    var vendor = this.router.url.slice(8, this.router.url.length).trim();
    console.log(vendor);
    if (vendor == 'userupdate') this.param = '';
    else this.param = vendor.toLocaleUpperCase();
    this.url = this.router.url.slice(0, 8);
    console.log(this.param, this.url);
    if (this.url == '/signup_' && this.param != '' && this.url != undefined && this.url != null) {
      this.router.navigateByUrl('/signup?vendor=' + this.param);
    } else if (this.url == '/signup_' && this.param == '') {
      this.router.navigateByUrl('/signup?userupdate=' + true);
    } else {
      this.router.navigateByUrl('/signup');
    }
  }
}
