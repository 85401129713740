<!-- <div class="d-flex justify-content-between align-item-center mb-24" *ngIf="!explore">
  <app-logo></app-logo>
  <div
    class="profile d-flex justify-content-center align-item-center"
    [ngStyle]="{
      'background-image': 'url(' + parentData?.profileImage + ')',
      'background-repeat': 'no-repeat',
      'background-size': 'cover'
    }"
  >
    <app-text (click)="settingDropdown()" class="profile-pw">
      {{ parentData?.shortName }}
    </app-text>
    <div *ngIf="setting" class="setting-dropdown">
      <div class="outline">
        <app-text class="account">You are currently logged in with your parent account.</app-text>
      </div>
      <div (click)="profilePopup($event)" style="padding: 16px 16px 8px 16px;"><app-text class="ur-pf">Your profile</app-text></div>
      <div (click)="Logout()" style="padding: 8px 16px 16px 16px;"><app-text class="logout">Log out</app-text></div>
    </div>
  </div>
</div> -->

<div class="d-flex justify-content-between align-item-center mobile-header" *ngIf="mobileView">
  <app-logo *ngIf="!clickedMenu"></app-logo>
  <div *ngIf="clickedMenu"></div>
  <img class="cursor" [src]="hamburger" (click)="showMenu()">
</div>
<div class="mobile-profile" id="mobile-profile">
  <app-mobile-profile [parentData]="parentData" [zoneList]="zoneList" [createdCount]="createdCount" [clickedMenu]="clickedMenu" (planChange)="planChange($event)" (clickedExplore)="clickedExplore()" (clickedDetail)="clickedDetail()" (clickedLogout)="Logout()"></app-mobile-profile>
</div>

<div class="d-flex justify-content-between align-item-center mb-24" *ngIf="!mobileView">
  <app-logo></app-logo>
  <div class="d-flex">
    <!-- <div class="btn-yellow d-flex align-items-center justify-content-center" *ngIf="explore">
      <div class="d-flex justify-content-between" style="cursor: pointer;" >
        <app-text [class]="'btn-yellow-text'" (click)="clickedExplore()">
          Go to Parent Portal
        </app-text>
        
      </div>
      <app-image [src]="goto"></app-image>
    </div> -->
    <app-button style="margin-right: 20px;" [class]="'btn-parent'" *ngIf="explore" (click)="clickedPortal()" (mouseenter)="enter()" (mouseleave)="leave()">
      <app-text [class]="'btn-parent-text'">Go to Parent Portal</app-text>
      <app-image [src]="goto" [width]="'20px'" [height]="'20px'" [maxWidth]="'20px'"></app-image>
    </app-button>
    <div class="d-flex align-items-center">
      <div class="d-flex justify-content-center" style="cursor: pointer;margin-right: 36px;" *ngIf="explore">
        <app-text class="btn-cancel" [class]="'btn-text-cancel'" (click)="clickedExplore()" style="padding: 8px 12px;">
          Explore STEMWerkz!
        </app-text>
      </div>
      <div
      class="profile d-flex justify-content-center align-item-center"
      [ngStyle]="{
        'background-image': 'url(' + parentData?.profileImage + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover'
      }"
    >
      <app-text (click)="settingDropdown()" class="profile-pw">
        {{ parentData?.shortName }}
      </app-text>
      <div *ngIf="setting" class="setting-dropdown">
        <div class="outline">
          <app-text class="account">You are currently logged in with your parent account.</app-text>
        </div>
        <div (click)="profilePopup($event)" style="padding: 16px 16px 8px 16px;"><app-text class="ur-pf">Your profile</app-text></div>
        <div (click)="Logout()" style="padding: 8px 16px 16px 16px;"><app-text class="logout">Log out</app-text></div>
      </div>
    </div>
    </div>
  </div>

</div>

