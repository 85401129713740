<div class="signup-box">
  <div class="signup-header-div">
    <app-text [class]="'signup-header'">Welcome to STEMWerkz</app-text><br>
    <app-text [class]="'signup-text5'" style="margin-top: 8px;">Enter the code you received from your school to unlock special discount</app-text>
  </div>

  <form (keyup.enter)="$event.preventDefault();onUnlock($event)">
    <div class="signup-input-div">
      <app-text [class]="'signup-label'">Code</app-text>
      <app-input
        [hint]="'Enter the code given by your school'"
        [control]="form.get('code')"
        [width]="'100%'"
        [class]="codeClass"
        (blurEvent)="blurInput($event)"
      >
        <app-image
          *ngIf="(!form.get('code').errors && uTick) || (clickBtn && appliedCode)"
          [src]="tickURL"
        ></app-image>
        <app-image
          *ngIf="((form.get('code').errors) && clickBtn) || (clickBtn && errText && !appliedCode)"
          [src]="alertURL"
        ></app-image>
      </app-input>
      <app-text *ngIf="errText && !appliedCode && clickBtn" [class]="'form-error-label'">
        {{errText}}
      </app-text>
    </div>
  </form>
  <div style="margin-top:8px" *ngIf="!errText && appliedCode && clickBtn">
    <app-text [class]="'form-red-label'">
      Discount for {{schoolName}} applied.
    </app-text>
  </div>

  <div class="signup-btn-div" (click)="onUnlock($event)">
    <app-button [class]="'btn-orange'" [loading]="pleasewait" >
      <app-text [class]="'btn-text-style2'" [loading]="pleasewait"> {{ pleasewait ? 'Please wait...' : btnText}} </app-text>
    </app-button>
  </div>
</div>
