import {Inject, Injectable} from '@angular/core';
import { DataRequirementsInjectionToken, DataRequirements } from '../data.requirements';
import { SubscriptionMapper } from "../mappers/subscription-plans-mapper";

@Injectable({
    providedIn: 'root'
})
export class GetPlanUsecase {
    constructor(
        private mapper: SubscriptionMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run(id) {
        return this.data.getPlan(id).pipe(this.mapper.map);
    }
}
