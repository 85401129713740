<div *ngIf="plan" class="plan-card bg-s0 d-flex flex-column cursor mb-2 text-c300"
[ngClass]="plan.selected? 'selected-card bg-accent500 text-s0' : 'non-selected-card'"  (click)="onClickCard()">
    <div class="body-l font-weight-bold mb-1 d-flex align-items-center">
        <div class="radio-box mr-3">
            <!-- <app-image *ngIf="" [src]="'./assets/images/giftcard_Tick.svg'"></app-image> -->
        </div>
        <app-text>A gift for a parent with {{plan.playerSlot>1? plan.playerSlot + ' kids' : plan.playerSlot + ' kid'}}</app-text>
    </div>
    <div class="light-body-m text-c200 d-flex align-items-baseline ml-36">
        <app-text>{{plan.planName}}</app-text>
    </div>
</div>
