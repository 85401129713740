import {BusinessLogicRequirements} from '../presentation/business-logic.requirements';
import {NgModule} from '@angular/core';
import {DataRequirementsInjectionToken} from './data.requirements';
import {DataFacade} from '../data/data.facade';
import {DataRequirementsFake} from './data.requirements.fake';
import { from, Observable, of } from 'rxjs';

import {SubscriptionPlanListUseCase} from './use-cases/subscription-plan-list.use-case';
import {GiftCardListUseCase} from './use-cases/gift-card-list.use-case';
import {PurchaseGiftCardUseCase} from './use-cases/purchase-gift-card.use-case';
import {GetPublishKeyUseCase} from './use-cases/get-publish-key.use-case';
import {GiftCardDetailsUseCase} from './use-cases/gift-card-details.use-case';

import {SubscriptionPlanListMapper} from './mappers/subscription-plan-list.mapper';
import {GiftCardListMapper} from './mappers/gift-card-list.mapper';
import {PurchaseGiftMapper} from './mappers/purchase-gift.mapper';
import {PublishKeyMapper} from './mappers/publish-key.mapper';
import {GiftCardDetailMapper} from './mappers/gift-card-detail.mapper';


@NgModule({
    imports: [DataFacade],

    providers: [
        {
            provide: DataRequirementsInjectionToken,
            useClass: DataFacade
            // useClass: DataRequirementsFake
        },
        SubscriptionPlanListMapper,
        GiftCardListMapper,
        PurchaseGiftMapper,
        PublishKeyMapper,
        GiftCardDetailMapper
    ]
})
export class BusinessLogicFacade implements BusinessLogicRequirements {
    constructor(
      private subscriptionPlanListUseCase: SubscriptionPlanListUseCase,
      private giftCardListUseCase: GiftCardListUseCase,
      private purchaseGiftCardUseCase: PurchaseGiftCardUseCase,
      private getPublishKeyUseCase: GetPublishKeyUseCase,
      private giftCardDetailsUseCase: GiftCardDetailsUseCase
    ) {}

    getAllPlans():Observable<any>{
      return this.subscriptionPlanListUseCase.run();
    }

    getAllGiftCards():Observable<any>{
      return this.giftCardListUseCase.run();
    }

    purchaseGift(data):Observable<any> {
      return this.purchaseGiftCardUseCase.run(data);
    }

    getPublishKey(): Observable<any> {
      return this.getPublishKeyUseCase.run();
    }

    getGiftCardDetail(id):Observable<any>{
      return this.giftCardDetailsUseCase.run(id);
    }
}
