import {InjectionToken} from '@angular/core';
import { Observable } from 'rxjs';

export interface BusinessLogicRequirements {
  getAllPlans():Observable<any>;
  getAllGiftCards():Observable<any>;
  purchaseGift(data):Observable<any>;
  getPublishKey(): Observable<any>;
  getGiftCardDetail(id):Observable<any>;
}

export const BusinessRequirementsInjectionToken = new InjectionToken<BusinessLogicRequirements>('gift-card-shop Business Requirements')
