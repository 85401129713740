import { Inject, Injectable } from "@angular/core";
import {
  DataRequirements,
  DataRequirementsInjectionToken,
} from "../data.requirements";
import { PlayerDetailMapper } from '../mappers/player-detail-mapper';
import { SubscriptionMapper } from "../mappers/subscription-plans-mapper";

@Injectable({
  providedIn: "root",
})
export class SubscriptionPlansUseCase {
  constructor(
    private mapper: SubscriptionMapper,
    @Inject(DataRequirementsInjectionToken) private data: DataRequirements
  ) {}

  run() {
    return this.data.checkPlan().pipe(this.mapper.map);
    // return this.data.checkPlan();
    // return of (true);
  }
}

