import { Inject, Component } from "@angular/core";
import {
  BusinessLogicRequirements,
  BusinessRequirementsInjectionToken,
} from "../business-logic.requirements";
import { HomeLoginStore } from "./home-login.store";
import { Router } from "@angular/router";
import { AuthService } from "../../../lib/auth.service";

@Component({
  selector: "app-home-login",
  templateUrl: "./home-login-controller.component.html",
  styleUrls: ["./home-login-controller.component.css"],
})
export class HomeLoginControllerComponent {
  public userName="";
  public showRedirectPopup = false;
  public channelUrl = "";
  public valleyUrl = "";
  public academyUrl = "";
  public dashboardUrl = "";
  public questUrl = "";
  public ClickSignup = false;

  constructor(
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements,
    private store: HomeLoginStore,
    private router: Router,
    private _authService: AuthService
  ) {}

  ngOnInit() {
    const isAuth = this._authService.isAuthenticated();
    console.log("this.authData ", isAuth);
    if (isAuth) {
      const userInfo = this._authService.getUserInfo();
      const parentInfo = this._authService.getParentObject();
      if(userInfo.UserType == 5){
        this.router.navigateByUrl(`/dashboard`);
      } else if(userInfo.UserType == 3){
        this.router.navigateByUrl("/login");
        // this.showRedirectPopup = true;
        this.channelUrl = this._authService.getRedirectChannelUrl();
        this.valleyUrl = this._authService.getRedirectValleyUrl();
        this.academyUrl = this._authService.getRedirectAcademyUrl();
        this.dashboardUrl =  this._authService.getRedirectDashboardUrl();
        this.questUrl =  this._authService.getRedirectQuestUrl();
        this.showRedirectPopup = true;
        this.userName = parentInfo.username;
      }
      //this.router.navigateByUrl(`/dashboard`);
    }
  }
  checkRedirectPopup(data){
    console.log('checkRedirectPopup', data);
    console.log(data.academyUrl);
    this.showRedirectPopup = true;
    this.dashboardUrl = data.dashboardUrl;
    this.channelUrl = data.channelUrl;
    this.valleyUrl = data.valleyUrl;
    this.academyUrl = data.academyUrl;
    this.questUrl = data.questUrl;
    this.userName = data.userName;
    //localStorage.removeItem('authData');
  }
  onClickPoupBtn($event){
    console.log('onClickPoupBtn');
    //this.router.navigateByUrl("");
  }
  onClickLogoutBtn($event){
    console.log('onClickLogoutBtn');
    var ref;
    if (localStorage.getItem('ref'))
      ref = localStorage.getItem('ref');
  localStorage.clear();
  localStorage.setItem('ref',ref);
    this.showRedirectPopup = false;
    window.location.reload();
    // this.router.navigateByUrl("/login");
    // this.ngOnInit();
  }
  closeRedirectPopup(){
    this.showRedirectPopup = false;
  }
  buttonClickSignup() {
    this.ClickSignup = true;
  }
}
