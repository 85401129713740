import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GlobalComponentsModule} from '../../../global-components/global-components.module';
import {SchoolSignupControllerComponent} from './school-signup-controller.component';
import {RouterModule} from '@angular/router';
import { SignupModule } from '../signup/signup.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';

@NgModule({
    declarations: [
        SchoolSignupControllerComponent
    ],
    exports: [
        SchoolSignupControllerComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GlobalComponentsModule,
        SharedComponentsModule,
        SignupModule
    ]
})
export class SchoolSignupModule {}
