<div *ngIf="!multiline && angularMask">
  <div class="d-flex input-div">
    <input
      [class]="class"
      [ngClass]="ngClass"
      #single
      [formControl]="control"
      [placeholder]="hint"
      *ngIf="!multiline"
      [type]="type"
      [readonly]="readonly"
      [ngStyle]="{
        width: width ? width : 'auto',
        height: height ? height : 'auto',
        'text-align': textAlign ? textAlign : 'left'
      }"
      [value]="value"
      (blur) = "onBlur($event)"
      (keyup) = "keyUp($event)"
      [textMask]="angularMask"
    />
    <span class="content-div"> <ng-content></ng-content></span>
  </div>
</div>
<div *ngIf="!multiline && angularMask == null ">
  <div class="d-flex input-div">
    <input
      [class]="class"
      [ngClass]="ngClass"
      #single
      [formControl]="control"
      [placeholder]="hint"
      *ngIf="!multiline"
      [type]="type"
      [readonly]="readonly"
      [ngStyle]="{
        'width': width ? width : 'auto',
        'height': height ? height : 'auto',
        'text-align': textAlign ? textAlign : 'left'
      }"
      [value]="value"
      (blur) = "onBlur($event)"
      (keyup) = "keyUp($event)"
    />
    <span class="content-div"> <ng-content></ng-content></span>
  </div>
</div>
<div *ngIf="multiline">
  <textarea
    [class]="class"
    #multi
    [formControl]="control"
    [rows]="rows"
    *ngIf="multiline"
    [placeholder]="hint"
    [readonly]="readonly"
    [ngStyle]="{ width: width ? width : 'auto', height: height }"
  ></textarea>
</div>

