import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlayersService {
  constructor(private http: HttpClient) {}
  private baseUrl = environment.apiurl;

  getUser(id) {
    let url = this.baseUrl + '/api/v2/users/' + id + '/players';
    return this.http.get(url).pipe(
      map((res: Response) => {
        let result = res;
        return result;
      })
    );
  }
  getParent(parentId) {
    let url = this.baseUrl + "/api/v2/users/" + parentId;
    console.log(url);
    return this.http.get(url).pipe(
      map((res: Response) => {
        // let result = [];
        let result = res;
        console.log(result);
        return result;
      })
    );
  }
}
