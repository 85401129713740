/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-info.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../subscription-history/subscription-history.component.ngfactory";
import * as i3 from "../subscription-history/subscription-history.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../../../lib/shared-service";
import * as i6 from "../payment-history/payment-history.component.ngfactory";
import * as i7 from "../payment-history/payment-history.component";
import * as i8 from "@angular/common";
import * as i9 from "./payment-info.component";
import * as i10 from "./payment-info.store";
var styles_PaymentInfoComponent = [i0.styles];
var RenderType_PaymentInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentInfoComponent, data: {} });
export { RenderType_PaymentInfoComponent as RenderType_PaymentInfoComponent };
function View_PaymentInfoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "main-div d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-subscription-history", [], null, [[null, "subscribeBtn"], [null, "cancelSubscribe"], [null, "planChange"], [null, "accountChange"], [null, "resubscribePlan"], [null, "blueDiv"], [null, "redDiv"], [null, "boxTypeEmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("subscribeBtn" === en)) {
        var pd_0 = (_co.subscription($event) !== false);
        ad = (pd_0 && ad);
    } if (("cancelSubscribe" === en)) {
        var pd_1 = (_co.cancel($event) !== false);
        ad = (pd_1 && ad);
    } if (("planChange" === en)) {
        var pd_2 = (_co.changPlan() !== false);
        ad = (pd_2 && ad);
    } if (("accountChange" === en)) {
        var pd_3 = (_co.changeAccount() !== false);
        ad = (pd_3 && ad);
    } if (("resubscribePlan" === en)) {
        var pd_4 = (_co.resubscribePlan($event) !== false);
        ad = (pd_4 && ad);
    } if (("blueDiv" === en)) {
        var pd_5 = (_co.blueDiv($event) !== false);
        ad = (pd_5 && ad);
    } if (("redDiv" === en)) {
        var pd_6 = (_co.redDiv() !== false);
        ad = (pd_6 && ad);
    } if (("boxTypeEmit" === en)) {
        var pd_7 = (_co.boxTypeEmit($event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, i2.View_SubscriptionHistoryComponent_0, i2.RenderType_SubscriptionHistoryComponent)), i1.ɵdid(2, 770048, null, 0, i3.SubscriptionHistoryComponent, [i4.Router, i5.SharedService, i1.ChangeDetectorRef], { parentData: [0, "parentData"], currentPlan: [1, "currentPlan"] }, { subscribeBtn: "subscribeBtn", cancelSubscribe: "cancelSubscribe", planChange: "planChange", accountChange: "accountChange", resubscribePlan: "resubscribePlan", blueDiv: "blueDiv", redDiv: "redDiv", boxTypeEmit: "boxTypeEmit" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.player; var currVal_1 = _co.currentPlan; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_PaymentInfoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "main-div d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-payment-history", [], null, [[null, "clickedPayment"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickedPayment" === en)) {
        var pd_0 = (_co.clickedPayment() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_PaymentHistoryComponent_0, i6.RenderType_PaymentHistoryComponent)), i1.ɵdid(2, 114688, null, 0, i7.PaymentHistoryComponent, [i5.SharedService], { player: [0, "player"], onlyPaymentHistory: [1, "onlyPaymentHistory"] }, { clickedPayment: "clickedPayment" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.player; var currVal_1 = _co.onlyPaymentHistory; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_PaymentInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentInfoComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentInfoComponent_2)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.player == null) ? null : _co.player.subscription.length) > 0) && !_co.onlyPaymentHistory); _ck(_v, 1, 0, currVal_0); var currVal_1 = (((_co.player == null) ? null : _co.player.payment.length) > 0); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_PaymentInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-info", [], null, null, null, View_PaymentInfoComponent_0, RenderType_PaymentInfoComponent)), i1.ɵdid(1, 114688, null, 0, i9.PaymentInfoComponent, [i10.PaymentInfoStore], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentInfoComponentNgFactory = i1.ɵccf("app-payment-info", i9.PaymentInfoComponent, View_PaymentInfoComponent_Host_0, { playerDetail: "playerDetail", loading: "loading", player: "player", currentPlan: "currentPlan", onlyPaymentHistory: "onlyPaymentHistory" }, { subscribeClick: "subscribeClick", unsubscribeClick: "unsubscribeClick", accountManagement: "accountManagement", planChange: "planChange", resubscribe: "resubscribe", showBlue: "showBlue", showRed: "showRed", hideAll: "hideAll", outputBoxType: "outputBoxType" }, []);
export { PaymentInfoComponentNgFactory as PaymentInfoComponentNgFactory };
