import {GiftCardDetailModel} from '../../../models/gift-card-detail.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class GiftCardDetailMapper extends ItemMapper<GiftCardDetailModel> {
  protected mapFromItem(item): GiftCardDetailModel {
      console.log(item);
      const giftCard = new GiftCardDetailModel();
      giftCard._id = item._id;
      giftCard.posterImageUrl = item.posterImageUrl != undefined ? item.posterImageUrl : '';
      giftCard.message = item.message;
      giftCard.amount = item.amount;
      giftCard.recipientName = item.recipientName;
      giftCard.recipientEmail = item.recipientEmail;
      giftCard.senderName = item.senderName;
      giftCard.senderEmail = item.senderEmail;
      giftCard.planId = item.subscriptionId._id;
      giftCard.planName = item.subscriptionId.name;
      giftCard.planInterval = item.subscriptionId.interval;
      giftCard.playerSlot = item.subscriptionId.playerSlot;
      return giftCard;
  }
}
