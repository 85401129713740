import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ParentApiService {
    constructor(http) {
        this.http = http;
        this.baseUrl = environment.apiurl;
    }
    getParent(parentId) {
        let url = this.baseUrl + '/api/v2/users/' + parentId;
        console.log(url);
        return this.http.get(url).pipe(map((res) => {
            let result = res;
            console.log(result);
            return result;
        }));
    }
    editParent(parentId, parentData) {
        let url = this.baseUrl + '/api/v2/users/' + parentId;
        console.log(url);
        console.log(parentData);
        return this.http.put(url, parentData).pipe(map((res) => {
            let result = res;
            console.log(res);
            return result;
        }));
    }
    checkPlan() {
        let url = this.baseUrl + '/api/v2/users/current-subscription';
        return this.http.get(url).pipe(map((res) => {
            let result = res;
            console.log(result);
            return result;
        }));
        // const obj = {'playLimit':4};
        // return of(obj);
    }
    emailCopy(id, pw) {
        let url = this.baseUrl + '/api/v2/users/email-playerinfo';
        let body = {
            playerUserId: id,
            password: pw
        };
        console.log(url);
        return this.http.post(url, body).pipe(map((res) => {
            let result = res;
            console.log(result);
            return result;
        }));
    }
}
ParentApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ParentApiService_Factory() { return new ParentApiService(i0.ɵɵinject(i1.HttpClient)); }, token: ParentApiService, providedIn: "root" });
